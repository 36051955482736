import React, { useState } from 'react'
import PanelHeader from 'components/PanelHeader/PanelHeader'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import SearchSecurityAlert from './SearchSecurityAlert'
import ViewSecurityAlert from './ViewSecurityAlert'
import SecurityAlert from 'assets/img/Icons/Privacy/Security_Alert.png'
const Transactions = () => {
  const [isSearch , setIsSearch] = useState(false)
  const [sendData, setSendData] = useState('')
  const searchFilter = (value) => {
    setSendData(value)
  }
  return (
    <>
      <PanelHeader size="sm" className="scroll-top" />
      <div className="content society-details-container">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  <img
                    src={SecurityAlert}
                    alt="MntBilling"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <span className="head-title">Security Alert</span>
              </CardHeader>
              <CardBody>
                <SearchSecurityAlert searchFilter={searchFilter} setIsSearch={() => setIsSearch(!isSearch)}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ViewSecurityAlert sendData={sendData} onTap={isSearch}/>
      </div>
    </>
  )
}

export default Transactions
