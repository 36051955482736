import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
//import FileBase64 from 'react-file-base64';
import { Spin, Select } from 'antd'
import { addGateway, resetAddGateway } from 'redux/actions/addGatewayAction'
import { getGateway, resetGetGateway } from 'redux/actions/getGatewayAction'
import Util from 'validations'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { getSocietyList } from 'redux/actions/getSocietyListAction'

const AddGateway = () => {
  // UseStates
  const [formData, setFormData] = useState({
    ownerId: '',
    intType: '',
    currency: '',
    intName: '',
    memUserName: '',
    memId: '',
    memSecretKey: '',
    partId: '',
    partKey: '',
    partUserName: '',
    termId: '',
    toType: '',
    payType: '',
    payBrand: '',
    payMode: '',
    transUrl: '',
    redirectUrl: '',
    checkoutTransUrl: '',
    withdrawUrl: '',
    authTokenUrl: '',
    displayName: '',
  })
  const {
    ownerId,
    intType,
    currency,
    intName,
    memUserName,
    memId,
    memSecretKey,
    partId,
    partKey,
    partUserName,
    termId,
    toType,
    payType,
    payBrand,
    payMode,
    transUrl,
    redirectUrl,
    checkoutTransUrl,
    withdrawUrl,
    authTokenUrl,
    displayName,
  } = formData

  const [societyName, setSocietyName] = useState('')

  // UseSelectors
  const { Option } = Select
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  // useSelector
  const addGatewaySel = useSelector(({ addGateway }) => addGateway)
  const getSocietyListDrop = useSelector(({ getSocietyList }) => getSocietyList)

  console.log(getSocietyListDrop, 'getSocietyListDrop')

  // Requests
  const getSocietyListRequest = () => {
    const getSocietyListReq = {
      initiatedBy: login.data.user.role,
      societyName: societyName
    }
    return dispatch(getSocietyList(getSocietyListReq))
  }

  useEffect(() => {
    getSocietyListRequest()
  }, [societyName])

  const addGatewayListRequest = () => {
    const addGatewayListReq = {
      initiatedBy: login.data.user.role,
      action: 'add',
      walletOwnerId: ownerId,
      acctPartnerId: partId,
      accName: memUserName,
      integrationName: intName.trim(),
      acctTerminalId: termId,
      acctToId: memId,
      integrationType: intType,
      acctPartnerKey: partKey,
      acctPartnerUsername: partUserName.trim(),
      acctToType: toType.trim(),
      acctRedirectUrl: redirectUrl,
      acctMerchantSecKey: memSecretKey,
      acctPaymentType: payType.trim(),
      acctPaymentBrand: payBrand.trim(),
      acctPaymentMode: payMode.trim(),
      acctTransactionUrl: transUrl,
      acctSdkTransactionUrl: checkoutTransUrl,
      accTerminalCurrency: currency,
      acctWithdrawUrl: withdrawUrl,
      acctAuthTokenUrl: authTokenUrl,
      displayName: displayName,
    }
    return dispatch(addGateway(addGatewayListReq))
  }
  const getGatewayListRequest = () => {
    const getGatewayListReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getGateway(getGatewayListReq))
  }
  // UseEffects
  useEffect(() => {
    if (addGatewaySel.success) {
      setFormData('')
    }
    if (addGatewaySel.failed) {
      NotificationPopups('error', 'Failed', (addGatewaySel.data.errorVOList.map((d,id)=> <>{id === 0 ? 1 : id+1}-{d.errorMessage}<br/></>)))
    }
    if (addGatewaySel.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddGateway())
    }
  }, [addGatewaySel.success, addGatewaySel.failed, addGatewaySel.network])

  // Declarations
  const currencyDrop = ['INR']
  const IntDrop = ['API', 'Checkout', 'Both']

  const onInputChange = ({ target: { name, value } }, type) => {
    const obj = {
      ...formData,
    }
    // obj[name] = value
    // setFormData(obj)
    // validations
    if (type == 'number') {
      obj[name] = Util.verifyMobile(value) ? value : obj[name]
      // setFormData(obj)
    } else if (type == 'alphabet') {
      obj[name] = Util.alphabetsOnly(value) ? value : obj[name]
    } else if (type == 'both') {
      obj[name] = Util.alphaNumeric(value) ? value : obj[name]
    } else {
      // obj[name] = value.trim()
      obj[name] = Util.noWhiteSpace(value) ? value : obj[name]
    }
    // else if(type == 'both') {
    //   obj[name] = Util.alphaNumeric(value) ? value : obj[name]
    // }
    // else {
    //   obj[name] = value
    // }
    console.log({ name, value })
    setFormData(obj)
    // type == "number" && Util.verifyMobile(value) && obj[name]
    // console.log(type);

    // obj[name] = type === "number" ? Util.verifyMobile(value) ? value : obj[name] : obj[name] = type === "" && Util.verifyAlpahbet(value) ? value : obj[name]
    // obj[name] = type === "alphabet" && Util.verifyAlpahbet(value) ? value : obj[name]
    // obj[name] = type === "both" ? Util.alphaNumeric(value) ? value : obj[name] : ""
    // setFormData(obj)
    // switch(type){
    //   case "number" :
    //   return Util.verifyMobile(value) && obj[name]
    //   case "alphabet" :
    //   return Util.verifyAlphabet(value) && obj[name]
    //   default :
    //   return Util.alphaNumeric(value) && obj[name]
    // }
    // setFormData(obj);
    // setFormData({ ...formData, [e.target.name] : [e.target.value] })
    // console.log(type);
  }
  function onSelectOwner(value) {
    console.log(value, 'OwnerId')
    const obj = {
      ...formData,
    }
    obj['ownerId'] = value.split('-')[0]
    obj['displayName'] = value.split('-')[1]
    // console.log(formData, "formData");
    setSocietyName('')
    setFormData(obj)
  }

  function onSearch(val) {
    setSocietyName(val)
  }

  function onSelectInt(IntDrop, { value }) {
    console.log(IntDrop, 'IntDrop')
    console.log(value, 'Integration')
    const obj = {
      ...formData,
    }
    obj['intType'] = value
    console.log(formData, 'formData')

    setFormData(obj)
  }
  function onSelectCurrency(currencyDrop, { value }) {
    console.log(currencyDrop, 'currencyDrop')
    console.log(value, 'Currency')
    const obj = {
      ...formData,
    }
    obj['currency'] = value
    console.log(formData, 'formData')

    setFormData(obj)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addGatewayListRequest()
    //   if (addGatewaySel.success) {
    //     setFormData(formData || '')
    //     NotificationPopups('success', 'Success', addGatewaySel.data.message)
    // }
  }

  // const disableBtn = () => {
  //   return !formData
  // }

  return (
    <div style={{ marginTop: '20px' }}>
      <>
        <Form>
          <Row>
            <Col md="12" sm="12" xs="12" lg="12">
              <Row>
                {/* ROW - 1 */}
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="areaType" required>
                      Society Name
                    </Label>
                    <Select
                      type="select"
                      showSearch
                      value={displayName}
                      placeholder="Select Society Name"
                      optionFilterProp="children"
                      onChange={onSelectOwner}
                      onSearch={onSearch}
                      style={{ width: '100%' }}
                    >
                      <Option className="loginSelect" value="">
                        Select Society Name
                      </Option>
                      {getSocietyListDrop.success &&
                        getSocietyListDrop.data.societyList.map((d, id) => (
                          <Option
                            key={id}
                            className="loginSelect"
                            value={`${d.id}-${d.societyName}`}
                          >
                            {d.societyName}
                          </Option>
                        ))}
                    </Select>
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="areaId" required>
                      Integration Type
                    </Label>
                    <Select
                      type="select"
                      showSearch
                      value={intType}
                      placeholder="Select Integration Type"
                      optionFilterProp="children"
                      onChange={onSelectInt}
                      // onSearch={onSearchAreaId}
                      style={{ width: '100%' }}
                    >
                      <Option className="loginSelect" value="">
                        Select Integration Type
                      </Option>
                      {IntDrop.map((d, id) => (
                        <Option key={id} value={d}>
                          {d}
                        </Option>
                      ))}
                    </Select>
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="currency" required>
                      Currency
                    </Label>
                    <Select
                      name="currency"
                      type="select"
                      showSearch
                      value={currency}
                      placeholder="Select Currency"
                      optionFilterProp="children"
                      onChange={onSelectCurrency}
                      // onSearch={onSearchAreaId}
                      style={{ width: '100%' }}
                    >
                      <Option className="loginSelect" value="">
                        Select Currency
                      </Option>
                      {currencyDrop.map((d, id) => (
                        <Option key={id} value={d}>
                          {d}
                        </Option>
                      ))}
                    </Select>
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="intName">Integration Name</Label>
                    <Input
                      type="text"
                      name="intName"
                      id="intName"
                      placeholder="Integration Name"
                      value={intName || ''}
                      // onChange={onInputChange}
                      onChange={(e) => onInputChange(e, 'alphabet')}
                    />
                  </FormGroup>
                </Col>
                {/* ROW - 2 */}
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="memUserName">Member User Name/Number</Label>
                    <Input
                      type="text"
                      name="memUserName"
                      id="memUserName"
                      placeholder="Member User Name/Number"
                      value={memUserName}
                      onChange={(e) => onInputChange(e, 'both')}
                    />
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="memId">Member ID/SID</Label>
                    <Input
                      type="text"
                      name="memId"
                      id="memId"
                      placeholder="Member ID/SID"
                      value={memId}
                      onChange={(e) => onInputChange(e, 'number')}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="memSecretKey">Member Secret Key</Label>
                    <Input
                      type="text"
                      name="memSecretKey"
                      id="memSecretKey"
                      placeholder="Member Secret Key"
                      value={memSecretKey}
                      onChange={(e) => onInputChange(e)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="partId">Partner ID</Label>
                    <Input
                      type="text"
                      name="partId"
                      id="partId"
                      placeholder="Partner ID"
                      value={partId || ''}
                      onChange={(e) => onInputChange(e, 'number')}
                      required
                    />
                  </FormGroup>
                </Col>
                {/* ROW - 3 */}
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="termId">Terminal ID</Label>
                    <Input
                      type="text"
                      name="termId"
                      id="termId"
                      placeholder="Terminal ID"
                      value={termId}
                      onChange={(e) => onInputChange(e, 'number')}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="toType">To Type</Label>
                    <Input
                      type="text"
                      name="toType"
                      id="toType"
                      placeholder="To Type"
                      value={toType}
                      onChange={(e) => onInputChange(e, 'alphabet')}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="redirectUrl">Redirect URL</Label>
                    <Input
                      type="text"
                      name="redirectUrl"
                      id="redirectUrl"
                      placeholder="Redirect URL"
                      value={redirectUrl}
                      onChange={(e) => onInputChange(e)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="payType">Payment Type</Label>
                    <Input
                      type="text"
                      name="payType"
                      id="payType"
                      placeholder="Payment Type"
                      value={payType}
                      onChange={(e) => onInputChange(e, 'alphabet')}
                      required
                    />
                  </FormGroup>
                </Col>
                {/* ROW - 4 */}
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="payBrand">Payment Brand</Label>
                    <Input
                      type="text"
                      name="payBrand"
                      id="payBrand"
                      placeholder="Payment Brand"
                      value={payBrand}
                      onChange={(e) => onInputChange(e, 'alphabet')}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="payMode">Payment Mode</Label>
                    <Input
                      type="text"
                      name="payMode"
                      id="payMode"
                      placeholder="Payment Mode"
                      value={payMode}
                      onChange={(e) => onInputChange(e, 'alphabet')}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="transUrl">Transaction URL</Label>
                    <Input
                      type="text"
                      name="transUrl"
                      id="transUrl"
                      placeholder="Transaction URL"
                      value={transUrl}
                      onChange={(e) => onInputChange(e)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="checkoutTransUrl">
                      CheckOut Transaction URL
                    </Label>
                    <Input
                      type="text"
                      name="checkoutTransUrl"
                      id="checkoutTransUrl"
                      placeholder="CheckOut Transaction URL"
                      value={checkoutTransUrl}
                      onChange={(e) => onInputChange(e)}
                      required
                    />
                  </FormGroup>
                </Col>
                {/* ROW - 5 */}
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="withdrawUrl">Withdraw URL</Label>
                    <Input
                      type="text"
                      name="withdrawUrl"
                      id="withdrawUrl"
                      placeholder="WithDraw URL"
                      value={withdrawUrl}
                      onChange={(e) => onInputChange(e)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="authTokenUrl">AuthToken URL</Label>
                    <Input
                      type="text"
                      name="authTokenUrl"
                      id="authTokenUrl"
                      placeholder="AuthToken URL"
                      value={authTokenUrl}
                      onChange={(e) => onInputChange(e)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="partKey">Partner Key</Label>
                    <Input
                      type="text"
                      name="partKey"
                      id="partKey"
                      placeholder="Partner Key"
                      value={partKey}
                      onChange={(e) => onInputChange(e, 'both')}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="3" sm="12" xs="12" lg="3">
                  <FormGroup>
                    <Label for="partUserName">Partner User Name</Label>
                    <Input
                      type="text"
                      name="partUserName"
                      id="partUserName"
                      placeholder="Partner User Name"
                      value={partUserName}
                      onChange={(e) => onInputChange(e, 'alphabet')}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row style={{ justifyContent: 'center' }}>
              {docType.success ? (
                <Button
                  className="cust-button"
                  onClick={() => setFormStep(2)}
                  disabled={disabledButton()}
                >
                  {helperAdded.isLoading ? <Spin /> : 'Next'}
                </Button>
              ) : (
                <Button
                  className="cust-button"
                  onClick={handleSubmit}
                  disabled={disabledButton()}
                >
                  {helperAdded.isLoading ? <Spin /> : 'Add'}
                </Button>
              )}
            </Row> */}
        </Form>
      </>

      <>
        {/* <Row>
                <Col md={4} sm={12} lg={4} key={id}>
                  <Label>
                    Upload {d.documentName}{' '}
                    {d.mandatory && <span className="errorText">*</span>}
                  </Label>
                  {documents[id].photo && (
                    <img
                      src={documents[id].photo}
                      width="50%"
                      height="150"
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    />
                  )}

                  <FileBase64
                    multiple={false}
                    id={id}
                    accept=".jpeg, .png, .jpg"
                    onDone={handleAttachmentsDoc}
                  />
                  <FormGroup>
                    <Label for="helperMobile">{d.documentName} Number</Label>
                    <Input
                      type="text"
                      name={id}
                      id="helperMobile"
                      placeholder={`${d.documentName} Number`}
                      maxLength="15"
                      // value={ || ''}
                      // onChange={handleChangeDocNumber}
                      required
                    />
                  </FormGroup>
                </Col>
          </Row> */}

        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
          // disabled={disableBtn}
          >
            Add
            {/* {helperAdded.isLoading ? <Spin /> : 'Add'} */}
          </Button>
        </Row>
      </>
    </div>
  )
}

export default AddGateway
