import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Tooltip, Popconfirm, Alert, Switch, Select } from 'antd'
import { Spin } from 'antd'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetupdateDocType,
  updateDocType,
} from 'redux/actions/updateDocTypeAction'
import {
  resetgetSocietyDoc,
  getSocietyDoc,
} from 'redux/actions/getSocietyDocAction'
import {
  resetdeleteDocType,
  deleteDocType,
} from 'redux/actions/deleteDocTypeAction'
import PaginationComponent from 'views/Pagination'
import AddSocietyDoc from './AddSocietyDoc'
import Util from 'validations/index'

function SocietyDocList(onTap) {
  const [documentTypeId, setDocumentTypeId] = useState('')
  const [documentType, setDocumentType] = useState('')
  const [searchDocumentType, setSearchDocumentType] = useState('')
  const [editModal, setEditModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [editdocType, seteditdocType] = useState('')
  const [id, setId] = useState('')
  const [isActive, setisActive] = useState()
  const [isMandatory, setIsMandatory] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [limit] = useState(6)
  const [page, setPage] = useState(1)

  const docType = useSelector(({ getSocietyDocs }) => getSocietyDocs)
  const addeddocType = useSelector(({ addDocType }) => addDocType)
  const login = useSelector(({ login }) => login)
  const [initiatedBy] = useState(login.data.user.role)
  const deletedDocType = useSelector(({ deleteDocType }) => deleteDocType)
  const updatedDocType = useSelector(({ updateDocType }) => updateDocType)
  const dispatch = useDispatch()
  const pageSize = docType.data.pages
  const totalItems = docType.data.count

  console.log(docType, 'doctype')
  const getDocTypeRequest = () => {
    if (initiatedBy === 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy,
        limit,
        page,
      }
      return dispatch(getSocietyDoc(docTypeReq))
    }
  }

  const { Option } = Select

  function onChangeDocumentType(value) {
    const val = value.split(',')
    setDocumentTypeId(val[0])
    setDocumentType(val[1])
  }
  function onSearchDocumentType(value) {
    setSearchDocumentType(value)
  }
  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }
  useEffect(() => {
    if (initiatedBy === 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy,
        limit,
        page,
      }
      return dispatch(getSocietyDoc(docTypeReq))
    }
  }, [page])

  useEffect(() => {
    setPage(1)
  }, [onTap])

  useEffect(() => {
    getDocTypeRequest()
  }, [addeddocType.success])

  useEffect(() => {
    if (updatedDocType.success) {
      seterrMessage('')
      setModal(false)
      NotificationPopups('success', 'Success', updatedDocType.data.message)
      getDocTypeRequest()
    }
    if (updatedDocType.failed) {
      if (updatedDocType.data.message) {
        seterrMessage(updatedDocType.data.message)
      }
      if((updatedDocType.data.message === 'Mandatory Parameter Missing')){
        seterrMessage(updatedDocType.data.errorVOList.map((ele, id) => (<>{ele.errorMessage}<br /></>)))
      }
    }
   
    if (updatedDocType.network || deletedDocType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    if (deletedDocType.success) {
      NotificationPopups('success', 'Success', deletedDocType.data.message)
      getDocTypeRequest()
    }
    if (deletedDocType.failed) {
      NotificationPopups('error', 'Failed', deletedDocType.data.message)
    }

    return () => {
      dispatch(resetupdateDocType())
      dispatch(resetdeleteDocType())
    }
  }, [
    updatedDocType.success,
    updatedDocType.failed,
    updatedDocType.network,
    deletedDocType.success,
    deletedDocType.failed,
    deletedDocType.network,
  ])

  const handleDelete = (id) => {
    const deleteDocTypeReq = {
      id: id,
    }
    return dispatch(deleteDocType(deleteDocTypeReq))
  }

  const openEditModal = (type) => {
    console.log(type)
    setId(type.id)
    setDocumentTypeId(type.documentTypeId)
    setDocumentType(type.type)

    seteditdocType(type.type)
    setIsMandatory(type.isMandatory)
    setisActive(type.isActive)
    setModal(!modal)
  }

  const toggle = () => {
    seterrMessage('')
    setModal(!modal)
  }
  const toggleEdit = () => {
    setEditModal(!editModal)
  }

  const handleChange = ({ target: { name, value } }) => {
    //console.log(value)
    if (name === 'editdocType')
      Util.alphabetsOnly(value)
        ? seteditdocType(value)
        : seteditdocType(editdocType)
    if (name === 'isMandatory')
      Util.alphabetsOnly(value)
        ? setIsMandatory(Boolean(value))
        : setIsMandatory(Boolean(isMandatory))
  }

  const handleEditDocType = () => {
    const editDocTypeReq = {
      id,
      documentTypeId,
      initiatedBy: login.data.user.role,
      isActive,
      isMandatory: isMandatory,
    }
    return dispatch(updateDocType(editDocTypeReq))
  }

  const onChange = (checked) => {
    setisActive(checked)
    console.log(`switch to ${checked}`)
  }

  return (
    // <Row>
    //   <Col md="12">
    //     <Card>
    //       <CardBody>
    //         <Row>
    //           <Col md="12" style={{ padding: '20px' }}>
    //             <div>
    //               {docType.success && (
    //                 <>
    //                   {' '}
    //                   <div className="typeHeading">
    //                     Active Unit Document Type
    //                   </div>
    //                   <Row>
    //                     {docType.data.activeType.map((d, id) => (
    //                       <Col sm="12" md="4" lg="4" key={id}>
    //                         <Card className="typeContent">
    //                           <CardBody>
    //                             <CardText>
    //                               <span
    //                                 style={{
    //                                   textTransform: 'capitalize',
    //                                   fontWeight: 'semi-bold',
    //                                   color: ' #00326c',
    //                                 }}
    //                               >
    //                                 {d.type}
    //                               </span>
    //                               <div style={{ fontSize: '15px' }}>
    //                                 <i class="fas fa-clipboard-check"></i>{' '}
    //                                 {d.isMandatory
    //                                   ? 'Mandatory'
    //                                   : 'Not Mandatory'}
    //                               </div>
    //                             </CardText>
    //                             <CardText>
    //                               <Button
    //                                 color="secondary"
    //                                 className="modalCustomButton"
    //                                 onClick={() => openEditModal(d)}
    //                               >
    //                                 Edit
    //                               </Button>{' '}
    //                             </CardText>
    //                           </CardBody>
    //                         </Card>
    //                       </Col>
    //                     ))}
    //                   </Row>
    //                 </>
    //               )}

    //               {docType.success && !docType.data.activeType.length && (
    //                 <div style={{ textAlign: 'center', padding: '20px 0px' }}>
    //                   <Alert
    //                     message="No Active Document Type Found"
    //                     type="info"
    //                   />
    //                 </div>
    //               )}

    //               {/* {docType.success && (
    //                 <>
    //                   {' '}
    //                   <div className="typeHeading">
    //                     Inactive Unit Document Type
    //                   </div>
    //                   <Row>
    //                     {docType.data.inActiveType.map((d, id) => (
    //                       <Col sm="12" md="4" lg="4" key={id}>
    //                         <Card className="typeContent">
    //                           <CardBody>
    //                             <CardText>
    //                               <span
    //                                 style={{
    //                                   textTransform: 'capitalize',
    //                                   fontWeight: 'semi-bold',
    //                                   color: ' #00326c',
    //                                 }}
    //                               >
    //                                 {d.type}
    //                               </span>
    //                               <div style={{ fontSize: '15px' }}>
    //                                 <i class="fas fa-clipboard-check"></i>{' '}
    //                                 {d.isMandatory
    //                                   ? 'Mandatory'
    //                                   : 'Not Mandatory'}
    //                               </div>
    //                             </CardText>
    //                             <CardText>
    //                               <Button
    //                                 color="secondary"
    //                                 className="modalCustomButton"
    //                                 onClick={() => openEditModal(d)}
    //                               >
    //                                 Edit
    //                               </Button>
    //                             </CardText>
    //                           </CardBody>
    //                         </Card>
    //                       </Col>
    //                     ))}
    //                   </Row>
    //                 </>
    //               )} */}

    //               {/* {docType.success && !docType.data.inActiveType.length && (
    //                 <div style={{ textAlign: 'center', padding: '20px 0px' }}>
    //                   <Alert
    //                     message="No Inactive Document Type Found"
    //                     type="info"
    //                   />
    //                 </div>
    //               )} */}

    //               {/* {docType.failed && (
    //                 <div style={{ textAlign: 'center', paddingTop: '20px' }}>
    //                   <Alert message={docType.data.message} type="info" />
    //                 </div>
    //               )} */}

    //               {docType.success && (
    //                 <div className="col-xs-1 center-block">
    //                   <PaginationComponent
    //                     totalItems={totalItems}
    //                     pageSize={limit}
    //                     maxPaginationNumbers={5}
    //                     defaultActivePage={page}
    //                     nextPageText={'Next'}
    //                     previousPageText={'Previous'}
    //                     onSelect={handlePagination}
    //                   />
    //                 </div>
    //               )}

    //               {modal && (
    //                 <Modal
    //                   isOpen={modal}
    //                   toggle={toggle}
    //                   size="sm"
    //                   style={{ maxWidth: '250px' }}
    //                 >
    //                   <ModalHeader toggle={toggle}>Edit</ModalHeader>
    //                   <ModalBody>
    //                     <FormGroup>
    //                       <Label for="helperType">Document Type</Label>
    //                       <Select
    //                         type="select"
    //                         value={documentType}
    //                         showSearch
    //                         placeholder="Select Service Type"
    //                         optionFilterProp="children"
    //                         onChange={onChangeDocumentType}
    //                         onSearch={onSearchDocumentType}
    //                         style={{ width: '100%' }}
    //                       >
    //                         <Option className="loginSelect" value="">
    //                           Select Document Type
    //                         </Option>

    //                         {docType.success &&
    //                           docType.data.activeType.map((d, id) => (
    //                             <Option
    //                               key={id}
    //                               className="loginSelect"
    //                               value={[d.id, d.type]}
    //                             >
    //                               {d.type}
    //                             </Option>
    //                           ))}
    //                         {docType.failed && (
    //                           <Option className="loginSelect" value="">
    //                             No Data Found
    //                           </Option>
    //                         )}
    //                       </Select>
    //                     </FormGroup>
    //                     <FormGroup tag="fieldset">
    //                       <Label>Mandatory</Label>
    //                       <FormGroup check>
    //                         <Label check>
    //                           <Input
    //                             type="radio"
    //                             name="isMandatory"
    //                             defaultChecked={isMandatory ? 'true' : null}
    //                             value="True"
    //                             onChange={handleChange}
    //                           />{' '}
    //                           Yes
    //                         </Label>
    //                         <Label check>
    //                           <Input
    //                             type="radio"
    //                             name="isMandatory"
    //                             defaultChecked={isMandatory ? null : 'false'}
    //                             value=""
    //                             onChange={handleChange}
    //                           />{' '}
    //                           No
    //                         </Label>
    //                       </FormGroup>
    //                     </FormGroup>
    //                     <FormGroup>
    //                       <Label>Status</Label>
    //                       <br />
    //                       <Switch
    //                         defaultChecked={isActive}
    //                         name="isActive"
    //                         checkedChildren="Active"
    //                         unCheckedChildren="Inactive"
    //                         onChange={onChange}
    //                       />
    //                     </FormGroup>
    //                     <div
    //                       style={{ textAlign: 'center' }}
    //                       className="errorText"
    //                     >
    //                       {errMessage}
    //                     </div>
    //                     <Button
    //                       color="secondary"
    //                       className="modalCustomButton"
    //                       onClick={handleEditDocType}
    //                       disabled={updatedDocType.isLoading || !editdocType}
    //                     >
    //                       {updatedDocType.isLoading ? <Spin /> : 'Edit'}
    //                     </Button>
    //                     <Button
    //                       color="secondary"
    //                       style={{ float: 'right' }}
    //                       className="modalCustomButton"
    //                       onClick={toggle}
    //                     >
    //                       Close
    //                     </Button>
    //                   </ModalBody>
    //                 </Modal>
    //               )}
    //             </div>
    //           </Col>
    //         </Row>
    //       </CardBody>
    //     </Card>
    //   </Col>
    // </Row>
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Row>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={{ marginRight: '15px' }}>
                  <Button
                    color="secondary"
                    className="modalCustomButton"
                    onClick={() => {
                      setEditModal(!editModal)
                    }}
                  >
                    Add Document
                  </Button>
                </div>
                <Col>
                  <div className="typeHeading" id="onTop">
                    Society Document List
                  </div>
                </Col>
              </div>

              {docType.success && (
                <Col md="12" style={{ padding: '20px' }}>
                  <Table responsive style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>Document </th>
                        <th>Mandatory</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    {docType.success && (
                      <tbody>
                        {docType.data.societyDocumentTypeList.map((d, id) => (
                          <React.Fragment key={id}>
                            <tr key={id} id={`toggler${id}`}>
                              <td>{d.type}</td>
                              <td>
                                {d.isMandatory ? 'Mandatory' : 'Not Mandatory'}
                              </td>
                              <td>{d.isActive ? 'Active' : 'Inactive'}</td>
                              <td>
                                <Button
                                  color="secondary"
                                  className="modalCustomButton"
                                  onClick={() => {
                                    openEditModal(d)
                                  }}
                                >
                                  Edit
                                </Button>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    )}
                  </Table>
                </Col>
              )}
            </Row>
            <Col>
              {docType.success &&
                !docType.data.societyDocumentTypeList.length && (
                  <div style={{ textAlign: 'center', padding: '20px 0px' }}>
                    <Alert
                      message="No Inactive Document Type Found"
                      type="info"
                    />
                  </div>
                )}

              {docType.failed && (
                <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                  <Alert message={docType.data.message} type="info" />
                </div>
              )}
            </Col>
            <Col>
              {docType.success && (
                <PaginationComponent
                  limit={limit}
                  totalItems={totalItems}
                  onSelect={handlePagination}
                  defaultPage={page}
                />
              )}
            </Col>
          </CardBody>
        </Card>
      </Col>
      {editModal && (
        <Modal
          isOpen={editModal}
          toggle={toggleEdit}
          size="sm"
          style={{ maxWidth: '350px' }}
        >
          <ModalHeader toggle={toggleEdit}>Add Document</ModalHeader>
          <ModalBody>
            <AddSocietyDoc />
          </ModalBody>
        </Modal>
      )}
      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="sm"
          centered
          style={{ maxWidth: '250px', marginLeft: "auto", marginRight: "auto" }}
        >
          <ModalHeader toggle={toggle}>Edit</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="helperType">Document Type</Label>
              <Select
                type="select"
                value={documentType}
                showSearch
                placeholder="Select Service Type"
                optionFilterProp="children"
                onChange={onChangeDocumentType}
                onSearch={onSearchDocumentType}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Document Type
                </Option>

                {docType.success &&
                  docType.data.societyDocumentTypeList.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.id},${d.type}`}
                    >
                      {d.type}
                    </Option>
                  ))}
                {docType.failed && (
                  <Option className="loginSelect" value="" disabled>
                    No Data Found
                  </Option>
                )}
              </Select>
            </FormGroup>
            <FormGroup tag="fieldset">
              <Label>Mandatory</Label>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="isMandatory"
                    defaultChecked={isMandatory ? 'true' : null}
                    value="True"
                    onChange={handleChange}
                  />{' '}
                  Yes
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    name="isMandatory"
                    defaultChecked={isMandatory ? null : 'false'}
                    value=""
                    onChange={handleChange}
                  />{' '}
                  No
                </Label>
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <br />
              <Switch
                defaultChecked={isActive}
                name="isActive"
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={onChange}
              />
            </FormGroup>
            <div style={{ textAlign: 'center' }} className="errorText">
              {errMessage}
            </div>
            <Button
              color="secondary"
              className="modalCustomButton"
              onClick={handleEditDocType}
              disabled={updatedDocType.isLoading || !editdocType}
            >
              {updatedDocType.isLoading ? <Spin /> : 'Edit'}
            </Button>
            <Button
              color="secondary"
              style={{ float: 'right' }}
              className="modalCustomButton"
              onClick={toggle}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>
      )}
    </Row>
  )
}

export default SocietyDocList
