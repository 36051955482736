import React, { useEffect, useState } from 'react'
import { requestForToken, onMessageListener } from '../../firebase'
// import toast, { Toaster } from 'react-o'
import { } from 'firebase/app-check'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { useDispatch, useSelector } from 'react-redux'
import {
    getAmenityList,
} from '../../redux/actions/getAmenityListAction'

import {
    getComplaintList,
} from '../../redux/actions/getComplaintListAction'

import {
    getPendingMemberList,
} from '../../redux/actions/getPendingMemberListAction'
import { getMessaging, onMessage } from 'firebase/messaging'

const Notification = () => {
    const messaging = getMessaging();
    const login = useSelector(({ login }) => login)
    const dispatch = useDispatch()
    const getAmenityListRequest = (isRead = false) => {
        const getAmenityListReq = {
            amenityStatus: "pending",
            initiatedBy: "Society",
            societyId: login.data.society.id,
        }
        return dispatch(getAmenityList(getAmenityListReq, isRead))
    }

    // Complaints 

    const getComplainListRequest = (isRead = false) => {
        const getComplainListReq = {
            initiatedBy: "Society",
            societyId: login.data.society.id,
            complainStatus: 'pending',
        }
        return dispatch(getComplaintList(getComplainListReq, isRead))
    }

    // Member

    const getPendingMemberListRequest = (isRead = false) => {
        const getPendingMemberListReq = {
            initiatedBy: "Society",
            societyId: login.data.society.id,
            userStatus: 'pending'
        }
        return dispatch(getPendingMemberList(getPendingMemberListReq, isRead))
    }


    requestForToken();

    useEffect(() => {
        const subscribe = onMessage(messaging, (payload) => {
            if (payload?.notification?.title === "Complain for Society") {
                getComplainListRequest()
                return NotificationPopups('info', `${payload?.notification?.title}`, <p>{payload?.notification?.body}</p>)
            } if (payload?.notification?.title === "Amenity Request") {
                getAmenityListRequest()
                return NotificationPopups('info', `${payload?.notification?.title}`, <p>{payload?.notification?.body}</p>)
            } if (payload?.notification?.title === "Member Request") {
                getPendingMemberListRequest()
                return NotificationPopups('info', `${payload?.notification?.title}`, <p>{payload?.notification?.body}</p>)
            }
        })

        return (() => subscribe())
        // onMessageListener()
        //     .then((payload) => {

        //     })
        //     .catch(err => console.log('Failed', err))

    }, [])

    return (
        <>

        </>
    )
}

export default Notification;