import axios from 'axios'

// process.env.NODE_ENV == 'development'
// ? null : axios.defaults.headers.common["Accept"] = "origin";
//axios.defaults.headers.common["Content-Type"] = "application/json";
//axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

let ignoreApiList = ["user/adminSignin", "society/allCityList", "society/getSocietyList", "society/getAreaList",
  "user/login", "user/resendUserLoginOtp", "user/verifyLoginOtp", "user/regenerateAuthToken",
  "guard/login", "guard/verifyOtp", "guard/resendOtp", "user/adminSignUp",
  "user/signup", "user/verifySignupOtp","https://api.postalpincode.in/pincode","amenity/badgeCounter"]

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV == 'development'
      ? `http://localhost:9300/`
      : 'https://app.hisociety.co.in/mySocietyServicesApp/',
      
});

instance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('authToken');
    if (!ignoreApiList.includes(config.url)&& !config.url.includes('https://api.postalpincode.in/pincode')) {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }
    if(config.url.includes('https://api.postalpincode.in/pincode')) config.headers.origin = '*'
    return Promise.resolve(config);
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    // const originalRequest = error.config;

    // // If the error status is 401 and there is no originalRequest._retry flag,
    // // it means the token has expired and we need to refresh it
    // if (error.response.status === 401) {
    //   try {
    //     const oldToken = sessionStorage.getItem('authToken');
    //     const response = await instance.post('user/regenerateAuthToken', { token: oldToken });
    //     const { token } = response.data;

    //     sessionStorage.setItem('authToken', token);
    //     // Retry the original request with the new token
    //     if (token) {
    //       originalRequest.headers.AuthToken = token;
    //     }

    //     return axios(originalRequest);
    //   } catch (error) {
    //     // Handle refresh token error or redirect to login
    //   }
    // }


    return Promise.reject(error);
  }
);


export default instance