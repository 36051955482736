import axios from '../../config/axios'
import {
  GET_SOCIETY_INTEREST_SUCCESS,
  GET_SOCIETY_INTEREST_FAILED,
  LOADING_GET_SOCIETY_INTEREST,
  RESET_GET_SOCIETY_INTEREST,
  GET_SOCIETY_INTEREST_NETWORKERROR,
} from '../types'

const resetGetSocietyInterest = () => {
  return {
    type: RESET_GET_SOCIETY_INTEREST,
  }
}

const getSocietyInterest = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_SOCIETY_INTEREST })
  axios
    .post('mnt/getSocietyInterest', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: GET_SOCIETY_INTEREST_NETWORKERROR, payload: err }))
}

function success(data) {
  return {
    type: GET_SOCIETY_INTEREST_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_SOCIETY_INTEREST_FAILED,
    payload: data,
  }
}

export { resetGetSocietyInterest, getSocietyInterest }
