import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import randomColor from 'randomcolor'
import { Alert, Switch } from 'antd'
import { Tooltip, Popconfirm, Spin } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetgetAmenityTypeList,
  getAmenityTypeList,
} from 'redux/actions/getAmenityTypeAction'
import {
  resetupdateAmenityType,
  updateAmenityType,
} from 'redux/actions/updateAmenityTypeAction'
import {
  resetdeleteAmenityType,
  deleteAmenityType,
} from 'redux/actions/deleteAmenityTypeAction'
import Util from '../../validations'

import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'

import moment from 'moment'
import { Calendar, momentLocalizer, EventProps, Views } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import NotificationPopupsTop from 'components/NotificationPopups/NotificationPopupTop'
import './calendar.css'


function AmenityListDisplay() {
  const [modal, setModal] = useState(false)
  const [editAmenityType, seteditAmenityType] = useState('')
  const [id, setId] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [isActive, setisActive] = useState(true)
  const [areaType, setareaType] = useState('Amenities')
  const [calendarModel, setCalendarModel] = useState(false)

  const login = useSelector(({ login }) => login)
  const amenityTypes = useSelector(({ amenityType }) => amenityType)
  const updatedAmenityType = useSelector(
    ({ updateAmenityType }) => updateAmenityType
  )
  const deletedAmenityType = useSelector(
    ({ deleteAmenityType }) => deleteAmenityType
  )
  const addedAmenityType = useSelector(({ addAmenityType }) => addAmenityType)
  const buildingList = useSelector(({ buildingList }) => buildingList)

  const dispatch = useDispatch()

  const getAmenityTypeRequest = () => {
    const getAmenityTypeReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      userId: login.data.user.id,
    }
    return dispatch(getAmenityTypeList(getAmenityTypeReq))
  }
  console.log(amenityTypes, 'amenityType')
  useEffect(() => {
    getAmenityTypeRequest()
  }, [addedAmenityType.success])

  // console.log('amenityType', amenityType.data.amenityDetails)

  useEffect(() => {
    if (updatedAmenityType.success) {
      seterrMessage('')
      setModal(!modal)
      NotificationPopups('success', 'Success', updatedAmenityType.data.message)
      getAmenityTypeRequest()
    }
    if (updatedAmenityType.failed) {
      seterrMessage(updatedAmenityType.data.message)
      //NotificationPopups('error', 'Failed', updatedAmenityType.data.message)
      getAmenityTypeRequest()
    }
    if (updatedAmenityType.network || deletedAmenityType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetupdateAmenityType())
    }
  }, [
    updatedAmenityType.success,
    updatedAmenityType.failed,
    deletedAmenityType.success,
    deletedAmenityType.failed,
    updatedAmenityType.network,
    deletedAmenityType.network,
  ])

  const handleDelete = (id) => {
    const deleteAmenitytypeReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      id: id,
    }
    return dispatch(deleteAmenityType(deleteAmenitytypeReq))
  }

  const openEditModal = (type) => {
    console.log(type, "dvhjbd")
    setId(type.id)
    seteditAmenityType(type.type)
    setisActive(type.isActive)
    setModal(!modal)
  }

  const openAminityCalender = (type) => {
    setCalendarModel(!calendarModel)
  }

  const calendarToggle = () => {
    setCalendarModel(!calendarModel)
  }

  const toggle = () => {
    seterrMessage('')
    setModal(!modal)
  }
  const onChange = (checked) => {
    setisActive(checked)
    // console.log(`switch to ${checked}`)
  }
  const handleChange = ({ target: { name, value } }) => {
    if (name === 'editAmenityType')
      Util.checkWhiteSpace(value)
        ? seteditAmenityType(value)
        : seteditAmenityType('')
  }

  const handleEditAmenityType = () => {
    const editAmenityTypeReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      id: id,
      type: editAmenityType.trim(),
      isActive,
    }
    return dispatch(updateAmenityType(editAmenityTypeReq))
  }

  //GetAreaList

  useEffect(() => {
    if (login.data.user.role !== 'SuperAdmin' && areaType != '') {
      const getBuildingListReq = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      return dispatch(getBuildingList(getBuildingListReq))
    }
  }, [areaType])

  //Calendar

  const now = new Date();

  const events = [
    {
      id: 0,
      title: "All Day Event very long title",
      allDay: true,
      start: new Date(2015, 3, 0),
      end: new Date(2015, 3, 1)
    },
    {
      id: 1,
      title: "Long Event",
      start: new Date(2015, 3, 7),
      end: new Date(2015, 3, 10)
    },

    {
      id: 2,
      title: "DTS STARTS",
      start: new Date(2016, 2, 13, 0, 0, 0),
      end: new Date(2016, 2, 20, 0, 0, 0)
    },

    {
      id: 3,
      title: "DTS ENDS",
      start: new Date(2016, 10, 6, 0, 0, 0),
      end: new Date(2016, 10, 13, 0, 0, 0)
    },

    {
      id: 4,
      title: "Some Event",
      start: new Date(2015, 3, 9, 0, 0, 0),
      end: new Date(2015, 3, 10, 0, 0, 0)
    },
    {
      id: 5,
      title: "Conference",
      start: new Date(2015, 3, 11),
      end: new Date(2015, 3, 13),
      desc: "Big conference for important people"
    },
    {
      id: 6,
      title: "Meeting",
      start: moment('2024-10-17 08:00:00').toDate(),
      end: moment('2024-10-17 12:00:00').toDate(),
      data: {
        desc: "Pre-meeting meeting, to prepare for the meeting",
        name: "John Doe",
        building: "Delta"
      }

    },
    {
      id: 14,
      title: "Today",
      start: new Date(new Date().setHours(new Date().getHours() - 3)),
      end: new Date(new Date().setHours(new Date().getHours() + 3)),
      data: {
        desc: "Pre-meeting meeting",
        name: "Tejas Gandhi",
        building: "Om Sai"
      }
    },

  ];

  const [eventsData, setEventsData] = useState(events);

  moment.locale("en-GB");
  const localizer = momentLocalizer(moment);

  // const handleSelect = ({ start, end }) => {
  //   const title = window.prompt("New Event name");
  //   if (title)
  //     setEventsData([
  //       ...eventsData,
  //       {
  //         start,
  //         end,
  //         title
  //       }
  //     ]);
  // };

  const [currentDate, setCurrentDate] = useState(new Date());

  const handleNavigate = (newDate) => {
    setCurrentDate(newDate);
  };

  const handleInputChange = (event) => {
    const selectedDate = event.target.value;
    setCurrentDate(new Date(selectedDate)); // Update the calendar with the selected date
  };

  // const [view, setView] = useState(Views.WEEK);

  // const onPrevClick = useCallback(() => {
  //   if (view === Views.DAY) {
  //     setCurrentDate(moment(currentDate).subtract(1, "d").toDate());
  //   } else if (view === Views.WEEK) {
  //     setCurrentDate(moment(currentDate).subtract(1, "w").toDate());
  //   } else {
  //     setCurrentDate(moment(currentDate).subtract(1, "M").toDate());
  //   }
  // }, [view, currentDate]);

  // const onNextClick = useCallback(() => {
  //   if (view === Views.DAY) {
  //     setCurrentDate(moment(currentDate).add(1, "d").toDate());
  //   } else if (view === Views.WEEK) {
  //     setCurrentDate(moment(currentDate).add(1, "w").toDate());
  //   } else {
  //     setCurrentDate(moment(currentDate).add(1, "M").toDate());
  //   }
  // }, [view, currentDate]);


  return (
    <>
      <>
        <div style={{ padding: '25px' }}>
          <div className="typeHeading">Amenity List </div>
          <Row>
            {buildingList.success &&
              buildingList.data.areaListActive.map((d, id) => (
                <>
                  {
                    <Col sm="12" md="3" lg="3" key={id}>
                      <Card
                        className="typeContent"
                      >
                        <CardBody>
                          <CardText>
                            <span
                              style={{
                                textTransform: 'capitalize',
                                fontWeight: '600',
                              }}
                            >
                              {d.areaName}
                            </span>
                          </CardText>
                          <CardText>
                            <Button
                              className="cust-button"
                              onClick={() => openAminityCalender(d)}
                            >
                              Calendar
                            </Button>

                          </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                  }
                </>
              ))}
            {/* <Col>
              {amenityTypes.failed && (
                <div style={{ textAlign: 'center', marginTop: '40px' }}>
                  <Alert message={amenityTypes.data.message} type="info" />
                </div>
              )}
            </Col> */}
          </Row>
          {/* {amenityTypes.success &&
            !amenityTypes.data.activeAmenityTypeList.length && (
              <div style={{ textAlign: 'center', padding: '20px 0px' }}>
                <Alert message="No Active Amenity Types" type="info" />
              </div>
            )} */}
        </div>
      </>

      <>
        <div style={{ padding: '25px' }}>
          <div className="typeHeading">Active Amenity List </div>
          <Row>
            {amenityTypes.success &&
              amenityTypes.data.activeAmenityTypeList.map((d, id) => (
                <>
                  {
                    <Col sm="12" md="3" lg="3" key={id}>
                      <Card
                        className="typeContent"
                      // style={{
                      //   background: randomColor({
                      //     luminosity: 'light',
                      //     alpha: 0.5,
                      //   }),
                      // }}
                      >
                        <CardBody>
                          <CardText>
                            <span
                              style={{
                                textTransform: 'capitalize',
                                fontWeight: '600',
                              }}
                            >
                              {d.type}
                            </span>
                          </CardText>
                          <CardText>
                            <Button
                              className="cust-button"
                              // style={{ marginTop: '22px', width: '50%' }}
                              onClick={() => openEditModal(d)}
                            >
                              Edit
                            </Button>
                          </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                  }
                </>
              ))}
            <Col>
              {amenityTypes.failed && (
                <div style={{ textAlign: 'center', marginTop: '40px' }}>
                  <Alert message={amenityTypes.data.message} type="info" />
                </div>
              )}
            </Col>
          </Row>
          {amenityTypes.success &&
            !amenityTypes.data.activeAmenityTypeList.length && (
              <div style={{ textAlign: 'center', padding: '20px 0px' }}>
                <Alert message="No Active Amenity Types" type="info" />
              </div>
            )}
        </div>
      </>

      {/* inActive */}
      {amenityTypes.success && (
        <>
          <div style={{ padding: '25px' }}>
            <div className="typeHeading">Inactive Amenity List </div>
            <Row>
              {amenityTypes.success &&
                amenityTypes.data.inActiveAmenityTypeList.map((d, id) => (
                  <>
                    {
                      <Col sm="12" md="3" lg="3" key={id}>
                        <Card
                          className="typeContent"
                        // style={{
                        //   background: randomColor({
                        //     luminosity: 'light',
                        //     alpha: 0.5,
                        //   }),
                        // }}
                        >
                          <CardBody>
                            <CardText>
                              <span
                                style={{
                                  textTransform: 'capitalize',
                                  fontWeight: '600',
                                }}
                              >
                                {d.type}
                              </span>
                            </CardText>
                            <CardText>
                              <Button
                                className="cust-button"
                                // style={{ marginTop: '22px', width: '50%' }}
                                onClick={() => openEditModal(d)}
                              >
                                Edit
                              </Button>
                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                    }
                  </>
                ))}
            </Row>
          </div>
        </>
      )}
      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="sm"
          style={{ maxWidth: '250px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <ModalHeader toggle={toggle}>Edit</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="editAmenityType">Area Type</Label>
              <Input
                type="text"
                name="editAmenityType"
                id="editAmenityType"
                value={editAmenityType}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <br />
              <Switch
                defaultChecked={isActive}
                name="isActive"
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={onChange}
              // style={{ width: '100%' }}
              />
            </FormGroup>
            <div style={{ textAlign: 'center' }} className="errorText">
              {errMessage}
            </div>
            <Button
              color="secondary"
              className="modalCustomButton"
              onClick={handleEditAmenityType}
              disabled={updatedAmenityType.isLoading || !editAmenityType}
            >
              {updatedAmenityType.isLoading ? <Spin /> : 'Edit'}
            </Button>
            <Button
              color="secondary"
              style={{ float: 'right' }}
              className="modalCustomButton"
              onClick={toggle}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>
      )}

      {calendarModel && (
        <Modal
          isOpen={calendarModel}
          toggle={calendarToggle}
          size="sm"
          style={{ maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <ModalHeader toggle={calendarToggle}>Calendar</ModalHeader>
          <ModalBody>
            <div>
              <Input
                name="startDate"
                value={moment(currentDate).format('YYYY-MM-DD')}
                type="date"
                onChange={handleInputChange}
                min={moment().format('YYYY-MM-DD')}
                style={{ width: '12%' }}
              /><br />

              {/* <button onClick={onPrevClick}>Prev</button>
              <button onClick={onNextClick}>Next</button> */}

              <Calendar
                views={["month", "week", "day", "agenda"]}
                selectable
                localizer={localizer}
                defaultDate={new Date()} // Default starting date
                date={currentDate} // Sync the calendar with the current date
                defaultView="month"
                events={eventsData}
                style={{ height: "65vh" }}
                onSelectEvent={(event) => NotificationPopupsTop('success', 'Success', event.name)}
                onNavigate={handleNavigate} // Update the DatePicker when navigating the calendar
              />
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default AmenityListDisplay
