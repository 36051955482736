import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Tooltip, Popconfirm, Alert } from 'antd'
import { Spin, Switch, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import FileBase64 from 'components/Custom/UploadFile'
import {
  resetAddProductServiceSubCategory,
  addProductServiceSubCategory,
} from 'redux/actions/addProductServiceSubCategoryAction'
import Util from 'validations/index'

function UpdateProductServiceSubCategory(data) {
  console.log(data,"fvgavhga")
  const [type, setType] = useState(data.data.type)
  const [name, setName] = useState(data.data.name)
  const [featureIcon, setFeatureIcon] = useState(data.data.iconUrl)
  const [productCategoryId, setProductCategoryId] = useState(data.data.id)
  const [productCategoryName, setProductCategoryName] = useState(
    data.data.productCategory
  )
  const [id, setId] = useState(data.data.id)
  const [isActive, setIsActive] = useState(data.data.isActive)
  const [updatedPhoto, setUpdatedPhoto] = useState('')
  const [profilePictureStatus, setprofilePictureStatus] = useState(false)
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const productServiceCat = useSelector(
    ({ getProductServiceCategory }) => getProductServiceCategory
  )
  const addedProductServCat = useSelector(
    ({ addProductServiceSubCategory }) => addProductServiceSubCategory
  )

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'name') setName(value)
  }

  const onChange = (checked) => {
    setIsActive(checked)
  }

  const labelOptionsDropDown = [
    {
      id: 1,
      name: 'Product',
    },
    {
      id: 2,
      name: 'Service',
    },
  ]

  const handleAttachments = (filesList) => {
    setprofilePictureStatus(true)
    const icon = filesList.base64.split(`,`)
    if (icon[0].includes('application')) {
      NotificationPopups('error', "Failed", "Wrong File Formate")
    }
    else {
      setFeatureIcon(icon[1])
      setUpdatedPhoto(filesList.base64)
    }
  }

  const { Option } = Select
  function onChangeFeature(value) {
    setType(value)
  }

  function onSearchFeature(val) {
    setType(val)
  }
  function onChangeProductCat(value) {
    console.log(value,"valuevalue")
    const val = value.split(',')
    setProductCategoryId(val[0])
    setProductCategoryName(val[1])
  }

  function onSearchProductCat(val) {
    setProductCategoryName(val[1])
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    addProductServiceCategoryRequest()
  }
  const addProductServiceCategoryRequest = () => {
    const addProductServiceCategoryReq = {
      initiatedBy: login.data.user.role,
      id,
      productCategoryId,
      type,
      name,
      iconUrl: profilePictureStatus ? featureIcon : '',
      isActive,
      action: 'update',
    }
    return dispatch(addProductServiceSubCategory(addProductServiceCategoryReq))
  }

  useEffect(() => {
    if (addedProductServCat.success) {
      setFeatureIcon('')
      setName('')
      setType('')
      NotificationPopups('success', 'Success', addedProductServCat.data.message)
    }
    if (addedProductServCat.failed) {
      NotificationPopups('error', 'Failed', addedProductServCat.data.message)
    }
    if (addedProductServCat.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddProductServiceSubCategory())
    }
  }, [
    addedProductServCat.success,
    addedProductServCat.failed,
    addedProductServCat.network,
  ])

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormGroup style={{ margin: '10px 1px' }}>
          <Label for="serviceType">Enter Name</Label>
          <Input
            type="text"
            placeholder="Name"
            name="name"
            id="serviceType"
            value={name || ''}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup style={{ margin: '10px 1px' }}>
          <label>Select Product Category Name</label>
          <Select
            type="select"
            value={productCategoryName}
            showSearch
            placeholder="Select Feature Category"
            optionFilterProp="children"
            onChange={onChangeProductCat}
            onSearch={onSearchProductCat}
            style={{ width: '100%' }}
          >
            <Option className="loginSelect" value="">
              Select Product Category Name
            </Option>
            {productServiceCat.data.productCategoryList.map((d, id) => (
              <Option
                key={id}
                className="loginSelect"
                value={`${d.id},${d.name}`}
                style={{ textTransform: 'capitalize' }}
              >
                {d.name}
              </Option>
            ))}
          </Select>
        </FormGroup>
        <FormGroup style={{ margin: '10px 1px' }}>
          <label>Select Type</label>
          <Select
            type="select"
            value={type}
            showSearch
            placeholder="Select Feature Category"
            optionFilterProp="children"
            onChange={onChangeFeature}
            onSearch={onSearchFeature}
            style={{ width: '100%', textTransform: 'capitalize' }}
          >
            <Option className="loginSelect" value="">
              Select Type
            </Option>
            {labelOptionsDropDown.map((d, id) => (
              <Option key={id} className="loginSelect" value={d.name}>
                {d.name}
              </Option>
            ))}
          </Select>
        </FormGroup>

        <FormGroup>
          <Col xs="12" sm="12" md="12">
            <label>Icon</label>
            <div style={{ textAlign: 'center' }}>
              {
                <img
                  src={
                    profilePictureStatus
                      ? `${updatedPhoto}`
                      : `${Util.imageUrl}${featureIcon}`
                  }
                  style={{
                    width: '50%',
                    height: '150px',
                    border: 'rounded',
                  }}
                />
              }
            </div>{' '}
            <div style={{ marginTop: '10px' }}>
              <FileBase64
                multiple={false}
                id="UpdateProductService"
                accept=".jpeg, .png, .jpg, .svg"
                onDone={handleAttachments}
              />
            </div>
          </Col>
        </FormGroup>
        <FormGroup>
          <Label>Status</Label>
          <br />
          <Switch
            defaultChecked={isActive}
            name="isActive"
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            onChange={onChange}
          // style={{ width: '100%' }}
          />
        </FormGroup>
        <Button
          className="cust-button"
          onClick={handleSubmit}
          // disabled={addedServiceType.isLoading || !serviceType}
          type="submit"
        >
          {/* {addedServiceType.isLoading ? <Spin /> : 'Add'} */}
          Edit
        </Button>
      </Form>
    </>
  )
}

export default UpdateProductServiceSubCategory
