import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  Table,
  UncontrolledCollapse,
} from 'reactstrap'
import { Alert, Popconfirm, Tooltip, Spin, Select, Collapse } from 'antd'
import {
  getComplaintList,
  resetgetComplaintList,
} from 'redux/actions/getComplaintListAction'
// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'
import DisplayAttachment from 'components/Custom/DisplayAttachment'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  addComplaintStatus,
  resetComplaintStatus,
} from 'redux/actions/addComplaintStatusAction'
import {
  addComplaintMessage,
  resetAddComplaintMessage,
  getComplaintMessage,
  resetGetComplaintMessage,
} from 'redux/actions/complaintMessageAction'
import PaginationComponent from 'react-reactstrap-pagination'

import RightIcon from 'assets/img/Icons/options/right.png'
import WrongIcon from 'assets/img/Icons/options/wrong.png'
import Send from '../../../../assets/img/send.png'

function Complaints() {
  const [status, setStatus] = useState('pending')
  const [complainId, setComplainId] = useState('')
  const [viewDetails, setViewDetails] = useState(false)
  const [fileModal, setFileModal] = useState(false)
  const [attachment, setAttachment] = useState(false)
  const [limit] = useState(5)
  const [page, setPage] = useState(1)
  const screenRef = React.createRef()
  const [profilePictureStatus, setprofilePictureStatus] = useState(false)
  const [iconImage, setIconImage] = useState('')
  const [updatedPhoto, setUpdatedPhoto] = useState('')

  const complaintList = useSelector(({ complaintList }) => complaintList)

  const ComplainStatus = useSelector(
    ({ addComplainStatus }) => addComplainStatus
  )

  const getComplaintMessageData = useSelector(
    ({ getComplaintMessage }) => getComplaintMessage
  )
  const addComplaintMessageData = useSelector(
    ({ addComplaintMessage }) => addComplaintMessage
  )

  const { Panel } = Collapse
  // const {
  //   data: { user, society },
  // } = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const [message, setMessage] = useState({
    id: '',
    content: '',
    attachment: '',
  })
  const [msgAtt, setMsgAtt] = useState('')

  const login = useSelector(({ login }) => login)
  const totalItems = complaintList.data.count
  // useEffect(() => {
  //   getComplainListRequest('pending')
  //   return () => {
  //     dispatch(resetgetComplaintList())
  //     dispatch(resetAddComplaintMessage())
  //     dispatch(resetGetComplaintMessage())
  //   }
  // }, [])

  const handleStatus = (status) => {
    setStatus(status)
    getComplainListRequest(status)
  }

  const getComplainListRequest = (status) => {
    const getComplainListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      complainStatus: status,
      limit,
      page,
    }
    return dispatch(getComplaintList(getComplainListReq))
  }
  const handlePagination = (selectedPage) => {
    setPage(selectedPage)
  }
  useEffect(() => {
    getComplainListRequest(status)
  }, [page])

  const handleComplaintStatus = (id, status) => {
    const complaintStatusReq = {
      initiatedBy: login.data.user.role,
      complainId: id,
      complainStatus: status,
    }
    return dispatch(addComplaintStatus(complaintStatusReq))
  }

  const attachmentType = (a) => {
    const myArr = a.split(',')
    switch (myArr[0]) {
      case 'data:image/png;base64':
      case 'data:image/jpeg;base64':
      case 'data:image/jpg;base64':
        return 'image'
      default:
        return 'notImage'
    }
  }

  //Open Files
  const handleOpenFile = (a) => {
    setAttachment(a)
    setFileModal(true)
  }

  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }
  const scrollHandle = () => {
    const scrollY = window.scrollY
  }

  useEffect(() => {
    setPage(1)
  }, [complaintList.failed])

  useEffect(() => {
    if (ComplainStatus.success) {
      getComplainListRequest('pending')
      NotificationPopups('success', 'Success', ComplainStatus.data.message)
    }
    if (ComplainStatus.failed) {
      NotificationPopups('error', 'Failed', ComplainStatus.data.message)
    }
    if (ComplainStatus.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetComplaintStatus())
    }
  }, [ComplainStatus.success, ComplainStatus.failed, ComplainStatus.network])

  const getComplaintMessageList = (id) => {
    const req = {
      initiatedBy: login.data.user.role,
      complainId: id,
    }
    dispatch(getComplaintMessage(req))
  }

  const handleAttachments = (filesList) => {
    setprofilePictureStatus(true)
    const icon = filesList.base64.split(',')
    setIconImage(icon[1])
    setUpdatedPhoto(filesList.base64)
  }

  const handleComplainMessage = (evt, id) => {
    const {
      target: { value },
    } = evt
    if (Util.checkWhiteSpace(value)) {
      setMessage({
        ...message,
        id: id,
        content: value,
        // attachment,
      })
    }
  }

  const handleAddComplainMessage = (id) => {
    // if (message.content != '' || message.attachment != '') {
    const req = {
      initiatedBy: login.data.user.role,
      role: login.data.user.role,
      adminId: login.data.user.id,
      content: message.content.trim(),
      // content:
      //   message.id === id && message.content
      //     ? message.content.trim()
      //     : 'PLease Check the Image',
      attachment: profilePictureStatus ? iconImage : '',
      complainId: id,
    }
    return dispatch(addComplaintMessage(req))
    // }
  }

  useEffect(() => {
    if (addComplaintMessageData.success) {
      getComplaintMessageList(complainId)
      setMessage({
        id: '',
        content: '',
        attachment: '',
      })
      setIconImage('')
      NotificationPopups('success', 'Success', addComplaintMessageData.data.message)
    }
    if (addComplaintMessageData.failed) {
      NotificationPopups('error', 'Failed', (addComplaintMessageData.data.errorVOList.map((item, id) => (<>{id === 0 ? 1 : id + 1}-{item.errorMessage}</>))))
    }
    return () => {
      dispatch(resetAddComplaintMessage())
    }
  }, [addComplaintMessageData.success, addComplaintMessageData.failed])

  return (
    <Table responsive style={{ textAlign: 'center', fontSize: '12px' }}>
      <thead>
        <tr style={{ fontSize: '15px', fontWeight: 'bold' }}>
          <th>Raised By</th>
          <th>Complain Title</th>
          {/* <th>Email</th> */}
          {/* <th>Priority</th> */}
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {complaintList.success &&
          complaintList.data.complainDetails.map((d, id) => (
            <React.Fragment key={id}>
              <tr
                key={id}
                id={`toggler${id}`}
                onClick={() => {
                  getComplaintMessageList(d.id)
                  setComplainId(d.id)
                  complainId === d.id
                    ? setViewDetails(!viewDetails)
                    : setViewDetails(viewDetails)
                }}
                style={{ cursor: 'pointer' }}
              >
                <td>
                  {d.userName}
                  <br />
                  {d.areaName} / {d.unit}
                  <br />
                  {`(${d.mobileNumber})`}
                </td>
                {/* <td>{d.userName}</td>
                <td>{d.mobileNumber}</td> */}
                <td>{d.complainTitle}</td>
                <td>
                  <Popconfirm
                    title="Resolved Complain ?"
                    onConfirm={() => handleComplaintStatus(d.id, 'resolved')}
                    okText={addComplaintMessageData.isLoading ? <Spin /> : "Resolved"}
                    cancelText="Cancel"
                  >
                    <Tooltip
                      placement="leftBottom"
                      title="Resolve"
                      color="#108ee9"
                    >
                      <img
                        className="amenity_option_icon"
                        src={RightIcon}
                        alt="RightIcon"
                      />
                    </Tooltip>
                  </Popconfirm>
                  {'|'}
                  <Popconfirm
                    title="Reject Complain ?"
                    onConfirm={() => handleComplaintStatus(d.id, 'rejected')}
                    okText={addComplaintMessageData.isLoading ? <Spin /> : "Reject"}
                    cancelText="Cancel"
                  >
                    <Tooltip
                      placement="rightBottom"
                      title="Reject"
                      color="#108ee9"
                    >
                      <img
                        className="amenity_option_icon"
                        src={WrongIcon}
                        alt="WrongIcon"
                      />
                    </Tooltip>
                  </Popconfirm>
                </td>
              </tr>
              {viewDetails && complainId === d.id && (
                <tr className="ml-detail-tr">
                  <td colSpan="6" className="ml-detail-td">
                    <Row className="ml-detail-row">
                      <Col
                        xs="12"
                        sm="6"
                        md="12"
                        lg="12"
                        style={{
                          padding: '20px',
                        }}
                      >
                        <label>Message</label>
                      </Col>
                    </Row>
                    <Row
                      className="ml-detail-row sidebar-wrapper"
                      onScroll={scrollHandle}
                      style={{
                        maxHeight: '250px',
                        overflow: 'auto',
                        margin: 'auto',
                        width: '80%'
                      }}
                      id="scrollbar-sidebar"
                    >
                      {getComplaintMessageData.success &&
                        getComplaintMessageData.record[d.id] &&
                        getComplaintMessageData.record[d.id].map(
                          (data, index) => (
                            <>
                              <Col
                                xs="12"
                                sm="6"
                                md="4"
                                lg="4"
                                style={{
                                  padding: '10px 5px',
                                  marginLeft: "auto"
                                }}
                              >
                                {data.attachment && (
                                  <div
                                    onClick={() =>
                                      handleOpenFile(data.attachment)
                                    }
                                  >
                                    {data.attachment ? (
                                      <img
                                        src={`${Util.imageUrl}${data.attachment}`}
                                        style={{
                                          width: '100px',
                                          cursor: 'pointer'
                                        }}
                                        alt="image"
                                      />
                                    ) : (
                                      <i
                                        className="far fa-file-alt"
                                        style={{
                                          fontSize: '60px',
                                        }}
                                      ></i>
                                    )}
                                  </div>
                                )}
                              </Col>
                              <Col
                                xs="12"
                                sm="6"
                                md="12"
                                lg="12"
                                style={{
                                  padding: '10px 5px',
                                  borderBottom: '1px solid #ebecf1',
                                }}
                              >
                                <div
                                  className="detail-val"
                                  style={{
                                    float:
                                      data.role === 'Society'
                                        ? 'right'
                                        : 'left',
                                  }}
                                >
                                  {data.content ?
                                    <div
                                      className={`custom-text-message-${data.role === 'Society' ? 'right' : 'left'
                                        }`}
                                    >
                                      {data.content && data.content}
                                    </div>
                                    :
                                    null
                                  }

                                </div>
                                <div
                                  className="detail-time"
                                  style={{
                                    textAlign:
                                      data.role === 'Society'
                                        ? 'right'
                                        : 'left',
                                  }}
                                >
                                  {Util.formatDateTimeHour(data.timestamp)}
                                </div>
                              </Col>
                            </>
                          )
                        )}
                    </Row>

                    {d.complainStatus === 'pending' && (
                      <>
                        <div className="detail-val">
                          <Row style={{ justifyContent: 'center' }}>
                            <Col
                              xs="12"
                              sm="12"
                              md="6"
                              lg="6"
                              style={{ padding: '10px' }}
                            >
                              <FormGroup>
                                <Input
                                  type="textarea"
                                  row="1"
                                  name={message.content}
                                  value={
                                    message.id === d.id ? message.content : ''
                                  }
                                  //id={}
                                  placeholder="Reply"
                                  // defaultValue={message[d.complainId] && message[d.complainId].content}
                                  onChange={(e) =>
                                    handleComplainMessage(e, d.id)
                                  }
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col
                              xs="12"
                              sm="12"
                              md="3"
                              lg="3"
                              style={{ padding: '25px 10px' }}
                            >
                              {iconImage && (
                                <img
                                  src={`${updatedPhoto}`}
                                  width="40px"
                                  height="40px"
                                  style={{
                                    display: 'block',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    marginBottom: '10px'
                                  }}
                                />
                              )}
                              <FileBase64
                                multiple={false}
                                name={message.attachment}
                                value={msgAtt}
                                id={`remark_${d.id}`}
                                accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                                onDone={handleAttachments}
                              />
                            </Col>
                            <Col
                              xs="1"
                              sm="1"
                              md="1"
                              lg="1"
                              style={{ margin: '30px 0px 0px 0px' }}
                            >
                              {/* <Button
                                className="cust-button"
                                style={{ minWidth: '100%' }}
                                onClick={() => handleAddComplainMessage(d.id)}
                              >
                                Send
                              </Button> */}
                              <img src={Send}
                                onClick={() => handleAddComplainMessage(d.id)}
                                style={{ height: '25px', cursor: 'pointer' }}
                              ></img>
                            </Col>
                          </Row>
                        </div>
                        <div className="detail-val">
                          <span
                            className="right-menu"
                            onClick={() =>
                              handleComplaintStatus(d.id, 'resolved')
                            }
                          >
                            Resolved
                          </span>
                          {' | '}
                          <span
                            className="right-menu"
                            onClick={() =>
                              handleComplaintStatus(d.id, 'rejected')
                            }
                          >
                            Reject
                          </span>
                        </div>
                      </>
                    )}

                    {d.complainStatus === 'resolved' && (
                      <>
                        <div className="detail-val">
                          <Row style={{ justifyContent: 'center' }}>
                            <Col
                              xs="12"
                              sm="12"
                              md="6"
                              lg="6"
                              style={{ padding: '10px' }}
                            >
                              <FormGroup>
                                <Input
                                  type="textarea"
                                  row="1"
                                  name={message.content}
                                  value={
                                    message.id === d.id ? message.content : ''
                                  }
                                  //id={}
                                  placeholder="Reply"
                                  // defaultValue={message[d.complainId] && message[d.complainId].content}
                                  onChange={(e) =>
                                    handleComplainMessage(e, d.id)
                                  }
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col
                              xs="12"
                              sm="12"
                              md="3"
                              lg="3"
                              style={{ padding: '25px 10px' }}
                            >
                              <FileBase64
                                multiple={false}
                                name={message.attachment}
                                value={
                                  // message.id === d.id
                                  // ?
                                  msgAtt
                                }
                                id={`remark_${d.id}`}
                                accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                                onDone={handleAttachments}
                              />
                              {/* <FileBase64
                                 multiple={false}
                                 // id={type.type}
                                 value={msgAtt}
                                 accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                                 onDone={handleAttachments}
                               /> */}
                            </Col>
                            <Col
                              xs="12"
                              sm="12"
                              md="3"
                              lg="3"
                              style={{ padding: '25px 10px' }}
                            >
                              <Button
                                className="cust-button"
                                style={{ minWidth: '100%' }}
                                onClick={() => handleAddComplainMessage(d.id)}
                              >
                                Send
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}

        {fileModal && (
          <DisplayAttachment
            attachment={attachment}
            fileModal={fileModal}
            toggleModal={toggleFileModal}
          />
        )}
      </tbody>
    </Table>
  )
}

export default Complaints
