import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  FormFeedback,
  Input,
  Row,
  Col,
} from 'reactstrap'
import { Spin, Select } from 'antd'
import { useHistory } from 'react-router-dom'
import {
  resetgetPincodeDetails,
  getPincodeDetails,
} from 'redux/actions/pincodeAction'
import {
  resetLinkNewSociety,
  linkNewSociety,
} from 'redux/actions/linkSocietyAction'
import {
  resetValidateSignupOtp,
  validateSignupOtp,
} from 'redux/actions/validateSignupOtpAction'
import { adminSignup, resetAdminSignup } from 'redux/actions/adminSignupAction'
// Country Region Dropdown
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import {
  resetgetSocietyList,
  getSocietyList,
} from 'redux/actions/getSocietyListAction'
import { resetGetCityList, getCityList } from 'redux/actions/getCityListAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import axios from 'config/axios'
import FileBase64 from '../../components/Custom/UploadFile'
import Util from 'validations/index'

function RegisterSociety(isLoggedUser) {
  // console.log(isLoggedUser, 'user logged in')
  const [activeTab, setActiveTab] = useState('')
  const [city, setCity] = useState('')
  const [societyId, setSocietyId] = useState('')
  const [societyName, setSocietyName] = useState('')
  const [societyAddress, setsocietyAddress] = useState('')
  const [landmark, setlandmark] = useState('')
  const [areaBlock, setareaBlock] = useState('')
  const [region, setRegion] = useState('')
  const [newCity, setNewCity] = useState('')
  const [pincode, setPincode] = useState('')
  const [role, setRole] = useState('')
  const [societyImage, setSocietyImage] = useState('')
  const [photo, setPhoto] = useState('')
  const [registrationNumber, setRegistration] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [email, setEmail] = useState('')

  const [searchCityName, setSearchCityName] = useState('')
  const [cityName, setCityName] = useState('')
  const [searchhSocietyName, setSearchSocietyName] = useState('')
  const [socName, setSocName] = useState('')

  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])

  const [toggleSocietyDetails, setToggleSocietyDetails] = useState(false)
  const login = useSelector(({ login }) => login)
  console.log(login.succes && login.data.user.id)
  const cityList = useSelector(({ getCityList }) => getCityList)

  const societyList = useSelector(({ getSocietyList }) => getSocietyList)
  const adminSignup = useSelector(({ adminSignup }) => adminSignup)
  console.log(adminSignup,"adminSignupsssss")
  const validateSignupOTP = useSelector(
    ({ validateSignupOTP }) => validateSignupOTP
  )
  const getpincode = useSelector(({ pincode }) => pincode)
  const linkSociety = useSelector(({ linkSociety }) => linkSociety)
  const dispatch = useDispatch()
  const { Option } = Select

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  function onChangeCity(value) {
    setCity(value)
    setSearchCityName('')
    setSearchSocietyName('')
    setSocName('')
  }
  function onSearchCity(value) {
    setSearchCityName(value)
  }
  function onChangeSearch(value) {
    const val = value.split(',')
    setSocietyId(val[0])
    setSocName(val[1])
    setSearchSocietyName('')
  }

  function onSearch(val) {
    setSearchSocietyName(val)
  }

  const getCityListRequest = () => {
    const getCityListReq = {
      initiatedBy: 'Society',
    }
    return dispatch(getCityList(getCityListReq))
  }

  useEffect(() => {
    getCityListRequest()
  }, [])

  const handleCity = ({ target: { name, value } }) => {
    if (name === 'city') setCity(value)
  }

  useEffect(() => {
    const getSocListReq = {
      city,
      initiatedBy: 'Society',
    }
    return dispatch(getSocietyList(getSocListReq))
  }, [city])

  useEffect(() => {
    if (searchCityName != '') {
      const userData = {
        initiatedBy: 'Society',
        type: searchCityName,
      }
      axios.post('society/allCityList', userData).then((res) => {
        // console.log(res.data.activeType, 'res.data.activeType')
        setOptions2(res.data.city)
      })
    }
  }, [searchCityName])

  useEffect(() => {
    if (searchhSocietyName != '') {
      const userData = {
        city,
        initiatedBy: 'Society',
        societyName: searchhSocietyName,
      }
      axios.post('society/getSocietyList', userData).then((res) => {
        setOptions(res.data.societyList)
      })
    }
  }, [searchhSocietyName, city])

  const linkSocietyRequest = () => {
    let linkSocietyRequest = ''

    if (toggleSocietyDetails) {
      linkSocietyRequest = {
        initiatedBy: 'Society',
        user: {
          id: adminSignup.data.user.id,
        },
        society: {
          societyName: societyName.trim(),
          address: societyAddress.trim(),
          landmark: landmark.trim(),
          state: region.trim(),
          city: newCity.trim(),
          societyBlock: areaBlock.trim(),
          pincode:pincode,
          role: role.trim(),
          photo,
          registrationNumber: registrationNumber,
          billingEmail: email
        },
      }
    } else {
      linkSocietyRequest = {
        initiatedBy: 'Society',
        society: {
          id: societyId,
        },
        user: {
          id: adminSignup.data.user.id,
        },
      }
    }
    return dispatch(linkNewSociety(linkSocietyRequest))
  }

  const selectOptions = [
    { id: 1, role: 'Secretary' },
    { id: 2, role: 'Tresure' },
    { id: 3, role: 'Commitee Member' },
  ]

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'societyId') setSocietyId(value)
    if (name === 'societyName')
      Util.alphabetsOnly(value)
        ? setSocietyName(value)
        : setSocietyName(societyName)
    if (name === 'societyAddress') setsocietyAddress(value)
    if (name === 'landmark')
      Util.alphabetsOnly(value) ? setlandmark(value) : setlandmark(landmark)
    if (name === 'newCity')
      Util.alphabetsOnly(value) ? setNewCity(value) : setNewCity(newCity)
    if (name === 'pincode') {
      setPincode(value)
      if (value.length === 6) {
        getPincodeRequest(value)
      }
    }
    if (name === 'region')
      Util.alphabetsOnly(value) ? setRegion(value) : setRegion(region)
    if (name === 'areaBlock')
      Util.alphabetsOnly(value) ? setareaBlock(value) : setareaBlock(areaBlock)
    if (name === 'role')
      Util.alphabetsOnly(value) ? setRole(value) : setRole('')
    if (name === 'registration')
      Util.verifyMobile(value)
        ? setRegistration(value)
        : setRegistration(registrationNumber)
    if (name === 'email') {
      setIsEmailValid(Util.verifyEmail(value))
      setEmail(value.trim())
    }
  }

  const getPincodeRequest = (pin) => {
    console.log(pin,"pincode")
    return dispatch(getPincodeDetails(pin))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    linkSocietyRequest()
    if (linkSociety.success) {
      NotificationPopups('success', 'Success', linkSociety.data.message)
    }
  }

  const handleSocietyToggleChange = () => {
    setToggleSocietyDetails(!toggleSocietyDetails)
  }
  const handleAttachments = (filesList) => {
    let img = filesList.base64.split(`,`)
    setPhoto(img[1])
    setSocietyImage(filesList.base64)
    // setSocietyImage(filesList.base64)
  }

  //
  useEffect(() => {
    if (linkSociety.success) {
      // toggle('1')
      setCity('')
      setSocietyId('')
      setSocietyName('')
      setsocietyAddress('')
      setlandmark('')
      setareaBlock('')
      setRegion('')
      setNewCity('')
      setPincode('')
      setRole('')
      setPhoto('')
      setSocietyImage('')
      setIsEmailValid('')
      setEmail('')
      setRegistration('')
      // NotificationPopups('success', 'Success', linkSociety.data.message)
    }
    if (linkSociety.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    // if (linkSociety.failed) {
    //   NotificationPopups('error', 'Failed', (linkSociety.data.errorVOList.map((ele ,id) =>(<>{id === 0 ? 1 : id+1}-{ele.errorMessage}<br/></>))))
    // }
    return () => {
      dispatch(resetLinkNewSociety())
      dispatch(resetgetSocietyList())
      dispatch(resetValidateSignupOtp())
      dispatch(resetAdminSignup())
      dispatch(resetgetPincodeDetails())
    }
  }, [linkSociety.success, linkSociety.network, linkSociety.failed])

  useEffect(() => {
    if (validateSignupOTP.success) {
      // NotificationPopups('success', 'Success', validateSignupOTP.data.message)
    }
  }, [validateSignupOTP.success])

  useEffect(() => {
    if (getpincode.success) {
      setareaBlock(getpincode.block)
      setNewCity(getpincode.city)
      setRegion(getpincode.state)
    }
    if (getpincode.failed) {
      setareaBlock('')
      setNewCity('')
      setRegion('')
    }
    return () => {
      dispatch(resetgetPincodeDetails())
    }
  }, [getpincode.success, getpincode.failed])

  const disabledButton = () => {
    return !(city && societyId)
  }

  const disabledSocButton = () => {
    return !(
      societyName &&
      societyAddress &&
      landmark &&
      areaBlock &&
      region &&
      newCity &&
      pincode &&
      societyImage
    )
  }

  if (toggleSocietyDetails) {
    return (
      <Card>
        <Row>
          <Col>
            <CardBody className="registerform">
              <FormGroup style={{ margin: '20px 0' }}>
                <label>SOCIETY NAME <span className="errorText">*</span></label>
                <Input
                  type="text"
                  name="societyName"
                  id="societyname"
                  value={societyName || ''}
                  onChange={handleChange}
                  required
                  placeholder="Society Name"
                />
              </FormGroup>
              <FormGroup style={{ margin: '20px 0' }}>
                <label>
                  BILLING EMAIL <span className="errorText">*</span>
                </label>
                <Input
                  invalid={email && !isEmailValid}
                  placeholder="Email"
                  type="email"
                  name="email"
                  value={email || ''}
                  onChange={handleChange}
                />
                <FormFeedback style={{ textAlign: 'center' }}>
                  {email && !isEmailValid ? 'Invalid Email' : ''}
                </FormFeedback>
              </FormGroup>
              <FormGroup style={{ margin: '20px 0' }}>
                <label>ADDRESS <span className="errorText">*</span></label>
                <Input
                  type="textarea"
                  rows="4"
                  name="societyAddress"
                  id="address1"
                  value={societyAddress || ''}
                  onChange={handleChange}
                  required
                  placeholder="Address"
                />
              </FormGroup>
              <FormGroup style={{ margin: '20px 0' }}>
                <label>LANDMARK <span className="errorText">*</span></label>
                <Input
                  type="text"
                  name="landmark"
                  id="landmark"
                  value={landmark || ''}
                  onChange={handleChange}
                  required
                  placeholder="Landmark"
                />
              </FormGroup>
              <FormGroup style={{ margin: '20px 0' }}>
                <label>PINCODE <span className="errorText">*</span></label>
                <Input
                  type="text"
                  name="pincode"
                  id="pincode"
                  minLength={6}
                  maxLength={6}
                  value={pincode || ''}
                  onChange={handleChange}
                  required
                  invalid={!(pincode && pincode.length === 6)}
                  placeholder="Pincode"
                />
                <FormFeedback style={{ textAlign: 'center' }}>
                  {pincode && !(pincode.length === 6)
                    ? 'Invalid Pincode. Enter 6 Digits'
                    : ''}
                  {/* {pincode.length === 6 && getpincode.failed ? "Incorrect Pincode" : ""} */}
                </FormFeedback>
              </FormGroup>
              <FormGroup style={{ margin: '20px 0' }}>
                <label>BLOCK <span className="errorText">*</span></label>
                <Input
                  type="text"
                  name="areaBlock"
                  id="areaBlock"
                  value={areaBlock || ''}
                  onChange={handleChange}
                  required
                  placeholder="Block"
                />
              </FormGroup>
              <FormGroup style={{ margin: '20px 0' }}>
                <label>CITY <span className="errorText">*</span></label>
                <Input
                  type="text"
                  name="newCity"
                  id="newCity"
                  value={newCity || ''}
                  onChange={handleChange}
                  required
                  placeholder="City"
                />
              </FormGroup>
              <FormGroup style={{ margin: '20px 0' }}>
                <label>STATE <span className="errorText">*</span></label>
                <Input
                  type="text"
                  name="region"
                  id="region"
                  value={region || ''}
                  onChange={handleChange}
                  required
                  placeholder="State"
                />
              </FormGroup>
              <FormGroup style={{ margin: '20px 0' }}>
                <label>
                  REGISTRATION NUMBER <span className="errorText">*</span>
                </label>
                <Input
                  placeholder="Registration Number"
                  type="text"
                  maxLength="10"
                  name="registration"
                  value={registrationNumber || ''}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup style={{ margin: '20px 0' }}>
                <label>SOCIETY IMAGE</label>
                <FileBase64
                  multiple={false}
                  id="registerSocietyPhoto"
                  accept=".jpeg, .png, .jpg"
                  onDone={handleAttachments}
                />
                {societyImage && (
                  <img
                    src={societyImage}
                    width="50%"
                    height="150"
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  />
                )}
              </FormGroup>
              <FormGroup
                style={{ margin: '54px 0px 0px', textAlign: 'center' }}
              >
                <Button
                  onClick={handleSubmit}
                  // disabled={linkSociety.isLoading || disabledSocButton()}
                >
                  {linkSociety.isLoading ? <Spin /> : 'Add Society'}
                </Button>
              </FormGroup>
              <div className="register" onClick={handleSocietyToggleChange}>
                Already a Registered Society ?
              </div>
            </CardBody>
          </Col>
        </Row>
      </Card>
    )
  }

  return (
    <Card>
      <Row>
        <Col>
          <CardBody>
            <FormGroup>
              <Label for="city">City <span className='required'>*</span></Label>
              <Select
                type="select"
                value={city}
                showSearch
                placeholder="Select your society name"
                onChange={onChangeCity}
                onSearch={onSearchCity}
                style={{ width: '100%' }}
              >
                <Option value="">Select your city name</Option>
                {searchCityName != '' &&
                  options2 != undefined &&
                  options2.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d}>
                      {d}
                    </Option>
                  ))}
                {cityList.success &&
                  searchCityName === '' &&
                  cityList.data.city.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d}>
                      {d}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
            <FormGroup style={{ margin: '20px 0' }}>
              <label>Society <span className='required'>*</span></label>
              <Select
                type="select"
                showSearch
                value={socName}
                placeholder="Select your society name"
                optionFilterProp="children"
                onChange={onChangeSearch}
                onSearch={onSearch}
                style={{ width: '100%' }}
              >
                <Option value="">Select your society name</Option>
                {/* {searchhSocietyName != '' &&
                  options != undefined &&
                  options.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={[d.id, d.societyName]}
                    >
                      {d.societyName}
                    </Option>
                  ))} */}
                {societyList.success &&
                  searchhSocietyName === '' &&
                  societyList.data.societyList.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.id},${d.societyName}`}
                    >
                      {d.societyName}
                    </Option>
                  ))}

                {searchhSocietyName != '' &&
                  options != undefined &&
                  options.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.id},${d.societyName}`}
                    >
                      {d.societyName}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
            <FormGroup style={{ margin: '54px 0px 0px', textAlign: 'center' }}>
              <Button
                onClick={handleSubmit}
                disabled={linkSociety.isLoading || disabledButton()}
              >
                {linkSociety.isLoading ? <Spin /> : 'Link Society'}
              </Button>
            </FormGroup>
            <div style={{textAlign:'center'}}>
              <span>Did not find your society?</span><br /><span className="register" onClick={handleSocietyToggleChange}>Add Here!</span>
            </div>
          </CardBody>
        </Col>
      </Row>
    </Card>
  )
}

export default RegisterSociety
