import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// reactstrap components
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'

import { Spin, Select } from 'antd'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import {
  resetgetServiceType,
  getServiceType,
} from 'redux/actions/getServiceTypeAction'
import { resetgetAreaType, getAreaType } from 'redux/actions/getAreaTypeAction'
import { resetaddHelper, addHelper } from 'redux/actions/addHelperAction'
import {
  resetgetHelperType,
  getHelperType,
} from 'redux/actions/getHelperTypeAction'
import {
  resetgetHelperDetails,
  getHelperDetails,
} from 'redux/actions/getHelperDetailsAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import Util from 'validations/index'
import axios from 'config/axios'

function SearchHelper(props) {
  console.log(props, "props")
  const [areaType, setareaType] = useState('')
  const [areaTypeId, setareaTypeId] = useState('')
  const [areaId, setareaId] = useState()
  const [flatid, setflatid] = useState()
  const [initiatedBy, setInitiatedBy] = useState('Society')
  const [helperTypeId, setHelperType] = useState('')
  const [helperName, sethelperName] = useState('')
  const [helperMobile, sethelperMobile] = useState()
  const [helperPhoto, sethelperPhoto] = useState('')
  const [serviceTypeId, setServiceTypeId] = useState('')
  const [searchAreaType, setSearchAreaType] = useState('')
  const [searchFlat, setSearchFlat] = useState('')
  const [searchArea, setStearchArea] = useState('')
  const [searchServType, setSearchServType] = useState('')
  const [searchHelperType, setSearchHelperType] = useState('')
  const [optionsAreaType, setOptionsAreaType] = useState([''])
  const [optionsUnit, setOptionsUnit] = useState([''])
  const [optionsServType, setOptionsServType] = useState([''])
  const [optionsHelperType, setOptionsHelperType] = useState([''])

  const [bldgName, setBldgName] = useState('')
  const [flatName, setFlatName] = useState('')
  const login = useSelector(({ login }) => login)
  const areaTypes = useSelector(({ areaType }) => areaType)

  // const buildingList = useSelector(({ buildingList }) => buildingList)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  // const getFlat = useSelector(({ getFlatList }) => getFlatList)
  const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)
  const dailyHelpType = useSelector(({ dailyHelpType }) => dailyHelpType)
  const servType = useSelector(({ getservicetype }) => getservicetype)
  const helperTypeList = useSelector(({ getHelperType }) => getHelperType)
  const helperAdded = useSelector(({ addHelper }) => addHelper)
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'areaType') setareaType(value)
    if (name === 'areaId') setareaId(value)
    if (name === 'flatid') setflatid(value)
    if (name === 'serviceTypeId') setServiceTypeId(value)
    if (name === 'helperType') setHelperType(value)
    if (name === 'helperName') {
      Util.alphabetsOnly(value)
        ? sethelperName(value)
        : sethelperName(helperName)
    }
    if (name === 'helperMobile') {
      Util.verifyMobile(value)
        ? sethelperMobile(value)
        : sethelperMobile(helperMobile)
    }
    if (name === 'helperPhoto') sethelperPhoto(value)
  }
  const { Option } = Select

  function onChangeSearchAreaType(value) {
    setareaType(value)
    setareaId('')
    setSearchAreaType('')
  }

  function onSearchAreaType(val) {
    setareaType(val)
  }

  function onChangeSearchAreaId(value) {
    setareaId(value[0])
    // setBuildingId(value[0])
    setBldgName(value[1])
    setSearchAreaType('')
    setflatid('')
    setFlatName('')
  }

  function onSearchAreaId(val) {
    setSearchAreaType(val)
  }

  function onChangeSearchFlatId(value) {
    setflatid(value[0])
    setFlatName(value[1])
    setSearchFlat('')
  }

  function onSearchFlatId(val) {
    setSearchFlat(val)
  }

  function onChangeSearchServType(value) {
    setServiceTypeId(value)
    setHelperType('')
    setSearchServType('')
    console.log(value, serviceTypeId)
  }

  function onSearchServType(val) {
    setSearchServType(val)
  }

  function onChangeSearchHelperType(value) {
    setHelperType(value)
    setSearchHelperType('')
  }

  function onSearchHelperType(val) {
    setSearchHelperType(val)
  }
  //search of area type
  useEffect(() => {
    if (searchAreaType != '') {
      const userData = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: searchAreaType,
      }
      axios.post('society/getAreaList', userData).then((res) => {
        if (res.status === 200 && res.data.activeType != '') {
          setOptionsAreaType(res.data.activeType)
        }
      })
    }
  }, [searchAreaType])

  //search of flat
  useEffect(() => {
    if (areaType === 'Building' && areaId && searchFlat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId,
        areaType: 'Flat',
        unit: searchFlat,
      }
      axios.post('society/getUnitList', userData).then((res) => {
        if (res.status === 200 && res.data.activeType != '') {
          setOptionsUnit(res.data.activeType)
        }
      })
    }
  }, [searchFlat])

  //search service type
  useEffect(() => {
    if (searchServType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        type: searchServType,
      }
      axios.post('helper/getServiceType', userData).then((res) => {
        if (res.status === 200 && res.data.activeType != '') {
          setOptionsServType(res.data.activeType)
        }
      })
    }
  }, [searchServType])

  //search helper type
  useEffect(() => {
    if (searchHelperType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        serviceTypeId,
        type: searchHelperType,
      }
      axios.post('helper/getHelperType', userData).then((res) => {
        if (res.status === 200 && res.data.helperTypeList != '') {
          setOptionsHelperType(res.data.helperTypeList)
        }
      })
    }
  }, [searchHelperType, serviceTypeId])

  useEffect(() => {
    if (helperPhoto != '') {
      NotificationPopups('success', 'Success', 'Image Uploaded')
    }
  }, [helperPhoto])

  useEffect(() => {
    const getServiceTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getServiceType(getServiceTypeReq))
  }, [])

  useEffect(() => {
    const areaTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getAreaType(areaTypeReq))
  }, [])

  useEffect(() => {
    if (login.data.user.role !== 'SuperAdmin' && areaType != '') {
      const getBuildingListReq = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      return dispatch(getBuildingList(getBuildingListReq))
    }
  }, [areaType])

  useEffect(() => {
    if (areaType === 'Building' && areaId) {
      const getUnitFlatListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: areaId,
        areaType: 'Flat',
      }
      return dispatch(getUnitFlatList(getUnitFlatListReq))
    }
  }, [areaType, areaId])

  useEffect(() => {
    if (login.data.user.role === 'Society' && serviceTypeId != '') {
      setHelperType('')
      const getHelperTypeReq = {
        initiatedBy: login.data.user.role,
        serviceTypeId,
      }
      return dispatch(getHelperType(getHelperTypeReq))
    }
  }, [serviceTypeId])

  const handleSubmit = (e) => {
    e.preventDefault()
    getHelperRequest()
    props.setIsSearch()
    const obj = {
      serviceTypeId: serviceTypeId,
      helperTypeId: helperTypeId,
      helperName: helperName.trim(),
      helperMobile: helperMobile,
    }
    send(obj)
  }

  const getHelperRequest = () => {
    if (login.data.user.role === 'Society') {
      const getHelperReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        serviceTypeId,
        helperTypeId,
        helperName: helperName.trim(),
        helperMobile : helperMobile === '' ? null : helperMobile,
        limit: 6,
      }
      // const getHelperMobileReq = {
      //   initiatedBy: login.data.user.role,
      //   societyId: login.data.society.id,
      //   serviceTypeId,
      //   helperTypeId,
      //   helperName:helperName.trim(),
      //   helperMobile,
      //   limit: 6,
      // }
      return dispatch(
        getHelperDetails(getHelperReq)
      )
    }
  }
  const send = (value) => {
    props.searchFilter(value)
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="12" sm="12" xs="12" lg="12">
            <Row>
              {/* <Col md="4" sm="12" xs="12" lg="6">
                <FormGroup>
                  <Label for="areaType">Area Type</Label>
                  <Select
                    type="select"
                    showSearch
                    value={areaType}
                    placeholder="Select  Select Area Type"
                    optionFilterProp="children"
                    onChange={onChangeSearchAreaType}
                    onSearch={onSearchAreaType}
                    style={{ width: '100%' }}
                  >
                    <Option className="loginSelect" value="">
                      Select Area Type
                    </Option>
                    {areaTypes.success &&
                      areaTypes.data.activeType.map((d, id) => (
                        <Option key={id} className="loginSelect" value={d.type}>
                          {d.type}
                        </Option>
                      ))}
                  </Select>
                </FormGroup>
              </Col> */}
              {/* {areaType === 'Building' && (
                <Col md="4" sm="12" xs="12" lg="6">
                  <FormGroup>
                    <Label for="flatid" lat>
                      Flat
                    </Label>

                    <Select
                      type="select"
                      showSearch
                      value={flatName}
                      placeholder="Select Flat Number"
                      optionFilterProp="children"
                      onChange={onChangeSearchFlatId}
                      onSearch={onSearchFlatId}
                      style={{ width: '100%' }}
                    >
                      <Option className="loginSelect" value="">
                        Select Flat Number
                      </Option>
                      {searchFlat != '' && optionsUnit != undefined
                        ? optionsUnit.map((d, id) => (
                            <Option
                              key={id}
                              className="loginSelect"
                              value={[d.id, d.type]}
                            >
                              {d.type}
                            </Option>
                          ))
                        : getFlat.success &&
                          getFlat.data.activeType.map((d, id) => (
                            <Option
                              key={id}
                              className="loginSelect"
                              value={[d.id, d.type]}
                            >
                              {d.type}
                            </Option>
                          ))}
                    </Select>
                  </FormGroup>
                </Col>
              )}
              {areaType === 'Flat' && (
                <Col md="4" sm="12" xs="12" lg="6">
                  <FormGroup>
                    <Label for="flatid" lat>
                      Flat
                    </Label>
                    <Select
                      type="select"
                      value={flatid}
                      showSearch
                      placeholder="Select Flat Number"
                      optionFilterProp="children"
                      onChange={onChangeSearchFlatId}
                      onSearch={onSearchFlatId}
                      style={{ width: '100%' }}
                    >
                      <Option className="loginSelect" value="">
                        Select Flat Number
                      </Option>
                      {searchFlat != '' && optionsUnit != undefined
                        ? optionsUnit.map((d, id) => (
                            <Option
                              key={id}
                              className="loginSelect"
                              value={d.id}
                            >
                              {d.type}
                            </Option>
                          ))
                        : getFlat.success &&
                          getFlat.data.activeType.map((d, id) => (
                            <Option
                              key={id}
                              className="loginSelect"
                              value={d.id}
                            >
                              {d.type}
                            </Option>
                          ))}
                    </Select>
                  </FormGroup>
                </Col>
              )} */}
              <Col md="4" sm="12" xs="12" lg="6">
                <FormGroup>
                  <Label for="helperType">Service Type</Label>
                  <span className='required'>*</span>
                  <Select
                    type="select"
                    value={serviceTypeId}
                    showSearch
                    placeholder="Select Service Type"
                    optionFilterProp="children"
                    onChange={onChangeSearchServType}
                    onSearch={onSearchServType}
                    style={{ width: '100%' }}
                  >
                    <Option className="loginSelect" value="">
                      Select Service Type
                    </Option>

                    {searchServType != '' && optionsServType != undefined
                      ? optionsServType.map((d, id) => (
                        <Option key={id} className="loginSelect" value={d.id}>
                          {d.type}
                        </Option>
                      ))
                      : servType.success &&
                      servType.data.activeType.map((d, id) => (
                        <Option key={id} className="loginSelect" value={d.id}>
                          {d.type}
                        </Option>
                      ))}
                    {servType.failed && (
                      <Option className="loginSelect" value="">
                        No Data Found
                      </Option>
                    )}
                  </Select>
                </FormGroup>
              </Col>
              <Col md="4" sm="12" xs="12" lg="6" id='a'>
                <FormGroup>
                  <Label for="helperType">Helper Type</Label>
                  <span className='required'>*</span>
                  <Select
                    type="select"
                    value={helperTypeId}
                    showSearch
                    placeholder="Select Helper Type"
                    optionFilterProp="children"
                    onChange={onChangeSearchHelperType}
                    onSearch={onSearchHelperType}
                    style={{ width: '100%' }}
                  >
                    <Option className="loginSelect" value="">
                      Select Helper Type
                    </Option>
                    {searchHelperType != '' && optionsHelperType != undefined
                      ? optionsHelperType.map((d, id) => (
                        <Option key={id} className="loginSelect" value={d.id}>
                          {d.type}
                        </Option>
                      ))
                      : helperTypeList.success &&
                      helperTypeList.data.helperTypeList.map((d, id) => (
                        <Option key={id} className="loginSelect" value={d.id}>
                          {d.type}
                        </Option>
                      ))}
                  </Select>
                </FormGroup>
              </Col>
              <Col md="6" sm="12" xs="12" lg="6">
                <FormGroup>
                  <Label for="helperName">Name</Label>
                  <Input
                    type="text"
                    name="helperName"
                    id="helperName"
                    placeholder="Name"
                    value={helperName || ''}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6" sm="12" xs="12" lg="6">
                <FormGroup>
                  <Label for="helperMobile">Mobile</Label>
                  <Input
                    type="text"
                    name="helperMobile"
                    id="helperMobile"
                    placeholder="Mobile"
                    maxLength="10"
                    value={helperMobile}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          {/* <Col md="4" sm="12" xs="12" lg="4">
            <label>Profile Picture</label>
            <FileBase64
              multiple={false}
              id="addhelperPhoto"
              accept=".jpeg, .png, .jpg"
              onDone={handleAttachments}
            />
            {helperPhoto && (
              <img
                src={helperPhoto}
                width="50%"
                height="150"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            )}
          </Col> */}
        </Row>

        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
          // disabled={disabledButton()}
          >
            {helperAdded.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default SearchHelper
