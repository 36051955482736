import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Tooltip, Popconfirm, Alert, Select } from 'antd'
import { Spin, Switch } from 'antd'
import {
  resetaddSocietyMnt,
  addSocietyMnt,
} from 'redux/actions/addSocietyMntTypeAction'
import {
  resetgetMaintenanceType,
  getMaintenanceType,
} from 'redux/actions/getMntTypeAction'
import Util from 'validations/index'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

const AddMaintenaceMapping = () => {
  const [names, setName] = useState('')
  const [columnNumber, setColumnNumber] = useState('')
  const [feeData, setFeeData] = useState('')
  const [monthlyFee, setMonthlyFee] = useState('')
  const [mntTypeID, setMntTypeId] = useState('')
  const [CaluclationLogic, setCalculationLogic] = useState('')
  const [totalAmount, setTotalAmount] = useState('')
  const [mntTypeId, setMasterDataName] = useState('')
  const login = useSelector(({ login }) => login)
  const addMntType = useSelector(({ addSocietyMnt }) => addSocietyMnt)
  const mntType = useSelector(({ getMntType }) => getMntType)

  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'name')
      Util.alphabetsOnly(value) ? setName(value) : setName(names)
    if (name === 'columnNumber')
      Util.alphabetsOnly(value)
        ? setColumnNumber(value)
        : setColumnNumber(columnNumber)
  }

  const { Option } = Select
  function onChangeMasterData(value) {
    setMasterDataName(value)
  }

  function onSearchMasterData(val) {
    setMasterDataName(val)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addMntTypeRequest()
  }
  const getMntTypeRequest = () => {
    const getMntTypeReq = {
      initiatedBy: login.data.user.role,
      action: 'fee',
    }
    return dispatch(getMaintenanceType(getMntTypeReq))
  }

  const addMntTypeRequest = () => {
    const addMntTypeReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      action: 'add',
      columnType: 'master',
      name: names.trim(),
      // columnNumber,
      mntTypeId,
    }
    return dispatch(addSocietyMnt(addMntTypeReq))
  }

  useEffect(() => {
    getMntTypeRequest()
  }, [])

  const disabledBtn = () => { }
  useEffect(() => {
    if (addMntType.success) {
      NotificationPopups('success', 'Success', addMntType.data.message)
    }
    if (addMntType.failed) {
      if (addMntType.data.message === "Mandatory Parameter Missing") {
        NotificationPopups('error', 'Failed', (addMntType.data.errorVOList.map((item, id) => (<>{id === 0 ? 1 : id + 1}-{item.errorMessage}<br /></>))))
      } else {
        NotificationPopups('error', 'Failed', addMntType.data.message)
      }
    }
    if (addMntType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetaddSocietyMnt())
    }
  }, [addMntType.success, addMntType.failed, addMntType.network])

  return (
    <>
      <FormGroup style={{ margin: '10px 1px' }}>
        <Label for="serviceType">Enter Name</Label>
        <span className='required'>*</span>
        <Input
          type="text"
          placeholder="Name"
          name="name"
          id="serviceType"
          value={names || ''}
          onChange={handleChange}
          required
        />
      </FormGroup>
      {/* <FormGroup style={{ margin: '10px 1px' }}>
        <Label for="serviceType">Enter Column</Label>
        <Input
          type="text"
          placeholder="Column"
          name="columnNumber"
          id="serviceType"
          value={columnNumber || ''}
          onChange={handleChange}
          maxLength='1'
          required
        />
      </FormGroup> */}
      <FormGroup style={{ margin: '10px 1px' }}>
        <label>Select Master Data</label>
        <span className='required'>*</span>
        <Select
          type="select"
          value={mntTypeId}
          showSearch
          placeholder="Select Feature Category"
          optionFilterProp="children"
          onChange={onChangeMasterData}
          onSearch={onSearchMasterData}
          style={{ width: '100%' }}
        >
          <Option className="loginSelect" value="">
            Select Master Data
          </Option>
          {mntType.success &&
            mntType.data?.maintenanceTypeList.map((data, id) => (
              <Option key={id} value={data.id}>
                {data.name}
              </Option>
            ))}
        </Select>
      </FormGroup>
      <Row style={{ justifyContent: 'center' }}>
        <Button
          className="cust-button"
          onClick={handleSubmit}
          disabled={disabledBtn()}
          type="submit"
        >
          {addMntType.isLoading ? <Spin /> : 'Add'}
        </Button>
      </Row>
    </>
  )
}

export default AddMaintenaceMapping
