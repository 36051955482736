import axios from "../../config/axios"
import {
    ADD_UNIT_SIZE_SUCCESS,
    ADD_UNIT_SIZE_FAILED,
    LOADING_ADD_UNIT_SIZE,
    RESET_ADD_UNIT_SIZE,
    ADD_UNIT_SIZE_NETWORKERROR
} from "../types";

const resetAddUnitSize = () => {
    return {
        type: RESET_ADD_UNIT_SIZE
    };
};

const addUnitSize = userData => dispatch => {
    dispatch({ type: LOADING_ADD_UNIT_SIZE });
    axios
        .post("society/addUnitSize", userData)
        .then(res => {
            switch (res.data.statusCode) {
                case 200:
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: ADD_UNIT_SIZE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: ADD_UNIT_SIZE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: ADD_UNIT_SIZE_FAILED,
        payload: data
    };
}

export { resetAddUnitSize, addUnitSize };