import {
    GETMEMBERLIST_SUCCESS,
    GETMEMBERLIST_FAILED,
    LOADING_GETMEMBERLIST,
    RESET_GETMEMBERLIST,
    GETMEMBERLIST_NETWORKERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    network: false,
    failed: false,
    data: {},
    message: "",
    networkError: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_GETMEMBERLIST:
            return {
                ...state,
                isLoading: true,
                success: false,
                failed: false,
                network: false,
                data: {},
                message: "",
                networkError: ""
            };
        case GETMEMBERLIST_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                network: false,
                failed: true,
                data: action.payload,
                message: "",
                networkError: ""

            };
        case GETMEMBERLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                failed: false,
                network: false,
                data: action.payload,
                message: "",
                networkError: ""
            };
        case GETMEMBERLIST_NETWORKERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                failed: false,
                data: action.payload,
                network: true,
                message: "",
                networkError: "Network Error"
            };
        case RESET_GETMEMBERLIST:
            return (state = initialState);

        default:
            return state;
    }
};