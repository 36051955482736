import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from 'reactstrap'
import { Select, Spin } from 'antd'
import Util from 'validations/index'
import {
  resetGetMntInvoice,
  getMntInvoice,
} from 'redux/actions/getMntInvoiceAction'
// import {addAddMntInvoice, resetAddMntInvoice} from 'redux/actions/addMntInvoiceAction'
import axios from 'config/axios'
import { getBuildingList } from 'redux/actions/getBuildingListAction'
import { getUnitFlatList } from 'redux/actions/getUnitFlatListAction'
import {getAreaSearchList} from 'redux/actions/getAreaSearchListAction'

const SearchMntBilling = (props) => {
  const { Option } = Select

  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [status, setStatus] = useState('')
  const [areaType, setareaType] = useState('')
  const [areaTypeId, setareaTypeId] = useState('')
  const [areaId, setareaId] = useState()
  const [flatid, setflatid] = useState()
  const [searchAreaType, setSearchAreaType] = useState('')
  const [searchFlat, setSearchFlat] = useState('')
  const [searchArea, setStearchArea] = useState('')
  const [searchServType, setSearchServType] = useState('')
  const [searchHelperType, setSearchHelperType] = useState('')
  const [buildingId, setBuildingId] = useState()

  const [serachAreaName, setSearchAreaName] = useState('')
  // const [bldgName, setBldgName] = useState('')
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [optionsAreaType, setOptionsAreaType] = useState([''])
  const [optionsUnit, setOptionsUnit] = useState([''])

  const [bldgName, setBldgName] = useState('')
  const [flatName, setFlatName] = useState('')

  const [statusId, setStatusId] = useState('')
  const [billType, setBillType] = useState('')
  const [billFile, setBillFile] = useState('')

  const login = useSelector(({ login }) => login)
  const getInvoice = useSelector(({ getMntInvoice }) => getMntInvoice)

  const areaTypes = useSelector(({ areaType }) => areaType)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  const getAreaSearchListRes = useSelector(({ getAreaSearchList }) => getAreaSearchList)
  console.log(getAreaSearchListRes, "getAreaSearchList")
  const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)
  const dispatch = useDispatch()

  // const addMntInvoiceRequest = () => {
  //   const addMntInvoiceReq = {
  //     initiatedBy: login.data.user.role,
  //     societyId: login.data.society.id,
  //     type: billType,
  //     file: billFile,
  //   }
  //   dispatch(addAddMntInvoice(addMntInvoiceReq))
  // }
  function onChangeSearchAreaType(value) {
    setareaType(value)
    setareaId('')
    setSearchAreaType('')
  }

  function onSearchAreaType(val) {
    setareaType(val)
  }
  function onChangeSearch(value) {
    let val = value.split(',')
    let val1 = val[0]
    let val2 = val[1]
    setBuildingId(val1)
    setBldgName(val2)
    setareaId(val1)
    setSearchAreaType('')
    setflatid('')
    setFlatName('')
    setSearchAreaName('')
  }

  function onSearch(value) {
    setSearchAreaName(value)
  }

  function onChangeSearchAreaId(value) {
    setareaId(value[0])
    // setBuildingId(value[0])
    setBldgName(value[1])
    setSearchAreaType('')
    setflatid('')
    setFlatName('')
  }

  function onSearchAreaId(val) {
    setSearchAreaType(val)
  }

  function onChangeSearchFlatId(value) {
    let flatval = value.split(",")
    let flatval1 = flatval[0].trim()
    let flatval2 = flatval[1]
    setflatid(flatval1)
    setFlatName(flatval2)
  }

  function onSearchFlatId(val) {
    setSearchFlat(val)
  }

  useEffect(() =>{
    const getAreaSearchListReq ={ 
      areaType: areaType, 
      initiatedBy: login.data.user.role, 
      societyId: login.data.society.id 
      }
      return dispatch(getAreaSearchList(getAreaSearchListReq))
  },[areaType])

  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName])

  useEffect(() => {
    if (login.data.user.role !== 'SuperAdmin' && areaType != '') {
      const getBuildingListReq = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      return dispatch(getBuildingList(getBuildingListReq))
    }
  }, [areaType])

  useEffect(() => {
    if (areaType === 'Building' && buildingId) {
      const getUnitFlatListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
      }
      return dispatch(getUnitFlatList(getUnitFlatListReq))
    }
  }, [buildingId])

  useEffect(() => {
    if (areaType === 'Building' && buildingId && searchFlat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
        unit: searchFlat,
      }
      axios.post('society/getUnitList', userData).then((res) => {
        // if (res.statusCode === 200 && res.data.activeUnitList != '') {
        setOptionsUnit(res.data.activeUnitList)
        // }
      })
    }
  }, [searchFlat])

  useEffect(() => {
    if (serachAreaName === '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        // areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        // if (res.statusCode === 200 && res.data.activeAreaList != '') {
        setOptions2(res.data.activeAreaList)
        // }
      })
    }
  }, [])

  //search of area type
  useEffect(() => {
    if (searchAreaType != '') {
      const userData = {
        areaType: "building",
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: searchAreaType,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        if (res.status === 200 && res.data.activeAreaList != '') {
          setOptionsAreaType(res.data.activeAreaList)
        }
      })
    }

  }, [searchAreaType])
  console.log(areaType, "areaType")


  //search of flat
  useEffect(() => {
    if (areaType === 'Building' && areaId && searchFlat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: areaId,
        areaType: 'Flat',
        unit: searchFlat,
      }
      axios.post('society/getUnitList', userData).then((res) => {
        if (res.status === 200) {
          setOptionsUnit(res.data.activeUnitList)
        }
      })
    }
  }, [searchFlat, areaId])

  const getMntInvoiceRequest = () => {
    const getMntInvoiceReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      area: bldgName,
      unit: flatName,
      month: month.trim(),
      year,
      invoiceStatus: status,
      limit: 10
    }
    dispatch(getMntInvoice(getMntInvoiceReq))
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'month')
      Util.alphabetsOnly(value) ? setMonth(value) : setMonth(month)
    if (name === 'year')
      Util.verifyMobile(value) ? setYear(value) : setYear(year)
  }

  const onChangeMntTypeId = (value) => {
    setStatus(value)
  }
  const onSearchMntTypeId = (val) => {
    setStatus(val)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    // addMntInvoiceRequest()
    getMntInvoiceRequest()
    props.setIsSearch()
    const obj = {
      area: bldgName,
      unit: flatName,
      year: year,
      month: month,
      invoiceStatus: status,
    }
    send(obj)
  }

  const menu = [
    {
      key: 1,
      status: 'Uploaded',
      value: 'uploaded',
    },
    {
      key: 2,
      status: 'Sent',
      value: 'sent',
    },
  ]
  const send = (value) => {
    props.searchFilter(value)
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="4" sm="12" xs="12" lg="4">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="areaType">Area Type</Label>
              <Select
                type="select"
                showSearch
                value={areaType}
                placeholder="Select  Select Area Type"
                optionFilterProp="children"
                onChange={onChangeSearchAreaType}
                onSearch={onSearchAreaType}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Area Type
                </Option>
                {areaTypes.success &&
                  areaTypes.data.areaTypeList.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.type}>
                      {d.type}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="4" sm="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="city">Building Name</Label>
              <Select
                type="select"
                value={bldgName}
                showSearch
                placeholder="Select Building Name"
                optionFilterProp="children"
                onChange={onChangeSearch}
                onSearch={onSearch}
                style={{ width: '100%', borderRadius: '25px' }}
              // loading={buildingNames.isLoading}
              >
                <Option className="loginSelect" value="">
                  Select Building Name
                </Option>

                {searchAreaType != '' && optionsAreaType != undefined
                  ? optionsAreaType.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.areaId},${d.areaName}`}
                    >
                      {d.areaName ? `${d.areaName}${'  -  '}` : ''}
                      {d.unit}
                    </Option>
                  ))
                  : getAreaSearchListRes.success &&
                  getAreaSearchListRes.data.activeAreaList.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.areaId},${d.areaName}`}
                    >
                      {/* {areaType != 'Building'
                        ? `${d.areaName ? d.areaName : '-'} - ${d.unit ? d.unit : ""}`
                        : d.areaName
                          ? d.areaName
                          : null} */}

                          {areaType != 'Building' ? `${d.parentName}-${d.areaName}` : d.areaName}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>
          {areaType === 'Building' && (
            <Col md="4" sm="12">
              <FormGroup style={{ margin: '10px 1px' }}>
                <Label for="flatid" lat>
                  Flat
                </Label>
                <Select
                  type="select"
                  showSearch
                  value={flatName}
                  placeholder="Select Flat Number"
                  optionFilterProp="children"
                  onChange={onChangeSearchFlatId}
                  onSearch={onSearchFlatId}
                  style={{ width: '100%' }}
                >
                  <Option className="loginSelect" value="">
                    Select Flat Number
                  </Option>
                  {searchFlat != '' && optionsUnit != undefined
                    ? optionsUnit.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={`${d.id},${d.unit}`}
                      >
                        {d.unit}
                      </Option>
                    ))
                    : getFlat.success &&
                    getFlat.data.activeUnitList.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={`${d.id},${d.unit}`}
                      >
                        {d.unit}
                      </Option>
                    ))}
                </Select>
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          <Col md="4" sm="12" lg="4" xs="12">
            <Label for="month">Month</Label>
            <Input
              style={{ padding: "8px" }}
              type="text"
              name="month"
              id="name"
              placeholder="Month"
              value={month}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md="4" sm="12" lg="4" xs="12">
            <Label for="year">Year</Label>
            <Input
              style={{ padding: "8px" }}
              type="text"
              name="year"
              id="name"
              placeholder="Year"
              value={year}
              onChange={handleChange}
              maxlength={4}
              required
            />
          </Col>
          <Col md="4" sm="12" lg="4" xs="12">
            <Label for="status">Status</Label>
            <Select
              type="select"
              showSearch
              value={status}
              placeholder="Select Notice Type"
              optionFilterProp="children"
              onChange={onChangeMntTypeId}
              onSearch={onSearchMntTypeId}
              style={{ width: '100%' }}
            >
              <Option className="loginSelect" value="" overlay={menu}>
                Select Status
              </Option>
              {menu.map((data, id) => (
                <Option key={id} value={data.value}>
                  {data.status}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button className="cust-button" onClick={handleSubmit} type="submit">
            {getInvoice.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default SearchMntBilling
