import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  Table,
  FormGroup,
  Form,
  Label,
  Input,
  UncontrolledCollapse,
} from 'reactstrap'
import { Alert, Popconfirm, Tooltip, Spin, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetgetPendingMemberList,
  getPendingMemberList,
} from 'redux/actions/getPendingMemberListAction'
import {
  addUserStatus,
  resetaddUserStatus,
} from 'redux/actions/addUserStatusAction'
import DisplayAttachment from '../../components/Custom/DisplayAttachment'
import Util from 'validations/index'
import {
  applicationMessage,
  resetapplicationMessage,
} from 'redux/actions/applicationMessageAction'
import {
  resetUpdateUnitDocumentStatus,
  updateUnitDocumentStatus,
} from 'redux/actions/updateUnitDocumentStatusAction'
import {
  resetUpdateOwnerStatus,
  updateOwnerStatus,
} from 'redux/actions/updateOwnerStatusAction'
import PaginationComponent from 'views/Pagination'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import axios from 'config/axios'

import RightIcon from 'assets/img/Icons/options/right.png'
import WrongIcon from 'assets/img/Icons/options/wrong.png'

function MembersPendingList({ isOpen, toggle }) {
  const [userMessage, setuserMessage] = useState('')
  const [userId, setUserId] = useState('')
  const [docId, setdocId] = useState('')
  const [docRemark, setdocRemark] = useState('')
  const [showMsgField, setshowMsgField] = useState(false)
  const [rejectRemark, setRejectRemark] = useState('')
  // const [showMsgField, setshowMsgField] = useState(false)
  const [showRejectMsg, setShowRejectMsg] = useState(false)
  const [fileModal, setFileModal] = useState(false)
  const [attachment, setAttachment] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  //filter
  const [areaType, setareaType] = useState('Building')
  const [areaId, setareaId] = useState()
  const [flatid, setflatid] = useState()
  const [serachAreaName, setSearchAreaName] = useState('')
  const [optionsUnit, setOptionsUnit] = useState([''])
  const [flatName, setFlatName] = useState('')
  const [searchFlat, setSearchFlat] = useState('')
  const [buildingId, setBuildingId] = useState('')
  const [bldgName, setBldgName] = useState('')
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [memberName, setMemberName] = useState('')
  const [phone, setPhone] = useState('')
  const [residentType, setResidentType] = useState('')
  const [occupancyStatus, setOccupancyStatus] = useState('')

  const [limit] = useState(10)
  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState('')
  const [ids, setIds] = useState('')

  const getPendingMembers = useSelector(
    ({ getPendingMemberList }) => getPendingMemberList
  )

  console.log(getPendingMembers)

  const applicationMsg = useSelector(
    ({ applicationMessage }) => applicationMessage
  )
  const login = useSelector(({ login }) => login)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  const addedUserStatus = useSelector(({ addUserStatus }) => addUserStatus)
  const updateUnitDocStatus = useSelector(
    ({ updateUnitDocumentStatus }) => updateUnitDocumentStatus
  )
  const ownerStatus = useSelector(({ updateOwnerStatus }) => updateOwnerStatus)
  const memberList = useSelector(
    ({ getPendingMemberList }) => getPendingMemberList
  )
  const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)
  const dispatch = useDispatch()

  const { Option } = Select

  function onChangeSearch(value) {
    let val = value.split(',')
    let val1 = val[0]
    let val2 = val[1]
    setBuildingId(val1)
    setBldgName(val2)
    setSearchAreaName('')
    setflatid('')
    setFlatName('')
  }

  function onSearch(value) {
    setSearchAreaName(value)
  }
  function onChangeSearchFlatId(value) {
    let flatval = value.split(",")
    let flatval1 = flatval[0]
    let flatval2 = flatval[1]
    setflatid(flatval1)
    setFlatName(flatval2)
    setSearchFlat('')
    console.log(flatval, "flatval")
    console.log(value, "valuessssssssss")
  }
  function onSearchFlatId(val) {
    setSearchFlat(val)
  }
  function onChangeResidentType(value) {
    setResidentType(value)
  }
  function onSearchResidentType(value) {
    setResidentType(value)
  }

  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        // console.log(res.data.activeType, 'res.data.activeType')
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName])

  useEffect(() => {
    if (login.data.user.role !== 'SuperAdmin' && areaType != '') {
      const getBuildingListReq = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      return dispatch(getBuildingList(getBuildingListReq))
    }
  }, [areaType])

  useEffect(() => {
    if (areaType === 'Building' && buildingId) {
      const getUnitFlatListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
      }
      return dispatch(getUnitFlatList(getUnitFlatListReq))
    }
  }, [buildingId])
  useEffect(() => {
    if (areaType === 'Building' && buildingId && searchFlat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
        unit: searchFlat,
      }
      axios.post('society/getUnitList', userData).then((res) => {
        setOptionsUnit(res.data.activeUnitList)
      })
    }
  }, [searchFlat])

  useEffect(() => {
    if (serachAreaName === '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        // areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions2(res.data.activeAreaList)
      })
    }
  }, [])

  const selectOptions = [
    { id: 1, residentType: 'Owner' },
    { id: 2, residentType: 'Tenant' },
  ]

  const pageSize = memberList.data.pages
  const totalItems = memberList.data.count

  // useEffect(() => {
  //   getPendingMemberListRequest()
  // }, [])

  const getPendingMemberListRequest = (isRead = false) => {
    const getPendingMemberListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      userStatus: 'pending',
      limit,
      page,
    }
    return dispatch(getPendingMemberList(getPendingMemberListReq, isRead))
  }
  const getPendingMemberListSearchRequest = (isRead = false) => {
    const getPendingMemberListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      userStatus: 'pending',
      areaId: buildingId,
      unitId: flatid,
      type: residentType,
      occupancyStatus,
      limit,
      page,
    }
    return dispatch(getPendingMemberList(getPendingMemberListReq, isRead))
  }

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  useEffect(() => {
    setPage(1)
  }, [isSearch])

  useEffect(() => {
    getPendingMemberListRequest()
  }, [page])

  useEffect(() => {
    getPendingMemberListSearchRequest()
  }, [page])

  useEffect(() => {
    setPage(1)
    // console.log(page)
  }, [memberList.failed])
  const handleMemberStatus = (applicationId, status) => {
    setUserId(applicationId)
    if (status === 'approved') {
      const addUserStatusReq = {
        id: applicationId,
        userStatus: status,
        initiatedBy: login.data.user.role,
      }
      return dispatch(updateOwnerStatus(addUserStatusReq))
    }
    if (status === 'rejected') {
      setShowRejectMsg(true)
    }
  }

  const addUserStatusRequest = (id, status) => {
    const addUserStatusReq = {
      id,
      userStatus: status,
      initiatedBy: login.data.user.role,
      message: rejectRemark,
    }
    return dispatch(updateOwnerStatus(addUserStatusReq))
  }

  const handleRemark = ({ target: { name, value } }) => {
    if (name === 'docRemark') setdocRemark(value)
    if (name === 'userMessage') setuserMessage(value)
    if (name === 'rejectRemark') setRejectRemark(value)
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'userMessage') setuserMessage(value)
    if (name === 'rejectRemark') setRejectRemark(value)
  }

  const handleDocStatus = (id, status) => {
    setdocId(id)
    setdocRemark('')
    if (status === 'rejected') {
      setshowMsgField(true)
    } else {
      setshowMsgField(false)
      updateDocumentStatusRequest(id, status)
    }
  }

  const handleRejectDoc = (e, id, status) => {
    e.preventDefault()
    updateDocumentStatusRequest(id, status)
  }

  const updateDocumentStatusRequest = (docId, docStatus) => {
    const updateDocStatusReq = {
      initiatedBy: login.data.user.role,
      id: docId,
      status: docStatus,
    }

    const updateRemark = {
      remark: docRemark,
    }

    let updateReq = ''

    if (docStatus === 'approved') {
      updateReq = { ...updateDocStatusReq }
      return dispatch(updateUnitDocumentStatus(updateReq))
    }
    if (docStatus === 'rejected' && docRemark != '') {
      updateReq = { ...updateDocStatusReq, ...updateRemark }
      return dispatch(updateUnitDocumentStatus(updateReq))
    }

    // console.log('updateDocStatusReq', updateReq)
  }

  useEffect(() => {
    if (ownerStatus.success) {
      setRejectRemark('')
      setdocRemark('')
      getPendingMemberListRequest()
      NotificationPopups('success', 'Success', ownerStatus.data.message)
    }
    if (ownerStatus.failed) {
      NotificationPopups('error', 'Failed', ownerStatus.data.message)
    }
    if (ownerStatus.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetUpdateOwnerStatus())
    }
  }, [ownerStatus.success, ownerStatus.failed, ownerStatus.network])

  useEffect(() => {
    if (updateUnitDocStatus.success) {
      getPendingMemberListRequest()
      NotificationPopups('success', 'Success', updateUnitDocStatus.data.message)
    }
    if (updateUnitDocStatus.failed) {
      NotificationPopups('error', 'Failed', updateUnitDocStatus.data.message)
    }
    if (updateUnitDocStatus.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetUpdateUnitDocumentStatus())
    }
  }, [
    updateUnitDocStatus.success,
    updateUnitDocStatus.failed,
    updateUnitDocStatus.network,
  ])

  useEffect(() => {
    if (applicationMsg.success) {
      setuserMessage('')
      NotificationPopups('success', 'Success', applicationMsg.data.message)
    }
    if (applicationMsg.failed) {
      NotificationPopups('error', 'Failed', applicationMsg.data.message)
    }
    if (applicationMsg.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetapplicationMessage())
    }
  }, [applicationMsg.success, applicationMsg.failed, applicationMsg.network])

  //Open Files
  const handleOpenFile = (a) => {
    setAttachment(a)
    setFileModal(true)
  }

  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }

  const searchSubmit = (e) => {
    e.preventDefault()
    getPendingMemberListSearchRequest()
    setIsSearch(!isSearch)
  }

  useEffect(() => {
    if (buildingId === '') {
      dispatch(resetGetUnitFlatList())
    }
  }, [buildingId])

  const handleSubmit = (id) => {
    const applicationMessageReq = {
      id: id,
      initiatedBy: login.data.user.role,
      message: userMessage,
    }
    return dispatch(applicationMessage(applicationMessageReq))
  }

  useEffect(() => {
    if (buildingId === '') {
      dispatch(resetGetUnitFlatList())
    }
  }, [buildingId])

  return (
    <div className="table-full-width table-responsive" id="onTop">
      {buildingList.success && (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="3" sm="12">
              <FormGroup style={{ margin: '10px 1px' }}>
                <Label for="city">Building Name</Label>
                <Select
                  type="select"
                  value={bldgName}
                  showSearch
                  placeholder="Select Building Name"
                  optionFilterProp="children"
                  onChange={onChangeSearch}
                  onSearch={onSearch}
                  style={{ width: '100%', borderRadius: '25px' }}
                // loading={buildingNames.isLoading}
                >
                  <Option className="loginSelect" value="" >
                    Select Building Name
                  </Option>

                  {serachAreaName != '' &&
                    options != undefined &&
                    options.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={`${d.areaId},${d.areaName}`}
                      >
                        {d.areaName}
                      </Option>
                    ))}
                  {serachAreaName === '' &&
                    options2.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={`${d.areaId},${d.areaName}`}
                      >
                        {d.areaName}
                      </Option>
                    ))}
                </Select>
              </FormGroup>
            </Col>
            {areaType === 'Building' && (
              <Col md="3" sm="12">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <Label for="flatid" lat>
                    Flat
                  </Label>
                  <Select
                    // disabled={bldgName ? false : true}
                    type="select"
                    showSearch
                    value={flatName}
                    placeholder="Select Flat Number"
                    optionFilterProp="children"
                    onChange={onChangeSearchFlatId}
                    onSearch={onSearchFlatId}
                    style={{ width: '100%' }}
                  >
                    <Option className="loginSelect" value="" >
                      {getFlat.data.message === 'Not Found' ? 'No Flat Found ' : "Select Flat Number"}
                    </Option>
                    {searchFlat != '' && optionsUnit != undefined
                      ? optionsUnit.map((d, id) => (
                        <Option
                          key={id}
                          className="loginSelect"
                          value={`${d.id}, ${d.unit}`}
                        >
                          {d.unit}
                        </Option>
                      ))
                      : getFlat.success &&
                      getFlat.data.activeUnitList.map((d, id) => (
                        <Option
                          key={id}
                          className="loginSelect"
                          value={`${d.id}, ${d.unit}`}
                        >
                          {d.unit}
                        </Option>
                      ))}
                  </Select>
                </FormGroup>
              </Col>
            )}

            {/* <Col md="3" sm="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="societyName">Member Name</Label>
              <Input
                type="text"
                placeholder="Member Name"
                name="MemberName"
                id="city"
                value={memberName || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="pincode">Phone Number</Label>
              <Input
                type="tel"
                placeholder="Phone Number"
                name="phone"
                id="phone"
                value={phone || ''}
                onChange={handleChange}
              />
            </FormGroup>
          </Col> */}
            <Col md="3" sm="12">
              <FormGroup style={{ margin: '10px 1px' }}>
                <Label for="city"> Select Resident Type</Label>
                <Select
                  type="select"
                  value={residentType}
                  showSearch
                  placeholder="Select Building Name"
                  optionFilterProp="children"
                  onChange={onChangeResidentType}
                  onSearch={onSearchResidentType}
                  style={{ width: '100%', borderRadius: '25px' }}
                // loading={buildingNames.isLoading}
                >
                  <Option className="loginSelect" value="">
                    Select Resident Type
                  </Option>
                  {selectOptions.map((d, id) => (
                    <Option value={d.residentType}>{d.residentType}</Option>
                  ))}
                </Select>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ justifyContent: 'center' }}>
            <Col sm="12" style={{ textAlign: 'center' }}>
              <Button
                className="cust-button"
                onClick={searchSubmit}
                disabled={getPendingMembers.isLoading}
              >
                {getPendingMembers.isLoading ? <Spin /> : 'Search'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      <Table responsive style={{ textAlign: 'center' }}>
        <thead>
          <tr>
            <th>Flat</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Occupancy Status</th>
            <th>Resident </th>
          </tr>
        </thead>
        {getPendingMembers.success && (
          <tbody>
            {getPendingMembers.data.ownerList.map((d, id) => (
              <React.Fragment key={id}>
                <tr
                  key={id}
                  id={`toggler${id}`}
                  style={{
                    backgroundColor: '#e8f5fd',
                    color: 'black',
                    fontWeight: 'semi-bold',
                    boxShadow: 'none',
                  }}
                  onClick={() => { setIds(id) }}
                >
                  <td>
                    {d.areaName} / {d.unit}
                  </td>
                  <td>{d.name}</td>
                  <td>{d.mobileNumber}</td>
                  <td>{d.email}</td>
                  <td>{d.occupancyStatus}</td>
                  <td>{d.type}</td>
                </tr>

                <tr className="ml-detail-tr">
                  <td colSpan="6" className="ml-detail-td">
                    <UncontrolledCollapse toggler={`toggler${id}`}>
                      {ids === id ?
                        <>
                          <Row>
                            {d.documentList &&
                              d.documentList.map((doc, id) => (
                                <Col
                                  xs="12"
                                  sm="6"
                                  md="4"
                                  lg="3"
                                  style={{ padding: '20px' }}
                                  key={doc.id}
                                >
                                  <label>{doc.documentType}</label>
                                  {doc.document && (
                                    <div
                                      style={{
                                        cursor: 'pointer',
                                        padding: '5px',
                                        height: '130px',
                                      }}
                                      onClick={() => handleOpenFile(doc.document)}
                                    >
                                      {doc.document &&
                                        Util.attachmentType(doc.document) ? (
                                        <img
                                          // src={doc.document}
                                          src={`${Util.imageUrl}${doc.document}`}
                                          // alt={`${Util.imageUrl}${doc.document}`}
                                          style={{
                                            width: '100px',
                                            height: '100px',
                                          }}
                                        />
                                      ) : (
                                        <i
                                          className={Util.iconType(doc.document)}
                                          style={{ fontSize: '100px' }}
                                        ></i>
                                      )}
                                    </div>
                                  )}
                                  <div
                                    className="showStatus"
                                    style={{ color: Util.statusColor(doc.status) }}
                                  >
                                    {doc.status.toUpperCase()}
                                  </div>
                                  {doc.remark && (
                                    <Col
                                      xs="12"
                                      sm="12"
                                      md="12"
                                      lg="12"
                                      style={{ padding: '10px' }}
                                    >
                                      <label>Remark</label>
                                      <div className="detail-val">{doc.remark}</div>
                                    </Col>
                                  )}
                                  {doc.status === 'pending' ? (
                                    <>
                                      <div style={{ marginTop: '15px' }}>
                                        <span style={{ padding: '10px 20px', cursor: 'pointer' }}>
                                          <Popconfirm
                                            title="Approve Document?"
                                            onConfirm={() =>
                                              handleDocStatus(doc.id, 'approved')
                                            }
                                            okText="Approve"
                                            cancelText="No"
                                          >
                                            <Tooltip
                                              placement="leftBottom"
                                              title="Approve"
                                              color="#108ee9"
                                            >
                                              <img
                                                src={RightIcon}
                                                alt="RightIcon"
                                                className="amenity_option_icon"
                                              />
                                            </Tooltip>
                                          </Popconfirm>
                                        </span>{' '}
                                        {'|'}
                                        <span style={{ padding: '10px 20px', cursor: 'pointer' }}>
                                          <Popconfirm
                                            title="Reject Document?"
                                            onConfirm={() =>
                                              handleDocStatus(doc.id, 'rejected')
                                            }
                                            okText="Reject"
                                            cancelText="No"
                                          >
                                            <Tooltip
                                              placement="rightBottom"
                                              title="Reject"
                                              color="#108ee9"
                                            >
                                              {/* <i className="fas fa-times right-menu"></i> */}
                                              <img
                                                src={WrongIcon}
                                                alt="WrongIcon"
                                                className="amenity_option_icon"
                                              />
                                            </Tooltip>
                                          </Popconfirm>
                                        </span>
                                      </div>
                                      {showMsgField && docId === doc.id && (
                                        <div>
                                          <FormGroup>
                                            <Input
                                              type="textarea"
                                              row="1"
                                              // value={docRemark}
                                              name="docRemark"
                                              id={docRemark}
                                              placeholder="Rejection Remark"
                                              onChange={handleRemark}
                                              required
                                            />
                                          </FormGroup>
                                          <Button
                                            className="cust-button"
                                            style={{ minWidth: '100%' }}
                                            onClick={(e) =>
                                              handleRejectDoc(e, doc.id, 'rejected')
                                            }
                                          >
                                            Send
                                          </Button>
                                        </div>
                                      )}
                                    </>
                                  ) : null}
                                </Col>
                              ))}
                          </Row>
                          <Row>
                            {/* {d.userStatus === 'pending' && ( */}
                            <>
                              <Col xs="12" sm="12" md="12" lg="12">
                                {/* <Row style={{ justifyContent: 'center' }}>
                                <Col
                                  xs="12"
                                  sm="12"
                                  md="9"
                                  lg="9"
                                  style={{ padding: '10px' }}
                                >
                                  <FormGroup>
                                    <Input
                                      type="textarea"
                                      row="1"
                                      name="userMessage"
                                      id="userMessage"
                                      placeholder="Reply"
                                      value={userMessage || ''}
                                      onChange={handleChange}
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col
                                  xs="12"
                                  sm="12"
                                  md="3"
                                  lg="3"
                                  style={{ padding: '25px 10px' }}
                                >
                                  <Button
                                    className="cust-button"
                                    style={{ minWidth: '100%' }}
                                    onClick={() => handleSubmit(d.applicationId)}
                                  >
                                    Send
                                  </Button>
                                </Col>
                              </Row> */}
                              </Col>
                              <Col
                                xs="12"
                                sm="12"
                                md="12"
                                lg="12"
                                style={{
                                  padding: '10px',
                                  justifyContent: 'center',
                                }}
                              >
                                <label>Review</label>
                                <div className="detail-val">
                                  <span
                                    className="right-menu"
                                    onClick={() =>
                                      handleMemberStatus(
                                        d.applicationId,
                                        'approved'
                                      )
                                    }
                                  >
                                    Approve
                                  </span>
                                  {' | '}
                                  <span
                                    className="right-menu"
                                    onClick={
                                      () =>
                                        handleMemberStatus(
                                          d.applicationId,
                                          'rejected'
                                        )
                                      // console.log(d.applicationId, 'rejected')
                                    }
                                  >
                                    Reject
                                  </span>
                                </div>
                                {showRejectMsg && userId === d.applicationId && (
                                  <div style={{ minWidth: '50%' }}>
                                    <Row style={{justifyContent:'center'}}>
                                      <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                          <Input
                                            type="textarea"
                                            row="1"
                                            // value={rejectRemark}
                                            name="rejectRemark"
                                            id={docRemark}
                                            placeholder="Rejection Remark"
                                            onChange={handleRemark}
                                            required
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col className="col-12 col-sm-12 col-md-3 col-lg-3" style={{display:'flex' , alignItems:'center'}}>
                                        <Button
                                          className="cust-button"
                                          style={{ minWidth: '50%' }}
                                          onClick={(e) =>
                                            addUserStatusRequest(
                                              d.applicationId,
                                              'rejected'
                                            )
                                          }
                                        >
                                          {ownerStatus.isLoading ? <Spin /> : 'Send'}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </Col>
                            </>
                            {/* )} */}
                          </Row>
                        </>
                        : ''}
                    </UncontrolledCollapse>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        )}
      </Table>
      {getPendingMembers.failed && (
        <div style={{ textAlign: 'center', paddingTop: '20px' }}>
          <Alert message={getPendingMembers.data.message} type="info" />
        </div>
      )}

      {fileModal && (
        <DisplayAttachment
          attachment={`${Util.imageUrl}${attachment}`}
          fileModal={fileModal}
          toggleModal={toggleFileModal}
        />
      )}
      {memberList.success && (
        <PaginationComponent
          limit={limit}
          totalItems={totalItems}
          onSelect={handlePagination}
          defaultPage={page}
        />
      )}
    </div>
  )
}

export default MembersPendingList
