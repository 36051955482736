// Import the functions you need from the SDKs you need
import { RetweetOutlined } from "@ant-design/icons";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, } from "firebase/messaging";
export let DEVICE_TOKEN;
const firebaseConfig = {
    apiKey: "AIzaSyDH-ATrizpIHXfvw8xuvK8e8hsUvdqMV-w",
    authDomain: "hisociety-1c844.firebaseapp.com",
    projectId: "hisociety-1c844",
    storageBucket: "hisociety-1c844.appspot.com",
    messagingSenderId: "270763824595",
    appId: "1:270763824595:web:3c7fa7c5dca63ed04e4b26",
    measurementId: "G-5G46S9B1JS"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {

    Notification.requestPermission().then((permission) => {
        console.log("Requesting User Permission……");
        if (permission === "granted") {
            return getToken(messaging, { vapidKey: "BLzVKXvHkuBmCAQYVnRrjCz_A_2aV5aRSVH3mNYWcnIy-3RK_aHp__ARpaqbH3TiiOSU23vOEYozT7mjbiqolVw" })
                .then((currentToken) => {
                    if (currentToken) {
                        DEVICE_TOKEN = currentToken
                        console.log("Client Token: ", currentToken)
                    } else {
                        console.log("Failed to generate the app registration token.")
                    }
                })
                .catch(err => {
                    new Error("An Error Occured in requesting notification", err)
                })
        } else {
            console.log("User permission Denied.")
        }
    });
}

export const onMessageListener = () => {
    return new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("Onmessage Payload", payload)
            resolve(payload)
        })

    })
}