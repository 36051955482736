import React from 'react'
import { Spin, notification } from 'antd'

const NotificationPopups = (type, mes, des) => {
  console.log('HELLOIHSIJSOIj')
  notification[type]({
    message: `${mes}`,
    description: des,
    placement: `top`,
    duration: 5,
  })
}

export default NotificationPopups
