import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  UncontrolledCollapse,
} from 'reactstrap'
import {
  Tooltip,
  Popconfirm,
  Spin,
  Alert,
  Switch,
  List,
  Avatar,
  Skeleton,
} from 'antd'
import {
  resetGetVisitorDetails,
  getVisitorDetails,
} from 'redux/actions/getVisitorDetailsAction'
import randomColor from 'randomcolor'
import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'
import DisplayAttachment from 'components/Custom/DisplayAttachment'
import PaginationComponent from 'views/Pagination'
import moment from 'moment'
import './visitorstyle.css'

function VisitorList({ sendData, onTap }) {
  const { companyName, helperName, helperTypeId, loginStatus, timestamp } = sendData
  const [modal, setmodal] = useState(false)
  const [data, setData] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [fileModal, setFileModal] = useState(false)
  const [limit] = useState(6)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const login = useSelector(({ login }) => login)
  const visitorDetail = useSelector(
    ({ getVisitorDetails }) => getVisitorDetails
  )
  const totalItems = visitorDetail.data.count
  const openEditModal = (d) => {
    setmodal(!modal)
    setData(d)
  }
  const toggle = () => {
    seterrMessage('')
    setmodal(!modal)
  }
  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }
  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  useEffect(() => {
    setPage(1)
  }, [onTap])

  const getVisitorDetailsReq = () => {
    const getVisitorDetailsReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      timestamp,
      companyName,
      helperName,
      helperTypeId,
      loginStatus,
      page,
      limit,
    }
    return dispatch(getVisitorDetails(getVisitorDetailsReq))
  }

  useEffect(() => {
    getVisitorDetailsReq()
  }, [page])

  // useEffect(() => {
  //   setPage(1)
  // }, [onTap])

  // useEffect(() => {
  //   if (login.data.user.role === 'Society') {
  //     const getVisitorDetailsReq = {
  //       initiatedBy: login.data.user.role,
  //       societyId: login.data.society.id,
  //       page,
  //       limit,
  //     }
  //     return dispatch(getVisitorDetails(getVisitorDetailsReq))
  //   }
  // }, [page])

  // useEffect(() => {
  //   if (login.data.user.role === 'Society') {
  //     const getVisitorDetailsReq = {
  //       initiatedBy: login.data.user.role,
  //       societyId: login.data.society.id,
  //       helperTypeId: datas.data.helperTypeId,
  //       helperMobile: datas.data.helperMobile,
  //       helperName: datas.data.helperName,
  //       loginStatus: datas.data.loginStatus,
  //       companyName: datas.data.companyName,
  //       page,
  //       limit,
  //     }
  //     return dispatch(getVisitorDetails(getVisitorDetailsReq))
  //   }
  // }, [page])

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Col>
              <div className="typeHeading" id="onTop">Visitor</div>
            </Col>
            <Row>
              <Col md="12" style={{ padding: '20px' }}>
                {visitorDetail.success && (
                  <Col md="12" style={{ padding: '20px' }} id="onTop">
                    <Table responsive style={{ textAlign: 'center' }}>
                      <thead>
                        <tr>
                          <th>Profile</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Company </th>
                          {/* <th>Company Name</th> */}
                          <th>Added by</th>
                          <th>Status</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      {visitorDetail.success && (
                        <tbody>
                          {visitorDetail.data.helperListVOList.map((d, id) => (
                            <React.Fragment key={id}>
                              <tr key={id} id={`toggler${id}`}>
                                <td>
                                  <img
                                    src={`${Util.imageUrl}${d.photo}`}
                                    alt="icon"
                                    // className="AvatarProfile"
                                    style={{
                                      height: '40px',
                                      width: '40px',
                                    }}
                                  />
                                </td>
                                <td>{d.helperName}</td>
                                <td>{d.helperMobile}</td>

                                <td>
                                  {d.companyLogo ? (
                                    <img
                                      src={`${Util.imageUrl}${d.companyLogo}`}
                                      alt="icon"
                                      // className="AvatarProfile"
                                      style={{
                                        height: '40px',
                                        width: '40px',
                                      }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                  <br />
                                  {d.companyName ? d.companyName : '-'}
                                </td>
                                <td style={{ textTransform: 'capitalize' }}>
                                  {d.addedBy}
                                </td>
                                <td style={{ textTransform: 'capitalize' }}>
                                  {d.loginStatus}
                                  <div className={d.loginStatus}></div>
                                </td>
                                <td>{moment(d.timestamp).format('DD-MM-YYYY')}</td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      )}
                    </Table>
                  </Col>
                )}
              </Col>
              <Col md="12">
                {visitorDetail.failed && (
                  <div style={{ textAlign: 'center' }}>
                    <Alert message="No Visitors Found" type="info" style={{ margin: "20px 0" }} />
                  </div>
                )}
              </Col>
              <Col>
                {visitorDetail.success && (
                  <PaginationComponent
                    limit={limit}
                    totalItems={totalItems}
                    onSelect={handlePagination}
                    defaultPage={page}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default VisitorList
