import React from 'react'

export default class FileBase64 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filenames: [],
      files: [],
    }
  }

  handleChange(e) {
    // get the files
    let files = e.target.files
    let f = []

    // Process each file
    var allFiles = []
    for (var i = 0; i < files.length; i++) {
      let file = files[i]

      f.push(file.name)

      this.setState({
        filenames: [...f],
      })

      // Make new FileReader
      let reader = new FileReader()

      // Convert the file to base64 text
      reader.readAsDataURL(file)

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + ' kB',
          base64: reader.result,
          file: file,
          id: this.props.id,
        }

        // Push it to the state
        allFiles.push(fileInfo)

        // If all files have been proceed
        if (allFiles.length == files.length) {
          // Apply Callback function
          if (this.props.multiple) this.props.onDone(allFiles)
          else this.props.onDone(allFiles[0])
          console.log("tiusdjfbjdfbw", allFiles,this.props)
        } else {
          console.log("what is going on????")
        }
      } // reader.onload
    } // for
  }

  render() {

    return (
      <>
        <label
          for={this.props.id}
          style={{
            cursor: 'pointer',
            //margin: "10px",
            // padding: '7px',
            height: "35px",
            width: '100%',
            textAlign: 'center',
            borderRadius: '30px',
            border: '1px solid #E3E3E3',
          }}
        >
          <i
            className="fas fa-cloud-upload-alt"
            style={{ color: '#1890ff', fontSize: '20px' }}
          ></i>
          <span style={{ color: '#1890ff', padding: '10px' }}>Choose File</span>
        </label>
        {/* {this && this.state.filenames.map((d, id) => <div key={id}>{d.substr(0, 20)}</div>)} */}
        <input
          id={this.props.id}
          type="file"
          onChange={this.handleChange.bind(this)}
          multiple={this.props.multiple}
          accept={this.props.accept}
          style={{ display: 'none', visibility: 'none', height: '' }}
        />
      </>
    )
  }
}

FileBase64.defaultProps = {
  multiple: false,
}
