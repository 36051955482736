import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PanelHeader from 'components/PanelHeader/PanelHeader.js'
// reactstrap components
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Tooltip, Popconfirm, Alert, Switch } from 'antd'
import { Spin } from 'antd'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetAddHelperType,
  addHelperType,
} from 'redux/actions/addHelperTypeAction'
import { getHelperType } from 'redux/actions/getHelperTypeAction'
import FileBase64 from '../../components/Custom/UploadFile'
import Util from 'validations/index'

function AddServiceTypeForm({ serType, serTypeId }) {
  console.log(serType, serTypeId ,"serveice")
  const [profilePictureStatus, setprofilePictureStatus] = useState(false)
  const [updatedPhoto, setUpdatedPhoto] = useState('')
  const login = useSelector(({ login }) => login)
  const addedHelperType = useSelector(({ addHelperType }) => addHelperType)
  const dispatch = useDispatch()
  const [helpertype, sethelpertype] = useState('')
  const [isFullTime, setIsFullTime] = useState(false)
  const [iconImage, setIconImage] = useState('')

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'helpertype')
      Util.alphabetsOnly(value)
        ? sethelpertype(value)
        : sethelpertype(helpertype)
  }
  const handleAttachments = (filesList) => {
    const icon = filesList[0].base64.split(`,`)
    setIconImage(icon[1])
    setUpdatedPhoto(filesList[0].base64)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addHelperTypeRequest()
  }

  const addHelperTypeRequest = () => {
    const addHelperTypeReq = {
      initiatedBy: login.data.user.role,
      isActive: true,
      isFullTime,
      type: helpertype.trim(),
      serviceTypeId: serTypeId,
      iconImage,
    }
    return dispatch(addHelperType(addHelperTypeReq))
  }
  useEffect(() => {
    if (iconImage != '') {
      NotificationPopups('success', 'Success', 'Image Uploaded')
    }
  }, [iconImage])

  useEffect(() => {
    if (addedHelperType.success) {
      sethelpertype('')
      setIsFullTime(isFullTime)
      setIconImage('')

      NotificationPopups('success', 'Success', addedHelperType.data.message)
    }
    if (addedHelperType.failed) {
      NotificationPopups('error', 'Failed', addedHelperType.data.message)
    }
    if (addedHelperType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddHelperType())
    }
  }, [addedHelperType.success, addedHelperType.failed, addedHelperType.network])

  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="helpertype">{serType}(Helper Type)</Label>
              <Input
                type="text"
                placeholder="Helper Type"
                name="helpertype"
                id="helpertype"
                value={helpertype || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>

          <Col md="2" sm="12" lg="2" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="isFullTime">Full Time Helper</Label>
              <Row style={{ padding: '8px 25px' }}>
                <Col md="4" sm="12" lg="4">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultunChecked
                    onChange={(checked) => {
                      setIsFullTime(checked)
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Add Helper Type Icon</Label>
              <FileBase64
                multiple={true}
                id="iconImage"
                accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                onDone={handleAttachments}
              />
            </FormGroup>
            {iconImage && (
              <img
                src={updatedPhoto}
                width="50%"
                height="60"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            )}
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            //onClick={handleSubmit}
            disabled={addedHelperType.isLoading || !helpertype}
            type="submit"
          >
            {addedHelperType.loading ? <Spin /> : 'Add Type'}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default AddServiceTypeForm
