import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  UncontrolledCollapse,
} from 'reactstrap'
import { Alert, Collapse, Select, Spin } from 'antd'
import {
  getAmenityList,
  resetgetAmenityList,
} from 'redux/actions/getAmenityListAction'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import AmenityApproved from './AmenityApproved'
import AmenityPending from './AmenityPending'
import AmenitytRejected from './AmenitytRejected'
import AddAmenityType from './AddAmenityType'
import Util from '../../validations'
// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  addAmenityStatus,
  resetAddAmenityStatus,
} from 'redux/actions/addAmenityStatusAction'
import {
  resetUpdateAmenityStatus,
  updateAmenityStatus,
} from 'redux/actions/updateAmenityStatusAction'
import {
  addAmenityMessage,
  getAmenityMessage,
  resetAddAmenityMessage,
  resetGetAmenityMessage,
} from 'redux/actions/amenityMessageAction'
import PaginationComponent from 'views/Pagination'
import axios from 'config/axios'
import AmenityTimeLine from './AmenityTimeLine'
import Tick from '../../assets/img/check1.png'
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { getAmenityListCalendar, resetgetAmenityListCalendar } from '../../redux/actions/getAmenityListCalendarAction'



function Amenity() {
  // let pageNumber = 5
  // console.log(typeOf(pageNumber), 'is a number')
  const dispatch = useDispatch()
  const [status, setStatus] = useState('pending')
  const [amenityId, setAmenityId] = useState('')
  const [toggle, setToggle] = useState(false)
  const [limit] = useState(10)
  const [page, setPage] = useState(1)
  const [isSearch, setIsSearch] = useState(false)
  //filter
  const [complainType, setComplainType] = useState('')
  const [areaType, setareaType] = useState('Building')
  const [areaId, setareaId] = useState()
  const [flatid, setflatid] = useState()
  const [serachAreaName, setSearchAreaName] = useState('')
  const [optionsUnit, setOptionsUnit] = useState([''])
  const [flatName, setFlatName] = useState('')
  const [searchFlat, setSearchFlat] = useState('')
  const [buildingId, setBuildingId] = useState()
  const [bldgName, setBldgName] = useState('')
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [memberName, setMemberName] = useState('')
  const [phone, setPhone] = useState('')
  const [residentType, setResidentType] = useState('')
  const [startDate, setStartDate] = useState('')
  const [ids, setIds] = useState('')
  const [calendarData, setCalendarData] = useState([])
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date().toLocaleString('default', { month: 'long' }))
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear())
  console.log(calendarData,currentDate,currentMonth,currentYear, "currentMonth")


  const buildingList = useSelector(({ buildingList }) => buildingList)
  const addedAmenityStatus = useSelector(
    ({ addAmenityStatus }) => addAmenityStatus
  )
  const AmenityList = useSelector(({ getAmenityList }) => getAmenityList)
  const AmenityCalendarList = useSelector(({ getAmenityListCalendar }) => getAmenityListCalendar)
  console.log(AmenityCalendarList, "AmenityCalendarList")

  const login = useSelector(({ login }) => login)
  const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)
  const [message, setMessage] = useState({
    id: '',
    content: '',
    attachment: '',
  })
  const getAmenityMessageData = useSelector(
    ({ getAmenityMessage }) => getAmenityMessage
  )
  const addAmenityMessageData = useSelector(
    ({ addAmenityMessage }) => addAmenityMessage
  )
  const updatedAmenityStatus = useSelector(
    ({ updateAmenityStatus }) => updateAmenityStatus
  )

  const totalItems = AmenityList.data.count

  const getAmenityListRequest = (status, isRead = false) => {
    const getAmenityListReq = {
      initiatedBy: login.data.user.role,
      amenityStatus: status === "calender" ? "approved" : status,
      societyId: login.data.society.id,
      unitId: flatid,
      startDate,
      status: status === "calender" ? "approved" : status,
      page,
      limit,
    }
    return dispatch(getAmenityList(getAmenityListReq, isRead))
  }

  const getAmenityCalendarListRequest = (status, isRead = false) => {
    const getAmenityListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      unitId: flatid,
      startDate,
      // month:currentMonth,
      // year: currentYear
    }
    return dispatch(getAmenityListCalendar(getAmenityListReq, isRead))
  }

  useEffect(() => {
    getAmenityCalendarListRequest()
  }, [])

  const getAmenityListSearchRequest = () => {
    const getAmenityListReq = {
      initiatedBy: login.data.user.role,
      amenityStatus: status,
      societyId: login.data.society.id,
      unitId: flatid,
      startDate,
      page,
      limit,
    }
    return dispatch(getAmenityList(getAmenityListReq))
  }

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }
  const handleStatus = (status) => {
    console.log(status, "zvuhidk")
    setStatus(status)
    if (
      status === 'approved' ||
      status === 'rejected' ||
      status === 'pending'
    ) {
      getAmenityListRequest(status)
    }
    if (status === 'amenitylists') {
      return dispatch(resetgetAmenityList())
    }
    if (status === 'calender') {
      getAmenityCalendarListRequest(status)
    }
    // if(status === 'calender'){
    //   getAmenityListRequest("approved")
    // }
  }

  const handleAmenityStatus = (id, status) => {
    const amenityStatusReq = {
      initiatedBy: login.data.user.role,
      amenityStatus: status,
      amenityId: id,
    }
    return dispatch(updateAmenityStatus(amenityStatusReq))
  }

  useEffect(() => {
    getAmenityListRequest(status)
    return () => {
      dispatch(resetAddAmenityMessage())
      dispatch(resetGetAmenityMessage())
    }
  }, [page])

  useEffect(() => {
    setPage(1)
  }, [AmenityList.failed])

  useEffect(() => {
    setPage(1)
    setStartDate('')
  }, [status])
  useEffect(() => {
    if (updatedAmenityStatus.success) {
      getAmenityListRequest('pending')
      NotificationPopups(
        'success',
        'Success',
        updatedAmenityStatus.data.message
      )
    }
    if (updatedAmenityStatus.failed) {
      NotificationPopups('error', 'Failed', updatedAmenityStatus.data.message)
    }
    if (updatedAmenityStatus.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    if (addAmenityMessageData.success) {
      setMessage({})
    }
    if (addAmenityMessageData.failed) {
      NotificationPopups('error', 'Failed', addAmenityMessageData.data.message)
    }
    return () => {
      dispatch(resetUpdateAmenityStatus())
    }
  }, [
    updatedAmenityStatus.success,
    updatedAmenityStatus.failed,
    updatedAmenityStatus.network,
    addAmenityMessageData.success,
    addAmenityMessageData.failed,
    addAmenityMessageData.network,
  ])

  const handleAmenityMessage = (evt, id) => {
    const {
      target: { value },
    } = evt
    setMessage({
      ...message,
      id: id,
      content: value,
      attachment: '',
    })
  }

  const handleAddAmenityMessage = (id) => {
    if (message.content !== '' || message.attachment != '') {
      const req = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        amenityId: id,
        role: login.data.user.role,
        adminId: login.data.user.id,
        content:
          message.id === id && message.content ? message.content.trim() : '',
        attachment:
          message.id === id && message.attachment ? message.attachment : '',
      }
      return dispatch(addAmenityMessage(req))
    }
  }

  const getAmenityMessageList = (id) => {
    const req = {
      initiatedBy: login.data.user.role,
      amenityId: id,
    }
    return dispatch(getAmenityMessage(req))
  }
  const disabledButton = () => {
    return !message.content
  }

  useEffect(() => {
    if (addAmenityMessageData.success) {
      getAmenityMessageList(amenityId)
      setMessage({
        id: '',
        content: '',
        attachment: '',
      })
    }
  }, [addAmenityMessageData])

  //Filter
  const { Option } = Select

  function onChangeSearch(value) {
    let val = value.split(',')
    let val1 = val[0]
    let val2 = val[1]
    setBuildingId(val1)
    setBldgName(val2)
    setSearchAreaName('')
    setflatid('')
    setFlatName('')
  }

  function onSearch(value) {
    setSearchAreaName(value)
  }
  function onChangeSearchFlatId(value) {
    let flatval = value.split(",")
    let flatval1 = flatval[0]
    let flatval2 = flatval[1]
    setflatid(flatval1)
    setFlatName(flatval2)
    setSearchFlat('')
  }
  function onSearchFlatId(val) {
    setSearchFlat(val)
  }
  function onChangeResidentType(value) {
    setResidentType(value)
  }
  function onSearchResidentType(value) {
    setResidentType(value)
  }

  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName])

  useEffect(() => {
    if (login.data.user.role !== 'SuperAdmin' && areaType != '') {
      const getBuildingListReq = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      return dispatch(getBuildingList(getBuildingListReq))
    }
  }, [areaType])

  useEffect(() => {
    if (areaType === 'Building' && buildingId) {
      const getUnitFlatListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
      }
      return dispatch(getUnitFlatList(getUnitFlatListReq))
    }
  }, [buildingId])

  useEffect(() => {
    if (areaType === 'Building' && buildingId && searchFlat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
        unit: searchFlat,
      }
      axios.post('society/getUnitList', userData).then((res) => {
        setOptionsUnit(res.data.activeUnitList)
      })
    }
  }, [searchFlat])

  useEffect(() => {
    if (serachAreaName === '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        // areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions2(res.data.activeAreaList)
      })
    }
  }, [])
  const handleChange = ({ target: { name, value } }) => {
    if (name === 'startDate') setStartDate(value)
  }

  const handleSubmit = () => {
    getAmenityListSearchRequest()
    setIsSearch(!isSearch)
  }

  useEffect(() => {
    if (buildingId === '') {
      dispatch(resetGetUnitFlatList())
    }
  }, [buildingId])

  useEffect(() => {
    setPage(1)
  }, [isSearch])

  useEffect(() => {
    setBuildingId('')
    setBldgName('')
    setFlatName('')
    setflatid('')
  }, [status])

  useEffect(() => {
    if (AmenityCalendarList.success) {
      setCalendarData(AmenityCalendarList.data.amenityDetailsList.map((data) => data))
    }

  }, [AmenityCalendarList.success])

  const myEventsList = calendarData.map(events=> ({
    title: events.type,
    start: moment(events.startDate).toDate(),
    end: moment(events.endDate).toDate(),
    allDay: false,
    desc: events.description,
    status:events.amenityStatus
  }));

  const eventPropGetter = ((event, start, end, isSelected)=>{
    let style = {
      backgroundColor: 'transparent',
      color: 'white',
    };
  
    if (event.status === "approved") {
      style = {
        backgroundColor: '#8bc58b',
        color: 'white',
      };
    }
    if (event.status === "pending") {
      style = {
        backgroundColor: '#ff8600e0',
        color: 'white',

      };
    }
  
    return { style };
  })   
    
  moment.locale("en-GB");
  const localizer = momentLocalizer(moment)

  const handleNavigate = (newDate) => {
    setCurrentDate(newDate);
    setCurrentMonth(newDate.toLocaleString('default', { month: 'long' }))
    setCurrentYear(newDate.getFullYear())
  };

  const handleInputChange = ({target:{name,value}}) => {
    if(name === 'startDate')setCurrentDate(value); // Update the calendar with the selected date
  };

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content members-list">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  {' '}
                  <i className={'now-ui-icons shopping_shop'} />{' '}
                </div>
                <div>
                  <span className="head-title">
                    {status === 'approved' && 'Approved Amenity Bookings'}
                    {status === 'rejected' && 'Rejected Amenity Bookings'}
                    {status === 'pending' && 'Pending Amenity Bookings'}
                    {status === 'amenitylists' && 'Amenity List'}
                    {status === 'calender' && "Calender"}
                    {/* {status === 'amenityTimeline' && 'Amenity Timeline'} */}
                  </span>
                  <span
                    style={{ float: 'right', paddingTop: '10px' }}
                    className="right-menu"
                    onClick={() => handleStatus('amenitylists')}
                  >
                    {' '}
                    Amenity List
                  </span>
                  {/* <span
                    style={{ float: 'right', paddingTop: '10px' }}
                    className="right-menu"
                    onClick={() => handleStatus('amenityTimeline')}
                  >
                    {' '}
                    Amenity Timeline
                  </span> */}
                </div>
                <span style={{ float: 'right', paddingTop: '10px' }}>
                  <span
                    className="right-menu"
                    onClick={() => handleStatus('approved')}
                  >
                    {' '}
                    Approved List{' '}
                  </span>{' '}
                  |
                  <span
                    className="right-menu"
                    onClick={() => handleStatus('rejected')}
                  >
                    {' '}
                    Rejected List{' '}
                  </span>{' '}
                  |
                  <span
                    className="right-menu"
                    onClick={() => handleStatus('pending')}
                  >
                    {' '}
                    Pending List{' '}
                  </span>
                  |
                  <span
                    className="right-menu"
                    onClick={() => handleStatus('calender')}
                  >
                    {' '}
                    Calender{' '}
                  </span>
                </span>
              </CardHeader>

              <CardBody>
                {/* {AmenityList.success && ( */}
                <div className="table-full-width table-responsive" id="onTop">
                  {(status === 'approved' ||
                    status === 'rejected' ||
                    status === 'pending') && (
                      <Form onSubmit={handleSubmit}>
                        {buildingList.success && (
                          <Row>
                            <Col md="3" sm="12">
                              <FormGroup style={{ margin: '10px 1px' }}>
                                <Label for="city">Building Name</Label>
                                <Select
                                  type="select"
                                  value={bldgName}
                                  showSearch
                                  placeholder="Select Building Name"
                                  optionFilterProp="children"
                                  onChange={onChangeSearch}
                                  onSearch={onSearch}
                                  style={{ width: '100%', borderRadius: '25px' }}
                                // loading={buildingNames.isLoading}
                                >
                                  <Option className="loginSelect" value="">
                                    Select Building Name
                                  </Option>

                                  {serachAreaName != '' &&
                                    options != undefined &&
                                    options.map((d, id) => (
                                      <Option
                                        key={id}
                                        className="loginSelect"
                                        value={`${d.areaId},${d.areaName}`}
                                      >
                                        {d.areaName}
                                      </Option>
                                    ))}
                                  {serachAreaName === '' &&
                                    options2.map((d, id) => (
                                      <Option
                                        key={id}
                                        className="loginSelect"
                                        value={`${d.areaId},${d.areaName}`}
                                      >
                                        {d.areaName}
                                      </Option>
                                    ))}
                                </Select>
                              </FormGroup>
                            </Col>
                            {areaType === 'Building' && (
                              <Col md="3" sm="12">
                                <FormGroup style={{ margin: '10px 1px' }}>
                                  <Label for="flatid" lat>
                                    Flat
                                  </Label>
                                  <Select
                                    type="select"
                                    showSearch
                                    value={flatName}
                                    placeholder="Select Flat Number"
                                    optionFilterProp="children"
                                    onChange={onChangeSearchFlatId}
                                    onSearch={onSearchFlatId}
                                    style={{ width: '100%' }}
                                  >
                                    <Option className="loginSelect" value="">
                                      {getFlat.data.message === 'Not Found' ? 'No Flat Found ' : "Select Flat Number"}
                                    </Option>
                                    {searchFlat != '' && optionsUnit != undefined
                                      ? optionsUnit.map((d, id) => (
                                        <Option
                                          key={id}
                                          className="loginSelect"
                                          value={`${d.id}, ${d.unit}`}
                                        >
                                          {d.unit}
                                        </Option>
                                      ))
                                      : getFlat.success &&
                                      getFlat.data.activeUnitList.map(
                                        (d, id) => (
                                          <Option
                                            key={id}
                                            className="loginSelect"
                                            value={`${d.id}, ${d.unit}`}
                                          >
                                            {d.unit}
                                          </Option>
                                        )
                                      )}
                                  </Select>
                                </FormGroup>
                              </Col>
                            )}
                            <Col xs="12" sm="12" md="3">
                              <FormGroup style={{ margin: '10px 1px' }}>
                                <label>Start Date</label>{' '}
                                {/* <span className="sub-label">(optional)</span> */}
                                <Input
                                  name="startDate"
                                  value={startDate || ''}
                                  type="date"
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )}

                        {buildingList.success && (
                          <Row style={{ justifyContent: 'center' }}>
                            <Col sm="12" style={{ textAlign: 'center' }}>
                              <Button
                                className="cust-button"
                                onClick={handleSubmit}
                                disabled={AmenityList.isLoading}
                              >
                                {AmenityList.isLoading ? <Spin /> : 'Search'}
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Form>
                    )}
                  {(status === 'approved' ||
                    status === 'rejected' ||
                    status === 'pending') && (
                      <Table responsive style={{ textAlign: 'center' }}>
                        <thead
                        // className="text-primary"
                        >
                          <tr>
                            <th>Booked By</th>
                            {/* <th>Name</th> */}
                            {/* <th>Phone</th> */}
                            <th>Amenity</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Duration</th>
                          </tr>
                        </thead>
                        <tbody>
                          {AmenityList.success &&
                            AmenityList.data.amenityDetailsList.map((d, id) => (
                              <React.Fragment key={id}>
                                <tr
                                  key={id}
                                  id={`toggler${id}`}
                                  onClick={() => {
                                    getAmenityMessageList(d.id)
                                    setAmenityId(d.id)
                                    setIds(id)
                                  }}
                                  className="ml-detail-tr"
                                  style={{
                                    backgroundColor: '#e8f5fd',
                                    color: 'black',
                                    fontWeight: 'semi-bold',
                                    boxShadow: 'none',
                                  }}
                                >
                                  <td>
                                    {d.userName ? d.userName : '-'} <br />
                                    {d.areaName ? d.areaName : '-'} /
                                    {d.unit ? d.unit : '-'} <br />
                                    {d.mobileNumber ? d.mobileNumber : '-'}
                                  </td>
                                  <td>{d.type}</td>
                                  <td>
                                    {moment(d.startDate).format('Do MMMM YY')}
                                    <br /> to
                                    <br />
                                    {moment(d.endDate).format('Do MMMM YY')}
                                  </td>
                                  <td>
                                    {moment(d.startDate).format('h:mm a')}
                                    <br /> to
                                    <br /> {moment(d.endDate).format('h:mm a')}
                                  </td>
                                  <td>{d.duration ? d.duration : '-'}</td>
                                </tr>

                                <tr className="ml-detail-tr">
                                  <td colSpan="6" className="ml-detail-td">
                                    <UncontrolledCollapse
                                      id="amenityChat"
                                      toggler={`toggler${id}`}
                                    // style={{ maxHeight: "350px", minHeight: "110px", overflowX: "hidden", width: "100%", margin: "10px 0px" }}
                                    >
                                      {ids === id ?
                                        <>
                                          <Row className="ml-detail-row">
                                            <Col
                                              xs="12"
                                              sm="6"
                                              md="12"
                                              lg="12"
                                              style={{
                                                padding: '20px',
                                              }}
                                            >
                                              <label>Message</label>
                                            </Col>
                                          </Row>
                                          <Row className="ml-detail-row"
                                            style={{
                                              maxHeight: '250px',
                                              overflow: 'auto',
                                              margin: 'auto',
                                              width: "100%"
                                            }}>
                                            {getAmenityMessageData.success &&
                                              getAmenityMessageData.record[d.id] &&
                                              getAmenityMessageData.record[d.id].map(
                                                (data, index) => (
                                                  <>
                                                    <Col
                                                      xs="12"
                                                      sm="2"
                                                      md="2"
                                                      lg="2"
                                                      style={{ padding: '10px 5px' }}
                                                    >
                                                    </Col>
                                                    <Col
                                                      xs="12"
                                                      sm="8"
                                                      md="8"
                                                      lg="8"
                                                      key={index}
                                                      style={{
                                                        padding: '10px 5px',
                                                        borderBottom:
                                                          '1px solid #ebecf1',
                                                      }}
                                                    >
                                                      <div
                                                        className="detail-val"
                                                        style={{
                                                          float:
                                                            data.role === 'Society'
                                                              ? 'right'
                                                              : 'left',
                                                        }}
                                                      >

                                                        <div
                                                          className={`custom-text-message-${data.role === 'Society' ? 'right' : 'left'}`}
                                                        >
                                                          {/* {data.content &&
                                                        data.content} */}
                                                          <div>
                                                            {data.content &&
                                                              data.content}
                                                          </div>
                                                          {data.role === 'Society' && <div style={{ display: 'flex', justifyContent: "end" }}>
                                                            <img
                                                              src={Tick}
                                                              alt="tick"
                                                              width="15px"
                                                              height="15px"
                                                            />
                                                          </div>}
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="detail-time"
                                                        style={{
                                                          textAlign:
                                                            data.role === 'Society'
                                                              ? 'right'
                                                              : 'left',
                                                        }}
                                                      >
                                                        {Util.formatDateTimeHour(
                                                          data.timestamp
                                                        )}
                                                      </div>
                                                    </Col>
                                                    <Col
                                                      xs="12"
                                                      sm="2"
                                                      md="2"
                                                      lg="2"
                                                      style={{ padding: '10px 5px' }}
                                                    >
                                                    </Col>
                                                  </>
                                                )
                                              )}
                                          </Row>
                                          {d.amenityStatus === 'pending' && (
                                            <>
                                              <div className="detail-val">
                                                <Row
                                                  style={{
                                                    justifyContent: 'center',
                                                  }}
                                                >
                                                  <Col
                                                    xs="12"
                                                    sm="12"
                                                    md="6"
                                                    lg="6"
                                                    style={{ padding: '10px' }}
                                                  >
                                                    <FormGroup>
                                                      <Input
                                                        type="textarea"
                                                        row="1"
                                                        name={message.id}
                                                        value={
                                                          message.id === d.id
                                                            ? message.content
                                                            : ''
                                                        }
                                                        id={d.amenityTypeId}
                                                        placeholder="Reply"
                                                        onChange={(e) =>
                                                          handleAmenityMessage(
                                                            e,
                                                            d.id
                                                          )
                                                        }
                                                        required
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                  <Col
                                                    xs="12"
                                                    sm="12"
                                                    md="2"
                                                    lg="2"
                                                    style={{ padding: '25px 10px' }}
                                                  >
                                                    <Button
                                                      className="cust-button"
                                                      style={{ minWidth: '100%' }}
                                                      onClick={() =>
                                                        handleAddAmenityMessage(d.id)
                                                      }
                                                      disabled={disabledButton() || addAmenityMessageData.isLoading}
                                                    >
                                                      {addAmenityMessageData.isLoading ? <Spin /> : 'Send'}
                                                    </Button>
                                                  </Col>
                                                </Row>
                                              </div>
                                              <div className="detail-val">
                                                <span
                                                  className="right-menu"
                                                  onClick={() =>
                                                    handleAmenityStatus(
                                                      d.id,
                                                      'approved'
                                                    )
                                                  }
                                                >
                                                  Approve
                                                </span>
                                                {' | '}
                                                <span
                                                  className="right-menu"
                                                  onClick={() =>
                                                    handleAmenityStatus(
                                                      d.id,
                                                      'rejected'
                                                    )
                                                  }
                                                >
                                                  Reject
                                                </span>
                                              </div>
                                            </>
                                          )}
                                        </>
                                        : ''}
                                    </UncontrolledCollapse>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                        </tbody>
                      </Table>
                    )}

                  {(status === 'calender') && (
                    <div>
                      <Input
                        name="startDate"
                        value={currentDate || ''}
                        type="date"
                        onChange={handleInputChange}
                        style={{ width: '12%' }}
                      /><br />
                      <Calendar
                        localizer={localizer}
                        events={myEventsList}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}
                        onNavigate={handleNavigate}
                        date={currentDate}
                        eventPropGetter={eventPropGetter}
                      />
                    </div>
                  )}

                  {status === 'amenitylists' && <AddAmenityType />}

                  {(status === 'calender') ? null :
                    AmenityList.failed && (
                      <div style={{ textAlign: 'center' }}>
                        <Alert message={AmenityList.data.message} type="info" style={{ margin: "20px 0 20px 0" }} />
                      </div>
                    )
                  }

                  {(status === 'calender') ? null :
                    AmenityList.success && (
                      <PaginationComponent
                        limit={limit}
                        totalItems={totalItems}
                        onSelect={handlePagination}
                        defaultPage={page}
                      />
                    )
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Amenity
