import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import { Spin } from 'antd';
import { resetAddAreaType, addAreaType } from "redux/actions/addAreaTypeAction";
import NotificationPopups from "components/NotificationPopups/NotificationPopups";
import FileBase64 from 'components/Custom/UploadFile'

function AddAreaType() {

  const [areaType, setAreaType] = useState("");
  const [areaIcon, setAreaIcon] = useState('')
  const [updatedPhoto, setUpdatedPhoto] = useState('')

  const login = useSelector(({ login }) => login);
  const addedareaType = useSelector(({ addareaType }) => addareaType);
  const dispatch = useDispatch();

  const handleChange = ({ target: { name, value } }) => {
    if (name === "areaType") setAreaType(value);
  }

  const addAreaTypeRequest = () => {

    const addAreaTypeReq = {
      initiatedBy: login.data.user.role,
      type: areaType,
      iconUrl: areaIcon
    }

    console.log(addAreaTypeReq)
    return dispatch(addAreaType(addAreaTypeReq));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    addAreaTypeRequest()
  }

  useEffect(() => {
    if (addedareaType.success) {
      setAreaType("")
      NotificationPopups('success', 'Success', addedareaType.data.message)
    }
    if (addedareaType.failed) {
      NotificationPopups('error', 'Failed', addedareaType.data.message)
    }
    if (addedareaType.network) {
      NotificationPopups('error', 'Network Error', "Check your internet connection")
    }
    return () => {
      dispatch(resetAddAreaType());
    };
  }, [addedareaType.success, addedareaType.failed, addedareaType.network])

  // Upload Image

  const handleAttachments = (filesList) => {
    const icon = filesList[0].base64.split(`,`)
    if (icon[0].includes('application')) {
      NotificationPopups("error", 'Failed', "Wrong File format")
    } else {
      setAreaIcon(icon[1])
      setUpdatedPhoto(filesList[0].base64)
    }
  }

  return (
    <div style={{ marginTop: "20px" }}>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="4" sm="12">
            <FormGroup>
              <Label for="areaType">Area Type</Label>
              <span className='required'>*</span>
              <Input type="text"
                name="areaType"
                placeholder="Area Type"
                id="areaType"
                value={areaType || ""}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="4" sm="12" lg="3" xs="12">
            <FormGroup>
              <Label for="serviceType">Select Icon</Label>
              <span className='required'>*</span>
              <FileBase64
                multiple={true}
                id="iconImage"
                accept=".jpeg, .png, .jpg, .svg"
                onDone={handleAttachments}
              />
            </FormGroup>
            {areaIcon && (
              <img
                src={updatedPhoto}
                width="40px"
                height="40px"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            )}
          </Col>
          <Col md="4" sm="12">
            <Button className='cust-button' style={{ marginTop: "22px", width: "70%" }}
              disabled={!areaType || !areaIcon || addedareaType.isLoading}
              onClick={handleSubmit}
            >{addedareaType.isLoading ? <Spin /> : "Add"}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default AddAreaType;
