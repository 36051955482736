import {
    ADD_SOCIETY_INTEREST_SUCCESS,
    ADD_SOCIETY_INTEREST_FAILED,
    LOADING_ADD_SOCIETY_INTEREST,
    RESET_ADD_SOCIETY_INTEREST,
    ADD_SOCIETY_INTEREST_NETWORKERROR,
  } from 'redux/types'
  
  const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: '',
    networkError: '',
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case LOADING_ADD_SOCIETY_INTEREST:
        return {
          isLoading: true,
          success: false,
          failed: false,
          network: false,
          data: {},
          message: '',
          networkError: '',
        }
      case ADD_SOCIETY_INTEREST_SUCCESS:
        return {
          isLoading: false,
          success: true,
          failed: false,
          network: false,
          data: action.payload,
          message: '',
          networkError: '',
        }
      case ADD_SOCIETY_INTEREST_FAILED:
        return {
          isLoading: false,
          success: false,
          failed: true,
          network: false,
          data: action.payload,
          message: '',
          networkError: '',
        }
      case ADD_SOCIETY_INTEREST_NETWORKERROR:
        return {
          isLoading: false,
          success: false,
          failed: false,
          network: true,
          data: action.payload,
          message: '',
          networkError: '',
        }
      case RESET_ADD_SOCIETY_INTEREST:
        return (state = initialState)
      default:
        return state
    }
  }
  