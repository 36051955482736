import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import { Tooltip, Popconfirm, Spin, Switch, Select } from 'antd'

import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { resetAddParking, addParking } from 'redux/actions/addParkingAction'
import axios from 'config/axios'
import Util from 'validations/index'

import Add from 'assets/img/Icons/flat/add.png'
import Delete from 'assets/img/Icons/flat/delete.png'
import { resetGetParking, getParking } from 'redux/actions/getParkingAction'
import {
  resetAddUnitFlatList,
  addUnitFlatList,
} from 'redux/actions/addUnitFlatListAction'
import {
  resetUpdateUnitFlatList,
  updateUnitFLatList,
} from 'redux/actions/updateUnitFlatListAction'
import {
  resetupdateBuilding,
  updateBuilding,
} from 'redux/actions/updateBuildingAction'

function AddParking({ areaTypeId, id }) {
  const [buildingId, setBuildingId] = useState('')
  const [noOfFlatsPF, setNoOfFlatsPF] = useState(0)
  const [startRange, setStartRange] = useState('')
  const [startFloor, setStartFloor] = useState(0)
  const [unitId, setUnitId] = useState('')
  const [bldgName, setBldgName] = useState('')
  const [prefix, setPrefix] = useState('')
  const [floors, setFloors] = useState([])
  const [noOfParking, setNoOfParking] = useState(0)
  const [Parking, setParking] = useState([])
  const [noOfFloor, setNoOfFloor] = useState('')

  const getareaType = useSelector(({ areaType }) => areaType)
  const addedFlatList = useSelector(({ addUnitFlatList }) => addUnitFlatList)
  const buildingNames = useSelector(({ buildingNames }) => buildingNames)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  const login = useSelector(({ login }) => login)
  const addedParking = useSelector(({ addParking }) => addParking)
  const updatedUnitFlat = useSelector(
    ({ updateUnitFlatList }) => updateUnitFlatList
  )
  const updatedBuilding = useSelector(({ updateBuilding }) => updateBuilding)

  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [serachAreaName, setSearchAreaName] = useState('')

  const inputRef = useRef(null)

  const dispatch = useDispatch()

  function handleChange({ target: { name, value, max } }) {
    if (name === 'noOfFlatsPF') {
      Util.verifyMobile(value) && value >= max
        ? setNoOfFlatsPF(0)
        : setNoOfFlatsPF(Math.max(0, value))
    }
    if (name === 'prefix') {
      // Util.verifyAlpahbet(value) ? setPrefix(value.toUpperCase()) : setPrefix(prefix.toUpperCase())
      setPrefix(value)
    }
    if (name === 'noOfParking') {
      Util.verifyMobile(value)
        ? setNoOfParking(Number(value))
        : setNoOfParking(noOfParking)
    }
    if (name === 'startRange')
      Util.verifyMobile(value)
        ? setStartRange(Math.max(0, value))
        : setStartRange(null)
    if (name === "noOfFloor") {
      if (value == "undefined") {
        setNoOfFloor(1)
      } else {
        setNoOfFloor(value)
      }

    }
    if (name === 'startFloor')
      Util.verifyMobile(value)
        ? setStartFloor(value)
        : setStartFloor(null)
    // setStartFloor(value)
  }
  const { Option } = Select

  function onChangeSearch(value) {
    const parkings = value.split(',')
    let park1 = parkings[0]
    let park2 = parkings[1]
    // let park3 = parkings[2]
    setBuildingId(park1)
    setBldgName(park2 === undefined ? '' : park2.split('-')[0])
    // setUnitId(park3)
    // setNoOfFloors(value[2])
    setSearchAreaName('')
    setPrefix(park2 === undefined ? '' : park2.split("-")[1])
    if (parkings[2] == "undefined") {
      setNoOfFloor(1)
    } else {
      setNoOfFloor(parkings[2])
    }
    // (bldgName !== park2.split('-')[0])
    if (bldgName !== park2) {
      setNoOfParking('')
      setStartRange('')
    }
  }

  function onSearch(val) {
    console.log(val,"val")
    setSearchAreaName(val)
  }

  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: 'parking',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
        isActive: true,
      }
      axios.post('society/getAreaList', userData).then((res) => {
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName, buildingList.success])

  useEffect(() => {
    if (updatedBuilding.success) {
      setBldgName('')
      setNoOfFloor('')
    }
    if (serachAreaName === '') {
      const userData = {
        areaType: 'parking',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        isActive: true,
        // areaName: serachAreaName,
      }
      axios.post('society/getAreaList', userData).then((res) => {
        setOptions2(res.data.areaListActive)
      })
    }
  }, [addedFlatList.success, updatedUnitFlat.success, updatedBuilding.success, buildingList.success])

  function EditUnit(floorIndex, roomIndex, name, e, checked) {
    const mainObj = [...Parking]
    const floorsMap = [...mainObj[floorIndex]]
    floorsMap[roomIndex] = {
      ...floorsMap[roomIndex],
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      areaId: buildingId,
      // [name]: Util.alphaNumeric(e.target.value)
      //   ? e.target.value
      //   : floorsMap[roomIndex].name,
      [name]: name === "name" ? e.target.value : checked,
      // unitId,
    }
    mainObj[floorIndex] = floorsMap
    setParking(mainObj)
  }

  function EditParkingVisitor(roomIndex, name, e, checked) {
    const mainObj = [...Parking]
    mainObj[roomIndex].isVisitor = checked
  }

  function AddFlatBtn(floorIndex) {
    const newFloors = Parking.map((rooms, floorIdx) => {
      if (floorIndex === floorIdx) {
        return [
          ...rooms,
          {
            initiatedBy: login.data.user.role,
            societyId: login.data.society.id,
            areaId: buildingId,
            name: startRange
              ? `${prefix}-` +
              parseInt(startRange + floorIndex * 100 + rooms.length)
              : null,
            // unitId,
            // visitor: false,
          },
        ]
      }
      return rooms
    })
    setParking(newFloors)
  }

  function RemoveFlatBtn(floorIndex) {
    const newFloors = Parking.map((rooms, i) => {
      if (floorIndex === i) {
        return rooms.splice(0, rooms.length - 1)
      }
      return rooms
    })
    setParking(newFloors)
  }


  useEffect(() => {
    const increment = startRange < 99 ? 10 : 100

    if (noOfFloor && noOfParking) {
      let len = startFloor > noOfFloor ? 0 : parseInt(noOfFloor - startFloor + 1)
      console.log(len,noOfFloor,noOfParking,"fvhfdhjv")
      const parkingFlr = Array(len).fill(null).map((_, floorIdx) =>
        Array(noOfParking)
          .fill(null)
          .map((_, idx) => {
            return {
              // visitor: false,
              initiatedBy: login.data.user.role,
              societyId: login.data.society.id,
              areaId: buildingId,
              name: startRange ? (prefix && `${prefix}-`) + (startRange < 99 ? parseInt(startRange + floorIdx * increment * (floorIdx === 0 ? '' : 10) + idx) : parseInt(startRange + floorIdx * increment + idx)) : '',
              // unitId,
              floor: `${parseInt(startFloor) + floorIdx}`
            }
          })

      )
      parkingFlr ? setParking(parkingFlr) : setParking([])
    }

  }, [noOfParking, noOfFloor, startRange, prefix,startFloor])

  const addFlatListRequest = () => {
    const obj = Parking.flat()
    return dispatch(addParking(obj))
  }

  function handleSubmit(e) {
    e.preventDefault()
    addFlatListRequest()
  }

  useEffect(() => {
    if (addedParking.success) {
      // setNoOfFloors('')
      setNoOfFlatsPF('')
      setUnitId('')
      setParking([])
      setStartRange('')
      setBuildingId('')
      setBldgName('')
      setFloors([])
      setPrefix('')
      setNoOfParking(0)
      setNoOfFloor('')
      setStartFloor('')
      NotificationPopups('success', 'Success', addedParking.data.message)
    }
    if (addedParking.failed) {
      setNoOfFlatsPF('')
      setUnitId('')
      setParking([])
      setStartRange('')
      setBuildingId('')
      setBldgName('')
      setFloors([])
      setPrefix('')
      setNoOfParking(0)
      setNoOfFloor('')
      setStartFloor('')
      NotificationPopups('error', 'Failed', addedParking.data.message)
    }
    if (addedParking.network) {
      NotificationPopups('error', 'Network Error', addedParking.data.message)
    }
    return () => {
      dispatch(resetAddParking())
    }
  }, [addedParking.success, addedParking.failed, addedParking.network])

  const disableButton = () => {
    return !(bldgName && noOfParking && startRange && startFloor<=noOfFloor && startFloor)
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <Row>
        <Col lg="3" md="3" sm="12" xs="12">
          {options2 ? (
            <FormGroup>
              <Label for="buildingId">Parking Area</Label>
              <span className='required'>*</span>
              <Select
                type="select"
                showSearch
                value={bldgName}
                placeholder="Select Building Name"
                optionFilterProp="children"
                onChange={onChangeSearch}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Parking Area
                </Option>
                {serachAreaName != '' &&
                  options != undefined &&
                  options.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.areaId},${d.areaName},${d.floor}`}
                    >
                      {`${d.parentName} / ${d.areaName}`}
                    </Option>
                  ))}
                {serachAreaName === '' &&
                  options2.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.areaId},${d.areaName},${d.floor}`}
                    >
                      {`${d.parentName} / ${d.areaName}`}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          ) : (
            <FormGroup>
              <Label for="buildingId">Please Add Parking Area</Label>
              <span className='required'>*</span>
              <Select
                disabled
                type="select"
                showSearch
                value='Please Add Parking Area'
                placeholder="Please Add Parking Area"
                optionFilterProp="children"
                onChange={onChangeSearch}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ width: '100%' }}
              ></Select>
            </FormGroup>
          )}
        </Col>

        <Col lg="3" md="3" sm="12" xs="12">
          <FormGroup>
            <Label for="noOfFloor">Number of Floors</Label>
            <span className='required'>*</span>
            <Input
              type="text"
              name="noOfFloor"
              id="noOfFloor"
              placeholder="Number of Floors"
              value={noOfFloor || ''}
              onChange={handleChange}
              max="99"
              required
              disabled
            />
          </FormGroup>
        </Col>

        <Col lg="3" md="3" sm="12" xs="12">
          <FormGroup>
            <Label for="startRange">Start Floor</Label>
            <span className='required'>*</span>
            <Input
              type="text"
              name="startFloor"
              id="startFloor"
              placeholder="Start Floor"
              value={startFloor || ''}
              onChange={handleChange}
              required
            />
          </FormGroup>
          {noOfFloor<startFloor ? <span style={{color:'red'}}>Start floor is greater than no. of floors</span>: ''}
          
        </Col>
        
        <Col lg="3" md="3" sm="12" xs="12">
          <FormGroup>
            <Label for="noOfParking">Number of Parking Slots</Label>
            <span className='required'>*</span>
            <Input
              type="text"
              name="noOfParking"
              id="noOfParking"
              placeholder="Number of Parking Slots"
              value={noOfParking || ''}
              onChange={handleChange}
              max="99"
              required
              disabled={noOfFloor<startFloor ? true : false}
            />
          </FormGroup>
        </Col>

        <Col lg="3" md="3" sm="12" xs="12">
          <FormGroup>
            <Label for="prefix">Prefix</Label>
            <Input
              type="text"
              name="prefix"
              id="prefix"
              placeholder="Enter Prefix"
              value={prefix || ''}
              onChange={handleChange}
              maxLength="5"
            />
          </FormGroup>
        </Col>

        <Col lg="3" md="3" sm="12" xs="12">
          <FormGroup>
            <Label for="startRange">Start Range</Label>
            <span className='required'>*</span>
            <Input
              type="text"
              name="startRange"
              id="startRange"
              placeholder="Start Range"
              value={startRange || ''}
              onChange={handleChange}
              required
            />
          </FormGroup>
        </Col>

      </Row>
      
      {Parking.length
        ? Parking.map((floor, floorIndex) => {
          return (
            <Row key={floorIndex}>
              {noOfParking && startFloor ?
                <>
                  <div style={{
                    width: "100%",
                    padding: "15px",
                    textDecorationLine: 'underline',
                    fontSize: '18px'
                  }}>Floor : {`${parseInt(startFloor) + floorIndex}`}</div>
                  {
                    floor.map((room, roomIndex) => (
                      <Col
                        xs={12}
                        sm={12}
                        md={noOfParking < 4 ? 2 : 2}
                        key={roomIndex}
                        style={{ marginLeft: "20px" }}
                      >
                        <FormGroup>
                          <Label>{`${bldgName} Slot`} </Label>
                          <Input
                            placeholder="Parking Slot"
                            type="text"
                            name="unit"
                            value={room.name}
                            onChange={(e) => {
                              EditUnit(floorIndex, roomIndex, 'name', e, null)
                            }}
                          />
                        </FormGroup>
                        <FormGroup tag="fieldset">
                          <Label>Visitor</Label>
                          <br />
                          <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            defaultunchecked
                            onChange={(checked, e) => {
                              EditUnit(floorIndex, roomIndex, 'isVisitor', e, checked)
                            }}
                          />
                        </FormGroup>
                      </Col>
                    ))
                  }
                  {
                    floor.length !== 0 ? (
                      <>
                        <div style={{ padding: '10px', margin: '5px' }}>
                          <Tooltip
                            placement="leftBottom"
                            title="Add Parking"
                            color="#108ee9"
                          >
                            <Button
                              className="sd-add-icons"
                              onClick={() => {
                                AddFlatBtn(floorIndex)
                              }}
                            >
                              <img src={Add} alt="add" style={{ width: '20px' }} />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            placement="rightBottom"
                            title="Delete Parking"
                            color="#108ee9"
                          >
                            <Button
                              className="sd-add-icons"
                              onClick={() => {
                                RemoveFlatBtn(floorIndex)
                              }}
                            >
                              <img
                                src={Delete}
                                alt="delete"
                                style={{ width: '20px' }}
                              />
                            </Button>
                          </Tooltip>
                        </div>
                      </>
                    ) : null
                  }
                </>
                : null
              }
            </Row>
          )
        })
        : null}

      <Row style={{ justifyContent: 'center', textAlign: "center" }}>
        <Col lg="4" md="4" sm="12" xs="12" style={{ textAlign: "center" }}>
          <Button
            className="cust-button"
            style={{ marginTop: '22px', width: '25%' }}
            onClick={handleSubmit}
            disabled={disableButton()}
            ref={inputRef}
          >
            {addedFlatList.isLoading ? <Spin /> : 'Add'}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default AddParking
