import axios from '../../config/axios'
import {
    LOGOUT_SUCCESS,
    LOGOUT_FAILED,
    LOADING_LOGOUT,
    RESET_LOGOUT,
    LOGOUT_NETWORKERROR,
} from '../types'

const resetLogoutUser = () => {
    return {
        type: RESET_LOGOUT,
    }
}

const logoutUser = (userData) => (dispatch) => {
    dispatch({ type: LOADING_LOGOUT })
    axios
        .post('user/adminSignOut', userData)
        .then((res) => {
            switch (res.data.statusCode) {
                case 200:
                    dispatch(success(res.data))
                    break
                default:
                    dispatch(failed(res.data))
                    break
            }
        })
        .catch((err) => dispatch({ type: LOGOUT_NETWORKERROR, payload: err }))
}

function success(data) {
    return {
        type: LOGOUT_SUCCESS,
        payload: data,
    }
}

function failed(data) {
    return {
        type: LOGOUT_FAILED,
        payload: data,
    }
}

export { resetLogoutUser, logoutUser }
