// Redux Imports
import React, { Suspense, useEffect, useState } from 'react'
import { Provider, useSelector } from 'react-redux'
import { store, persistor } from 'redux/store'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch, Redirect } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/js/src/collapse.js'
import 'assets/scss/now-ui-dashboard.scss?v1.4.0'
import 'assets/css/demo.css'
// import 'antd/dist/antd.less'
// import 'antd/dist/antd.css'
import Loader from './components/Loader/Loading.js'
import Society from 'layouts/Society'
import { getBadgeCount } from 'redux/actions/getBadgeCount.js'
import Notification from './views/PopUp_Message/Notification.js'
import ErrorPage from 'views/ErrorPage/ErrorPage.js'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import NetworkStatus from './views/NetworkStatus/NetworkStatus.js'


const AdminLayout = React.lazy(() => import('layouts/Admin.js'))
const Login = React.lazy(() => import('views/Login.js'))
const LoginSA = React.lazy(() => import('views/LoginSA.jsx'))

const hist = createBrowserHistory()

function App() {

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    hist.listen((location, action) => {
      if (action === 'POP' && !store.getState().login.isAvailable && location.pathname === '/' && token === undefined && token === "undefined") {
        hist.push('/SessionOut');
      }
    });
  }, [hist])



  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    if (isOnline === false) {
      NotificationPopups(
        'error',
        'Network Error',
        'No internet connection. Please check your network.'
      )
      setTimeout(() => {
        window.location.reload(true)
      }, 2000)

    }

  }, [isOnline])

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);


    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [isOnline]);

  return (
    <Suspense fallback={<Loader />}>
      <Router history={hist}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) =>
              store.getState().login.isAvailable &&
                store.getState().login.isSociety ? (
                <AdminLayout {...props} />
                // <Society {...props} />
              ) : (
                <Login />
              )
            }
          />
          <Route
            exact
            path="/Superadmin/Signin"
            render={(props) =>
              store.getState().login.isAvailable &&
                store.getState().login.isSociety ? (
                <AdminLayout {...props} />
              ) : (
                <LoginSA />
              )
            }
          />
          <Route
            path="/Society"
            render={(props) => <AdminLayout {...props} />}
          />
          <Route
            path="/Superadmin"
            render={(props) => <AdminLayout {...props} />}
          />
          <Route
            render={(props) => <ErrorPage {...props} />}
          />
          <Route
            path="/"
            render={(props) => store.getState().login.isAvailable === false &&
              store.getState().login.isSociety ? (
              <Login />
              // <Society {...props} />
            ) : (
              <Login />
            )}
          />

        </Switch>
      </Router>
      <Notification />

    </Suspense>
  )
}

export default App
