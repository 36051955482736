import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Table,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { Alert, Tooltip, Popconfirm, Select, Spin } from 'antd'
import {
  resetgetCompanyList,
  getCompanyList,
} from 'redux/actions/getCompanyListAction'
import {
  resetgetServiceType,
  getServiceType,
} from '../../redux/actions/getServiceTypeAction'
import {
  resetgetHelperType,
  getHelperType,
} from '../../redux/actions/getHelperTypeAction'
import {
  resetGetVisitorDetails,
  getVisitorDetails,
} from 'redux/actions/getVisitorDetailsAction'
import axios from 'config/axios'
import Util from 'validations/index'
import moment from 'moment'

function SearchFilter(props) {
  // const [editCompanyLogo, seteditCompanyLogo] = useState('')
  const [timestamp, settimestamp] = useState('')
  const [endDate, setEndDate] = useState('')
  const [servTypeId, setServTypeId] = useState('')
  const [helperTyperId, setHelperTypeId] = useState('')
  const [limit] = useState(6)
  const [page, setPage] = useState(1)
  const [companyName, setCompanyName] = useState('')
  const [helperName, setHelperName] = useState('')
  const [helperMobile, setHelperMobile] = useState()
  const [loginStatus, setLoginStatus] = useState('')

  const [isActive, setIsActive] = useState(false)

  const [searchHelperType, setSearchHelperType] = useState('')
  const [optionsHelperType, setOptionsHelperType] = useState([''])
  const [type, setType] = useState([''])
  const getCompany = useSelector(({ getCompany }) => getCompany)
  const visitorDetail = useSelector(
    ({ getVisitorDetails }) => getVisitorDetails
  )
  const helperTypeList = useSelector(({ getHelperType }) => getHelperType)
  const servType = useSelector(({ getservicetype }) => getservicetype)
  const login = useSelector(({ login }) => login)
  const initiatedBy = useState(login.data.user.role)
  const dispatch = useDispatch()

  const pageSize = getCompany.data.pages
  const totalItems = getCompany.data.count

  // useEffect(() => {
  //   const date = new Date()
  //   const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  //   const timestamp = moment(firstDay).format()
  //   settimestamp(timestamp)
  //   // const endDate = moment().format('YYYY-MM-DD')
  //   // setEndDate(endDate)
  // }, [])

  const getCompanyListRequest = () => {
    if (initiatedBy != 'Society') {
      const getCompanyListReq = {
        initiatedBy: login.data.user.role,
        companyTypeId: helperTyperId,
        limit,
        page,
      }
      return dispatch(getCompanyList(getCompanyListReq))
    }
  }

  const handlePagination = (selectedPage) => {
    setPage(selectedPage)
  }

  const getServiceTypeRequest = () => {
    const getServiceTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getServiceType(getServiceTypeReq))
  }

  const onChange = (checked) => {
    setIsActive(checked)
  }

  const { Option } = Select

  function onChangeSearchServType(value) {
    setServTypeId(value)
    setHelperTypeId('')
  }

  function onSearchServType(val) {
    setServTypeId(val)
  }

  function onChangeSearchHelperType(value) {
    setHelperTypeId(value)
  }

  function onSearchHelperType(val) {
    setHelperTypeId(val)
  }
  function onChangeLoginStatus(value) {
    setLoginStatus(value)
  }
  function onSearchLoginStatus(val) {
    setLoginStatus(val)
  }

  const handleSubmit = (e) => {
    props.setIsSearch()
    e.preventDefault()
    VisitorDetailsRequest()
    const objHelperMobile = {
      helperTypeId: helperTyperId,
      helperMobile: helperMobile,
      helperName: helperName,
      loginStatus: loginStatus,
      companyName: companyName,
      timestamp,
      // endDate
    }
    const obj = {
      helperTypeId: helperTyperId,
      helperName: helperName,
      loginStatus: loginStatus,
      companyName: companyName,
      timestamp,
      // endDate
    }
    helperMobile ? send(objHelperMobile) : send(obj)
  }
  
  useEffect(() => {
    if (servType.success && servType.data.activeServiceType) {
      const serviceTypeId = servType.data.activeServiceType
      const helperType = serviceTypeId.filter((d) => d.isVisitor === true)
      setType(helperType)
    }
  }, [servType.success])

  useEffect(() => {
    if (servTypeId != '' && login.data.user.role != 'Society') {
      const getHelperTypeReq = {
        initiatedBy: login.data.user.role,
        serviceTypeId: servTypeId,
      }
      return dispatch(getHelperType(getHelperTypeReq))
    }
  }, [servTypeId])

  useEffect(() => {
    if (initiatedBy != 'Society') {
      const getCompanyListReq = {
        initiatedBy: login.data.user.role,
        companyTypeId: helperTyperId,
        limit,
        page,
      }
      return dispatch(getCompanyList(getCompanyListReq))
    }
  }, [page])

  useEffect(() => {
    if (searchHelperType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        serviceTypeId: servTypeId,
        type: searchHelperType,
      }
      axios.post('helper/getHelperType', userData).then((res) => {
        if (res.status === 200 && res.data.helperTypeList != '') {
          setOptionsHelperType(res.data.helperTypeList)
        }
      })
    }
  }, [searchHelperType, servTypeId])

  useEffect(() => {
    const getHelperTypeReq = {
      initiatedBy: login.data.user.role,
      serviceTypeId: servTypeId,
    }
    return dispatch(getHelperType(getHelperTypeReq))
  }, [servTypeId])

  useEffect(() => {
    getServiceTypeRequest()
  }, [])

  useEffect(() => {
    getServiceTypeRequest()

    if (servType.success) {
      const type = servType.data.activeServiceType.filter((d) => d.isVisitor)
      setType(type)
    }
  }, [])

  const handleChange = ({ target: { name, value, checked } }) => {
    // if (name === 'companyName')
    //   Util.verifyAlpahbet(value)
    //     ? setCompanyName(value)
    //     : setCompanyName(companyName)
    if (name === 'companyName')
      setCompanyName(value)

    if (name === 'helperName')
      Util.alphabetsOnly(value)
        ? setHelperName(value)
        : setHelperName(helperName)
    if (name === 'helperMobile')
      Util.verifyMobile(value)
        ? setHelperMobile(value)
        : setHelperMobile(helperMobile)

    if (name === 'timestamp') settimestamp(value)
    // if (name === 'endDate') setEndDate(value)
  }
  const loginOptions = [
    {
      id: 1,
      name: 'In',
      value: 'In',
    },
    {
      id: 2,
      name: 'Out',
      value: 'Out',
    },
    {
      id: 3,
      name: 'Waiting',
      value: 'Waiting',
    },
  ]
  const VisitorDetailsRequest = () => {
    if (login.data.user.role === 'Society') {
      const getVisitorDetailsReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        helperTypeId: helperTyperId,
        helperName:helperName.trim(),
        loginStatus,
        companyName,
        page,
        limit,
        timestamp
        // endDate
      }
      const getVisitorDetailsHelperMobileReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        helperTypeId: helperTyperId,
        helperName:helperName.trim(),
        helperMobile,
        loginStatus,
        companyName,
        page,
        limit,
        // timestamp
        // endDate
      }
      return dispatch(
        getVisitorDetails(
          helperMobile ? getVisitorDetailsHelperMobileReq : getVisitorDetailsReq
        )
      )
    }
  }

  const send = (value) => {
    props.searchFilter(value)
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row style={{ alignItems: "baseline" }}>
          <Col xs="12" sm="12" md="3">
            <FormGroup>
              <Label>Date</Label>
              <span className="sub-label"></span>
              <Input
                name="timestamp"
                value={timestamp || ''}
                type="date"
                onChange={handleChange}
              // min={moment().format('YYYY-MM-DD')}
              />
            </FormGroup>
          </Col>
          <Col md="4" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType"> Name</Label>
              <Input
                type="text"
                placeholder="Name"
                name="helperName"
                id="serviceType"
                value={helperName || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="4" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Company Name</Label>
              <Input
                type="text"
                placeholder="Company Name"
                name="companyName"
                id="serviceType"
                value={companyName || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="4" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Phone No.</Label>
              <Input
                type="text"
                placeholder="Phone No."
                name="helperMobile"
                id="serviceType"
                maxLength="10"
                value={helperMobile || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="4" sm="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Service Type</Label>
              <Select
                type="select"
                showSearch
                value={servTypeId}
                placeholder="Select Service Type"
                optionFilterProp="children"
                onChange={onChangeSearchServType}
                onSearch={onSearchServType}
                style={{ width: '100%' }}
                loading={servType.isLoading}
              >
                <Option className="loginSelect" value="">
                  Select Service Type
                </Option>
                {servType.success &&
                  type.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.type}
                    </Option>
                  ))}

                {servType.failed && (
                  <Option className="loginSelect" value="">
                    No Data Found
                  </Option>
                )}
              </Select>
            </FormGroup>
          </Col>
          
          <Col md="4" sm="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Company Type</Label>
              <Select
              disabled={servTypeId === "" ? true : false}
                type="select"
                showSearch
                value={helperTyperId}
                placeholder="Select Company Type"
                optionFilterProp="children"
                onChange={onChangeSearchHelperType}
                onSearch={onSearchHelperType}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ width: '100%' }}
                loading={helperTypeList.isLoading}
              >
                <Option className="loginSelect" value="">
                  Select Company Type
                </Option>
                {searchHelperType != '' && optionsHelperType != undefined
                  ? optionsHelperType.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.type}
                    </Option>
                  ))
                  : helperTypeList.success &&
                  helperTypeList.data.helperTypeList.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.type}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="4" sm="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Login Status</Label>
              <Select
                type="select"
                showSearch
                value={loginStatus}
                placeholder="Select Service Type"
                optionFilterProp="children"
                onChange={onChangeLoginStatus}
                onSearch={onSearchLoginStatus}
                style={{ width: '100%' }}
              // loading={servType.isLoading}
              >
                <Option className="loginSelect" value="">
                  Select Login Status
                </Option>
                {loginOptions.map((d, id) => (
                  <Option key={id} className="loginSelect" value={d.value}>
                    {d.name}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>
        </Row>

        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
          // disabled={!helperTyperId || getCompany.isLoading}
          >
            {visitorDetail.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default SearchFilter
