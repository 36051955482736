// authReducer.js

import {
    IS_SESSION
} from "redux/types";

const initialState = {
    is_session: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case IS_SESSION:
            return {
                is_session: true,
            };

        default:
            return state;
    }
};