import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormFeedback,
  Form,
  Input,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Label,
} from 'reactstrap'
import { Spin ,Select} from 'antd'
import {
  resetgetPincodeDetails,
  getPincodeDetails,
} from 'redux/actions/pincodeAction'
import Util from '../../validations/index'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { addSociety, resetAddSociety } from 'redux/actions/addSocietyAction'

// Country Region Dropdown
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

import axios from 'config/axios'

// ant design components
import { Drawer } from 'antd'
import FileBase64 from 'components/Custom/UploadFile'

function AddSociety({ modal, toggle }) {
  const [societyName, setSocietyName] = useState('')
  const [societyAddress, setsocietyAddress] = useState('')
  const [societyAddress2, setsocietyAddress2] = useState('')
  const [country, setCountry] = useState('')
  const [region, setRegion] = useState('')
  const [landmark, setlandmark] = useState('')
  const [city, setCity] = useState('')
  const [searchCityName, setSearchCityName] = useState('')
  const [socName, setSocName] = useState('')
  const [options2, setOptions2] = useState([''])
  const [pincode, setPincode] = useState('')
  const [areaBlock, setareaBlock] = useState('')
  const [registrationNumber, setRegistration] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [showNotify, setShowNotify] = useState(true)
  const [email, setEmail] = useState('')
  const getpincode = useSelector(({ pincode }) => pincode)
  const login = useSelector(({ login }) => login)
  const addedSociety = useSelector(({ addSociety }) => addSociety)
  const cityList = useSelector(({ getCityList }) => getCityList)

  const dispatch = useDispatch()

  const [societyImage, setSocietyImage] = useState('')
  const [photo, setPhoto] = useState('')

  const { Option } = Select

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'societyName')
      Util.alphabetsOnly(value)
        ? setSocietyName(value)
        : setSocietyName(societyName)
    if (name === 'societyAddress') setsocietyAddress(value)
    if (name === 'landmark')
      Util.alphabetsOnly(value) ? setlandmark(value) : setlandmark(landmark)
    if (name === 'city')
      Util.alphabetsOnly(value) ? setCity(value) : setCity(city)
    if (name === 'pincode')
      Util.verifyMobile(value) ? setPincode(value) : setPincode(pincode)

    if (name === 'areaBlock')
      Util.alphabetsOnly(value) ? setareaBlock(value) : setareaBlock(areaBlock)
    if (name === 'region')
      Util.alphabetsOnly(value) ? setRegion(value) : setRegion(region)

    if (name === 'registration')
      Util.verifyMobile(value)
        ? setRegistration(value)
        : setRegistration(registrationNumber)

    if (name === 'email') {
      setIsEmailValid(Util.verifyEmail(value))
      setEmail(value.trim())
    }
  }

  function onChangeCity(value) {
    setCity(value)
    setSearchCityName('')
    setSocName('')
    // setSearchSocietyName('')
  }

  function onSearchCity(value) {
    setSearchCityName(value)
  }

  // All City List

  useEffect(() => {
    if (searchCityName != '') {
      const userData = {
        initiatedBy: 'Society',
        type: searchCityName,
      }
      axios.post('society/allCityList', userData).then((res) => {
        setOptions2(res.data.city)
      })
    }
  }, [searchCityName])

  const handleAttachments = (filesList) => {
    let img = filesList.base64.split(`,`)
    if (img[0].includes('application')) {
      NotificationPopups('error', 'Failed', 'Wrong File format.')
    } else {
      setPhoto(img[1])
      setSocietyImage(filesList.base64)
    }
  }

  const getPincodeRequest = (pin) => {
    console.log(pin)
    return dispatch(getPincodeDetails(pin))
  }

  const addSocietyRequest = () => {
    const addSocietyReq = {
      societyName: societyName.trim(),
      address: societyAddress.trim(),
      landmark: landmark.trim(),
      state: region.trim(),
      city: city.trim(),
      societyBlock: areaBlock.trim(),
      pincode,
      initiatedBy: login.data.user.role,
      registrationNumber,
      photo,
      billingEmail: email,
    }
    return dispatch(addSociety(addSocietyReq))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addSocietyRequest()
  }

  useEffect(() => {
    if (getpincode.success) {
      setareaBlock(getpincode.block)
      setCity(getpincode.city)
      setRegion(getpincode.state)
    }
    if (getpincode.failed) {
      setareaBlock('')
      setCity('')
      setRegion('')
    }
    // return () => {
    // 		dispatch(resetgetPincodeDetails());
    // 	};
  }, [getpincode.success, getpincode.failed])

  useEffect(() => {
    if (addedSociety.success) {
      setSocietyName('')
      setsocietyAddress('')
      setRegion('')
      setCity('')
      setPincode('')
      setlandmark('')
      setareaBlock('')
      setSocietyImage('')
      setPhoto('')
      setIsEmailValid('')
      setEmail('')
      setRegistration('')
      NotificationPopups('success', 'Success', addedSociety.data.message)
    }
    if (addedSociety.failed) {
      NotificationPopups('error', 'Failed', (addedSociety.data.errorVOList.map((ele, id) => (<>{id === 0 ? 1 : id + 1}-{ele.errorMessage} <br /></>))))
    }
    return () => {
      dispatch(resetAddSociety())
      dispatch(resetgetPincodeDetails())
    }
  }, [addedSociety.success, addedSociety.failed])

  const disabledSocButton = () => {
    return !(
      societyName &&
      societyAddress &&
      areaBlock &&
      city &&
      pincode &&
      societyImage
    )
  }

  return (
    <div className="addSoc">
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Col md="12" sm="12">
              <FormGroup>
                <Label for="societyname">
                  Society Name <span className="errorText">*</span>
                </Label>
                <Input
                  type="text"
                  name="societyName"
                  placeholder="Society Name"
                  id="societyname"
                  value={societyName || ''}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>
                  Billing Email <span className="errorText">*</span>
                </label>
                <Input
                  invalid={email && !isEmailValid}
                  placeholder="Email"
                  type="email"
                  name="email"
                  value={email || ''}
                  onChange={handleChange}
                />
                <FormFeedback style={{ textAlign: 'center' }}>
                  {email && !isEmailValid ? 'Invalid Email' : ''}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md="12" sm="12">
              <FormGroup>
                <Label for="address1">
                  Address <span className="errorText">*</span>
                </Label>
                <Input
                  type="textarea"
                  rows="4"
                  name="societyAddress"
                  id="address1"
                  value={societyAddress || ''}
                  onChange={handleChange}
                  required
                  placeholder="Address"
                />
              </FormGroup>
            </Col>
            <Col md="12" sm="12">
              <FormGroup>
                <Label for="address2">
                  Landmark <span className="errorText">*</span>
                </Label>
                <Input
                  type="text"
                  name="landmark"
                  id="landmark"
                  value={landmark || ''}
                  onChange={handleChange}
                  required
                  placeholder="Landmark"
                />
              </FormGroup>
            </Col>
            <Col md="12" sm="12">
              <FormGroup>
                <Label>
                  Pincode <span className="errorText">*</span>
                </Label>
                <Input
                  type="text"
                  name="pincode"
                  id="pincode"
                  minLength={6}
                  maxLength={6}
                  value={pincode || ''}
                  onChange={handleChange}
                  required
                  invalid={pincode && !(pincode.length === 6)}
                  placeholder="Pincode"
                />
                <FormFeedback style={{ textAlign: 'center' }}>
                  {pincode && !(pincode.length === 6)
                    ? 'Invalid Pincode. Enter 6 Digits'
                    : ''}
                  {/* {pincode.length === 6 && getpincode.failed ? "Incorrect Pincode" : ""} */}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md="12" sm="12">
              <FormGroup>
                <Label>
                  Block <span className="errorText">*</span>
                </Label>
                <Input
                  type="text"
                  name="areaBlock"
                  id="areaBlock"
                  value={areaBlock || ''}
                  onChange={handleChange}
                  required
                  placeholder="Block"
                />
              </FormGroup>
            </Col>
            <Col md="12" sm="12">
              <FormGroup>
                <Label for="city">
                  City <span className="errorText">*</span>
                </Label>
                <Select
                  type="select"
                  value={city}
                  showSearch
                  placeholder="Select City Name"
                  optionFilterProp="children"
                  onChange={onChangeCity}
                  onSearch={onSearchCity}
                  style={{ width: '100%' }}
                >
                  <Option value="">Select City Name</Option>
                  {searchCityName != '' &&
                    options2 != undefined &&
                    options2.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={d}
                      >
                        {d}
                      </Option>
                    ))}
                  {cityList.success &&
                    searchCityName === '' &&
                    cityList.data.city.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={d}
                      >
                        {d}
                      </Option>
                    ))}
                </Select>
                {/* <Input
                  type="text"
                  placeholder="City"
                  name="city"
                  id="city"
                  value={city || ''}
                  onChange={handleChange}
                  required
                /> */}
              </FormGroup>
            </Col>
            <Col md="12" sm="12">
              <FormGroup>
                <Label>
                  State <span className="errorText">*</span>
                </Label>
                <Input
                  type="text"
                  name="region"
                  id="region"
                  value={region || ''}
                  onChange={handleChange}
                  required
                  placeholder="State"
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>
                  Registration Number <span className="errorText">*</span>
                </label>
                <Input
                  placeholder="Registration Number"
                  type="text"
                  maxLength="10"
                  name="registration"
                  value={registrationNumber || ''}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>

            <Col md="12" sm="12">
              <label>Society Image <span className="errorText">*</span></label>
              <FormGroup>
                <FileBase64
                  multiple={false}
                  id="addhelperPhoto"
                  accept=".jpeg, .png, .jpg"
                  onDone={handleAttachments}
                />
                {societyImage && (
                  <img
                    src={societyImage}
                    width="35%"
                    height="35%"
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  />
                )}
              </FormGroup>
            </Col>

            <Row style={{ justifyContent: 'center' }}>
              <Button
                className="cust-button"
                onClick={handleSubmit}
                disabled={addedSociety.isLoading}
              >
                {addedSociety.isLoading ? <Spin /> : 'Add Society'}
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default AddSociety
