import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormFeedback,
  Form,
  Input,
  Row,
  // Modal,
  ModalHeader,
  ModalBody,
  Col,
  Label,
} from 'reactstrap'
import { Spin, Button, Modal } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  updateProfile,
  resetupdateProfile,
} from 'redux/actions/updateProfileAction'
// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import Progress from 'reactstrap/lib/Progress'
import Util from 'validations/index'
import imgsrc from '../../assets/img/Icons/SocietyDetails/building.png'

function UserPage({ showModal, handleCancel }) {
  const updatedProfile = useSelector(({ updateProfile }) => updateProfile)

  const login = useSelector(({ login }) => login)
  const [initiatedBy, setInitiatedBy] = useState(login.data.user.role)
  const userName = login.data.user.name
  const [name, setname] = useState(userName)
  const [mobile, setmobile] = useState(login.data.user.mobileNumber)

  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'name')
      Util.alphabetsOnly(value) ? setname(value) : setname(userName)
    if (name === 'mobile')
      Util.verifyMobile(value) ? setmobile(value) : setmobile(mobile)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    updateProfileRequest()
  }
  // console.log(updatedProfile, 'updatedProfile')
  const updateProfileRequest = () => {
    const updateProfileReq = {
      id: login.data.user.id,
      initiatedBy: login.data.user.role,
      mobileNumber: mobile,
      name,
    }
    return dispatch(updateProfile(updateProfileReq))
  }

  useEffect(() => {
    if (updatedProfile.success) {
      // console.log(updatedProfile.user)
      if (updatedProfile.data.status === 'OK') {
        console.log('200')
        setname(updatedProfile.data.user.name)
        setmobile(updatedProfile.data.user.mobileNumber)
        login.data.user.name = updatedProfile.data.user.name
        login.data.user.mobileNumber = updatedProfile.data.user.mobileNumber
      }

      NotificationPopups('success', 'Success', updatedProfile.data.message)
    }
    if (updatedProfile.failed) {
      NotificationPopups('error', 'Failed', updatedProfile.data.message)
    }
    if (updatedProfile.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetupdateProfile())
    }
  }, [updatedProfile.success, updatedProfile.failed, updatedProfile.network])


  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  return (
    <>
      {/* <PanelHeader size="sm" /> */}
      <Modal open={showModal} onCancel={handleCancel} footer={null} >

        <div className="content">
          <div className="author">
            <h4 className="title" style={{ textAlign: 'center' }}>Profile</h4>
          </div>
          <Row >
            <Col md='12'>
              {/* <Card style={{ borderRadius: "20px" }}> */}
              <Row >
                <Col md="12">

                  {/* <div className="author">
                    {initiatedBy === 'SuperAdmin' && <h5>SuperAdmin</h5>}
                    <h5 className="title">Email: {login.data.user.email}</h5>
                  </div> */}

                  <Form onSubmit={handleSubmit}>
                    <Row style={{ justifyContent: 'start' }}>
                      <Col md="12" sm="12">
                        {/* <h6>Contact Info</h6> */}
                        <FormGroup style={{ marginLeft: "15%", marginRight: "15%" }}>
                          <Label for="name" style={{ fontWeight: 500 }}>Name</Label>
                          <Input
                            type="text"
                            name="name"
                            placeholder="Name"
                            id="name"
                            value={name}
                            onChange={initiatedBy === 'SuperAdmin' ? handleChange : ""}
                            disabled={initiatedBy !== 'SuperAdmin' ? true : false}
                            required
                            style={{ fontWeight: 500 }}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12" sm="12">
                        <FormGroup style={{ marginLeft: "15%", marginRight: "15%" }}>
                          <Label for="name" style={{ fontWeight: 500 }}>Email</Label>
                          <Input
                            type="text"
                            name="email"
                            placeholder="Email"
                            id="email"
                            value={login.data.user.email}
                            disabled
                            required
                            style={{ fontWeight: 500 }}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12" sm="12">
                        <FormGroup style={{ marginLeft: "15%", marginRight: "15%" }}>
                          <Label for="mobile" style={{ fontWeight: 500 }}>Mobile</Label>
                          <Input
                            type="text"
                            name="mobile"
                            placeholder="Mobile"
                            id="mobile"
                            maxLength="10"
                            value={mobile}
                            onChange={initiatedBy === 'SuperAdmin' ? handleChange : ""}
                            required
                            disabled={initiatedBy !== 'SuperAdmin' ? true : false}
                            style={{ fontWeight: 500 }}
                          />
                        </FormGroup>
                      </Col>
                      {initiatedBy === 'SuperAdmin' ?
                        <div style={{ margin: "auto" }}>
                          <Button
                            className="cust-button"
                            style={{ marginTop: '22px', width: '70%', color: "white" }}
                            disabled={updatedProfile.isLoading}
                            onClick={handleSubmit}
                          >
                            {updatedProfile.isLoading ? <Spin /> : 'Update'}
                          </Button>
                        </div>
                        :
                        ''
                      }

                    </Row>
                  </Form>

                  <>
                    {initiatedBy === 'Society' && (
                      <CardBody style={{ padding: '15px' }}>
                        <div className="author">
                          <h6 className="title">Society Details </h6>
                        </div>
                        <Row>
                          <Col md="4" sm="12">
                            <img src={`${Util.imageUrl}${login.data.society.photo}`} alt="icon" style={{ display: "flex", margin: 'auto', height: "120px", width: "120px", marginTop: '15px' }}></img>
                          </Col>
                          <Col md="8" sm="12">
                            <p className="description" style={{ fontWeight: 500 }}>
                              {login.data.society.societyName} <br />
                              {login.data.society.address} <br />
                              {login.data.society.landmark}
                              {login.data.society.societyBlock},{' '}
                              {login.data.society.pincode}
                              <br />
                              {login.data.society.city}, {login.data.society.state}
                            </p>
                          </Col>
                        </Row>

                      </CardBody>
                    )}
                  </>
                </Col>
              </Row>
              {/* </Card> */}

            </Col>

          </Row>
        </div>
      </Modal>

    </>
  )
}

export default UserPage
