import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Tooltip, Popconfirm, Alert } from 'antd'
import { Spin, Switch, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetGetDashboard,
  getDashboard,
} from 'redux/actions/getDashboardAction'
import { getFeature } from 'redux/actions/getFeatureAction';
import { getSubCategory } from 'redux/actions/getSubCategoryAction'

function SearchDashboard(props) {
  const [displayDropdown, setDisplayDropdown] = useState('')
  const [featureId, setFeatureId] = useState('')
  const [featureName, setFeatureName] = useState('')
  const [subTypeId, setSubTypeId] = useState('')
  const [subTypeName, setSubTypeName] = useState('')
  const [priorityOrder, setPriorityOrder] = useState('')
  const [cardLayout, setCardLayout] = useState('')
  const [cardSize, setCardSize] = useState('')

  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const getFeatureCat = useSelector(({ getFeature }) => getFeature)
  const getSubCat = useSelector(({ getSubCategory }) => getSubCategory)
  const addedDashboard = useSelector(({ addDashboard }) => addDashboard)
  const getDashboardRes = useSelector(({ getDashboard }) => getDashboard)
  console.log(getDashboardRes, "getDashboardRes")

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'priorityOrder') setPriorityOrder(value)
    if (name === 'cardSize') setCardSize(value)
  }

  const labelOptionsDropDown = [
    {
      id: 1,
      name: 'Feature category',
    },
    {
      id: 2,
      name: 'Sub Category',
    },
  ]

  const getFeatureReq = () => {
    const req = {
      initiatedBy: login.data.user.role
    }
    return dispatch(getFeature(req))
  }

  const getSubCategoryReq = () => {
    const req = {
      initiatedBy: login.data.user.role
    }
    return dispatch(getSubCategory(req))
  }

  useEffect(() => {
    getFeatureReq()
    getSubCategoryReq()
  }, [])

  const cardLayoutDropDown = [
    {
      id: 1,
      layOutName: 'Cardinfo',
    },
    {
      id: 2,
      layOutName: 'Activitycard',
    },
    {
      id: 3,
      layOutName: 'Promocard',
    },
  ]
  const cardSizeDropDown = [
    {
      id: 1,
      size: '1',
    },
    {
      id: 2,
      size: '2',
    },
    {
      id: 3,
      size: '3',
    },
  ]

  const { Option } = Select

  function onChangeLabelOptons(value) {
    setDisplayDropdown(value)
  }

  function onSearchlabelOptions(val) {
    setDisplayDropdown(val)
  }
  function onChangeFeature(value) {
    setFeatureId(value)
  }

  function onSearchFeature(val) {
    setFeatureName(val)
  }
  function onChangeSubCat(value) {
    setSubTypeId(value)
  }

  function onSearchSubCat(val) {
    setSubTypeName(val)
  }
  function onChangeCardLayout(value) {
    setCardLayout(value)
  }

  function onSearchCardLayout(val) {
    setCardLayout(val)
  }
  function onChangeCardSize(value) {
    setCardSize(value)
  }

  function onSearchCardSize(val) {
    setCardSize(val)
  }

  const getDashboardListRequest = () => {
    if (login.data.user.role != 'Society') {
      const getFDashboardReq = {
        initiatedBy: login.data.user.role,
        labelType: displayDropdown,
        labelId: featureId ? featureId : subTypeId,
        cardName: cardLayout,
        cardSize: cardSize,
        limit: 6
      }
      return dispatch(getDashboard(getFDashboardReq))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    getDashboardListRequest()
    const obj = {
      labelType: displayDropdown,
      labelId: featureId ? featureId : subTypeId,
      cardName: cardLayout,
      cardSize: cardSize,
      // priorityOrder: priorityOrder,
    }
    send(obj)
  }

  const send = (value) => {
    props.searchFilter(value)
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ width: "100%", margin: "auto" }} id='a'>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Feature Label Type </label>
              <Select
                type="select"
                value={displayDropdown}
                showSearch
                placeholder="Select Feature Category"
                optionFilterProp="children"
                onChange={onChangeLabelOptons}
                onSearch={onSearchlabelOptions}
                style={{ width: '100%' }}
                getPopupContainer={() => document.getElementById('a')}
              >
                <Option className="loginSelect" value="">
                  Select Feature Label Type
                </Option>

                {labelOptionsDropDown.map((d, id) => (
                  <Option key={id} className="loginSelect" value={d.name}>
                    {d.name}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>

          {displayDropdown === 'Sub Category' ? (
            <Col md="3" sm="12" lg="3" xs="12">
              <FormGroup style={{ margin: '10px 1px' }}>
                <label>Label Name</label>
                <Select
                  type="select"
                  showSearch
                  value={subTypeId}
                  placeholder="Select Label Name"
                  optionFilterProp="children"
                  onChange={onChangeSubCat}
                  onSearch={onSearchSubCat}
                  style={{ width: '100%' }}
                  getPopupContainer={() => document.getElementById('a')}
                >
                  <Option className="loginSelect" value="" >
                    Select Label Name
                  </Option>
                  {getSubCat.success &&
                    getSubCat.data.subCategory.map((d, id) => (
                      <Option key={id} className="loginSelect" value={d.id}>
                        {d.name}
                      </Option>
                    ))}
                </Select>
              </FormGroup>
            </Col>
          ) : (
            <Col md="3" sm="12" lg="3" xs="12">
              <FormGroup style={{ margin: '10px 1px' }}>
                <label>Label Name</label>
                <Select
                  type="select"
                  showSearch
                  value={featureId}
                  placeholder="Select Label Name"
                  optionFilterProp="children"
                  onChange={onChangeFeature}
                  onSearch={onSearchFeature}
                  style={{ width: '100%' }}
                  getPopupContainer={() => document.getElementById('a')}
                >
                  <Option className="loginSelect" value="">
                    Select Label Name
                  </Option>
                  {getFeatureCat.success &&
                    getFeatureCat.data.featureCategory.map((d, id) => (
                      <Option key={id} className="loginSelect" value={d.id}>
                        {d.name}
                      </Option>
                    ))}
                </Select>
              </FormGroup>
            </Col>
          )}
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Card Layout</label>
              <Select
                type="select"
                showSearch
                value={cardLayout}
                placeholder="Select Card Layout"
                optionFilterProp="children"
                onChange={onChangeCardLayout}
                onSearch={onSearchCardLayout}
                style={{ width: '100%' }}
                getPopupContainer={() => document.getElementById('a')}
              >
                <Option className="loginSelect" value="">
                  Select Card Layout
                </Option>
                {cardLayoutDropDown.map((d, id) => (
                  <Option key={id} className="loginSelect" value={d.layOutName}>
                    {d.layOutName}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Select Card Size</Label>
              <Select
                type="select"
                showSearch
                placeholder="Select Card Size"
                optionFilterProp="children"
                onChange={onChangeCardSize}
                onSearch={onSearchCardSize}
                style={{ width: '100%' }}
                getPopupContainer={() => document.getElementById('a')}
              >
                <Option className="loginSelect" value="">
                  Select Card Size
                </Option>
                {cardSizeDropDown.map((d, id) => (
                  <Option key={id} className="loginSelect" value={d.size}>
                    {d.size}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>
        </Row>
        {/* <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Enter Priority Order</Label>
              <Input
                type="text"
                placeholder="Enter Priority Order"
                name="priorityOrder"
                id="serviceType"
                value={priorityOrder || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col> */}
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            disabled={getDashboardRes.isLoading}
            type="submit"
          >
            {getDashboardRes.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default SearchDashboard
