import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  TabContent,
  TabPane,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
// import TextField from '@mui/material/TextField'
// import Autocomplete from '@mui/material/Autocomplete'
import { Spin, Alert, Switch, Tooltip, Popconfirm, Select } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetgetHelperType,
  getHelperType,
} from '../../redux/actions/getHelperTypeAction'
import {
  resetUpdateHelperType,
  updateHelperType,
} from '../../redux/actions/updateHelperTypeAction'
import Util from 'validations/index'
import FileBase64 from '../../components/Custom/UploadFile'
import DisplayAttachment from '../../components/Custom/DisplayAttachment'
import PaginationComponent from 'views/Pagination'
import axios from 'config/axios'

function HelperTypeList() {
  const [profilePictureStatus, setprofilePictureStatus] = useState(false)
  const [updatedPhoto, setUpdatedPhoto] = useState('')
  const [serviceType, setserviceType] = useState('')
  const [optionsHelperType, setOptionsHelperType] = useState([''])
  const [searchHelperType, setSearchHelperType] = useState('')
  const [modal, setmodal] = useState(false)
  const [helptype, sethelptype] = useState('')
  const [id, setId] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [isFullTime, setisFullTime] = useState()
  const [isActive, setisActive] = useState()
  const [fileModal, setFileModal] = useState(false)
  const [iconImage, setIconImage] = useState('')
  const [value, setValue] = useState('')
  const [inputValue, setInputValue] = useState('')
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const servType = useSelector(({ getservicetype }) => getservicetype)
  const helperType = useSelector(({ getHelperType }) => getHelperType)
  const updatedHelper = useSelector(({ updateHelperType }) => updateHelperType)

  const [limit] = useState(20)
  const [page, setPage] = useState(1)
  const [initiatedBy] = useState(login.data.user.role)
  const totalItems = helperType.data.count
  const options = ['Option 1', 'Option 2', 'Option 4']

  const getHelperTypeRequest = () => {
    if (initiatedBy === 'SuperAdmin') {
      const getHelperTypeReq = {
        initiatedBy,
        serviceTypeId: serviceType,
        limit,
        page,
      }
      return dispatch(getHelperType(getHelperTypeReq))
    }
  }

  useEffect(() => {
    dispatch(resetgetHelperType())
    setOptionsHelperType([''])
  }, [])
  useEffect(() => {
    if (searchHelperType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        serviceTypeId: serviceType,
        type: searchHelperType,
      }
      console.log(userData, "dhjfakfbkabuakjsk")
      axios.post('helper/getHelperType', userData).then((res) => {
        if (res.status === 200 && res.data.activeType != '') {
          setOptionsHelperType(res.data.activeType)
        }
      })
    }
  }, [searchHelperType, serviceType])

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    getHelperTypeRequest()
  }

  const handleEditHelperType = () => {
    const updateHelperTypeReq = {
      initiatedBy: login.data.user.role,
      id,
      type: helptype,
      isFullTime,
      isActive,
      iconImage: profilePictureStatus ? iconImage : '',
    }
    return dispatch(updateHelperType(updateHelperTypeReq))
  }
  const { Option } = Select

  function onChangeSearch(value) {
    setserviceType(value)
  }

  function onSearch(val) {
    setSearchHelperType(val)
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'serviceType') setserviceType(value)
  }

  const handleEditChange = ({ target: { name, value, checked } }) => {
    console.log(value)
    if (name === 'helptype')
      Util.alphabetsOnly(value) ? sethelptype(value) : sethelptype(helptype)
  }

  const onChange = (checked) => {
    setisActive(checked)
    console.log(`switch to ${checked}`)
  }

  const onChangeFullTime = (checked) => {
    setisFullTime(checked)
  }

  const openEditModal = (type) => {
    setprofilePictureStatus(false)
    console.log(type)
    sethelptype(type.type)
    setId(type.id)
    setisActive(type.isActive)
    setisFullTime(type.isFullTime)
    setIconImage(type.iconImage)
    setmodal(!modal)
  }

  const toggle = () => {
    seterrMessage('')
    setmodal(!modal)
  }
  const handleAttachments = (filesList) => {
    setprofilePictureStatus(true)
    const icon = filesList.base64.split(`,`)
    setIconImage(icon[1])
    setUpdatedPhoto(filesList.base64)
  }
  const handleOpenFile = () => {
    setFileModal(true)
  }
  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }
  useEffect(() => {
    if (initiatedBy === 'SuperAdmin') {
      const getHelperTypeReq = {
        initiatedBy,
        serviceTypeId: serviceType,
        limit,
        page,
      }
      return dispatch(getHelperType(getHelperTypeReq))
    }
  }, [page])

  useEffect(() => {
    if (updatedHelper.success) {
      sethelptype('')
      setisActive()
      setisFullTime()
      setmodal(false)
      seterrMessage('')
      getHelperTypeRequest()
      NotificationPopups('success', 'Success', updatedHelper.data.message)
    }
    if (updatedHelper.failed) {
      seterrMessage(updatedHelper.data.message)
      sethelptype('')
      setisActive()
      setisFullTime()
      setmodal(false)
      seterrMessage('')
      NotificationPopups('error', 'Failed', updatedHelper.data.message)
    }
    if (updatedHelper.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetUpdateHelperType())
    }
  }, [updatedHelper.success, updatedHelper.failed, updatedHelper.network])

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <Col
                  md="12"
                >
                  <div>
                    <div className="typeHeading" id="onTop">
                      Helper Types
                    </div>
                    <Form >
                      <Row style={{ justifyContent: 'center' }}>
                        <Col md="4" sm="12">
                          <FormGroup>
                            <Select
                              type="select"
                              showSearch
                              placeholder="Select Helper Type"
                              optionFilterProp="children"
                              onChange={onChangeSearch}
                              onSearch={onSearch}
                              style={{ width: '100%' }}
                            >
                              <Option className="loginSelect" value="">
                                Select Helper Type
                              </Option>
                              {searchHelperType != '' &&
                                optionsHelperType != undefined
                                ? optionsHelperType.map((d, id) => (
                                  <Option
                                    key={id}
                                    className="loginSelect"
                                    value={d.id}
                                  >
                                    {d.type}
                                  </Option>
                                ))
                                : servType.success &&
                                servType.data.activeType.map((d, id) => (
                                  <Option
                                    key={id}
                                    className="loginSelect"
                                    value={d.id}
                                  >
                                    {d.type}
                                  </Option>
                                ))}
                            </Select>
                          </FormGroup>
                        </Col>

                        <Col md="2" sm="12">
                          <Button
                            className="cust-button"
                            style={{ marginTop: '0' }}
                            onClick={handleSubmit}
                          >
                            {helperType.isLoading ? <Spin /> : "Search"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>

                    <div style={{ marginTop: '25px' }}>
                      {helperType.success && (
                        <>
                          <Row>
                            {helperType.data.helperTypeList.map((d, id) => (
                              <Col sm="12" md="3" lg="3" key={id}>
                                <Card className="typeContent">
                                  <CardBody>
                                    <CardText>
                                      <CardImg
                                        src={`${Util.imageUrl}${d.iconImage}`}
                                        alt="Icon"
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                        }}
                                      />
                                      <span
                                        style={{
                                          textTransform: 'capitalize',
                                          fontWeight: 'semi-bold',
                                          color: ' #00326c',
                                        }}
                                      >
                                        {' '}
                                        {d.type}
                                      </span>
                                    </CardText>
                                    <div style={{ fontSize: '15px' }}>
                                      <i class="far fa-clock"></i> Full Time:{' '}
                                      {d.isFullTime ? 'Yes' : 'No'}
                                    </div>
                                    <CardText>
                                      <Button
                                        color="secondary"
                                        className="modalCustomButton"
                                        onClick={() => openEditModal(d)}
                                      >
                                        Edit
                                      </Button>
                                    </CardText>
                                  </CardBody>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </>
                      )}
                      {helperType.success &&
                        !helperType.data.helperTypeList.length && (
                          <div
                            style={{ textAlign: 'center', padding: '20px 0px' }}
                          >
                            <Alert
                              message="No Active Helper Type Found"
                              type="info"
                            />
                          </div>
                        )}
                    </div>
                  </div>

                  {helperType.failed && (
                    <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                      <Alert message={helperType.data.message} type="info" />
                    </div>
                  )}

                  {helperType.success && (
                    <PaginationComponent
                      limit={limit}
                      totalItems={totalItems}
                      onSelect={handlePagination}
                      defaultPage={page}
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered
          size="sm"
        >
          <ModalHeader toggle={toggle}>Edit</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="helptype">Helper Type</Label>
              <Input
                type="text"
                name="helptype"
                id="helptype"
                value={helptype}
                onChange={handleEditChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Full Time</Label>
              <br />
              <Switch
                defaultChecked={isFullTime}
                name="isFullTime"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={onChangeFullTime}
              />
            </FormGroup>
            <FormGroup>
              <Label>Icon</Label>
              <br />
              <Col xs="12" sm="12" md="12" style={{textAlign:'center'}}>
                <img
                  src={
                    profilePictureStatus
                      ? `${updatedPhoto}`
                      : `${Util.imageUrl}${iconImage}`
                  }
                  style={{
                    width: '50%',
                    height: '150px',
                    border: 'rounded',
                  }}
                />
                <div style={{ marginTop: '10px' }}>
                  <FileBase64
                    multiple={false}
                    id="helperTypeFiles"
                    accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                    onDone={handleAttachments}
                  />
                </div>
              </Col>
            </FormGroup>

            <FormGroup>
              <Label>Status</Label>
              <br />
              <Switch
                defaultChecked={isActive}
                name="isActive"
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={onChange}
              // style={{ width: '100%' }}
              />
            </FormGroup>
            <div style={{ textAlign: 'center' }} className="errorText">
              {errMessage}
            </div>
            <Button
              color="secondary"
              className="modalCustomButton"
              onClick={handleEditHelperType}
              disabled={updatedHelper.isLoading || !helptype}
            >
              {updatedHelper.isLoading ? <Spin /> : 'Edit'}
            </Button>
            <Button
              color="secondary"
              style={{ float: 'right' }}
              className="modalCustomButton"
              onClick={toggle}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>
      )}
      {fileModal && (
        <DisplayAttachment
          attachment={iconImage}
          fileModal={fileModal}
          toggleModal={toggleFileModal}
        />
      )}
    </>
  )
}

export default HelperTypeList
