import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Button,
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    CardBody,
    FormGroup,
    Table,
    Form,
    Label,
    Input,
    Row,
    Col,
    CardImg,
    CardText,
    CardTitle,
    CardSubtitle,
} from 'reactstrap'
import { Tooltip, Popconfirm, Spin, Alert, Switch } from 'antd'
import { getAllUnitType, resetGetAllUnitType } from 'redux/actions/getAllUnitTypeAction'
import {updateUnitType,resetUpdateUnitType} from 'redux/actions/updateUnitTypeAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import PaginationComponent from 'views/Pagination'
import Util from 'validations/index'

function FlatAreaType() {
    const [modal, setModal] = useState(false)
    const [editFlatAreaType, seteditFlatAreaType] = useState('')
    const [id, setId] = useState('')
    const [errMessage, seterrMessage] = useState('')
    const [limit] = useState(10)
    const [page, setPage] = useState(1)

    const login = useSelector(({ login }) => login)
    const getAllUnitTypeRes = useSelector(({ getAllUnitType }) => getAllUnitType)
    const updateUnitTypeRes = useSelector(({ updateUnitType }) => updateUnitType)
    const [initiatedBy] = useState(login.data.user.role)
    const dispatch = useDispatch()

    const totalItems = 10

    const getAllUnitTypeRequest = () => {
        const flatAreaTypeReq = {
            initiatedBy: login.data.user.role
        }
        return dispatch(getAllUnitType(flatAreaTypeReq))
    }

    const handlePagination = (selectedPage) => {
        setPage(selectedPage)
    }
    // useEffect(() => {
    //     if (initiatedBy === 'SuperAdmin') {
    //         const areaTypeReq = {
    //             initiatedBy,
    //         }
    //         return dispatch(getAllUnitType(areaTypeReq))
    //     }
    // }, [page])

    useEffect(() => {
        getAllUnitTypeRequest()
    }, [])

    useEffect(() => {
        if (updateUnitTypeRes.success) {
            seterrMessage('')
            setModal(!modal)
            getAllUnitTypeRequest()
            NotificationPopups('success', 'Success', updateUnitTypeRes.data.message)
        }
        if (updateUnitTypeRes.failed) {
            seterrMessage(updateUnitTypeRes.data.message)
        }
        if (updateUnitTypeRes.network) {
            NotificationPopups(
                'error',
                'Network Error',
                'Check your internet connection'
            )
        }
        return () => {
            dispatch(resetUpdateUnitType())
        }
    }, [
        updateUnitTypeRes.success,
        updateUnitTypeRes.failed,
        updateUnitTypeRes.network,
    ])

    const openEditModal = (type) => {
        seteditFlatAreaType(type.type)
        setId(type.id)
        setModal(!modal)
    }

    const toggle = () => {
        seterrMessage('')
        setModal(!modal)
    }

    const handleChange = ({ target: { name, value } }) => {
        console.log(value)
        if (name === 'editFlatAreaType') seteditFlatAreaType(value)
        // Util.alphaNumeric(value)
        //   ? seteditFlatAreaType(value)
        //   : seteditFlatAreaType(editFlatAreaType)
        seteditFlatAreaType(value)
        console.log(value)
      }

    const handleEditAreaType = () => {
        const editAreaTypeReq = {
            initiatedBy: 'SuperAdmin',
            id: id,
            type: editFlatAreaType
        }
        return dispatch(updateUnitType(editAreaTypeReq))
    }

    return (
        <>
            <Row>
                <Col md="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row-reverse',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Col>
                                        <div className="typeHeading">Flat Area List</div>
                                    </Col>
                                </div>

                                <Col md="12" style={{ padding: '20px' }}>
                                    {getAllUnitTypeRes.success && (
                                        <Col md="12" style={{ padding: '20px' }}>
                                            <Table responsive style={{ textAlign: 'center' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Flat Area</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                {getAllUnitTypeRes.success && (
                                                    <tbody>
                                                        {getAllUnitTypeRes.data?.unitTypeVOList.map((d, id) => (
                                                            <React.Fragment key={id}>
                                                                <tr key={id} id={`toggler${id}`}>
                                                                    <td>{d.type}</td>
                                                                    <td>
                                                                        <Button
                                                                            color="secondary"
                                                                            className="modalCustomButton"
                                                                            onClick={() => {
                                                                                openEditModal(d)
                                                                            }}
                                                                        >
                                                                            Edit
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ))}
                                                    </tbody>
                                                )}
                                            </Table>
                                        </Col>
                                    )}
                                </Col>
                                <Col>
                                    {getAllUnitTypeRes.success && (
                                        <PaginationComponent
                                            limit={limit}
                                            totalItems={totalItems}
                                            onSelect={handlePagination}
                                            defaultPage={page}
                                        />
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {getAllUnitTypeRes.failed && (
                                        <div style={{ textAlign: 'center', marginTop: '30px' }}>
                                            <Alert message={getAllUnitTypeRes.data.message} type="info" />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            {modal && (
                                <Modal
                                    isOpen={modal}
                                    toggle={toggle}
                                    centered
                                    size="sm"
                                    style={{ maxWidth: '250px', marginLeft: "auto", marginRight: "auto" }}
                                >
                                    <ModalHeader toggle={toggle}>Edit</ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <Label for="flatAreaType">Flat Area Type</Label>
                                            <Input
                                                type="text"
                                                name="editFlatAreaType"
                                                id="editFlatAreaType"
                                                value={editFlatAreaType}
                                                onChange={handleChange}
                                                required
                                            />
                                        </FormGroup>
                                        <div style={{ textAlign: 'center' }} className="errorText">
                                            {errMessage}
                                        </div>
                                        <Button
                                            color="secondary"
                                            className="modalCustomButton"
                                            onClick={handleEditAreaType}
                                            disabled={updateUnitTypeRes.isLoading || !editFlatAreaType}
                                        >
                                            {updateUnitTypeRes.isLoading ? <Spin /> : 'Edit'}
                                        </Button>
                                        <Button
                                            color="secondary"
                                            style={{ float: 'right' }}
                                            className="modalCustomButton"
                                            onClick={toggle}
                                        >
                                            Close
                                        </Button>
                                    </ModalBody>
                                </Modal>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default FlatAreaType
