import React, { useState, useEffect, useRef, memo, use } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from 'reactstrap'
//import FileBase64 from 'react-file-base64';
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import FileBase64 from '../../components/Custom/UploadFile'
import { Spin } from 'antd'
//ant design components
import { Upload, message, Select } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import { addNotice, resetAddNotice } from 'redux/actions/addNoticeAction'
import {
  getNoticeTypes,
  resetGetNoticeTypes,
} from 'redux/actions/getNoticeTypeAction'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import { resetgetAreaType, getAreaType } from 'redux/actions/getAreaTypeAction'
import axios from 'config/axios'
import moment from 'moment'
import Util from 'validations/index'
import TrashBtn from 'assets/img/Brand/buttons/trash-icon.png'

import NoticeBoardIcon from 'assets/img/Icons/Sidebar/noticeboard.png'

const { Dragger } = Upload

// const initialState = {
// 	startDate: new Date(),
// 	endDate: new Date(),
// 	title: "",
// 	content: "",
// 	type: ""
// }

function AddNotice() {
  //const [states, setStates] = useState(initialState);

  //const [ societyId, setSocietyId] = useState("");
  const [attachmentsStatus, setattachmentsStatus] = useState(false)
  const [noticeTitle, setNoticeTitle] = useState('')
  const [noticeContent, setNoticeContent] = useState('')
  const [attachment, setAttachment] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [noticeType, setNoticecType] = useState('')
  const [displayDropdown, setDisplayDropdown] = useState('To all')
  const [areaType, setareaType] = useState('')
  console.log(areaType,"areaTypeareaType")
  const [areaId, setareaId] = useState()
  const [flatid, setflatid] = useState()
  const [searchNoticeType, setSearchNoticeType] = useState('')
  const [searchAreaType, setSearchAreaType] = useState('')
  const [searchFlat, setSearchFlat] = useState('')

  const [bldgName, setBldgName] = useState('')
  const [flatName, setFlatName] = useState('')

  const [options, setOptions] = useState([])
  const [optionsAreaType, setOptionsAreaType] = useState([''])
  const [optionsUnit, setOptionsUnit] = useState([''])

  const [unitId, setUnitId] = useState()

  const login = useSelector(({ login }) => login)
  const addNewNotice = useSelector(({ addNotice }) => addNotice)
  const noticeTypes = useSelector(({ noticeTypes }) => noticeTypes)
  const areaTypes = useSelector(({ areaType }) => areaType)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)

  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'noticeTitle')
      Util.alphabetsOnly(value)
        ? setNoticeTitle(value)
        : setNoticeTitle(noticeTitle)
    if (name === 'noticeContent')
      setNoticeContent(value)
    if (name === 'startDate') setStartDate(value)

    if (name === 'endDate') setEndDate(value)
    if (name === 'noticeType') setNoticecType(value)
  }
  const { Option } = Select

  const labelOptionsDropDown = [
    {
      id: 1,
      name: 'To all',
    },
    {
      id: 2,
      name: 'Selected Area',
    },
  ]

  function onChangeLabelOptons(value) {
    setDisplayDropdown(value)
  }

  function onSearchlabelOptions(val) {
    setDisplayDropdown(val)
  }

  function onChangeSearch(value) {
    setNoticecType(value)
  }

  function onSearch(val) {
    setSearchNoticeType(val)
  }
  function onChangeSearchAreaType(value) {
    setareaType(value)
    setareaId('')
    setSearchAreaType('')
  }

  function onSearchAreaType(val) {
    setareaType(val)
  }

  function onChangeSearchAreaId(value) {
    console.log(value, "value")
    setareaId(value.split(',')[0])
    setBldgName(value.split(',')[1])
    setSearchAreaType('')
  }

  function onSearchAreaId(val) {
    setSearchAreaType(val)
  }

  function onChangeSearchFlatId(value) {
    setflatid(value)
    setFlatName(value)
    setSearchFlat('')
  }

  function onSearchFlatId(val) {
    setSearchFlat(val)
  }

  useEffect(() => {
    if (login.data.user.role !== 'SuperAdmin' && areaType != '') {
      const getBuildingListReq = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      return dispatch(getBuildingList(getBuildingListReq))
    }
  }, [areaType])

  useEffect(() => {
    if ( areaId) {
      const getUnitFlatListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: areaId,
        areaType: areaType === "Building" ? "Flat" : areaType,
      }
      return dispatch(getUnitFlatList(getUnitFlatListReq))
    }
  }, [areaType, areaId])

  const handleAttachments = (filesList) => {
    setattachmentsStatus(true)
    setAttachment(filesList[0].base64)
    if (attachment != '') {
      NotificationPopups('success', 'Success', 'Image Uploaded')
    }
  }

  const addNoticeRequest = () => {
    const addNoticeReq = {
      action: 'add',
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      noticeTitle: noticeTitle.trim(),
      noticeContent: noticeContent.trim(),
      attachment: attachmentsStatus ? attachment.split(`,`)[1] : '',
      startDate: startDate ? startDate : null,
      endDate: endDate ? endDate : null,
      noticeType: noticeType,
      unitId: flatid ? flatid : unitId,
      noticeFor: displayDropdown === 'To all' ? 'Society' : 'Unit',
    }
    return dispatch(addNotice(addNoticeReq))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addNoticeRequest()
  }

  const getNoticeTypeRequest = () => {
    const noticeypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getNoticeTypes(noticeypeReq))
  }
  useEffect(() => {
    if (searchNoticeType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
      }
      axios.post('notice/getNoticeType', userData).then((res) => {
        setOptions(res.data.activeNoticeType)
      })
    }
  }, [searchNoticeType])
  useEffect(() => {
    if (searchAreaType != '') {
      const userData = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: searchAreaType,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        if (res.status === 200 && res.data.activeType != '') {
          setOptionsAreaType(res.data.activeAreaList)
        }
      })
    }
  }, [searchAreaType])

  //search of flat
  useEffect(() => {
    if (areaId && searchFlat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: areaId,
        areaType: areaType === "Building" ? "Flat" : areaType,
        unit: searchFlat,
      }
      axios.post('society/getUnitList', userData).then((res) => {
        if (res.status === 200 && res.data.activeType != '') {
          setOptionsUnit(res.data.activeType)
        }
      })
    }
  }, [searchFlat])

  const getAreaTypeRequest = () => {
    const areaTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getAreaType(areaTypeReq))
  }

  useEffect(() => {
    getNoticeTypeRequest()
    getAreaTypeRequest()
  }, [])

  useEffect(() => {
    if (addNewNotice.success) {
      setNoticeTitle('')
      setNoticeContent('')
      setAttachment()
      setStartDate('')
      setEndDate('')
      setNoticecType('')
      setBldgName('')
      setDisplayDropdown('To all')
      setareaType('')
      setflatid('')
      setareaId('')
      NotificationPopups('success', 'Success', addNewNotice.data.message)
    }
    if (addNewNotice.failed) {
      NotificationPopups('error', 'Failed', addNewNotice.data.message === "Mandatory Parameter Missing" ? (addNewNotice.data.errorVOList.map((error, id) => (<>{id === 0 ? 1 : id + 1}-{error.errorMessage}<br /></>))) : addNewNotice.data.message)
    }
    if (addNewNotice.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }

    return () => {
      dispatch(resetAddNotice())
    }
  }, [addNewNotice.success, addNewNotice.failed, addNewNotice.network])

  const disabledBtn = () => {
    return !(noticeTitle && noticeContent && noticeType)
  }

  const remove = ()=>{
  setAttachment('')
  }

  return (
    <>
      <div className="listcontent">
        <Row>
          <Col xs="12" sm="6" md="8">
            <Row>
              <Col xs="12" sm="12" md="12">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <label>Notice Title<samll className="required">*</samll></label>
                  <Input
                    name="noticeTitle"
                    value={noticeTitle || ''}
                    placeholder="Title"
                    type="text"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col xs="12" sm="4" md="4">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <label>Start Date</label>{' '}
                  <span className="sub-label">(optional)</span>
                  <Input
                    name="startDate"
                    value={startDate || ''}
                    type="date"
                    onChange={handleChange}
                    min={moment().format('YYYY-MM-DD')}
                  />
                </FormGroup>
              </Col>
              <Col xs="12" sm="4" md="4">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <label>End Date</label>{' '}
                  <span className="sub-label">(optional)</span>
                  <Input
                    name="endDate"
                    value={endDate || ''}
                    type="date"
                    onChange={handleChange}
                    min={startDate ? startDate : moment().format('YYYY-MM-DD')}
                  />
                  {(startDate <= endDate) === true || (startDate && endDate) === '' ? '' : <p style={{ color: "red", fontSize: '12px' }}>Date must be {moment(startDate).format("L")} or later.</p>}
                </FormGroup>
              </Col>

              <Col xs="12" sm="4" md="4">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <label>Notice Event Type<samll className="required">*</samll></label>
                  <Select
                    type="select"
                    showSearch
                    value={noticeType}
                    placeholder="Select Notice Type"
                    optionFilterProp="children"
                    onChange={onChangeSearch}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                  >
                    <Option className="loginSelect" value="">
                      Select Notice Type
                    </Option>
                    {noticeTypes.success &&
                      noticeTypes.data.activeNoticeType.map((d, id) => (
                        <Option key={id} className="loginSelect" value={d.type}>
                          {d.type}
                        </Option>
                      ))}
                  </Select>
                </FormGroup>
              </Col>
              <Col md="4" sm="12" lg="4" xs="12">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <label>Send Notice</label>
                  <Select
                    type="select"
                    value={displayDropdown}
                    showSearch
                    placeholder="Select Feature Category"
                    optionFilterProp="children"
                    onChange={onChangeLabelOptons}
                    onSearch={onSearchlabelOptions}
                    style={{ width: '100%' }}
                  >
                    {/* <Option className="loginSelect" value="">
                      Send Notice to
                    </Option> */}
                    {labelOptionsDropDown.map((d, id) => (
                      <Option key={id} className="loginSelect" value={d.name}>
                        {d.name}
                      </Option>
                    ))}
                  </Select>
                </FormGroup>
              </Col>
              {displayDropdown != 'To all' && (
                <Col xs="12" sm="4" md="4">
                  <FormGroup style={{ margin: '10px 1px' }}>
                    <label for="areaType">Area Type<samll className="required">*</samll></label>
                    <Select
                      type="select"
                      showSearch
                      value={areaType}
                      placeholder="Select  Select Area Type"
                      optionFilterProp="children"
                      onChange={onChangeSearchAreaType}
                      onSearch={onSearchAreaType}
                      style={{ width: '100%' }}
                    >
                      <Option className="loginSelect" value="">
                        Select Area Type
                      </Option>
                      {areaTypes.success &&
                        areaTypes.data.areaTypeList.map((d, id) => (
                          <Option
                            key={id}
                            className="loginSelect"
                            value={d.type}
                          >
                            {d.type}
                          </Option>
                        ))}
                    </Select>
                  </FormGroup>
                </Col>
              )}
              {displayDropdown != 'To all' && (
                <Col md="4" sm="12" xs="12" lg="6">
                  <FormGroup style={{ margin: '10px 1px' }}>
                    <label for="areaId">Area Name<samll className="required">*</samll></label>
                    <Select
                      type="select"
                      showSearch
                      value={bldgName}
                      placeholder="Select Area Name"
                      optionFilterProp="children"
                      onChange={onChangeSearchAreaId}
                      onSearch={onSearchAreaId}
                      style={{ width: '100%' }}
                    >
                      <Option className="loginSelect" value="" >
                        Select Area Name
                      </Option>
                      {searchAreaType != '' && optionsAreaType != undefined
                        ? optionsAreaType.map((d, id) => (
                          <Option
                            key={id}
                            className="loginSelect"
                            value={`${d.areaId},${d.areaName}`}
                          >
                            {d.unit
                              ? `${d.unit} - ${d.areaName}`
                              : `${d.areaName}`}
                          </Option>
                        ))
                        : buildingList.success &&
                        buildingList.data.areaListActive.map((d, id) => (
                          <Option
                            key={id}
                            className="loginSelect"
                            value={`${d.areaId},${d.areaName}`}
                          // value={[d.areaId, d.unit ? d.unit : d.areaName]}
                          >
                            {d.unit
                              ? `${d.unit} - ${d.areaName}`
                              : `${d.areaName}`}
                          </Option>
                        ))}
                    </Select>
                  </FormGroup>
                </Col>
              )}
              {displayDropdown != 'To all' && (areaType === 'Building' || areaType === 'Shop' || areaType === "Bungalow") && (
                <Col md="4" sm="12" xs="12" lg="6">
                  <FormGroup style={{ margin: '10px 1px' }}>
                    <label for="flatid">{areaType === "Building" ? "Flat" : areaType}<samll className="required">*</samll></label>
                    <Select
                      required
                      type="select"
                      showSearch
                      value={flatid}
                      mode="multiple"
                      placeholder="Select Flat Number"
                      optionFilterProp="children"
                      onChange={onChangeSearchFlatId}
                      onSearch={onSearchFlatId}
                      style={{ width: '100%' }}
                    >
                      {/* <Option className="loginSelect" value="">
                        Select Flat Number
                      </Option> */}
                      {searchFlat != '' && optionsUnit != undefined
                        ? optionsUnit.map((d, id) => (
                          <Option
                            key={id}
                            className="loginSelect"
                            value={d.id}
                          >
                            {d.type}
                          </Option>
                        ))
                        : getFlat.success &&
                        getFlat.data.activeUnitList.map((d, id) => (
                          <Option
                            key={id}
                            className="loginSelect"
                            value={d.id}
                          >
                            {d.unit}
                          </Option>
                        ))}
                    </Select>
                  </FormGroup>
                </Col>
              )}
              {displayDropdown != 'To all' && areaType === 'Flat' && (
                <Col md="4" sm="12" xs="12" lg="6">
                  <FormGroup style={{ margin: '10px 1px' }}>
                    <label for="flatid" lat>
                      Flat<samll className="required">*</samll>
                    </label>

                    <Select
                      type="select"
                      value={flatid}
                      mode="multiple"
                      showSearch
                      placeholder="Select Flat Number"
                      optionFilterProp="children"
                      onChange={onChangeSearchFlatId}
                      onSearch={onSearchFlatId}
                      style={{ width: '100%' }}
                    >
                      <Option className="loginSelect" value="">
                        Select Flat Number
                      </Option>
                      {searchFlat != '' && optionsUnit != undefined
                        ? optionsUnit.map((d, id) => (
                          <Option
                            key={id}
                            className="loginSelect"
                            value={d.id}
                          >
                            {d.type}
                          </Option>
                        ))
                        : getFlat.success &&
                        getFlat.data.activeType.map((d, id) => (
                          <Option
                            key={id}
                            className="loginSelect"
                            value={d.id}
                          >
                            {d.unit}
                          </Option>
                        ))}
                    </Select>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs="12" sm="12" md="12">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <label>Content<samll className="required">*</samll></label>
                  <Input
                    style={{ maxHeight: '100px' }}
                    cols="80"
                    value={noticeContent}
                    pattern="^[0-9a-zA-Z]+$"
                    placeholder="Write the content of your notice"
                    rows="6"
                    type="textarea"
                    name="noticeContent"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>

          <Col xs="12" sm="6" md="4">
            <FormGroup style={{ margin: '10px 1px', textAlign: "center" }}>
              <label>Upload Attachments</label>
              <FileBase64
                multiple={true}
                id="addNoticeFiles"
                accept=".jpeg, .png, .doc, .docx, .pdf, .jpg, .svg"
                onDone={handleAttachments}
              />
              {attachment && (
                <>
                  <img
                    src={attachment}
                    width="50%"
                    height="150"
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  />
                </>
              )}
               {/* <Button className="cust-button" onClick={remove}>Delete</Button> */}
            </FormGroup>
          </Col>

          <Col
            xs="12"
            sm="12"
            md="12"
            style={{ textAlign: 'center', margin: '20px 0px' }}
          >
            <Button
              className="cust-button"
              onClick={handleSubmit}
              disabled={addNewNotice.isLoading || startDate <= endDate ? false : true}
            >
              {addNewNotice.isLoading ? <Spin /> : 'Add Notice'}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AddNotice
