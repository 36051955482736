import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Tooltip, Popconfirm, Alert } from 'antd'
import { Spin, Switch, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetAddDashboard,
  addDashboard,
} from 'redux/actions/addDashboardAction'
import axios from 'config/axios'

function AddDashboard() {
  const [displayDropdown, setDisplayDropdown] = useState('')
  const [featureId, setFeatureId] = useState('')
  const [featureName, setFeatureName] = useState('')
  const [subTypeId, setSubTypeId] = useState('')
  const [subTypeName, setSubTypeName] = useState('')
  const [priorityOrder, setPriorityOrder] = useState('')
  const [cardLayout, setCardLayout] = useState('')
  const [cardSize, setCardSize] = useState('')
  //
  const [optionsFeatureCat, setOptionsFeatureCat] = useState('')
  const [optionsSubCat, setOptionsSubCat] = useState('')
  const [searchFeatureCat, setSearchFeatureCat] = useState('')
  const [searchSubCat, setSearchSubCat] = useState('')
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const getFeatureCat = useSelector(({ getFeature }) => getFeature)
  const getSubCat = useSelector(({ getSubCategory }) => getSubCategory)
  const addedDashboard = useSelector(({ addDashboard }) => addDashboard)

  //feature dropdown
  useEffect(() => {
    if (searchFeatureCat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        name: searchFeatureCat,
      }
      axios.post('dashboard/getFeatureTypeList', userData).then((res) => {
        setOptionsFeatureCat(res.data.featureCategory)
      })
    }
  }, [searchFeatureCat])

  useEffect(() => {
    if (searchSubCat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        name: searchFeatureCat,
      }
      axios.post('dashboard/getSubCategoryList', userData).then((res) => {
        setOptionsSubCat(res.data.subCategory)
      })
    }
  }, [searchSubCat])

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'priorityOrder') setPriorityOrder(value)
    if (name === 'cardSize') setCardSize(value)
  }

  const labelOptionsDropDown = [
    {
      id: 1,
      name: 'Feature category',
    },
    {
      id: 2,
      name: 'Sub Category',
    },
  ]

  const cardLayoutDropDown = [
    {
      id: 1,
      layOutName: 'cardinfo',
    },
    {
      id: 2,
      layOutName: 'activitycard',
    },
    {
      id: 3,
      layOutName: 'promocard',
    },
  ]
  const cardSizeDropDown = [
    {
      id: 1,
      size: '1',
    },
    {
      id: 2,
      size: '2',
    },
    {
      id: 3,
      size: '3',
    },
  ]

  const { Option } = Select
  function onChangeLabelOptons(value) {
    setDisplayDropdown(value)
  }

  function onSearchlabelOptions(val) {
    setDisplayDropdown(val)
  }
  function onChangeFeature(value) {
    setFeatureId(value)
  }

  function onSearchFeature(val) {
    setSearchFeatureCat(val)
  }
  function onChangeSubCat(value) {
    setSubTypeId(value)
  }

  function onSearchSubCat(val) {
    setSearchSubCat(val)
  }
  function onChangeCardLayout(value) {
    setCardLayout(value)
  }

  function onSearchCardLayout(val) {
    setCardLayout(val)
  }
  function onChangeCardSize(value) {
    setCardSize(value)
  }

  function onSearchCardSize(val) {
    setCardSize(val)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    addDashboardRequest()
  }
  const addDashboardRequest = () => {
    const addDashboardReq = {
      initiatedBy: login.data.user.role,
      labelId: featureId ? featureId : subTypeId,
      labelType: displayDropdown,
      priorityOrder,
      cardName: cardLayout,
      cardSize,
      action: 'add',
    }
    return dispatch(addDashboard(addDashboardReq))
  }
  // const disabledBtn = () => {
  console.log(addedDashboard.data, "addedDashboard.data")
  // }
  useEffect(() => {
    if (addedDashboard.success) {
      // setDisplayDropdown('')
      setSubTypeId('')
      setSubTypeName('')
      setFeatureId('')
      setFeatureName('')
      setPriorityOrder('')
      setCardLayout('')
      setCardSize('')
      NotificationPopups('success', 'Success', addedDashboard.data.message)
    }
    if (addedDashboard.failed) {
      displayDropdown === "" || searchSubCat === "" || featureId === "" || cardLayout === "" || priorityOrder === "" || cardSize === "" ?
        NotificationPopups('error', 'Failed', (addedDashboard.data.errorVOList.map((d, id) => <>{id === 0 ? 1 : id + 1}-{d.errorMessage}<br /></>)))
        :
        NotificationPopups('error', 'Failed', addedDashboard.data.message)
    }
    if (addedDashboard.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddDashboard())
    }
  }, [addedDashboard.success, addedDashboard.failed, addedDashboard.network])

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Select Feature Label Type </label>
              <Select
                type="select"
                value={displayDropdown}
                showSearch
                placeholder="Select Feature Category"
                optionFilterProp="children"
                onChange={onChangeLabelOptons}
                onSearch={onSearchlabelOptions}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Feature Label Type
                </Option>

                {labelOptionsDropDown.map((d, id) => (
                  <Option key={id} className="loginSelect" value={d.name}>
                    {d.name}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>

          {displayDropdown === 'Sub Category' ? (
            <Col md="4" sm="12" lg="4" xs="12">
              <FormGroup style={{ margin: '10px 1px' }}>
                <label>Select Label Name</label>
                <Select
                  type="select"
                  showSearch
                  placeholder="Select Label Name"
                  optionFilterProp="children"
                  onChange={onChangeSubCat}
                  onSearch={onSearchSubCat}
                  style={{ width: '100%' }}
                >
                  <Option className="loginSelect" value="">
                    Select Label Name
                  </Option>
                  {getSubCat.success &&
                    getSubCat.data.subCategory.map((d, id) => (
                      <Option key={id} className="loginSelect" value={d.id}>
                        {d.name}
                      </Option>
                    ))}
                </Select>
              </FormGroup>
            </Col>
          ) : (
            <Col md="4" sm="12" lg="4" xs="12">
              <FormGroup style={{ margin: '10px 1px' }}>
                <label>Select Label Name</label>
                <Select
                  type="select"
                  showSearch
                  placeholder="Select Label Name"
                  optionFilterProp="children"
                  onChange={onChangeFeature}
                  onSearch={onSearchFeature}
                  style={{ width: '100%' }}
                >
                  <Option className="loginSelect" value="">
                    Select Label Name
                  </Option>
                  {getFeatureCat.success &&
                    getFeatureCat.data.featureCategory.map((d, id) => (
                      <Option key={id} className="loginSelect" value={d.id}>
                        {d.name}
                      </Option>
                    ))}
                </Select>
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Select Card Layout</label>
              <Select
                type="select"
                showSearch
                placeholder="Select Card Layout"
                optionFilterProp="children"
                onChange={onChangeCardLayout}
                onSearch={onSearchCardLayout}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Card Layout
                </Option>
                {cardLayoutDropDown.map((d, id) => (
                  <Option key={id} className="loginSelect" value={d.layOutName}>
                    {d.layOutName}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Select Card Size</Label>
              <Select
                type="select"
                showSearch
                placeholder="Select Card Size"
                optionFilterProp="children"
                onChange={onChangeCardSize}
                onSearch={onSearchCardSize}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Card Size
                </Option>
                {cardSizeDropDown.map((d, id) => (
                  <Option key={id} className="loginSelect" value={d.size}>
                    {d.size}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Enter Priority Order</Label>
              <Input
                type="text"
                placeholder="Enter Priority Order"
                name="priorityOrder"
                id="serviceType"
                value={priorityOrder || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            disabled={addedDashboard.isLoading}
            type="submit"
          >
            {addedDashboard.isLoading ? <Spin /> : 'Add'}
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default AddDashboard
