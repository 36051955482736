import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  UncontrolledCollapse,
} from 'reactstrap'
import randomColor from 'randomcolor'
// import 'react-calendar/dist/Calendar.css'
import {
  Tooltip,
  Popconfirm,
  Alert,
  Rate,
  Spin,
  Switch,
  Select,
  List,
  Avatar,
  Skeleton,
  Collapse,
} from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

import PaginationComponent from 'react-reactstrap-pagination'
import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'
import {
  resetAddDashboardFeatureType,
  addDashboardFeatureType,
} from 'redux/actions/addDashboardFeatureTypeAction'
import {
  resetUpdateDashboard,
  updateDashboard,
} from 'redux/actions/updateDashboardAction'
import { addDashboard,resetAddDashboard } from 'redux/actions/addDashboardAction'
import { getFeatureType } from 'redux/actions/getFeatureTypeAction'
import axios from 'config/axios'

import Add from 'assets/img/Icons/DashBoardConfig/add.png'
import Delete from 'assets/img/Icons/DashBoardConfig/delete.png'

function ViewDashboard({ data }) {
  console.log(data)
  const [displayfeatureType, setDisplayFeaturType] = useState(true)
  const [showEditFeatureCategory, setShowEditFeatureCategory] = useState(true)
  const [showAddFeatureType, setShowAddFeatureType] = useState(false)
  const [showEditFeatureType, setShowEditFeatureType] = useState(false)
  const [displayDropdown, setDisplayDropdown] = useState('')
  const [labelName, setLabelName] = useState('')
  const [layout, SetLayout] = useState('')
  const [redirect, setRedirect] = useState('')
  //options
  const [optionsFeatureCat, setOptionsFeatureCat] = useState([''])
  const [optionsSubCat, setOptionsSubCat] = useState('')
  const [searchFeatureCat, setSearchFeatureCat] = useState('')
  const [searchSubCat, setSearchSubCat] = useState('')
  //editFeatureCategory
  const [editId, setEditId] = useState(data.id)
  const [editLabelId, setEditLabelId] = useState(data.labelId)
  const [editDisplayDropDown, setEditDisplayDropDown] = useState(data.labelType)
  const [editFeatureCategory, setEditFeatureCategory] = useState(data.labelId)
  const [editSubCategory, setEditSubCategory] = useState(data.labelId)
  const [editCardName, setEditCardName] = useState(data.cardName)
  const [editCardSize, setEditCardSize] = useState(data.cardSize)
  const [editPriorityOrder, setEditPriorityOrder] = useState(data.priorityOrder)
  //add Feature Type
  const [addFeatureType, setAddFeatureType] = useState('')
  const [addPriorityOrder, setAddPriorityOrder] = useState('')
  const [featureType, setFeatureType] = useState(data.featureType)
  const [isActive, setIsActive] = useState(data.isActive)
  const [featureId, setFeatureId] = useState('')
  //Edit Feature Type
  const [editFeatureTypeId, setEditFeatureTypeId] = useState('')
  const [editFeatureType, setEditFeatureType] = useState('')
  const [editFeatureTypePriorityOrder, setEditFeaturePriorityOrder] =
    useState('')
  const [featureName, setFeatureName] = useState('')
  const [subTypeId, setSubTypeId] = useState('')
  const [subTypeName, setSubTypeName] = useState('')
  const [priorityOrder, setPriorityOrder] = useState('')
  const [action, setAcion] = useState('')
  const [dashboardId, setDashboardId] = useState(data.id)
  const [cardLayout, setCardLayout] = useState('')

  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const getFeatureCat = useSelector(({ getFeature }) => getFeature)
  const getSubCat = useSelector(({ getSubCategory }) => getSubCategory)
  const FeatureType = useSelector(({ getFeatureType }) => getFeatureType)
  const addedDashboard = useSelector(({ addDashboard }) => addDashboard)
  const addedDashboardFeatureType = useSelector(
    ({ addDashboardFeatureType }) => addDashboardFeatureType
  )
  const getFeatureTypeRequest = () => {
    const getFeatureTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getFeatureType(getFeatureTypeReq))
  }
  useEffect(() => {
    getFeatureTypeRequest()
  }, [])

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'priorityOrder')
      Util.verifyMobile(value)
        ? setPriorityOrder(value)
        : setPriorityOrder(priorityOrder)
    if (name === 'editPriorityOrder')
      Util.verifyMobile(value)
        ? setEditPriorityOrder(value)
        : setEditPriorityOrder(editPriorityOrder)
    if (name === 'addPriorityOrder')
      Util.verifyMobile(value)
        ? setAddPriorityOrder(value)
        : setAddPriorityOrder(addPriorityOrder)
    if (name === 'editFeatureTypePriorityOrder')
      setEditFeaturePriorityOrder(value)
    Util.verifyMobile(value)
      ? setEditFeaturePriorityOrder(value)
      : setEditFeaturePriorityOrder(editFeatureTypePriorityOrder)
    if (name === 'editCardSize') setEditCardSize(value)
  }

  const labelOptionsDropDown = [
    {
      id: 1,
      name: 'Feature Category',
    },
    {
      id: 2,
      name: 'Sub Category',
    },
  ]

  const cardLayoutDropDown = [
    {
      id: 1,
      layOutName: 'Cardinfo',
    },
    {
      id: 2,
      layOutName: 'Activitycard',
    },
    {
      id: 3,
      layOutName: 'Promocard',
    },
  ]
  const cardSizeDropDown = [
    {
      id: 1,
      size: '1',
    },
    {
      id: 2,
      size: '2',
    },
    {
      id: 3,
      size: '3',
    },
  ]
  const onChange = (checked) => {
    setIsActive(checked)
  }

  const { Option } = Select
  //

  //feature dropdown
  useEffect(() => {
    if (searchFeatureCat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        name: searchFeatureCat,
      }
      axios.post('dashboard/getFeatureTypeList', userData).then((res) => {
        setOptionsFeatureCat(res.data.featureType)
      })
    }
  }, [searchFeatureCat])

  function onChangeLabelOptons(value) {
    setDisplayDropdown(value)
  }

  function onSearchlabelOptions(val) {
    setDisplayDropdown(val)
  }
  function onChangeFeature(value) {
    setFeatureId(value)
  }

  function onSearchFeature(val) {
    setFeatureName(val)
  }
  //Edit Feature Cat
  function onChangeEditFeatureCatLabelOptons(value) {
    setEditDisplayDropDown(value)
  }

  function onSearchEditFeatureCatlabelOptions(val) {
    setEditDisplayDropDown(val)
  }
  function onChangeEditFeature(value) {
    setEditLabelId(value)
  }

  function onSearcheditFeature(val) {
    setSearchFeatureCat(val)
  }
  function onChangeEditSubCat(value) {
    setEditLabelId(value)
  }

  function onSearchEditSubCat(val) {
    setSearchSubCat(val)
  }
  function onChangeEditCardLayout(value) {
    setEditCardName(value)
  }

  function onSearchEditCardLayout(val) {
    setEditCardName(val)
  }
  function onChangeEditCardSize(value) {
    setEditCardSize(value)
  }

  function onSearchEditCardSize(val) {
    setEditCardSize(val)
  }

  function onChangeSubCat(value) {
    setSubTypeId(value)
  }

  function onSearchSubCat(val) {
    setSubTypeName(val)
  }
  //Add FeatureType
  function onChangeAddFeatureType(value) {
    setAddFeatureType(value)
  }

  function onSearchAddFeatureType(val) {
    setSearchFeatureCat(val)
  }
  //Edit FeatureType
  function onChangeEditFeatureType(value) {
    setEditFeatureTypeId(value[0])
    setEditFeatureType(value[1])
  }

  function onSearchEditFeatureType(val) {
    setEditFeatureType(val)
  }

  function onChangeCardLayout(value) {
    setEditCardName(value)
  }

  function onSearchCardLayout(val) {
    setEditCardName(val)
  }
  const handleSubmitUpdateDashboard = (e) => {
    e.preventDefault()
    updateDashboardRequest()
  }
  const handleSubmitAddDashboardFeatureType = (e) => {
    e.preventDefault()
    addDashboardFeatureTypeRequest()
  }

  const updateDashboardRequest = () => {
    const updateDahboardReq = {
      initiatedBy: login.data.user.role,
      id: editId,
      labelId: editLabelId,
      labelType: editDisplayDropDown,
      cardName: editCardName,
      priorityOrder: editPriorityOrder,
      cardSize: editCardSize,
      action: 'Update',
      isActive,
      isPriority: data.priorityOrder != editPriorityOrder ? true : false,
    }
    return dispatch(addDashboard(updateDahboardReq))
  }
  const addDashboardFeatureTypeRequest = () => {
    const addDashboardFeatureTypeReq = {
      dashboardId,
      id: addFeatureType,
      initiatedBy: login.data.user.role,
      priorityOrder: addPriorityOrder,
      action: 'add',
    }
    return dispatch(addDashboardFeatureType(addDashboardFeatureTypeReq))
    // console.log(addDashboardFeatureTypeReq)
  }
  const handleRemoveFeatureType = (data) => {
    console.log(data)
    const removeFeatureTypeReq = {
      id: data.id,
      action: 'remove',
      initiatedBy: login.data.user.role,
    }
    return dispatch(addDashboardFeatureType(removeFeatureTypeReq))
  }
  const handleEditFeatureType = (data) => {
    console.log(data)
    setEditFeatureTypeId(data.id)
    setEditFeatureType(data.name)
    setEditFeaturePriorityOrder(data.priorityOrder)
  }

  const handleSubmitEditFeatureType = (e) => {
    const editFeatureTypeReq = {
      dashboardId,
      id: editFeatureTypeId,
      initiatedBy: login.data.user.role,
      priorityOrder: editFeatureTypePriorityOrder,
      action: 'update',
    }
    return dispatch(addDashboardFeatureType(editFeatureTypeReq))
  }

  // const disabledBtn = () => {

  // }
  useEffect(() => {
    if (addedDashboardFeatureType.success) {
      setDisplayDropdown('')
      setSubTypeId('')
      setSubTypeName('')
      setFeatureId('')
      setFeatureName('')
      setPriorityOrder('')
      setCardLayout('')
      NotificationPopups(
        'success',
        'Success',
        addedDashboardFeatureType.data.message
      )
    }
    if (addedDashboardFeatureType.failed) {
      NotificationPopups(
        'error',
        'Failed',
        addedDashboardFeatureType.data.message
      )
    }
    if (addedDashboardFeatureType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    if (addedDashboard.success) {
      NotificationPopups('success', 'Success', addedDashboard.data.message)
    }
    if (addedDashboard.failed) {
      NotificationPopups('error', 'Failed', addedDashboard.data.message)
    }
    return () => {
      dispatch(resetAddDashboardFeatureType())
      dispatch(resetAddDashboard())
    }
  }, [
    addedDashboardFeatureType.success,
    addedDashboardFeatureType.failed,
    addedDashboardFeatureType.network,
    addedDashboard.success,
    addedDashboard.failed
  ])

  return (
    <>
      <Row>
        {showEditFeatureCategory && (
          <Col md="6" sm="12" lg="6" style={{ padding: '40px' }}>
            <Row>
              <div className="typeHeading">Edit Feature Category</div>
            </Row>
            <Row>
              <FormGroup style={{ margin: '10px 1px' }}>
                <label>Select Which Label Should be used </label>
                <Select
                  type="select"
                  showSearch
                  value={editDisplayDropDown}
                  placeholder="Select Feature Category"
                  optionFilterProp="children"
                  onChange={onChangeEditFeatureCatLabelOptons}
                  onSearch={onSearchEditFeatureCatlabelOptions}
                  style={{ width: '100%' }}
                >
                  <Option className="loginSelect" value="">
                    Select Which Label Should be used
                  </Option>

                  {labelOptionsDropDown.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.name}>
                      {d.name}
                    </Option>
                  ))}
                </Select>
              </FormGroup>
              {editDisplayDropDown === 'feature category' && (
                <FormGroup style={{ margin: '10px 1px' }}>
                  <label>Select Feature Category Label Name</label>
                  <Select
                    type="select"
                    showSearch
                    value={editLabelId}
                    placeholder="Select Feature Category"
                    optionFilterProp="children"
                    onChange={onChangeEditFeature}
                    onSearch={onSearcheditFeature}
                    style={{ width: '100%' }}
                  >
                    <Option className="loginSelect" value="">
                      Select Feature Category
                    </Option>
                    {searchFeatureCat != '' &&
                      optionsFeatureCat != undefined &&
                      optionsFeatureCat.map((d, id) => (
                        <Option key={id} className="loginSelect" value={d.id}>
                          {d.name}
                        </Option>
                      ))}
                    {searchFeatureCat === '' &&
                      FeatureType.success &&
                      FeatureType.data.featureType.map((d, id) => (
                        <Option key={id} className="loginSelect" value={d.id}>
                          {d.name}
                        </Option>
                      ))}
                  </Select>
                </FormGroup>
              )}
              {editDisplayDropDown === 'sub category' && (
                <FormGroup style={{ margin: '10px 1px' }}>
                  <label>Select Sub Category Type Label Name</label>
                  <Select
                    type="select"
                    value={editLabelId}
                    showSearch
                    placeholder="Select Sub Category Type"
                    optionFilterProp="children"
                    onChange={onChangeEditSubCat}
                    onSearch={onSearchEditSubCat}
                    style={{ width: '100%' }}
                  >
                    <Option className="loginSelect" value="">
                      Select Sub Category Type
                    </Option>
                    {searchSubCat === '' &&
                      getSubCat.success &&
                      getSubCat.data.subCategory.map((d, id) => (
                        <Option key={id} className="loginSelect" value={d.id}>
                          {d.name}
                        </Option>
                      ))}
                    {searchSubCat != '' &&
                      optionsSubCat.map((d, id) => (
                        <Option key={id} className="loginSelect">
                          {d.name}
                        </Option>
                      ))}
                  </Select>
                </FormGroup>
              )}
              <FormGroup style={{ margin: '10px 1px' }}>
                <label>Select Card Layout</label>
                <Select
                  type="select"
                  value={editCardName}
                  showSearch
                  placeholder="Select Card Layout"
                  optionFilterProp="children"
                  onChange={onChangeEditCardLayout}
                  onSearch={onSearchEditCardLayout}
                  style={{ width: '100%' }}
                >
                  <Option className="loginSelect" value="">
                    Select Card Layout
                  </Option>
                  {cardLayoutDropDown.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={d.layOutName}
                    >
                      {d.layOutName}
                    </Option>
                  ))}
                </Select>
              </FormGroup>
              <FormGroup style={{ margin: '10px 1px' }}>
                <Label for="serviceType">Select Card Size</Label>
                <Select
                  type="select"
                  value={editCardSize}
                  showSearch
                  placeholder="Select Card Size"
                  optionFilterProp="children"
                  onChange={onChangeEditCardSize}
                  onSearch={onSearchEditCardSize}
                  style={{ width: '100%' }}
                >
                  <Option className="loginSelect" value="">
                    Select Card Size
                  </Option>
                  {cardSizeDropDown.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.size}>
                      {d.size}
                    </Option>
                  ))}
                </Select>
              </FormGroup>
              <FormGroup style={{ margin: '10px 1px' }}>
                <Label for="serviceType">Enter Priority Order</Label>
                <Input
                  type="text"
                  placeholder="Enter Priority Order"
                  name="editPriorityOrder"
                  id="serviceType"
                  value={editPriorityOrder || ''}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup style={{ margin: '10px 5px' }}>
                <Label>Status</Label>
                <br />
                <Switch
                  defaultChecked={isActive}
                  name="isActive"
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  onChange={onChange}
                  // style={{ width: '100%' }}
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ margin: '10px 1px' }}>
                <Button
                  className="cust-button"
                  onClick={handleSubmitUpdateDashboard}
                  disabled={addedDashboardFeatureType.isLoading}
                  type="submit"
                >
                  {addedDashboardFeatureType.isLoading ? <Spin /> : 'Edit'}
                </Button>
              </FormGroup>
            </Row>
          </Col>
        )}
        {showAddFeatureType && (
          <Col md="6" sm="12" lg="6" style={{ padding: '40px' }}>
            <Row>
              <div className="typeHeading">Add Feature Type</div>
            </Row>
            <Row>
              <FormGroup style={{ margin: '10px 1px' }}>
                <label>Select Feature Type Label Name</label>
                <Select
                  type="select"
                  showSearch
                  value={addFeatureType}
                  placeholder="Select Feature Category"
                  optionFilterProp="children"
                  onChange={onChangeAddFeatureType}
                  onSearch={onSearchAddFeatureType}
                  style={{ width: '100%' }}
                >
                  <Option className="loginSelect" value="">
                    Select Feature Type
                  </Option>
                  {searchFeatureCat != '' &&
                    optionsFeatureCat != undefined &&
                    optionsFeatureCat.map((d, id) => (
                      <Option key={id} className="loginSelect" value={d.id}>
                        {d.name}
                      </Option>
                    ))}
                  {searchFeatureCat === '' &&
                    FeatureType.success &&
                    FeatureType.data.featureType.map((d, id) => (
                      <Option key={id} className="loginSelect" value={d.id}>
                        {d.name}
                      </Option>
                    ))}
                </Select>
              </FormGroup>
              <FormGroup style={{ margin: '10px 1px' }}>
                <Label for="serviceType">Enter Priority Order</Label>
                <Input
                  type="text"
                  placeholder="Enter Priority Order"
                  name="addPriorityOrder"
                  id="serviceType"
                  value={addPriorityOrder || ''}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ margin: '10px 1px' }}>
                <Button
                  className="cust-button"
                  onClick={handleSubmitAddDashboardFeatureType}
                  disabled={addedDashboardFeatureType.isLoading}
                  type="submit"
                >
                  {addedDashboardFeatureType.isLoading ? <Spin /> : 'Add'}
                </Button>
              </FormGroup>
            </Row>
          </Col>
        )}
        {displayfeatureType && (
          <Col md="6" style={{ padding: '40px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div className="typeHeading">Feature Type List</div>
              {showEditFeatureCategory && (
                <div>
                  <Button
                    color="secondary"
                    className="modalCustomButton"
                    onClick={() => {
                      setShowAddFeatureType(!showAddFeatureType)
                      setShowEditFeatureCategory(!showEditFeatureCategory)
                    }}
                  >
                    Add Feature Type
                  </Button>
                </div>
              )}
              {showAddFeatureType && (
                <Button
                  color="secondary"
                  className="modalCustomButton"
                  onClick={() => {
                    setShowAddFeatureType(!showAddFeatureType)
                    setShowEditFeatureCategory(!showEditFeatureCategory)
                  }}
                >
                  Edit Feature Category
                </Button>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                alignContent: 'flex-start',
                overflowY: 'auto',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'flex-start',
                }}
              >
                {featureType &&
                  featureType.map((list, id) => (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        {id + 1}.{list.name}
                      </div>
                      {showEditFeatureType && (
                        <>
                          <Input
                            type="text"
                            placeholder="Enter Priority Order"
                            name="priorityOrder"
                            id="serviceType"
                            value={priorityOrder || ''}
                            onChange={handleChange}
                            required
                          />
                          <Button
                            color="secondary"
                            className="modalCustomButton"
                            // onClick={handleSubmitActionFeatureType('')}
                            disabled={addedDashboardFeatureType.isLoading}
                            type="submit"
                          >
                            {addedDashboardFeatureType.isLoading ? (
                              <Spin />
                            ) : (
                              'Save'
                            )}
                          </Button>
                        </>
                      )}
                      {!showEditFeatureType && (
                        <div style={{ padding: '15px' }}>
                          {/* <i
                            className="fas fa-trash-alt"
                            style={{
                              color: '#4e97e6',
                              padding: '15px',
                              cursor: 'default',
                            }}
                            onClick={() => handleRemoveFeatureType(list)}
                          >
                            Remove
                          </i>
                          </i> */}
                          <img
                            src={Delete}
                            alt="Remove"
                            style={{ width: '20px',cursor:'pointer' }}
                            onClick={() => handleRemoveFeatureType(list)}
                          />
                          {/* <i
                            className="far fa-edit"
                            style={{ color: '#4e97e6', cursor: 'default' }}
                            onClick={() => {
                              setShowEditFeatureType(!showEditFeatureType)
                              setDisplayFeaturType(!displayfeatureType)
                              handleEditFeatureType(list)
                            }}
                          >
                            Edit
                          </i> */}
                          {/* <img
                            src={Delete}
                            alt="add"
                            style={{ width: '20px', marginLeft: '1rem' }}
                            onClick={() => {
                              setShowEditFeatureType(!showEditFeatureType)
                              setDisplayFeaturType(!displayfeatureType)
                              handleEditFeatureType(list)
                            }}
                          /> */}
                        </div>
                      )}
                    </div>
                  ))}
              </span>
            </div>
          </Col>
        )}
        {showEditFeatureType && (
          <Col md="6" sm="12" lg="6" style={{ padding: '40px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div className="typeHeading">Edit Feature Type</div>
              {showEditFeatureType && (
                <Button
                  color="secondary"
                  className="modalCustomButton"
                  onClick={() => {
                    setShowEditFeatureType(!showEditFeatureType)
                    setDisplayFeaturType(!displayfeatureType)
                  }}
                >
                  View Feature Type
                </Button>
              )}
            </div>

            <Row>
              <FormGroup style={{ margin: '10px 1px' }}>
                <label>Select Feature Type Label Name</label>
                <Select
                  type="select"
                  showSearch
                  value={editFeatureType}
                  placeholder="Select Feature Category"
                  optionFilterProp="children"
                  onChange={onChangeEditFeatureType}
                  onSearch={onSearchEditFeatureType}
                  style={{ width: '100%' }}
                >
                  <Option className="loginSelect" value="">
                    Select Feature Type
                  </Option>
                  {searchFeatureCat != '' &&
                    optionsFeatureCat != undefined &&
                    optionsFeatureCat.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={[d.id, d.name]}
                      >
                        {d.name}
                      </Option>
                    ))}
                  {searchFeatureCat === '' &&
                    FeatureType.success &&
                    FeatureType.data.featureType.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={[d.id, d.name]}
                      >
                        {d.name}
                      </Option>
                    ))}
                </Select>
              </FormGroup>

              <FormGroup style={{ margin: '10px 1px' }}>
                <Label for="serviceType">Enter Priority Order</Label>
                <Input
                  type="text"
                  placeholder="Enter Priority Order"
                  name="editFeatureTypePriorityOrder"
                  id="serviceType"
                  value={editFeatureTypePriorityOrder || ''}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ margin: '10px 1px' }}>
                <Button
                  className="cust-button"
                  onClick={handleSubmitEditFeatureType}
                  disabled={addedDashboardFeatureType.isLoading}
                  type="submit"
                >
                  {addedDashboardFeatureType.isLoading ? <Spin /> : 'Edit'}
                </Button>
              </FormGroup>
            </Row>
          </Col>
        )}
      </Row>
    </>
  )
}

export default ViewDashboard
