import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from 'reactstrap'
import { Spin, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetGetSocietyMnt,
  getSocietyMnt,
} from 'redux/actions/getSocietyMntTypeAction'
import {
  resetSingleInvoice,
  singleInvoice,
} from 'redux/actions/singleMntInvoice'
import axios from 'config/axios'
import Util from 'validations/index'

function UpdateMntBilling({ data }) {
  const [totalAmount, setTotalAmount] = useState(0)
  const [MntObj, setMntObj] = useState([])
  const [objEdit, setObjEdit] = useState([])
  const [index, setIndex] = useState(0)
  const [mntObjLength, setMntObjLength] = useState(0)
  const [updateMntObj, setUpdateMntObj] = useState([''])
  const login = useSelector(({ login }) => login)

  const socMntType = useSelector(({ getSocietyMnt }) => getSocietyMnt)
  const addedInvoice = useSelector(({ singleMntInvoice }) => singleMntInvoice)

  const dispatch = useDispatch()

  let amt = 0
  const handleChange = ([{ target: { name, value }, }, data, idx,]) => {
    if (name === data.name) {
      let amtt = MntObj.map((d) => d.value)

      amtt[idx] = Util.decimalUpto2(value) ? value :  amtt[idx]
      setObjEdit(amtt)

    }
    
    const objMnt = MntObj
    objMnt[idx].value = Util.decimalUpto2(value) ? value : objMnt[idx].value
    setMntObj(objMnt)
    const amtt = objMnt.map((d) => parseFloat(d.value))

    amt = amtt.reduce((pre, curr) => pre + curr)
    setTotalAmount(amt)
  }

  useEffect(() => {
    let obj = []
    for (const [key, value] of Object.entries(data.invoiceFee)) {
      obj.push({ name: key, value })
      setMntObj(obj)
    }
    setTotalAmount(data.totalAmount)
  }, [])


  useEffect(() => { }, [MntObj])

  const handleSubmit = (e) => {
    e.preventDefault()
    addInvoiceRequest()
    if (addedInvoice.success) {
      NotificationPopups('success', 'Success', addedInvoice.data.message)
    }
    resetSingleInvoice()
  }
  const addInvoiceRequest = () => {
    let obj
    let m = new Map()
    MntObj.map((d) => {
      m.set(d.name, d.value)
    })
    obj = Object.fromEntries(m)
    const addInvoiceReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      id: data.id,
      invoice: obj,
      totalAmount,
      action: 'update',
    }
    return dispatch(singleInvoice(addInvoiceReq))
  }

  const { Option } = Select

  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="12" sm="12">
            <Table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {MntObj &&
                  MntObj.map((d, idx) => (
                    <tr key={idx}>
                      <td>{d.name}<span className='required'>*</span></td>
                      <td>
                        <Input
                          type="text"
                          name={d.name}
                          id="areaName"
                          placeholder=""
                          value={objEdit[idx] ? objEdit[idx] : d.value}
                          onChange={(e) => handleChange([e, d, idx])}
                          required
                        />
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td>Total Amount</td>
                  <td>
                    <Input type="text" value={totalAmount} disabled />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col md="4" sm="12">
            <Button
              className="cust-button"
              style={{ marginTop: '22px', width: '25%' }}
              onClick={handleSubmit}
              disabled={isNaN(totalAmount)}
            >
              Update
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default UpdateMntBilling
