import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Label,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { sha256 } from 'js-sha256'
import { Spin } from 'antd'
import {
  resetgetSocietyList,
  getSocietyList,
} from 'redux/actions/getSocietyListAction'
import { adminSignup, resetAdminSignup } from 'redux/actions/adminSignupAction'
import {
  resetLinkNewSociety,
  linkNewSociety,
} from 'redux/actions/linkSocietyAction'
import Util from '../../validations/index'
// Country Region Dropdown
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

// ant design components
import { Drawer } from 'antd'
import loginReducer from 'redux/reducers/loginReducer'

import ShowPwd from 'assets/img/Icons/Privacy/show_pwd.png'
import HidePwd from 'assets/img/Icons/Privacy/hide_pwd.png'

import { Upload, message, Select } from 'antd'

import axios from 'config/axios'

function AddSocietyUser({ modal, toggle }) {
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [societyId, setSocietyId] = useState('')
  const [city, setCity] = useState('')
  const [isPwValid, setisPwValid] = useState('')
  const [role, setRole] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [showConfirmPassword, setshowConfirmPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [searchCityName, setSearchCityName] = useState('')
  const [options2, setOptions2] = useState([])
  const [socName, setSocName] = useState('')
  const [searchSocietyName, setSearchSocietyName] = useState('')
  const [options, setOptions] = useState([])
  const [showTextArea, setShowTextArea] = useState(false)

  const adminRegister = useSelector(({ adminSignup }) => adminSignup)
  const societyList = useSelector(({ getSocietyList }) => getSocietyList)
  const login = useSelector(({ login }) => login)
  const linkSociety = useSelector(({ linkSociety }) => linkSociety)
  const cityList = useSelector(({ getCityList }) => getCityList)
  const dispatch = useDispatch()

  const RoleData = [
    { role: 'Chairman', id: 1 },
    { role: 'Secretary', id: 2 },
    { role: 'Treasurer', id: 3 },
    { role: 'Committee Member', id: 4 },
    { role: 'Others', id: 5 }
  ]

  const onChangeRole = (value) => {
    if (value === "textArea") {
      setRole('')
      setShowTextArea(true)
    }
    else {
      setRole(value)
      setShowTextArea(false)
    }
  }

  const onSearchRole = () => {

  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'fullName') {
      Util.alphabetsOnly(value) ? setFullName(value) : setFullName(fullName)
    }
    if (name === 'password') {
      setisPwValid(Util.verifyPassword(value))
      setPassword(value.trim())
    }
    if (name === 'email') {
      setIsEmailValid(Util.verifyEmail(value))
      setEmail(value.trim())
    }
    if (name === 'phone') {
      // Util.verifyMobile(value) ? setPhone(value) : setPhone(phone)
      Util.verifyMobile(value) ? setPhone(value) : setPhone(phone)
    }
    if (name === 'confirmPassword') setConfirmPassword(value)
    if (name === 'societyId') setSocietyId(value)
    if (name === 'role') setRole(value)
  }

  const adminSignupRequest = () => {
    const adminSignupReq = {
      email: email.trim(),
      mobileNumber: parseInt(phone),
      password: sha256(password),
      role: 'Society',
      initiatedBy: login.data.user.role,
      name: fullName.trim(),
      societyRole:role
    }
    return dispatch(adminSignup(adminSignupReq))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    adminSignupRequest()
  }

  useEffect(() => {
    if (adminRegister.failed) {
      NotificationPopups('error', 'Failed', adminRegister.data.message)
    }
    if (adminRegister.network)
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    return () => {
      dispatch(resetAdminSignup())
    }
  }, [adminRegister.failed, adminRegister.network])

  const handleLinkSubmit = (e) => {
    e.preventDefault()
    linkSocietyRequest()
  }

  const linkSocietyRequest = () => {
    const linkSocietyRequest = {
      initiatedBy: login.data.user.role,
      society: {
        id: societyId,
      },
      user: {
        id: adminRegister.data.user.id,
      },
      // societyRole: role,
    }
    console.log(linkSocietyRequest)
    return dispatch(linkNewSociety(linkSocietyRequest))
  }

  const getSocietyListRequest = () => {
    const getSocietyListReq = {
      initiatedBy: login.data.user.role,
      city,
      societyName: socName,
    }
    return dispatch(getSocietyList(getSocietyListReq))
  }

  const handleCity = ({ target: { name, value } }) => {
    if (name === 'city') setCity(value)
    getSocietyListRequest(value)
  }

  const handlepassword = () => {
    setShowPassword(!showPassword)
  }

  const handleConfirmPassword = () => {
    setshowConfirmPassword(!showConfirmPassword)
  }

  const disableButton = () => {
    return !(
      email &&
      isEmailValid &&
      password &&
      isPwValid &&
      password === confirmPassword &&
      phone &&
      phone.length === 10
    )
  }

  const disabledButton = () => {
    return !(city && societyId)
  }

  useEffect(() => {
    return () => {
      if (linkSociety.success) {
        setCity('')
        setSocietyId('')
        NotificationPopups('success', 'Success', linkSociety.data.message)
      }
      dispatch(resetLinkNewSociety())
      dispatch(resetgetSocietyList())
      dispatch(resetAdminSignup())
    }
  }, [linkSociety.success])

  useEffect(() => {
    if (adminRegister.success) {
      setFullName('')
      setEmail('')
      setPassword('')
      setConfirmPassword('')
      setPhone('')
      setRole('')
      NotificationPopups('success', 'Success', adminRegister.data.message)
    }
  }, [adminRegister.success])

  //City List

  function onChangeCity(value) {
    setCity(value)
    setSearchCityName('')
    setSocName('')
    setSearchSocietyName('')
  }

  function onChangeSocName(value) {
    setSocName(value)
    setSocietyId(value)
    setSearchSocietyName('')
  }

  function onSearchSocName(value) {
    setSearchSocietyName(value)
  }

  const { Option } = Select

  useEffect(() => {
    if (searchSocietyName != '') {
      const userData = {
        city,
        initiatedBy: 'Society',
        societyName: searchSocietyName,
      }
      axios.post('society/getSocietyList', userData).then((res) => {
        setOptions(res.data.societyList)
      })
    }
  }, [searchSocietyName, city])

  useEffect(() => {
    if (searchCityName != '') {
      const userData = {
        initiatedBy: 'Society',
        type: searchCityName,
      }
      axios.post('society/allCityList', userData).then((res) => {
        setOptions2(res.data.city)
      })
    }
  }, [searchCityName])

  useEffect(() => {
    getSocietyListRequest()
  }, [city])

  if (adminRegister.success) {
    return (
      <>
        <div className="addSoc">
          <div className="typeHeading">Link Society</div>
          <Form>
            <FormGroup style={{ margin: '20px 0' }}>
              <Label for="city">CITY</Label>
              <Select
                type="select"
                value={city}
                showSearch
                placeholder="Select your society name"
                optionFilterProp="children"
                onChange={onChangeCity}
                // onSearch={onSearchCity}
                style={{ width: '100%' }}
              >
                <Option value="">Select your city name</Option>
                {searchCityName != '' &&
                  options2 != undefined &&
                  options2.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d}>
                      {d}
                    </Option>
                  ))}
                {cityList.success &&
                  searchCityName === '' &&
                  cityList.data.city.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d}>
                      {d}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
            <FormGroup style={{ margin: '20px 0' }}>
              {/* <label>SOCIETY </label> */}
              <Label for="city">SOCIETY</Label>
              <Select
                type="select"
                value={socName}
                showSearch
                placeholder="Select your society name"
                optionFilterProp="children"
                onChange={onChangeSocName}
                onSearch={onSearchSocName}
                style={{ width: '100%' }}
              >
                <Option value="">Select your society name</Option>
                {searchSocietyName != '' && options != undefined
                  ? options.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={d.id}
                    >
                      {d.societyName}
                    </Option>
                  ))
                  : societyList.success &&
                  societyList.data.societyList.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.societyName}
                    </Option>
                  ))}
              </Select>
            </FormGroup>

            <FormGroup style={{ textAlign: 'center' }}>
              <Button
                onClick={handleLinkSubmit}
                style={{ minWidth: '100%' }}
                className="cust-button"
                disabled={linkSociety.isLoading || disabledButton()}
              >
                {linkSociety.isLoading ? <Spin /> : 'Link Society'}
              </Button>
            </FormGroup>
          </Form>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="addSoc">
        <div className="typeHeading">Add Society User</div>
        <Form onSubmit={handleSubmit}>
          <FormGroup style={{ margin: '20px 0' }}>
            <label>FULL NAME </label>
            <span className='required'>*</span>
            <Input
              required
              placeholder="Fullname"
              type="text"
              name="fullName"
              value={fullName || ''}
              onChange={handleChange}
            />
            {/* <FormFeedback style={{ textAlign: 'center' }}>
                    {email && !isEmailValid ? 'Invalid Email' : ''}
                  </FormFeedback> */}
          </FormGroup>
          <FormGroup style={{ margin: '20px 0' }}>
            <label>EMAIL</label>
            <Input
              invalid={email && !isEmailValid}
              placeholder="Email"
              autoComplete="off"
              type="email"
              name="email"
              value={email || ''}
              onChange={handleChange}
            />
            <FormFeedback style={{ textAlign: 'center' }}>
              {email && !isEmailValid ? 'Invalid Email' : ''}
            </FormFeedback>
          </FormGroup>
          <FormGroup style={{ margin: '20px 0' }}>
            <label>PHONE</label>
            <Input

              placeholder="Phone"
              type="text"
              maxLength="10"
              name="phone"
              value={phone || ''}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup style={{ margin: '20px 0' }}>
            <label>ROLE IN SOCIETY</label>
            {!showTextArea &&
              <Select
                type="select"
                showSearch
                value={role}
                placeholder='Role'
                optionFilterProp="children"
                onChange={onChangeRole}
                onSearch={onSearchRole}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value=''>
                  Select Role
                </Option>
                {RoleData.map((d, id) => (
                  <Option
                    key={id}
                    className="loginSelect"
                    value={d.role === "Others" ? 'textArea' : d.role}
                  >
                    {d.role}
                  </Option>
                ))}
              </Select>
            }

            {showTextArea && <Input
              style={{ padding: "8px", borderRadius: "6px" }}
              type="text"
              name="role"
              id="role"
              placeholder="Others"
              value={role}
              onChange={handleChange}
              required
            />}
          </FormGroup>
          <FormGroup style={{ margin: '20px 0' }}>
            <label>PASSWORD</label>
            <InputGroup>
              <Input
                invalid={password && !isPwValid}
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                autoComplete="new-password"
                value={password || ''}
                onChange={handleChange}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText
                  onClick={handlepassword}
                  style={{
                    borderRadius: '30px',
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0',
                  }}
                >
                  {showPassword ? (
                    <img
                      src={ShowPwd}
                      style={{ marginLeft: '12px', width: '16px' }}
                    />
                  ) : (
                    <img
                      src={HidePwd}
                      style={{ marginLeft: '12px', width: '16px' }}
                    />
                  )}
                </InputGroupText>
              </InputGroupAddon>
              <FormFeedback style={{ textAlign: 'center' }}>
                {password && !isPwValid
                  ? 'Password should be 8 to 20 characters and should have numbers and special characters'
                  : ''}
              </FormFeedback>
            </InputGroup>
          </FormGroup>
          <FormGroup style={{ margin: '20px 0' }}>
            <label>CONFIRM PASSWORD</label>
            <InputGroup>
              <Input
                invalid={confirmPassword && !(password === confirmPassword)}
                placeholder="Confirm Password"
                autoComplete="new-password"
                type={showConfirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                value={confirmPassword || ''}
                onChange={handleChange}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText
                  onClick={handleConfirmPassword}
                  style={{
                    borderRadius: '30px',
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0',
                  }}
                >
                  {showConfirmPassword ? (
                    <img
                      src={ShowPwd}
                      style={{ marginLeft: '12px', width: '16px' }}
                    />
                  ) : (
                    <img
                      src={HidePwd}
                      style={{ marginLeft: '12px', width: '16px' }}
                    />
                  )}
                </InputGroupText>
              </InputGroupAddon>
              <FormFeedback style={{ textAlign: 'center' }}>
                {confirmPassword && !(password === confirmPassword)
                  ? 'Passwords do not match'
                  : ''}
              </FormFeedback>
            </InputGroup>
          </FormGroup>
          <FormGroup style={{ textAlign: 'center' }}>
            <Button
              onClick={handleSubmit}
              style={{ minWidth: '100%' }}
              disabled={
                adminRegister.isLoading ||
                adminRegister.success ||
                disableButton()
              }
              className="cust-button"
            >
              {adminRegister.isLoading ? <Spin /> : 'Signup'}
            </Button>
          </FormGroup>
        </Form>
      </div>
    </>
  )
}

export default AddSocietyUser
