import axios from '../../config/axios'
import {
  GETAMENITYLIST_SUCCESS,
  GETAMENITYLIST_FAILED,
  LOADING_GETAMENITYLIST,
  RESET_GETAMENITYLIST,
  GETAMENITYLIST_NETWORKERROR,
} from '../types'

const resetgetAmenityList = () => {
  return {
    type: RESET_GETAMENITYLIST,
  }
}

const getAmenityList = (userData, isRead = false) => (dispatch, getState) => {
  let pendingCount;
  let count;
  dispatch({ type: LOADING_GETAMENITYLIST })
  console.log(userData, isRead, count, "Amenitysssss")
  axios
    .post('amenity/getAmenityList', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          isRead ? count = getState().getAmenityList.data?.count : count = getState().getAmenityList.oldValue;
          userData?.amenityStatus === 'pending' ? pendingCount = res.data?.count : pendingCount = getState().getAmenityList.pendingCount;
          dispatch(success(res.data, count,pendingCount))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GETAMENITYLIST_NETWORKERROR, payload: err })
    )
}

function success(data, oldValue,pendingCount) {
  return {
    type: GETAMENITYLIST_SUCCESS,
    oldValue,
    payload: data,
    pendingCount
  }
}

function failed(data) {
  return {
    type: GETAMENITYLIST_FAILED,
    payload: data,
  }
}

export { resetgetAmenityList, getAmenityList }
