import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  FormFeedback,
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import Util from 'validations/index'
import { Spin } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  addEmergencyNumber,
  resetaddEmergencyNumber,
} from 'redux/actions/addEmergencyNumberAction'
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

import EmergencyContactIcon from 'assets/img/Icons/Sidebar/emergencycontact.png'

function AddEmergencyNumber() {
  const [names, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [emergencytype, setEmergencyType] = useState('')
  const addedEmergencyNumber = useSelector(
    ({ addEmergencyNumber }) => addEmergencyNumber
  )
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'name')
      Util.alphabetsOnly(value) ? setName(value) : setName(names)
    if (name === 'phone') {
      Util.verifyMobile(value) ? setPhone(value) : setPhone(phone)
    }
    if (name === 'email') {
      setIsEmailValid(Util.verifyEmail(value))
      setEmail(value.trim())
    }
    if (name === 'address') setAddress(value)
    if (name === 'emergencytype') setEmergencyType(value)
  }

  const addEmergencyNumberRequest = () => {
    const addEmergencyNumberReq = {
      societyId: login.data.society.id,
      name: names.trim(),
      mobileNumber: parseInt(phone),
      type: emergencytype.trim(),
      email: email.trim(),
      address: address.trim(),
      initiatedBy: login.data.user.role,
    }
    return dispatch(addEmergencyNumber(addEmergencyNumberReq))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addEmergencyNumberRequest()
  }

  const disabledButton = () => {
    return !(names && phone && phone.length === 10 && emergencytype && email)
    //return !(name && (phone && phone.length === 10) && (email && isEmailValid) && address && emergencytype);
  }
console.log(addedEmergencyNumber.data,"addedEmergencyNumber.data")
  useEffect(() => {
    if (addedEmergencyNumber.success) {
      setName('')
      setPhone('')
      setEmail('')
      setAddress('')
      setEmergencyType('')
      NotificationPopups(
        'success',
        'Success',
        addedEmergencyNumber.data.message
      )
    }
    // if (addedEmergencyNumber.failed) {
    //   NotificationPopups('error', 'Failed', (addedEmergencyNumber.data.errorVOList.map((item,id)=>(<>{id === 0 ? 1 : id+1}-{item.errorMessage}<br/></>))))
    // }
    if (addedEmergencyNumber.failed) {
      NotificationPopups('error', 'Failed', addedEmergencyNumber.data.message)
    }
    if (addedEmergencyNumber.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetaddEmergencyNumber())
    }
  }, [
    addedEmergencyNumber.success,
    addedEmergencyNumber.failed,
    addedEmergencyNumber.network,
  ])

  return (
    <>
      <PanelHeader size="sm" />
      <div className="listcontent society-details-container">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  {/* <i className={"now-ui-icons sport_user-run"} /> */}
                  <img
                    src={EmergencyContactIcon}
                    alt="EmergencyContactIcon"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <span className="head-title">Emergency Contacts</span>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <Row style={{ justifyContent: 'center' }}>
                    <Col md="4" sm="12" lg="4">
                      <FormGroup>
                        <Label for="emergencytype">Contact For</Label>
                        <span className='required'>*</span>
                        <Input
                          type="text"
                          name="emergencytype"
                          placeholder="Contact For"
                          id="emergencytype"
                          value={emergencytype || ''}
                          onChange={handleChange}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" sm="12" lg="4">
                      <FormGroup>
                        <Label for="name">Name</Label>
                        <span className='required'>*</span>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Name"
                          id="name"
                          value={names || ''}
                          onChange={handleChange}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" sm="12" lg="4">
                      <FormGroup>
                        <Label for="phone">Phone Number</Label>
                        <span className='required'>*</span>
                        <Input
                          type="text"
                          name="phone"
                          placeholder="Phone"
                          id="phone"
                          value={phone || ''}
                          onChange={handleChange}
                          maxLength="10"
                          required
                        />
                      </FormGroup>
                      {phone.length !== 10 ? phone === '' ? '' : <span className='phonevalidation'>Phone number should be 10 digits.</span> : null}
                    </Col>
                    <Col md="4" sm="12" lg="4">
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <span className='required'>*</span>
                        <Input
                          type="email"
                          name="email"
                          placeholder="Email"
                          id="email"
                          value={email || ''}
                          onChange={handleChange}
                          required
                          invalid={email && !isEmailValid}
                        />
                        <FormFeedback style={{ textAlign: 'center' }}>
                          {email && !isEmailValid ? 'Invalid Email' : ''}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="4" sm="12" lg="4">
                      <FormGroup>
                        <Label for="address">Address</Label>
                        <Input
                          type="textarea"
                          name="address"
                          placeholder="Type Address"
                          rows="1"
                          id="address"
                          value={address || ''}
                          onChange={handleChange}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" sm="12" lg="4"></Col>
                    <Col md="3" sm="12" lg="3">
                      <Button
                        className="cust-button"
                        style={{ margin: '28px 0px', width: '100%' }}
                        onClick={handleSubmit}
                        disabled={
                          addedEmergencyNumber.isLoading || disabledButton()
                        }
                      >
                        {addedEmergencyNumber.isLoading ? <Spin /> : 'Add'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AddEmergencyNumber
