import React, { useState, useEffect, useRef, memo, use } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import FileViewer from 'react-file-viewer'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
  Table,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from 'reactstrap'
import { Tooltip, Popconfirm, Spin, Select } from 'antd'
import Util from 'validations/index'
import FileBase64 from '../../components/Custom/UploadFile'
//import FileBase64 from 'react-file-base64';
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import DisplayAttachment from '../../components/Custom/DisplayAttachment'
import { addNotice, resetAddNotice } from 'redux/actions/addNoticeAction'
import { addGateway, resetAddGateway } from 'redux/actions/addGatewayAction'
import {
  resetdeleteNotice,
  deleteNotice,
} from 'redux/actions/deleteNoticeAction'

const EditGateway = ({ update, modal, toggle, errMessage }) => {
  // UseStates
  const [ownerName, setOwnerName] = useState(update.displayName)
  const [intType, setIntType] = useState(update.integrationType)
  const [currency, setCurrency] = useState(update.accTerminalCurrency)
  const [intName, setIntName] = useState(update.integrationName)
  const [memUserName, setUserName] = useState(update.accName)
  const [memId, setMemId] = useState(update.acctToId)
  const [memSecretKey, setMemSecretKey] = useState(update.acctMerchantSecKey)
  const [partId, setPartId] = useState(update.acctPartnerId)
  const [partKey, setPartKey] = useState(update.acctPartnerKey)
  const [partUserName, setPartUserName] = useState(update.acctPartnerUsername)
  const [termId, setTermId] = useState(update.acctTerminalId)
  const [toType, setToType] = useState(update.acctToType)
  const [payType, setPayType] = useState(update.acctPaymentType)
  const [payBrand, setPayBrand] = useState(update.acctPaymentBrand)
  const [payMode, setPayMode] = useState(update.acctPaymentMode)
  const [transUrl, setTransUrl] = useState(update.acctTransactionUrl)
  const [redirectUrl, setRedirectUrl] = useState(update.acctRedirectUrl)
  const [checkoutTransUrl, setCheckoutTransUrl] = useState(
    update.acctSdkTransactionUrl
  )
  const [withdrawUrl, setWithdrawUrl] = useState(update.acctWithdrawUrl)
  const [authTokenUrl, setAuthTokenUrl] = useState(update.acctAuthTokenUrl)

  const { Option } = Select
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()

  const addGatewaySel = useSelector(({ addGateway }) => addGateway)

  const currencyDrop = ['INR','USD']

  function onSelectcurrency(value) {
    setCurrency(value)
  }

  const onInputChange = ({ target: { name, value } }) => {
    if (name === 'intName')
      Util.alphabetsOnly(value) ? setIntName(value) : setIntName(intName)
    if (name === 'memUserName')
      Util.alphaNumeric(value) ? setUserName(value) : setUserName(memUserName)
    if (name === 'ownerName')
      Util.alphabetsOnly(value) ? setOwnerName(value) : setOwnerName(ownerName)
    if (name === 'intType')
      Util.alphabetsOnly(value) ? setIntType(value) : setIntType(intType)
    if (name === 'currency')
      Util.alphabetsOnly(value) ? console.log(value,"val") : setCurrency(currency)
    if (name === 'memId')
      Util.verifyMobile(value) ? setMemId(value) : setMemId(memId)
    if (name === 'memSecretKey')
      Util.noWhiteSpace(value)
        ? setMemSecretKey(value)
        : setMemSecretKey(memSecretKey)
    if (name === 'partId')
      Util.verifyMobile(value) ? setPartId(value) : setPartId(partId)
    if (name === 'partKey')
      Util.alphaNumeric(value) ? setPartKey(value) : setPartKey(partKey)
    if (name === 'partUserName')
      Util.alphabetsOnly(value)
        ? setPartUserName(value)
        : setPartUserName(partUserName)
    if (name === 'termId')
      Util.verifyMobile(value) ? setTermId(value) : setTermId(termId)
    if (name === 'toType')
      Util.alphabetsOnly(value) ? setToType(value) : setToType(toType)
    if (name === 'payType')
      Util.alphabetsOnly(value) ? setPayType(value) : setPayType(payType)
    if (name === 'payBrand')
      Util.alphabetsOnly(value) ? setPayBrand(value) : setPayBrand(payBrand)
    if (name === 'payMode')
      Util.alphabetsOnly(value) ? setPayMode(value) : setPayMode(payMode)
    if (name === 'transUrl')
      Util.noWhiteSpace(value) ? setTransUrl(value) : setTransUrl(transUrl)
    if (name === 'redirectUrl')
      Util.noWhiteSpace(value)
        ? setRedirectUrl(value)
        : setRedirectUrl(redirectUrl)
    if (name === 'checkoutTransUrl')
      Util.noWhiteSpace(value)
        ? setCheckoutTransUrl(value)
        : setCheckoutTransUrl(checkoutTransUrl)
    if (name === 'withdrawUrl')
      Util.noWhiteSpace(value)
        ? setWithdrawUrl(value)
        : setWithdrawUrl(withdrawUrl)
    if (name === 'authTokenUrl')
      Util.noWhiteSpace(value)
        ? setAuthTokenUrl(value)
        : setAuthTokenUrl(authTokenUrl)
  }

  const addGatewayListRequest = () => {
    const addGatewayListReq = {
      initiatedBy: login.data.user.role,
      action: 'update',
      // walletOwnerId: ownerId,
      acctPartnerId: partId,
      accName: memUserName,
      acctId: update.acctId,
      integrationName: intName.trim(),
      acctTerminalId: termId,
      acctToId: memId,
      integrationType: intType.trim(),
      acctPartnerKey: partKey,
      acctPartnerUsername: partUserName.trim(),
      acctToType: toType.trim(),
      acctRedirectUrl: redirectUrl,
      acctMerchantSecKey: memSecretKey,
      acctPaymentType: payType.trim(),
      acctPaymentBrand: payBrand.trim(),
      acctPaymentMode: payMode.trim(),
      acctTransactionUrl: transUrl,
      acctSdkTransactionUrl: checkoutTransUrl,
      accTerminalCurrency: currency.trim(),
      acctWithdrawUrl: withdrawUrl,
      acctAuthTokenUrl: authTokenUrl,
    }
    return dispatch(addGateway(addGatewayListReq))
  }

  useEffect(() => {
    if (addGatewaySel.success) {
      // setMntName('')
      // setMntFee('')
      // setEditModal(false)
      // getMntTypeRequest()
      // NotificationPopups('success', 'Success', addGatewaySel.data.message)
    }
    if (addGatewaySel.failed) {
      NotificationPopups('error', 'failed', addGatewaySel.data.message)
    }
    if (addGatewaySel.network) {
      NotificationPopups('error', 'Network Error', addGatewaySel.data.message)
    }
    return () => {
      resetAddGateway()
    }
  }, [addGatewaySel.success, addGatewaySel.failed, addGatewaySel.network])

  const handleEditSubmit = (e) => {
    e.preventDefault()
    addGatewayListRequest()
  }

  const IntDrop = ['API', 'Checkout', 'Both']

  function onSelectInt(value) {
    setIntType(value)
  }

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        style={{ maxWidth: '1000px', maxHeight: '500px' }}
      >
        <ModalHeader toggle={toggle}>Edit Gateway</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col md="12" sm="12" xs="12" lg="12">
                <Row>
                  {/* Row - 1 */}
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="ownerName" required>
                        Society Name
                      </Label>
                      <Input
                        type="text"
                        name="ownerName"
                        id="ownerName"
                        placeholder="Member User Name/Number"
                        maxLength="10"
                        value={ownerName}
                        onChange={onInputChange}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="areaId" required>
                        Integration Type
                      </Label>
                      <Select
                        type="select"
                        showSearch
                        value={intType}
                        placeholder="Select Integration Type"
                        optionFilterProp="children"
                        onChange={onSelectInt}
                        // onSearch={onSearchAreaId}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="">
                          Select Integration Type
                        </Option>
                        {IntDrop.map((d, id) => (
                          <Option key={id} value={d}>
                            {d}
                          </Option>
                        ))}
                      </Select>
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="currency" required>
                        Currency
                      </Label>
                      <Select
                        name="currency"
                        type="select"
                        showSearch
                        value={currency || ''}
                        placeholder="Select Area Name"
                        optionFilterProp="children"
                        onChange={onSelectcurrency}
                        // onSearch={onSearchAreaId}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="">
                          Select Currency
                        </Option>
                        {currencyDrop.map((d, id) => (
                          <Option key={id} value={d}>
                            {d}
                          </Option>
                        ))}
                      </Select>
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="intName">Integration Name</Label>
                      <Input
                        type="text"
                        name="intName"
                        id="intName"
                        placeholder="Integration Name"
                        maxLength="10"
                        value={intName}
                        onChange={onInputChange}
                      />
                    </FormGroup>
                  </Col>

                  {/* Row - 2 */}
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="memUserName">Member User Name/Number</Label>
                      <Input
                        type="text"
                        name="memUserName"
                        id="memUserName"
                        placeholder="Member User Name/Number"
                        maxLength="10"
                        value={memUserName}
                        onChange={onInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="memId">Member ID/SID</Label>
                      <Input
                        type="text"
                        name="memId"
                        id="memId"
                        placeholder="Member ID/SID"
                        maxLength="10"
                        value={memId}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="memSecretKey">Member Secret Key</Label>
                      <Input
                        type="text"
                        name="memSecretKey"
                        id="memSecretKey"
                        placeholder="Member Secret Key"
                        value={memSecretKey}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="partId">Partner ID</Label>
                      <Input
                        type="text"
                        name="partId"
                        id="partId"
                        placeholder="Partner ID"
                        maxLength="10"
                        value={partId || ''}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  {/* Row - 3 */}
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="termId">Terminal ID</Label>
                      <Input
                        type="text"
                        name="termId"
                        id="termId"
                        placeholder="Terminal ID"
                        maxLength="10"
                        value={termId}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="toType">To Type</Label>
                      <Input
                        type="text"
                        name="toType"
                        id="toType"
                        placeholder="To Type"
                        maxLength="10"
                        value={toType}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="redirectUrl">Redirect URL</Label>
                      <Input
                        type="text"
                        name="redirectUrl"
                        id="redirectUrl"
                        placeholder="Redirect URL"
                        value={redirectUrl}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="payType">Payment Type</Label>
                      <Input
                        type="text"
                        name="payType"
                        id="payType"
                        placeholder="Payment Type"
                        maxLength="10"
                        value={payType}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>

                  {/* Row - 4 */}
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="payBrand">Payment Brand</Label>
                      <Input
                        type="text"
                        name="payBrand"
                        id="payBrand"
                        placeholder="Payment Brand"
                        maxLength="10"
                        value={payBrand}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="payMode">Payment Mode</Label>
                      <Input
                        type="text"
                        name="payMode"
                        id="payMode"
                        placeholder="Payment Mode"
                        maxLength="10"
                        value={payMode}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="transUrl">Transaction URL</Label>
                      <Input
                        type="text"
                        name="transUrl"
                        id="transUrl"
                        placeholder="Transaction URL"
                        value={transUrl}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="checkoutTransUrl">
                        CheckOut Transaction URL
                      </Label>
                      <Input
                        type="text"
                        name="checkoutTransUrl"
                        id="checkoutTransUrl"
                        placeholder="CheckOut Transaction URL"
                        value={checkoutTransUrl}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>

                  {/* Row - 5 */}
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="withdrawUrl">WithDraw URL</Label>
                      <Input
                        type="text"
                        name="withdrawUrl"
                        id="withdrawUrl"
                        placeholder="WithDraw URL"
                        value={withdrawUrl}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="authTokenUrl">AuthToken URL</Label>
                      <Input
                        type="text"
                        name="authTokenUrl"
                        id="authTokenUrl"
                        placeholder="AuthToken URL"
                        value={authTokenUrl}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="partKey">Partner Key</Label>
                      <Input
                        type="text"
                        name="partKey"
                        id="partKey"
                        placeholder="Partner Key"
                        value={partKey}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="partUserName">Partner User Name</Label>
                      <Input
                        type="text"
                        name="partUserName"
                        id="partUserName"
                        placeholder="Partner User Name"
                        maxLength="10"
                        value={partUserName}
                        onChange={onInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Button
                    color="secondary"
                    className="modalCustomButton"
                    onClick={handleEditSubmit}
                    style={{ margin: 'auto', width: '15%' }}
                  >
                    Edit
                    {/* {addGatewaySel.isLoading ? <Spin /> : 'Edit'} */}
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default EditGateway
