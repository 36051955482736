import React, { useEffect, useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Nav } from 'reactstrap'
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar'
import { Avatar, Badge } from 'antd'

// import logo from "logo-white.svg";
import logo from 'assets/img/Brand/hii_soc.png'
//Side bar logo
import Dashboard from 'assets/img/Icons/Sidebar/dashboard.png'
import SocietyDeatil from 'assets/img/Icons/Sidebar/societydetail1.png'
import NoticeBoard from 'assets/img/Icons/Sidebar/noticeboard.png'
import Members from 'assets/img/Icons/Sidebar/member.png'
import Complaints from 'assets/img/Icons/Sidebar/complain.png'
import EmergencyContact from 'assets/img/Icons/Sidebar/emergencycontact.png'
import Categories from 'assets/img/Icons/Sidebar/category.png'
import Configurations from 'assets/img/Icons/Sidebar/documenttype.png'
import DashboardConfigurations from 'assets/img/Icons/Sidebar/configurations.png'
import Visitor from 'assets/img/Icons/Sidebar/visitor.png'
import FeeConfigIcon from 'assets/img/Icons/Maintaince/feeConfig.png'
import MaintainceIcon from 'assets/img/Icons/Maintaince/maintainceConfig.png'
import MntBilling from 'assets/img/Icons/Maintaince/MntBilling.png'
import Transactions from 'assets/img/Icons/Transactionslist/transaction.png'
import Amenity from 'assets/img/Icons/Sidebar/amenity.png'
import SecurityAlert from 'assets/img/Icons/Sidebar/Security_Alert.png'
import { getBadgeCount } from 'redux/actions/getBadgeCount'
import Booking from 'assets/img/Icons/Sidebar/noticetype51.png'

import {
  getAmenityList,
  resetgetAmenityList,
} from 'redux/actions/getAmenityListAction'

import {
  getComplaintList,
  resetgetComplaintList,
} from 'redux/actions/getComplaintListAction'

import {
  resetgetPendingMemberList,
  getPendingMemberList,
} from 'redux/actions/getPendingMemberListAction'

function Sidebar() {
  const [active, setActive] = useState(false)
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const AmenityList = useSelector(({ getAmenityList }) => getAmenityList)
  // console.log(AmenityList, "AmenityListssssss")
  const complaintList = useSelector(({ complaintList }) => complaintList)
  // console.log(complaintList, "complan")
  const memberList = useSelector(({ getPendingMemberList }) => getPendingMemberList)
  // console.log(memberList, "memberList")
  let amenityBadgeCount = AmenityList.data.count - AmenityList.oldValue
  let complaintBadgeCount = complaintList.data.count - complaintList.oldValue
  let memberBadgeCount = memberList.pendingCount - memberList.oldValue
  // Amenity
  const getAmenityListRequest = (isRead = false) => {
    // console.log('calling amenity API')
    const getAmenityListReq = {
      amenityStatus: "pending",
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
    }
    return dispatch(getAmenityList(getAmenityListReq, isRead))
  }

  // Complaints 

  const getComplainListRequest = (isRead = false) => {
    const getComplainListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      complainStatus: 'pending',
    }
    return dispatch(getComplaintList(getComplainListReq, isRead))
  }

  // Member

  const getPendingMemberListRequest = (isRead = false) => {
    console.log(isRead, "read")
    const getPendingMemberListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      userStatus: 'pending'
    }
    return dispatch(getPendingMemberList(getPendingMemberListReq, isRead))
  }

  useEffect(() => {
    getAmenityListRequest()
    getComplainListRequest()
    getPendingMemberListRequest()
    switch (window.location.pathname) {
      case "/Society/amenity-booking":
        getAmenityListRequest(true)
        break;
      case '/Society/complaints':
        getComplainListRequest(true)
        break;
      case '/Society/members':
        getPendingMemberListRequest(true)
      default:
        break;
    }
  }, [window.location.pathname])
  
  return (
    <div className="sidebar">
      <div className="logo">
        <Link to="/Society/dashboard">
          <img src={logo} alt="react-logo" />
        </Link>
      </div>
      <div className="sidebar-wrapper" id="scrollbar-sidebar">
        <Nav>
          <li>
            <NavLink
              to="/Society/dashboard"
            className="nav-link"
            activeClassName="actives"
            >
              <img src={Dashboard} style={{ height: '25px', width: '25px' }} />{' '}
              <span style={{ padding: '15px' }}>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/society-detail"
            className="nav-link"
            activeClassName="actives"
            >
              <img
                src={SocietyDeatil}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '15px' }}> Society Details</span>
            </NavLink>
          </li>
          <li className={active ? 'active active-pro' : ''}>
            <NavLink
              to="/Society/notice-board"
              className="nav-link"
              activeClassName="actives"
            >
              <img
                src={NoticeBoard}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '15px' }}> Notice Board</span>
            </NavLink>
          </li>
          <li className={active ? 'active active-pro' : ''}>
            <NavLink
              to="/Society/members"
              className="nav-link"
              activeClassName="actives"
            >
              <img src={Members} style={{ height: '25px', width: '25px' }} />{' '}
              <span style={{ padding: '15px' }}>Members {!isNaN(memberBadgeCount) && memberBadgeCount > 0 ? <Badge className='countbadge' count={parseInt(memberBadgeCount)} ></Badge> : null}</span>{' '}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/complaints"
              className="nav-link"
              activeClassName="actives"
            >
              <img src={Complaints} style={{ height: '25px', width: '25px' }} />{' '}
              <span style={{ padding: '15px' }}>Complaints {!isNaN(complaintBadgeCount) && complaintBadgeCount > 0 ? <Badge className='countbadge' count={parseInt(complaintBadgeCount)} ></Badge> : null}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/amenity-booking"
              className="nav-link"
              activeClassName="actives"
            >
              <img src={Amenity} style={{ height: '25px', width: '25px' }} />{' '}
              <span style={{ padding: '15px' }}>Amenity Bookings{!isNaN(amenityBadgeCount) && amenityBadgeCount > 0 ? <Badge className='countbadge' count={parseInt(amenityBadgeCount)} ></Badge> : null}</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/Society/visitor-parking-booking"
              className="nav-link"
              activeClassName="actives"
            >
              <img
                src={Booking}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '15px' }}> Booking</span>
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/Society/emergency"
              className="nav-link"
              activeClassName="actives"
            >
              <img
                src={EmergencyContact}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '15px' }}>Emergency Contacts</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/Visitor"
              className="nav-link"
              activeClassName="actives"
            >
              <img src={Visitor} style={{ height: '25px', width: '25px' }} />
              <span style={{ padding: '15px' }}>Visitors</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/categories"
              className="nav-link"
              activeClassName="actives"
            >
              <img src={Categories} style={{ height: '25px', width: '25px' }} />{' '}
              <span style={{ padding: '15px' }}>Categories</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/configuration"
              className="nav-link"
              activeClassName="actives"
            >
              <img
                src={Configurations}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '15px' }}>Doc Config</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/MaintenanceMapping"
              className="nav-link"
              activeClassName="actives"
            >
              <img
                src={MaintainceIcon}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '12px' }}>Mnt File Config</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/MaintenanceBilling"
              className="nav-link"
              activeClassName="actives"
            >
              <img src={MntBilling} style={{ height: '25px', width: '25px' }} />
              <span style={{ padding: '12px' }}>Mnt Billing</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/TransactionMaster"
              className="nav-link"
              activeClassName="actives"
            >
              <img
                src={Transactions}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '15px' }}>Transaction Master</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/SecurityAlert"
              className="nav-link"
              activeClassName="actives"
            >
              <img
                src={SecurityAlert}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '15px' }}>Security Alert</span>
            </NavLink>
          </li>
        </Nav>
      </div>
    </div>
  )
}

export default Sidebar
