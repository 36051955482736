import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  FormFeedback,
  Input,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { Spin } from 'antd'
import { sha256 } from 'js-sha256'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { loginUser, resetLogin } from 'redux/actions/loginAction'
import { Link } from 'react-router-dom'
import AdminSignup from './Account/AdminSignup'
import classnames from 'classnames'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import SocietyImg from 'assets/img/Brand/hi_soc_dark_login.png'
import Util from '../validations/index'
import ForgetPassword from './Account/ForgetPassword'
import OTPInput from 'otp-input-react'
import {
  resetresendSignupOTP,
  resendSignupOTP,
} from 'redux/actions/resendSignupOTPAction'
import {
  resetValidateSignupOtp,
  validateSignupOtp,
} from 'redux/actions/validateSignupOtpAction'
import RegisterSociety from 'views/Account/RegisterSociety'
import LoggedUserRegisterSociety from './Account/LoggedUserRegisterSociety'
import ShowPwd from 'assets/img/Icons/Privacy/show_pwd.png'
import HidePwd from 'assets/img/Icons/Privacy/hide_pwd.png'
import { DEVICE_TOKEN } from '../firebase'


function Login() {
  const [activeTab, setActiveTab] = useState('1')
  const [email, setEmail] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [fPwSection, setFPwSection] = useState(false)
  const [initiatedBy, setInitiatedBy] = useState('Society')
  const [openModal, setOpenModal] = useState(false)
  const [emailOTP, setEmailOTP] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [formStep, SetFormStep] = useState(1)
  const dispatch = useDispatch()
  const login = useSelector(({ login }) => login)
  console.log(login, "login")
  const resetOTP = useSelector(({ resetOTP }) => resetOTP)
  const validateSignUpOTP = useSelector(
    ({ validateSignupOTP }) => validateSignupOTP
  )
  const linkSociety = useSelector(({ linkSociety }) => linkSociety)

  useEffect(() => {
    if (login.success) {
      // login.data.
    }
    if (login.network) {
      NotificationPopups(
        'error',
        'Server Error',
        'Please try again after sometime'
      )
    }
    if (login.failed) {
      NotificationPopups('error', 'Failed', login.data.message)
    }
    if (login.data.statusCode === 503) {
      NotificationPopups('error', 'Server Error', "Server Not Available try again after sometime")
    }
  }, [login.success, login.network, login.failed, login.data.statusCode])

  //set items
  useEffect(() => {
    sessionStorage.setItem("authToken", login.data.authToken)
  }, [login.success])

  useEffect(() => {
    if (validateSignUpOTP.success) {
      setOpenModal(false)
      toggle('1')
      NotificationPopups('success', 'Account Created Successfully', 'Please Login to Link Society')
    }
    if (validateSignUpOTP.failed) {
      seterrMessage(validateSignUpOTP.data.message)
      //console.log("inside vaidate")
      setEmailOTP('')
      return () => {
        dispatch(resetresendSignupOTP())
      }
    }
    if (linkSociety.success) {
      toggle('1')
    }
  }, [validateSignUpOTP.failed, validateSignUpOTP.success, linkSociety.success])

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  useEffect(() => {
    if (linkSociety.success) {
      NotificationPopups('success', 'Success', linkSociety.data.message)
    }
  }, [linkSociety.success])

  const handleSubmit = (e) => {
    e.preventDefault()
    loginRequest()
    if (login.success && !login.isVerified) {
      setOpenModal(!openModal)
    }
    if (login.success && login.isVerified && !login.isSociety) {
      // setOpenModal(!openModal)
      toggle('5')
    }
  }

  useEffect(() => {
    if (login.data.adminStatus === "rejected") {
      NotificationPopups('error', 'Failed', login.data.remark)
    }
  }, [login.data.adminStatus])

  const loginRequest = () => {
    const loginreq = {
      email,
      password: sha256(password),
      initiatedBy: 'Society',
      deviceToken: DEVICE_TOKEN ? DEVICE_TOKEN : ''
    }
    return dispatch(loginUser(loginreq))
  }

  const handlepassword = () => {
    setShowPassword(!showPassword)
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'email') {
      setIsEmailValid(Util.verifyEmail(value))
      setEmail(value)
    }
    if (name === 'password') setPassword(value)
  }

  const disabledButton = () => {
    return !(email && isEmailValid && password)
  }

  //verify otp
  const toggleVOTP = () => {
    setOpenModal(!openModal)
  }

  const inputStyles = {
    width: '35px',
    height: '35px',
  }
  const otpValidateRequest = () => {
    const otpValidateReq = {
      initiatedBy,
      id: login.data.user.id,
      validateOtp: emailOTP,
    }
    return dispatch(validateSignupOtp(otpValidateReq))
  }

  const handleresendOTP = () => {
    const resendOTPReq = {
      initiatedBy,
      id: login.data.user.id,
    }
    return dispatch(resendSignupOTP(resendOTPReq))
  }

  const handleOTP = (e) => {
    e.preventDefault()
    otpValidateRequest()
  }

  if (login.isAvailable) {
    return <Redirect to="/Society/dashboard" />
  }

  return (
    <div className="background">
      <div className="login-form">
        <div className="container">
          <div>
            <picture>
              <img src={SocietyImg} />
            </picture>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Card>
                <Row>
                  <Col>
                    <CardHeader>Sign In</CardHeader>
                    <CardBody>
                      {formStep === 1 && (
                        <Form>
                          <FormGroup style={{ margin: '20px 0' }}>
                            <label>EMAIL <span className='required'>*</span></label>
                            <Input
                              required
                              invalid={!(email && isEmailValid)}
                              placeholder="Email"
                              type="email"
                              name="email"
                              value={email || ''}
                              onChange={handleChange}
                            />
                            <FormFeedback
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              {email && !isEmailValid ? 'Invalid Email' : ''}
                            </FormFeedback>
                          </FormGroup>
                          <FormGroup style={{ margin: '20px 0' }}>
                            <label>PASSWORD <span className='required'>*</span></label>
                            <InputGroup>
                              <Input
                                required
                                placeholder="Password"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={password || ''}
                                onChange={handleChange}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText
                                  style={{
                                    background: 'rgba(150, 146, 146, 0.3)',
                                    color: 'white',
                                    border: 'none',
                                  }}
                                  onClick={handlepassword}
                                >
                                  {showPassword ? (
                                    <img
                                      src={ShowPwd}
                                      style={{
                                        marginLeft: '12px',
                                        width: '16px',
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={HidePwd}
                                      style={{
                                        marginLeft: '12px',
                                        width: '16px',
                                      }}
                                    />
                                  )}
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup
                            style={{
                              margin: '20px 0px',
                              textAlign: 'center',
                            }}
                          >
                            <Button
                              type="submit"
                              onClick={handleSubmit}
                              disabled={disabledButton() || login.isLoading}
                            >
                              {login.isLoading ? <Spin /> : 'Sign In'}
                            </Button>
                          </FormGroup>
                        </Form>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <NavLink
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            toggle('3')
                            setFPwSection(true)
                          }}
                        >
                          Forgot Password ?
                        </NavLink>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === '2',
                          })}
                          onClick={() => {
                            toggle('2')
                          }}
                        >
                          Sign Up
                        </NavLink>
                      </div>

                      {/* {formStep === 1 && (
                        <div className="forgot-password">
                          <div className="fp-label">
                            <NavLink
                              onClick={() => {
                                toggle('3')
                                setFPwSection(true)
                              }}
                            >
                              Forgot Password ?
                            </NavLink>
                          </div>
                        </div>
                      )}
                      {formStep === 1 && (
                        <div className="forgot-password">
                          <div className="fp-label">
                            <NavLink
                              className={classnames({
                                active: activeTab === '2',
                              })}
                              onClick={() => {
                                toggle('2')
                              }}
                            >
                              SIGNUP
                            </NavLink>
                          </div>
                        </div>
                      )} */}
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </TabPane>
            <TabPane tabId="2">
              <AdminSignup toggle={toggle} />
            </TabPane>

            <TabPane tabId="3">
              <ForgetPassword
                toggle={toggle}
                changeFPwSection={() => {
                  setFPwSection(false)
                }}
              />
            </TabPane>
            <TabPane tabId="4">
              <RegisterSociety />
            </TabPane>
            <TabPane tabId="5">
              <LoggedUserRegisterSociety />
            </TabPane>
          </TabContent>
        </div>
      </div>
      
      {openModal && (
        <Modal
          isOpen={openModal}
          toggle={() => setOpenModal(!openModal)}
          backdrop="static"
          size="sm"
          style={{ maxWidth: '375px' }}
        >
          <ModalHeader toggle={() => setOpenModal(!openModal)}>
            Verify OTP
          </ModalHeader>
          <ModalBody style={{ textAlign: 'center', padding: '10px' }}>
            <Form>
              <label style={{ padding: '10px' }}>Enter Email OTP <span className='required'>*</span></label>
              <OTPInput
                style={{
                  display: 'block',
                  marginLeft: '20px',
                  marginBotton: '20px',
                }}
                value={emailOTP}
                onChange={setEmailOTP}
                autoFocus
                OTPLength={6}
                // otpType="number"
                disabled={false}
                inputStyles={inputStyles}
              />
              <p className="errorText">{errMessage}</p>
              <Button
                color="secondary"
                className="modalCustomButton"
                disabled={
                  validateSignUpOTP.isLoading ||
                  emailOTP.length !== 6 ||
                  resetOTP.isLoading
                }
                onClick={handleOTP}
              >
                {validateSignUpOTP.isLoading ? <Spin /> : 'Verify'}
              </Button>
            </Form>
            <div>
              <span onClick={handleresendOTP} className="resendOTP">
                Resend OTP
              </span>
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  )
}

export default Login
