import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Tooltip, Popconfirm, Alert } from 'antd'
import { Spin, Switch, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import FileBase64 from 'components/Custom/UploadFile'
import { addFeatureType } from 'redux/actions/addFeatureTypeAction'
import { resetAddFeatureType } from 'redux/actions/addFeatureTypeAction'
import { getSubCategory } from 'redux/actions/getSubCategoryAction'
import { getFeatureType } from 'redux/actions/getFeatureTypeAction'

function SearchFeatureType(props) {
  const [featureId, setFeatureId] = useState('')
  const [featureName, setFeatureName] = useState('')
  const [subTypeId, setSubTypeId] = useState('')
  const [subTypeName, setSubTypeName] = useState('')
  const [name, setName] = useState('')
  const [featureIcon, setFeatureIcon] = useState('')
  const [description, setDescription] = useState('')
  const [redirect, setRedirect] = useState('')
  const [externalUrl, setExternalUrl] = useState('')
  const [priorityOrder, setPriorityOrder] = useState('')
  const [isFavorite, setIsFav] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [isDashboard, setIsInDashboard] = useState(false)
  const [updatedPhoto, setUpdatedPhoto] = useState('')
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const getFeatureCat = useSelector(({ getFeature }) => getFeature)
  const getSubCat = useSelector(({ getSubCategory }) => getSubCategory)
  const addedFeatureType = useSelector(({ addFeatureType }) => addFeatureType)

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'name') setName(value)
    if (name === 'description') setDescription(value)
    if (name === 'redirect') setRedirect(value)
    if (name === 'priorityOrder') setPriorityOrder(value)
    if (name === 'externalUrl') setExternalUrl(value)
  }
  const getSubCategoryRequest = () => {
    const getSubCategoryReq = {
      initiatedBy: login.data.user.role,
      featureId,
    }
    return dispatch(getSubCategory(getSubCategoryReq))
  }
  useEffect(() => {
    getSubCategoryRequest()
  }, [featureId])

  const onChangeSwitch = (checked) => {
    setIsFav(checked)
  }
  const onChangeSwitch2 = (checked) => {
    setIsNew(checked)
  }
  const onChangeSwitch3 = (checked) => {
    setIsInDashboard(checked)
  }
  const handleAttachments = (filesList) => {
    const icon = filesList[0].base64.split(`,`)
    setFeatureIcon(icon[1])
    setUpdatedPhoto(filesList[0].base64)
  }

  const { Option } = Select
  function onChangeFeature(value) {
    setFeatureId(value)
    setSubTypeId('')
  }

  function onSearchFeature(val) {
    setFeatureName(val)
  }
  function onChangeSubCat(value) {
    setSubTypeId(value)
  }

  function onSearchSubCat(val) {
    setSubTypeName(val)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    getFeatureTypeRequest()
    props.setIsSearch()
  }

  const getFeatureTypeRequest = () => {
    const getFeatureTypeReq = {
      initiatedBy: login.data.user.role,
      featureId,
      subCategoryId: subTypeId,
      name,
      redirect,
      limit:6
    }
    return dispatch(getFeatureType(getFeatureTypeReq))
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' , width:"100%" , margin:"auto"}}>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Feature Category</label>
              <Select
                type="select"
                value={featureId}
                showSearch
                placeholder="Select Feature Category"
                optionFilterProp="children"
                onChange={onChangeFeature}
                onSearch={onSearchFeature}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Feature Category
                </Option>
                {getFeatureCat.success &&
                  getFeatureCat.data.featureCategory.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.name}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Sub Category </label>
              <Select
                type="select"
                value={subTypeId}
                showSearch
                placeholder="Select Sub Category Type"
                optionFilterProp="children"
                onChange={onChangeSubCat}
                onSearch={onSearchSubCat}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Sub Category
                </Option>
                {getSubCat.success &&
                  getSubCat.data.subCategory.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.name}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Feature Type Name</Label>
              <Input
                type="text"
                placeholder="Enter Feature Type Name"
                name="name"
                id="serviceType"
                value={name || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">App Screen Name</Label>
              <Input
                type="text"
                placeholder="App Screen Name"
                name="redirect"
                id="serviceType"
                value={redirect || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          {/* <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">External Url</Label>
              <Input
                type="text"
                placeholder="External Url"
                name="externalUrl"
                id="serviceType"
                value={externalUrl || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col> */}
          {/* <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Select Icon</Label>
              <FileBase64
                multiple={true}
                id="iconImage"
                accept=".jpeg, .png, .doc, .docx, .pdf, .jpg, .svg"
                onDone={handleAttachments}
              />
            </FormGroup>
            {featureIcon && (
              <img
                src={updatedPhoto}
                width="40px"
                height="40px"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            )}
          </Col> */}
          {/* <Col xs="12" sm="12" md="1">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label>Favourite</Label>
              <br />
              <Switch
                defaultChecked={isFavorite}
                name="isAttendance"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={onChangeSwitch}
                // style={{ width: '100%' }}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="12" md="1">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label>New</Label>
              <br />
              <Switch
                defaultChecked={isNew}
                name="isAttendance"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={onChangeSwitch2}
                // style={{ width: '100%' }}
              />
            </FormGroup>
          </Col> */}
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            // disabled={addedServiceType.isLoading || !serviceType}
            type="submit"
          >
            {/* {addedServiceType.isLoading ? <Spin /> : 'Add'} */}
            Search
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default SearchFeatureType
