import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import { Spin } from 'antd';
import { addUnitType, resetAddUnitType } from "../../redux/actions/addUnitTypeAction";
import { getAllUnitType } from 'redux/actions/getAllUnitTypeAction'
import NotificationPopups from "components/NotificationPopups/NotificationPopups";

function AddFlatAreaType() {

  const [flatAreaType, setFlatAreaType] = useState("");

  const login = useSelector(({ login }) => login);
  const addUnitTypeRes = useSelector(({ addUnitType }) => addUnitType);
  const getAllUnitTypeRes = useSelector(({ getAllUnitType }) => getAllUnitType)
  console.log(addUnitTypeRes, "addUnitTypeRes")
  const dispatch = useDispatch();

  const handleChange = ({ target: { name, value } }) => {
    if (name === "flatAreaType") setFlatAreaType(value);
  }

  const addUnitTypeRequest = () => {
    const addFlatAreaTypeReq = {
      initiatedBy: login.data.user.role,
      type: flatAreaType,
    }
    return dispatch(addUnitType(addFlatAreaTypeReq));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    addUnitTypeRequest()
  }

  useEffect(() => {
    if (addUnitTypeRes.success) {
      setFlatAreaType("")
      NotificationPopups('success', 'Success', addUnitTypeRes.data.message)
    }
    if (addUnitTypeRes.failed) {
      NotificationPopups('error', 'Failed', addUnitTypeRes.data.message)
    }
    if (addUnitTypeRes.network) {
      NotificationPopups('error', 'Network Error', "Check your internet connection")
    }
    return () => {
      dispatch(resetAddUnitType());
    };
  }, [addUnitTypeRes.success, addUnitTypeRes.failed, addUnitTypeRes.network])

  const getAllUnitTypeRequest = () => {
    const flatAreaTypeReq = {
      initiatedBy: login.data.user.role
    }
    return dispatch(getAllUnitType(flatAreaTypeReq))
  }

  useEffect(() => {
    getAllUnitTypeRequest()
  }, [addUnitTypeRes.success])

  return (
    <div style={{ marginTop: "20px" }}>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="4" sm="12">
            <FormGroup>
              <Label for="flatAreaType">Flat Area Type</Label>
              <span className='required'>*</span>
              <Input type="text"
                name="flatAreaType"
                placeholder="Flat Area Type"
                id="flatAreaType"
                value={flatAreaType || ""}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="4" sm="12">
            <Button className='cust-button' style={{ marginTop: "22px", width: "70%" }}
              disabled={!flatAreaType || addUnitTypeRes.isLoading}
              onClick={handleSubmit}
            >
              {addUnitTypeRes.isLoading ? <Spin /> : "Add"}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default AddFlatAreaType;
