import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
} from 'reactstrap'
import DisplayAttachment from '../../components/Custom/DisplayAttachment'
import FileBase64 from '../../components/Custom/UploadFile'
import { resetgetDocType, getDocType } from 'redux/actions/getDocTypeAction'
import {
  resetaddSocietyDoc,
  addSocietyDoc,
} from 'redux/actions/addSocietyDocAction'
import {
  resetgetSocietyDoc,
  getSocietyDoc,
} from 'redux/actions/getSocietyDocAction'
import {
  resetupdateSocietyDoc,
  updateSocietyDoc,
} from 'redux/actions/updateSocietyDocAction'
//ant design components
import { Spin } from 'antd'
import Util from 'validations/index'
import {logoutUser} from 'redux/actions/logoutAction'

function UploadDocument() {
  const [attachment, setattachment] = useState('')
  const [updateattachment, setupdateattachment] = useState('')
  const [file, setFile] = useState('')
  const [fileModal, setFileModal] = useState(false)
  const [fileType, setfileType] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [commonMessage, setcommonMessage] = useState('')
  const [successMessage, setsuccessMessage] = useState('')
  const [isSubmit, setIsSubmit] = useState(false)
  const [adminStatus, setAdminStatus] = useState('')
  const [documentType, setDocumentType] = useState([])
  const [documentTypeDisplay, setDocumentTypeDisplay] = useState([])
  const docType = useSelector(({ getSocietyDocs }) => getSocietyDocs)
  const login = useSelector(({ login }) => login)
  const addedSocietyDocs = useSelector(({ addSocietyDocs }) => addSocietyDocs)
  const getSocietyDocs = useSelector(({ getDocType }) => getDocType)
  const updatedSocietyDocs = useSelector(
    ({ updateSocietyDocs }) => updateSocietyDocs
  )
  const dispatch = useDispatch()
  // const [societyRole, setSocietyRole] = useState(login.data.societyRole)
  //Get Document Type
  const getDocTypeRequest = () => {
    const docTypeReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      id: login.data.id,
    }
    return dispatch(getDocType(docTypeReq))
  }
  const getSocietyDocumentRequest = () => {
    const getSocDocreq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      id: login.data.id,
    }
    return dispatch(getSocietyDoc(getSocDocreq))
  }

  // console.log(documentTypeDisplay, 'documentTypeDisplay')

  //Handle Attachments for Upload Documents
  const handleAttachments = (filesList) => {
    let a = { [filesList.id]: filesList.base64 }
    setattachment({
      ...attachment,
      ...a,
    })
  }
  // setAdminStatus(getSocietyDocs.data.adminStatus)
  //Upload Documents Request
  const uploadDocumentRequest = (documentTypeId, docFile) => {
    const document = docFile.split(`,`)[1]
    const uploadDocReq = {
      societyId: login.data.society.id,
      societyUserId: login.data.user.id,
      documentTypeId,
      document,
      initiatedBy: login.data.user.role,
      societyRole: login.data.societyRole,
    }

    return dispatch(addSocietyDoc(uploadDocReq))
  }

  //For Upload Documents
  const handleSubmit = (e, documentTypeId, docFile) => {
    e.preventDefault()
    setfileType(docType)
    uploadDocumentRequest(documentTypeId, docFile)
    setIsSubmit(!isSubmit)
  }

  //Open Files
  const handleOpenFile = (a) => {
    setFile(a)
    setFileModal(true)
  }

  //Toggle For Attachment Modal
  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }

  //useEffect for Add Society Documents
  useEffect(() => {
    if (addedSocietyDocs.success) {
      setsuccessMessage(addedSocietyDocs.data.message)
      setcommonMessage('')
      seterrMessage('')
      setFile('')
      getSocietyDocumentRequest()
    }
    if (addedSocietyDocs.failed) {
      setsuccessMessage('')
      if (addedSocietyDocs.data.message.includes(fileType)) {
        seterrMessage(addedSocietyDocs.data.message)
      } else {
        setcommonMessage(addedSocietyDocs.data.message)
      }
    }
    if (addedSocietyDocs.network) {
      setsuccessMessage('')
      setcommonMessage('Network Error. Check your internet connection')
    }

    return () => {
      dispatch(resetaddSocietyDoc())
    }
  }, [
    addedSocietyDocs.success,
    addedSocietyDocs.failed,
    addedSocietyDocs.network,
  ])

  //Get Society Documents
  // console.log(login)

  //Start - Checking if all Docs Uploaded
  useEffect(() => {
    getDocTypeRequest()
    getSocietyDocumentRequest()
  }, [])

  useEffect(() => {
    getDocTypeRequest()
    getSocietyDocumentRequest()
  }, [isSubmit])

  let doc
  let filtered_data
  useEffect(() => {
    if (docType.success) {
      doc = docType.data.societyDocumentTypeList.filter((d) => d.isActive)
      setDocumentType(doc)
    }
    if (getSocietyDocs.success) {
      filtered_data = documentType.filter((d) => {
        return !getSocietyDocs.data.societyDocumentList.some((data) => {
          return d.type == data.documentType
        })
      })
      setDocumentTypeDisplay(filtered_data)
    } else {
      setDocumentTypeDisplay(documentType)
    }
  }, [docType.success, getSocietyDocs.success, doc, filtered_data])

  useEffect(() => {
    documentType
      ? setDocumentTypeDisplay(documentType)
      : setDocumentTypeDisplay(filtered_data)
  }, [documentType, filtered_data])
  //End - Checking if all Docs Uploaded

  //Handle Attachments for Update Rejected Documents
  const handleUpdateAttachments = (filesList) => {
    let a = { [filesList.id]: filesList.base64 }
    setupdateattachment({
      ...updateattachment,
      ...a,
    })
  }
  console.log(docType, getSocietyDocs)
  //For Update Rejected Documents
  const handleUpdateSubmit = (e, docId, documentTypeId, docFile) => {
    e.preventDefault()
    setfileType(docType)
    upDateDocumentRequest(docId, documentTypeId, docFile)
  }

  //Update Rejected Documents Request
  const upDateDocumentRequest = (docId, documentTypeId, docFile) => {
    const document = docFile.split(`,`)[1]
    const updateSocDocReq = {
      id: docId,
      document,
      societyId: login.data.society.id,
      societyUserId: login.data.user.id,
      documentTypeId,
      initiatedBy: login.data.user.role,
      societyRole: login.data.societyRole,
    }
    return dispatch(updateSocietyDoc(updateSocDocReq))
  }

  //useEffect for Update Rejected Documents
  useEffect(() => {
    if (updatedSocietyDocs.success) {
      setsuccessMessage(updatedSocietyDocs.data.message)
      setcommonMessage('')
      seterrMessage('')
      getSocietyDocumentRequest()
    }
    if (updatedSocietyDocs.failed) {
      setsuccessMessage('')
      if (updatedSocietyDocs.data.message.includes(fileType)) {
        setcommonMessage('')
        seterrMessage(updatedSocietyDocs.data.message)
      } else {
        seterrMessage('')
        setcommonMessage(updatedSocietyDocs.data.message)
      }
    }
    if (updatedSocietyDocs.network) {
      seterrMessage('')
      setsuccessMessage('')
      setcommonMessage('Network Error. Check your internet connection')
    }

    return () => {
      dispatch(resetaddSocietyDoc())
    }
  }, [
    updatedSocietyDocs.success,
    updatedSocietyDocs.failed,
    updatedSocietyDocs.network,
  ])

  //Logout

  const logoutReq = () =>{
    const logout = {
      initiatedBy: login.data.user.role,
      id: login.data.id
    }
    return dispatch(logoutUser(logout))
  }

  const handleLogout = () => {
    sessionStorage.removeItem('persist:root')
    window.location.href = '/'
    logoutReq()
  }

  return (
    <>
      <Modal
        isOpen={true}
        size="lg"
        backdrop="static"
        centered
        className="docs"
        style={{ maxWidth: '1300px' }}
      >
        <div className="content upload-document-list">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <div className="head-icon">
                    <i className={'now-ui-icons files_single-copy-04'} />
                  </div>
                  <span className="head-title">Upload Documents</span>
                  <span className="logoutButton" onClick={handleLogout}>
                    Logout
                  </span>
                </CardHeader>
                <CardBody>
                  <Row style={{ justifyContent: 'center' }}>
                    <div className="errorText">
                      {commonMessage ? commonMessage : ''}
                    </div>
                    <div className="errorText" style={{ color: 'green' }}>
                      {successMessage ? successMessage : ''}
                    </div>
                    <div>
                      {login.data.adminStatus === 'document' &&
                        !successMessage ? (
                        <span style={{ color: 'golden' }}>
                          Please Upload Your Documents
                        </span>
                      ) : (
                        ''
                      )}
                      {login.data.adminStatus === 'pending' ? (
                        <span style={{ color: 'golden' }}>
                          Your Document is under review
                        </span>
                      ) : login.data.adminStatus === 'approved' ? (
                        <span style={{ color: 'golden' }}>
                          Your Application is under review
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </Row>
                  <div className="typeHeading"></div>
                  {login.data.adminStatus === 'document' &&
                    getSocietyDocs.data.adminStatus != 'rejected' && (
                      <Row
                        style={{ justifyContent: 'center', padding: '25px' }}
                      >
                        {docType.success &&
                          documentTypeDisplay.length > 0 &&
                          documentTypeDisplay.map((type, id) => (
                            <Col
                              key={id}
                              // xs="12"
                              // sm="12"
                              md="4"
                              lg="4"
                              style={{
                                textAlign: 'center',
                                // margin: '5px',
                                // padding: '25px',
                                border: '1px dotted #DCDCDC',
                              }}
                            >
                              {type.isMandatory === true && (
                                <h6 style={{ paddingTop: '10px' }}>
                                  {type.type}
                                  <span className="errorText">*</span>{' '}
                                </h6>
                              )}
                              {type.isMandatory != true && <h6 style={{ paddingTop: '10px' }}>{type.type}</h6>}
                              <Row
                                style={{
                                  justifyContent: 'center',
                                  // minHeight: '80px',
                                }}
                              >
                                <Col
                                  xs="12"
                                  sm="6"
                                  md="6"
                                  style={{ margin: '10px 0' }}
                                >
                                  <FileBase64
                                    multiple={false}
                                    id={type.type}
                                    accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                                    onDone={handleAttachments}
                                  />
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  justifyContent: 'center',
                                  // minHeight: '140px',
                                }}
                              >
                                {attachment[type.type] && (
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      handleOpenFile(attachment[type.type])
                                    }
                                  >
                                    {attachment &&
                                      Util.attachmentType(
                                        attachment[type.type]
                                      ) === 'image' ? (
                                      <img
                                        src={attachment[type.type]}
                                        style={{
                                          width: '100px',
                                          height: '100px',
                                        }}
                                      />
                                    ) : (
                                      <i
                                        className={Util.iconType(
                                          attachment[type.type]
                                        )}
                                        style={{ fontSize: '100px' }}
                                      ></i>
                                    )}
                                  </div>
                                )}
                              </Row>
                              <Row style={{ justifyContent: 'center' }}>
                                <div className="errorText">
                                  {errMessage.includes(type.type)
                                    ? errMessage
                                    : ''}
                                </div>
                              </Row>
                              <Row style={{ justifyContent: 'center' }}>
                                <Col xs="12" sm="12" md="6">
                                  <Button
                                    className="cust-button"
                                    id={type.type}
                                    disabled={
                                      !attachment[
                                      type.type || addedSocietyDocs.isLoading
                                      ]
                                    }
                                    onClick={(e) =>
                                      handleSubmit(
                                        e,
                                        type.documentTypeId,
                                        attachment[type.type]
                                      )
                                    }
                                  >
                                    {fileType === type.type &&
                                      addedSocietyDocs.isLoading ? (
                                      <Spin />
                                    ) : (
                                      `Upload`
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          ))}
                      </Row>
                    )}

                  {getSocietyDocs.data.adminStatus != 'rejected' && (
                    <Row style={{ justifyContent: 'center', padding: '25px' }}>
                      {getSocietyDocs.success &&
                        getSocietyDocs.data.societyDocumentList.map(
                          (type, id) => (
                            <Col
                              key={id}
                              xs="12"
                              sm="12"
                              md="5"
                              lg="5"
                              style={{
                                textAlign: 'center',
                                // margin: '5px',
                                // padding: '25px',
                                border: '1px dotted #DCDCDC',
                              }}
                            >
                              <h6 style={{ paddingTop: '25px' }}> {type.documentType} </h6>
                              <div
                                className="showStatus"
                                style={{
                                  color: Util.statusColor(type.documentStatus),
                                }}
                              >
                                {/* {doc.documentStatus.toUpperCase()} */}
                                {type.documentStatus.toUpperCase()}
                              </div>
                              {type.documentStatus === 'rejected' && (
                                <Row
                                  style={{
                                    justifyContent: 'center',
                                    minHeight: '80px',
                                  }}
                                >
                                  <Col
                                    xs="12"
                                    sm="6"
                                    md="6"
                                    style={{ margin: '10px 0' }}
                                  >
                                    <FileBase64
                                      multiple={false}
                                      id={type.documentType}
                                      accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                                      onDone={handleUpdateAttachments}
                                    />
                                  </Col>
                                </Row>
                              )}
                              <Row
                                style={{
                                  justifyContent: 'center',
                                  minHeight: '140px',
                                  padding: '25px',
                                }}
                              >
                                {!updateattachment[type.documentType] &&
                                  type.document && (
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        handleOpenFile(type.document)
                                      }
                                    >
                                      {document && (
                                        <img
                                          src={`${Util.imageUrl}${type.document}`}
                                          style={{
                                            width: '100px',
                                            height: '100px',
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                                {updateattachment[type.documentType] && (
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      handleOpenFile(
                                        updateattachment[type.documentType]
                                      )
                                    }
                                  >
                                    {updateattachment && (
                                      <img
                                        src={
                                          updateattachment[type.documentType]
                                        }
                                        style={{
                                          width: '100px',
                                          height: '100px',
                                        }}
                                      />
                                    )}
                                  </div>
                                )}
                              </Row>
                              {type.remark && (
                                <Row style={{ justifyContent: 'center' }}>
                                  <div className="errorText">
                                    Remark: {type.remark}
                                  </div>
                                </Row>
                              )}
                              <Row style={{ justifyContent: 'center' }}>
                                <div className="errorText">
                                  {errMessage.includes(type.documentType)
                                    ? errMessage
                                    : ''}
                                </div>
                              </Row>
                              {type.documentStatus === 'rejected' && (
                                <Row style={{ justifyContent: 'center' }}>
                                  <Col xs="12" sm="12" md="6">
                                    <Button
                                      className="cust-button"
                                      id={type.documentType}
                                      disabled={
                                        !updateattachment[type.documentType] ||
                                        resetupdateSocietyDoc.isLoading
                                      }
                                      //style={{ minWidth: "100px"}}
                                      onClick={(e) =>
                                        handleUpdateSubmit(
                                          e,
                                          type.id,
                                          type.documentTypeId,
                                          updateattachment[type.documentType]
                                        )
                                      }
                                    >
                                      {fileType === type.documentType &&
                                        resetupdateSocietyDoc.isLoading ? (
                                        <Spin />
                                      ) : (
                                        `Update`
                                      )}
                                    </Button>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          )
                        )}
                    </Row>
                  )}
                  {getSocietyDocs.data.adminStatus === 'rejected' && (
                    <Row style={{ justifyContent: 'center' }}>
                      Your Document is Rejected please contact this Number
                      18000-122000
                    </Row>
                  )}
                  {login.data.adminStatus === 'rejected' && (
                    <Row style={{ justifyContent: 'center', height: 200 }}>
                      <div>
                        {login.data.user.email} your Document is Rejected please
                        contact this Number 18000-122000
                        <br />
                        <div>
                          Remark:
                          <span className="errorText">{login.data.remark}</span>
                        </div>
                      </div>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal>

      {fileModal && (
        <DisplayAttachment
          attachment={`${Util.imageUrl}${file}`}
          fileModal={fileModal}
          toggleModal={toggleFileModal}
        />
      )}
    </>
  )
}

export default UploadDocument
