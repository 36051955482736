import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Table,
} from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import FileBase64 from 'components/Custom/UploadFile'

import {
  resetupdateSocietyDetails,
  updateSocietyDetails,
} from 'redux/actions/updateSocietyDetailsAction'

import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import Util from 'validations'
import 'assets/scss/customStyle.scss'
import { Spin } from 'antd'
// import imgSrc from 'assets/img/Icons/random.jpg'
// const [societyIcon, setSocietyIcon] = useState('')
// const [selectedPhoto, setSelectedPhoto] = useState('')

function SocietyDetail({ details, modal, toggle, societyAdmin }) {
  const [profilePictureStatus, setprofilePictureStatus] = useState(false)
  const [updatedPhoto, setUpdatedPhoto] = useState('')
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  // const addedFeatureType = useSelector(({ addFeatureType }) => addFeatureType)
  console.log(societyAdmin, 'societyAdmin')

  const [id, setid] = useState(details.id)
  const [photo, setPhoto] = useState(details.photo)
  const [societyName, setSocietyName] = useState(details.societyName)
  const [societyBlock, setSocietyBlock] = useState(details.societyBlock)
  const [address, setAddress] = useState(details.address)
  const [pincode, setPincode] = useState(details.pincode)
  const [state, setState] = useState(details.state)
  const [landmark, setLandmark] = useState(details.landmark)
  const [city, setCity] = useState(details.city)
  const [viewNav, setViewNav] = useState('AdminList')
  // const [profileName, setProfileName] = useState(societyAdmin.userList.name)

  const updatedSocietyDetails = useSelector(
    ({ updateSocietyDetails }) => updateSocietyDetails
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    editDetailsTypeRequest()
  }

  const editDetailsTypeRequest = () => {
    const editDetailsTypeReq = {
      initiatedBy: login.data.user.role,
      id,
      societyName:societyName.trim(),
      societyBlock:societyBlock.trim(),
      address,
      pincode,
      state,
      landmark:landmark.trim(),
      city,
      photo: profilePictureStatus ? photo : '',
    }
    return dispatch(updateSocietyDetails(editDetailsTypeReq))
  }

  useEffect(() => {
    if (updatedSocietyDetails.success) {
      setid('')
      setSocietyName('')
      setSocietyBlock('')
      setCity('')
      setAddress('')
      setLandmark('')
      setState('')
      setPincode('')
      NotificationPopups(
        'success',
        'Success',
        updatedSocietyDetails.data.message
      )
    }
    if (updatedSocietyDetails.failed) {
      NotificationPopups('error', 'Failed', updatedSocietyDetails.data.message)
      console.log(updatedSocietyDetails.data.message)
    }
    if (updatedSocietyDetails.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetupdateSocietyDetails())
    }
  }, [
    updatedSocietyDetails.success,
    updatedSocietyDetails.failed,
    updatedSocietyDetails.network,
  ])

  const handleAttachments = (filesList) => {
    setprofilePictureStatus(true)
    const icon = filesList.base64.split(`,`)
    if(icon[0].includes("application")){
      NotificationPopups("error", 'Failed', "Wrong File format")
    }else{
      setPhoto(icon[1])
      setUpdatedPhoto(filesList.base64)
    } 
  }

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'societyName')
      Util.alphabetsOnly(value)
        ? setSocietyName(value)
        : setSocietyName(societyName)
    if (name === 'societyBlock')
      Util.alphabetsOnly(value)
        ? setSocietyBlock(value)
        : setSocietyBlock(societyBlock)
    if (name === 'address') setAddress(value)
    if (name === 'pincode')
      Util.verifyMobile(value) ? setPincode(value) : setPincode(pincode)
    if (name === 'landmark')
      Util.alphabetsOnly(value) ? setLandmark(value) : setLandmark(landmark)
    if (name === 'state') setState(value)
    if (name === 'city') setCity(value)

    // if (name === 'name') setName(value)
    // if (name === 'description') setDescription(value)
    // if (name === 'redirect') setRedirect(value)
    // if (name === 'priorityOrder') setPriorityOrder(value)
    // if (name === 'externalUrl') setExternalUrl(value)
  }

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      size="sm"
      style={{ maxWidth: '700px' }}
    >
      <ModalHeader toggle={toggle}>Society Details</ModalHeader>
      <ModalBody>
        {/* <Row>
          <Col xs="12" sm="6" md="8">
            <Row>
              <Col xs="12" sm="12" md="12">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <Label>Society</Label>
                  <Input
                    type="text"
                    placeholder="Society Name"
                    name="societyName"
                    id="serviceType"
                    value={societyName || ''}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs="12" sm="4" md="4">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <Label>Lankmark</Label>
                  <Input
                    type="text"
                    placeholder="Landmark"
                    name="landmark"
                    id="serviceType"
                    value={landmark || ''}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs="12" sm="4" md="4">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <Label>Pincode</Label>
                  <Input
                    type="text"
                    placeholder="Pincode"
                    name="pincode"
                    id="serviceType"
                    value={pincode || ''}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs="12" sm="4" md="4">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <Label>Block</Label>
                  <Input
                    type="text"
                    placeholder="Block"
                    name="societyBlock"
                    id="serviceType"
                    value={societyBlock || ''}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>

          <Col xs="12" sm="6" md="4">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Society Image</Label>
              <FileBase64
                multiple={false}
                id="iconImage"
                accept=".jpeg, .png, .doc, .docx, .pdf, .jpg, .svg"
                onDone={handleAttachments}
              />

              {photo && (
                <img
                  src={
                    profilePictureStatus
                      ? `${updatedPhoto}`
                      : `${Util.imageUrl}${photo}`
                  }
                  width="180"
                  height="180"
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
              )}
            </FormGroup>
          </Col>
        </Row> */}

        {/* Start */}
        <Row>
          <Col xs="12" sm="6" md="4">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Society Image</Label>
              {photo && (
                <img
                  src={
                    profilePictureStatus
                      ? `${updatedPhoto}`
                      : `${Util.imageUrl}${photo}`
                  }
                  width="180"
                  height="180"
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: "10px"
                  }}
                />
              )}
              <FileBase64
                multiple={false}
                id="iconImage"
                accept=".jpeg, .png, .doc, .docx, .pdf, .jpg, .svg"
                onDone={handleAttachments}
              />
            </FormGroup>
          </Col>

          <Col xs="12" sm="6" md="8">
            <Row>
              <Col xs="12" sm="12" md="6">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <Label>Society</Label>
                  <Input
                    type="text"
                    placeholder="Society Name"
                    name="societyName"
                    id="serviceType"
                    value={societyName || ''}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>

              <Col xs="12" sm="4" md="6">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <Label>Lankmark</Label>
                  <Input
                    type="text"
                    placeholder="Landmark"
                    name="landmark"
                    id="serviceType"
                    value={landmark || ''}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs="12" sm="4" md="6">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <Label>Pincode</Label>
                  <Input
                    type="text"
                    placeholder="Pincode"
                    name="pincode"
                    id="serviceType"
                    value={pincode || ''}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs="12" sm="4" md="6">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <Label>Block</Label>
                  <Input
                    type="text"
                    placeholder="Block"
                    name="societyBlock"
                    id="serviceType"
                    value={societyBlock || ''}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="8" sm="12" md="12">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <Label for="serviceType">Address</Label>
                  <Input
                    style={{ maxHeight: '200px' }}
                    cols="80"
                    rows="6"
                    type="textarea"
                    placeholder="Enter Address"
                    name="address"
                    value={address || ''}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Button
                color="secondary"
                className="modalCustomButton"
                onClick={handleSubmit}
                style={{margin:"auto"}}
              >
                Edit
              </Button>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md="3" style={{ cursor: 'pointer' }}>
            {viewNav === 'AdminList' ? (
              <p
                onClick={() => {
                  setViewNav('AdminList')
                }}
                style={{
                  borderBottom: '2px solid #1890ff',
                  transition: '.2s',
                }}
              >
                Admin List
              </p>
            ) : (
              <p
                onClick={() => {
                  setViewNav('AdminList')
                }}
              >
                Admin List
              </p>
            )}
          </Col>

          <Col xs="12" sm="6" md="12">
            <div style={{ overflowY: 'scroll' }}>
              <Table
              //  responsive style={{ textAlign: 'center' }}
              >
                <thead>
                  <tr style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    <th>Name</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                {societyAdmin.success && (
                  <tbody>
                    {societyAdmin.data.userList.map((d, id) => (
                      <React.Fragment key={id}>
                        <tr key={id} id={`toggler${id}`}>
                          <td>{d.name}</td>
                          <td>{d.mobileNumber}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                )}
              </Table>
            </div>
          </Col>
          {/* <Col xs="12" sm="6" md="4">
            <thead>
              <tr style={{ fontSize: '15px', fontWeight: 'bold' }}>
                <th>Name</th>
                <th>Phone</th>
              </tr>
            </thead>

            {societyAdmin.success && (
              <tbody>
                {societyAdmin.data.userList.map((d, id) => (
                  <React.Fragment key={id}>
                    <tr key={id} id={`toggler${id}`}>
                      <td>{d.name}</td>
                      <td>{d.mobileNumber}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </Col> */}
        </Row>
        {/* End */}
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  )
}

export default SocietyDetail
