import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Row,
  Col,
  Table,
  FormGroup,
  Form,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledCollapse,
} from 'reactstrap'
import { Alert, Popconfirm, Tooltip, Spin, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetgetPendingMemberList,
  getPendingMemberList,
} from 'redux/actions/getPendingMemberListAction'
import {
  addUserStatus,
  resetaddUserStatus,
} from 'redux/actions/addUserStatusAction'
// import DisplayAttachment from '../../../../componentsCustom/DisplayAttachment'
import Util from 'validations/index'
import {
  applicationMessage,
  resetapplicationMessage,
} from 'redux/actions/applicationMessageAction'
import {
  resetUpdateUnitDocumentStatus,
  updateUnitDocumentStatus,
} from 'redux/actions/updateUnitDocumentStatusAction'
import {
  resetUpdateOwnerStatus,
  updateOwnerStatus,
} from 'redux/actions/updateOwnerStatusAction'
import PaginationComponent from 'react-reactstrap-pagination'
import axios from 'config/axios'

import RightIcon from 'assets/img/Icons/options/right.png'
import WrongIcon from 'assets/img/Icons/options/wrong.png'

function MembersPendingList({ isOpen, toggle }) {
  const [userMessage, setuserMessage] = useState('')
  const [userId, setUserId] = useState('')
  const [docId, setdocId] = useState('')
  const [docRemark, setdocRemark] = useState('')
  const [showMsgField, setshowMsgField] = useState(false)
  const [rejectRemark, setRejectRemark] = useState('')
  // const [showMsgField, setshowMsgField] = useState(false)
  const [showRejectMsg, setShowRejectMsg] = useState(false)
  const [fileModal, setFileModal] = useState(false)
  const [attachment, setAttachment] = useState(false)
  const [documentList, setDocumentList] = useState('')
  const [documentViewModal, setDocumentViewModal] = useState(false)
  const [viewDoc, setViewDoc] = useState(false)
  const [applicationId, setApplicationId] = useState('')
  //filter
  const [serachAreaName, setSearchAreaName] = useState('')
  const [buildingId, setBuildingId] = useState()
  const [bldgName, setBldgName] = useState('')
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [memberName, setMemberName] = useState('')
  const [phone, setPhone] = useState('')
  const [residentType, setResidentType] = useState('')

  const [limit] = useState(5)
  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState('')
  const getPendingMembers = useSelector(
    ({ getPendingMemberList }) => getPendingMemberList
  )
  const applicationMsg = useSelector(
    ({ applicationMessage }) => applicationMessage
  )
  const login = useSelector(({ login }) => login)
  const addedUserStatus = useSelector(({ addUserStatus }) => addUserStatus)
  const updateUnitDocStatus = useSelector(
    ({ updateUnitDocumentStatus }) => updateUnitDocumentStatus
  )
  const ownerStatus = useSelector(({ updateOwnerStatus }) => updateOwnerStatus)
  const memberList = useSelector(
    ({ getPendingMemberList }) => getPendingMemberList
  )
  const dispatch = useDispatch()

  const { Option } = Select
  function onChangeSearch(value) {
    setBuildingId(value[0])
    setBldgName(value[1])
    setSearchAreaName('')
  }

  function onSearch(value) {
    setSearchAreaName(value)
  }

  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaList', userData).then((res) => {
        setOptions(res.data.activeType)
      })
    }
  }, [serachAreaName])

  useEffect(() => {
    if (serachAreaName === '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        // areaName: serachAreaName,
      }
      axios.post('society/getAreaList', userData).then((res) => {
        setOptions2(res.data.activeType)
      })
    }
  }, [])

  const selectOptions = [
    { id: 1, residentType: 'owner' },
    { id: 2, residentType: 'tenant' },
  ]

  const pageSize = memberList.data.pages
  const totalItems = memberList.data.count

  // useEffect(() => {
  //   getPendingMemberListRequest()
  // }, [])

  const getPendingMemberListRequest = () => {
    const getPendingMemberListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      userStatus: 'pending',
      limit,
      page,
    }
    return dispatch(getPendingMemberList(getPendingMemberListReq))
  }

  const handlePagination = (selectedPage) => {
    // if (selectedPage > lastPage) {
    //   // getPendingMemberListRequest()
    //   // setPage(lastPage)
    // } else {
    //   setPage(selectedPage)
    // }
    setPage(selectedPage)
  }

  // const getPendingMemberListRequest = () => {
  //   const getPendingMemberListReq = {
  //     initiatedBy: login.data.user.role,
  //     societyId: login.data.society.id,
  //     userStatus: 'pending',
  //     limit,
  //     page,
  //   }
  //   return dispatch(getPendingMemberList(getPendingMemberListReq))
  // }
  useEffect(() => {
    getPendingMemberListRequest()
  }, [page])

  useEffect(() => {
    setPage(1)
  }, [memberList.failed])
  const handleMemberStatus = (applicationId, status) => {
    setUserId(applicationId)
    if (status === 'approved') {
      const addUserStatusReq = {
        id: applicationId,
        userStatus: status,
        initiatedBy: login.data.user.role,
      }
      return dispatch(updateOwnerStatus(addUserStatusReq))
    }
    if (status === 'rejected') {
      setShowRejectMsg(true)
    }
  }

  const addUserStatusRequest = (id, status) => {
    const addUserStatusReq = {
      id,
      userStatus: status,
      initiatedBy: login.data.user.role,
      message: rejectRemark,
    }
    return dispatch(updateOwnerStatus(addUserStatusReq))
  }

  const handleRemark = ({ target: { name, value } }) => {
    if (name === 'docRemark') setdocRemark(value)
    if (name === 'userMessage') setuserMessage(value)
    if (name === 'rejectRemark') setRejectRemark(value)
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'userMessage') setuserMessage(value)
    if (name === 'rejectRemark') setRejectRemark(value)
  }

  const handleDocStatus = (id, status) => {
    setdocId(id)
    setdocRemark('')
    if (status === 'rejected') {
      setshowMsgField(true)
      // updateDocumentStatusRequest(id, status)
    } else {
      setshowMsgField(false)
      updateDocumentStatusRequest(id, status)
    }
  }

  const handleRejectDoc = (e, id, status) => {
    e.preventDefault()
    updateDocumentStatusRequest(id, status)
  }

  const updateDocumentStatusRequest = (docId, docStatus) => {
    const updateDocStatusReq = {
      initiatedBy: login.data.user.role,
      id: docId,
      status: docStatus,
    }

    const updateRemark = {
      remark: docRemark,
    }

    let updateReq = ''

    if (docStatus === 'approved') {
      updateReq = { ...updateDocStatusReq }
      return dispatch(updateUnitDocumentStatus(updateReq))
    }
    if (docStatus === 'rejected' && docRemark != '') {
      updateReq = { ...updateDocStatusReq, ...updateRemark }
      return dispatch(updateUnitDocumentStatus(updateReq))
    }
  }

  useEffect(() => {
    if (ownerStatus.success) {
      setRejectRemark('')
      setdocRemark('')
      getPendingMemberListRequest()
      NotificationPopups('success', 'Success', ownerStatus.data.message)
    }

    if (ownerStatus.failed && ownerStatus.data.errorVOList) {
      NotificationPopups('error', 'Failed', (ownerStatus.data.errorVOList.map((item, id) => (<>{id === 0 ? 1 : id + 1} - {item.errorMessage}</>))))
    }
    else if (ownerStatus.failed) {
      NotificationPopups('error', 'Failed', ownerStatus.data.message)
    }


    if (ownerStatus.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetUpdateOwnerStatus())
    }
  }, [ownerStatus.success, ownerStatus.failed, ownerStatus.data.errorVOList, ownerStatus.network])

  useEffect(() => {
    if (updateUnitDocStatus.success) {
      getPendingMemberListRequest()

      NotificationPopups('success', 'Success', updateUnitDocStatus.data.message)
    }
    if (updateUnitDocStatus.success) {
      setDocumentViewModal(!documentViewModal)
    }
    if (updateUnitDocStatus.failed) {
      NotificationPopups('error', 'Failed', updateUnitDocStatus.data.message)
    }
    if (updateUnitDocStatus.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetUpdateUnitDocumentStatus())
    }
  }, [
    updateUnitDocStatus.success,
    updateUnitDocStatus.failed,
    updateUnitDocStatus.network,
  ])

  useEffect(() => {
    if (applicationMsg.success) {
      setuserMessage('')
      NotificationPopups('success', 'Success', applicationMsg.data.message)
    }
    if (applicationMsg.failed) {
      NotificationPopups('error', 'Failed', applicationMsg.data.message)
    }
    if (applicationMsg.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetapplicationMessage())
    }
  }, [applicationMsg.success, applicationMsg.failed, applicationMsg.network])

  //Open Files
  const handleOpenFile = (a) => {
    setAttachment(a)
    setFileModal(true)
  }
  const toggleDoc = () => {
    setDocumentViewModal(!documentViewModal)
  }
  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }

  const handleSubmit = (id) => {
    const applicationMessageReq = {
      id: id,
      initiatedBy: login.data.user.role,
      message: userMessage,
    }
    return dispatch(applicationMessage(applicationMessageReq))
  }

  return (
    <div>
      <Table responsive style={{ textAlign: 'center', fontSize: '12px' }}>
        <thead>
          <tr style={{ fontSize: '15px', fontWeight: 'bold' }}>
            <th>Name</th>
            <th>Flat No.</th>
            <th>Phone</th>
            <th>Action</th>
          </tr>
        </thead>
        {getPendingMembers.success && (
          <tbody>
            {getPendingMembers.data.ownerList.map((d, id) => (
              <React.Fragment key={id}>
                <tr
                  key={id}
                  id={`toggler${id}`}
                  style={{ background: 'white' }}
                >
                  <td>{`${d.name}/${d.type}`}</td>
                  <td>
                    {d.areaName}/{d.unit}
                  </td>
                  <td>{d.mobileNumber}</td>
                  <td>
                    <Popconfirm
                      title="Approve User ?"
                      onConfirm={
                        () => handleMemberStatus(d.applicationId, 'approved')
                      }
                      okText="Approve"
                      cancelText="Cancel"
                    >
                      <Tooltip
                        placement="leftBottom"
                        title="Approve"
                        color="#108ee9"

                      >
                        <img
                          className="amenity_option_icon"
                          src={RightIcon}
                          alt="RightIcon"
                        />
                      </Tooltip>
                    </Popconfirm>
                    {'|'}
                    <Popconfirm
                      placement="left"
                      title="Reject User ?"
                      onConfirm={
                        () => addUserStatusRequest(d.applicationId, 'rejected')
                        // setShowRejectMsg(true)
                      }
                      description={
                        <Input type="textarea"
                          style={{ width: '300px', padding: '5px' }}
                          row="1"
                          name="rejectRemark"
                          id={docRemark}
                          placeholder="Rejection Remark"
                          onChange={handleRemark}
                          required />
                      }
                      okText="Reject"
                      cancelText="Cancel"
                    >
                      <Tooltip
                        placement="rightBottom"
                        title="Reject"
                        color="#108ee9"
                      >
                        {/* <i className="fas fa-times right-menu"></i> */}
                        <img
                          className="amenity_option_icon"
                          src={WrongIcon}
                          alt="WrongIcon"
                        />
                      </Tooltip>
                    </Popconfirm>
                  </td>
                  {/* {showRejectMsg && userId === d.applicationId && (
                    <td>
                      <FormGroup>
                        <Input
                          type="textarea"
                          row="1"
                          // value={rejectRemark}
                          name="rejectRemark"
                          id={docRemark}
                          placeholder="Rejection Remark"
                          onChange={handleRemark}
                          required
                        />
                      </FormGroup>
                      <Button
                        className="cust-button"
                        style={{ minWidth: '20%' }}
                        onClick={(e) =>
                          addUserStatusRequest(d.applicationId, 'rejected')
                        }
                      >
                        {ownerStatus.isLoading ? <Spin /> : 'Send'}
                      </Button>
                      <Button
                        className="cust-button"
                        style={{ minWidth: '20%' }}
                        onClick={() => setShowRejectMsg(!showRejectMsg)}
                      >
                        Cancel
                      </Button>
                    </td>
                  )} */}
                </tr>
                {viewDoc && d.applicationId === applicationId && (
                  <tr>
                    <td colSpan={6}>
                      <Row>
                        {d.documentList &&
                          d.documentList.map((doc, id) => (
                            <Col
                              xs="12"
                              sm="6"
                              md="4"
                              lg="3"
                              style={{ padding: '20px' }}
                              key={doc.id}
                            >
                              <label>{doc.documentType}</label>
                              {doc.document && (
                                <div
                                  style={{
                                    cursor: 'pointer',
                                    padding: '5px',
                                    height: '130px',
                                  }}
                                  onClick={() => handleOpenFile(doc.document)}
                                >
                                  {doc.document &&
                                    Util.attachmentType(doc.document) ===
                                    'image' ? (
                                    <img
                                      src={doc.document}
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                      }}
                                    />
                                  ) : (
                                    <i
                                      className={Util.iconType(doc.document)}
                                      style={{ fontSize: '100px' }}
                                    ></i>
                                  )}
                                </div>
                              )}
                              <div
                                className="showStatus"
                                style={{
                                  color: Util.statusColor(doc.status),
                                }}
                              >
                                {doc.status.toUpperCase()}
                              </div>
                              {doc.remark && (
                                <Col
                                  xs="12"
                                  sm="12"
                                  md="12"
                                  lg="12"
                                  style={{ padding: '10px' }}
                                >
                                  <label>Remark</label>
                                  <div className="detail-val">{doc.remark}</div>
                                </Col>
                              )}
                              {doc.status === 'pending' ? (
                                <>
                                  <div style={{ marginTop: '15px' }}>
                                    <span>
                                      <Popconfirm
                                        title="Approve Document?"
                                        onConfirm={() =>
                                          handleDocStatus(doc.id, 'approved')
                                        }
                                        okText="Approve"
                                        cancelText="No"
                                      >
                                        <Tooltip
                                          placement="leftBottom"
                                          title="Approve"
                                          color="#108ee9"
                                        >
                                          {/* <i className="fas fa-check right-menu"></i> */}
                                          <img
                                            className="amenity_option_icon"
                                            src={RightIcon}
                                            alt="RightIcon"
                                          />
                                        </Tooltip>
                                      </Popconfirm>
                                    </span>{' '}
                                    {'|'}
                                    <span style={{ padding: '10px' }}>
                                      <Popconfirm
                                        title="Reject Document?"
                                        onConfirm={() =>
                                          handleDocStatus(doc.id, 'rejected')
                                        }
                                        okText="Reject"
                                        cancelText="No"
                                      >
                                        <Tooltip
                                          placement="rightBottom"
                                          title="Reject"
                                          color="#108ee9"
                                        >
                                          {/* <i className="fas fa-times right-menu"></i> */}
                                          <img
                                            className="amenity_option_icon"
                                            src={WrongIcon}
                                            alt="WrongIcon"
                                          />
                                        </Tooltip>
                                      </Popconfirm>
                                    </span>
                                  </div>
                                  {showMsgField && docId === doc.id && (
                                    <div>
                                      <FormGroup>
                                        <Input
                                          type="textarea"
                                          row="1"
                                          // value={docRemark}
                                          name="docRemark"
                                          id={docRemark}
                                          placeholder="Rejection Remark"
                                          onChange={handleRemark}
                                          required
                                        />
                                      </FormGroup>
                                      <Button
                                        className="cust-button"
                                        style={{ minWidth: '100%' }}
                                        onClick={(e) =>
                                          handleRejectDoc(e, doc.id, 'rejected')
                                        }
                                      >
                                        Send
                                      </Button>
                                    </div>
                                  )}
                                </>
                              ) : null}
                            </Col>
                          ))}
                      </Row>
                      <Row>
                        <>
                          <Col xs="12" sm="12" md="12" lg="12">
                            <Row style={{ justifyContent: 'center' }}>
                              <Col
                                xs="12"
                                sm="12"
                                md="9"
                                lg="9"
                                style={{ padding: '10px' }}
                              >
                                <FormGroup>
                                  <Input
                                    type="textarea"
                                    row="1"
                                    name="userMessage"
                                    id="userMessage"
                                    placeholder="Reply"
                                    value={userMessage || ''}
                                    onChange={handleChange}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col
                                xs="12"
                                sm="12"
                                md="3"
                                lg="3"
                                style={{ padding: '25px 10px' }}
                              >
                                <Button
                                  className="cust-button"
                                  style={{ minWidth: '100%' }}
                                  onClick={() => handleSubmit(d.applicationId)}
                                >
                                  Send
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </>
                      </Row>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        )}
      </Table>
      {getPendingMembers.failed && (
        <div style={{ textAlign: 'center', margin: '10px' }}>
          <Alert message={getPendingMembers.data.message} type="info" />
        </div>
      )}
    </div>
  )
}

export default MembersPendingList
