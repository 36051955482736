import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from 'reactstrap'
import { Spin, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import {
  resetGetSocietyMnt,
  getSocietyMnt,
} from 'redux/actions/getSocietyMntTypeAction'
import { resetAddInvoice, addInvoice } from 'redux/actions/addInvoiceAction'
import { singleInvoice } from 'redux/actions/singleMntInvoice'
import { getSocietyInterest } from 'redux/actions/getSocietyInterestAction'
import axios from 'config/axios'
import Util from 'validations/index'
import { setSyntheticTrailingComments } from 'typescript'
import moment from 'moment'

function AddMntBilling() {
  const [complainType, setComplainType] = useState('')
  const [areaType, setareaType] = useState('Building')
  const [areaId, setareaId] = useState()
  const [flatid, setflatid] = useState()

  const [serachAreaName, setSearchAreaName] = useState('')
  const [optionsUnit, setOptionsUnit] = useState([''])
  const [flatName, setFlatName] = useState('')
  const [searchFlat, setSearchFlat] = useState('')
  const [buildingId, setBuildingId] = useState()
  const [bldgName, setBldgName] = useState('')
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [memberName, setMemberName] = useState('')
  const [phone, setPhone] = useState('')
  const [residentType, setResidentType] = useState('')

  const buildingList = useSelector(({ buildingList }) => buildingList)
  const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)
  const getSocietyInterestRes = useSelector(({ getSocietyInterest }) => getSocietyInterest)
  console.log(getSocietyInterestRes, "getSocietyInterestRes")

  const [totalAmount, setTotalAmount] = useState(0)
  const [MntObj, setMntObj] = useState([])
  console.log(MntObj,"MntObj")
  const [socMntObj, setSocMntObj] = useState([])
  const [index, setIndex] = useState(0)
  const [invoice, setInvocie] = useState('')
  const [Name, setName] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [billNumber, setBillNumber] = useState('')
  const [billDate, setBillDate] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [interest, setInterest] = useState()
  const [termType, setTermType] = useState()

  const [charges, setCharges] = useState([])

  const login = useSelector(({ login }) => login)
  const addedInvoice = useSelector(({ singleMntInvoice }) => singleMntInvoice)

  const monthObj = [
    { id: 1, month: 'January' },
    { id: 2, month: 'Febraury' },
    { id: 3, month: 'March' },
    { id: 4, month: 'April' },
    { id: 5, month: 'May' },
    { id: 6, month: 'June' },
    { id: 7, month: 'July' },
    { id: 8, month: 'August' },
    { id: 9, month: 'September' },
    { id: 10, month: 'October' },
    { id: 11, month: 'November' },
    { id: 12, month: 'December' },
  ]
  const yearObj = [
    { id: 1, year: '2022' },
    { id: 2, year: '2023' },
    { id: 3, year: '2024' },
    { id: 4, year: '2025' },
    { id: 5, year: '2026' },
  ]
  const socMntType = useSelector(({ getSocietyMnt }) => getSocietyMnt)
  const dispatch = useDispatch()

  var h = []

  const handleChange = ([{target: { name, value },},data,idx,]) => {
    if (name === data.name) {
      var h = MntObj
      let d = h.find((s) => s.name === data.name)
      if (d === undefined) {
        // console.log(Util.verifyMobile(value) ? value : 0, 'price')
        var obj = {
          s: index,
          id: data.id,
          name: data.name,
          price: Util.verifyMobile(value) ? value : '',
          calculationLogic: data.calculationLogic,
        }
        setIndex(index + 1)
        h.push(obj)
      } else {
        console.log( h[d.s],"hssss")
        // console.log(Util.verifyMobile(value) && value, 'price')
        h[d.s].price = Util.verifyMobile(value) ? value : ''
      }
      setMntObj(h)
      var amt = MntObj.map((data) =>
        data.calculationLogic === 'Amount to be added'
          ? +Number(data.price)
          : -Number(data.price)
      )
      var amtt = 0
      amtt = amt.reduce((prev, curr) => prev + curr)
      setTotalAmount(amtt)
    }
  }

  // const handleChange = ([{ target: { name, value }, }, data, idx,]) => {
  //   console.log(value,"value")
  //   if (name === data.name) {
  //     setCharges({ ...charges, [name]: value })
  //   }
  // }

  // useEffect(() => {
  //   var amt = Object.values(charges)
  //   var amtt = amt.reduce((prev, next) => parseFloat(prev) + parseFloat(next), 0)
  //   setTotalAmount(amtt)
  // }, [charges, totalAmount])


  const textHandleChange = ({ target: { name, value } }) => {
    if (name === 'name')
      Util.alphabetsOnly(value) ? setName(value) : setName(Name)
    if (name === 'mobileNumber')
      Util.verifyMobile(value)
        ? setMobileNumber(value)
        : setMobileNumber(mobileNumber)
    if (name === 'billNumber')
      Util.verifyMobile(value)
        ? setBillNumber(value)
        : setBillNumber(billNumber)
    if (name === 'billDate') setBillDate(value)
    if (name === 'dueDate') setDueDate(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addInvoiceRequest()
    if (addedInvoice.success) {
      NotificationPopups('success', 'Success', addedInvoice.data.message)
    }
  }

  useEffect(() => {
    if (addedInvoice.failed) {
      NotificationPopups('error', 'Failed', addedInvoice.data.message)
    }
  }, [addedInvoice.failed])

  const getSocietyMntTypeRequest = () => {
    const getMaintenanceTypeReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      columnType: 'fee',
    }
    return dispatch(getSocietyMnt(getMaintenanceTypeReq))
  }

  useEffect(() => {
    getSocietyMntTypeRequest()

    return () => resetGetSocietyMnt()
  }, [])

  //Filter
  const { Option } = Select

  function onChangeSearch(value) {
    let val = value.split(',')
    let val1 = val[0]
    let val2 = val[1]
    setBuildingId(val1)
    setBldgName(val2)
    setareaId(val1)
    setSearchAreaName('')
    setflatid('')
    setFlatName('')
  }

  useEffect(() => {
    if (buildingId === '') {
      dispatch(resetGetUnitFlatList())
    }
  }, [buildingId])

  function onSearch(value) {
    setSearchAreaName(value)
  }
  function onChangeSearchFlatId(value) {
    let flatval = value.split(",")
    console.log(flatval,"flatval")
    let flatval1 = flatval[0]
    let flatval2 = flatval[1]
    setflatid(flatval1)
    setFlatName(flatval2)
    setSearchFlat('')
  }
  function onSearchFlatId(val) {
    setSearchFlat(val)
  }
  function onChangeResidentType(value) {
    setResidentType(value)
  }
  function onSearchResidentType(value) {
    setResidentType(value)
  }

  function onChangeMonth(value) {
    setMonth(value)
  }
  function onSearchMOnth(val) {
    setMonth(val)
  }
  function onChangeYear(value) {
    setYear(value)
  }
  function onSearchYear(val) {
    setYear(val)
  }

  const addInvoiceRequest = () => {
    const map = new Map()
    MntObj.map((d) => {
      map.set(d.name, d.price)
    })

    const invoice = Object.fromEntries(map)

    const addInvoiceReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      unit: flatName,
      unitId: flatid,
      area: bldgName,
      areaId: buildingId,
      month,
      year,
      invoice: charges,
      action: 'add',
      name: Name.trim(),
      mobileNumber,
      totalAmount,
      billNumber: billNumber,
      billDate: billDate,
      dueDate: dueDate
    }
    return dispatch(singleInvoice(addInvoiceReq))
    // console.log(addInvoiceReq)
  }

  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName])

  useEffect(() => {
    if (login.data.user.role !== 'SuperAdmin' && areaType != '') {
      const getBuildingListReq = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      return dispatch(getBuildingList(getBuildingListReq))
    }
  }, [areaType])

  useEffect(() => {
    setSocMntObj(socMntType.data?.societyMntList)
  }, [socMntType.success])

  useEffect(() => {
    if (areaType === 'Building' && buildingId) {
      const getUnitFlatListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
      }
      return dispatch(getUnitFlatList(getUnitFlatListReq))
    }
  }, [buildingId])

  useEffect(() => {
    if (areaType === 'Building' && buildingId && searchFlat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
        unit: searchFlat,
      }
      axios.post('society/getUnitList', userData).then((res) => {
        // if (res.status === 200 && res.data.activeUnitList != '') {
        setOptionsUnit(res.data.activeUnitList)
        // }
      })
    }
  }, [searchFlat])

  useEffect(() => {
    if (serachAreaName === '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        // areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions2(res.data.activeAreaList)
      })
    }
  }, [])

  const getSocietyInterestRequest = () => {
    const interestReq = {
      initiatedBy:  login.data.user.role,
      societyId: login.data.society.id
    }
    return dispatch(getSocietyInterest(interestReq))
  }

  useEffect(() =>{
    getSocietyInterestRequest()
    setInterest(getSocietyInterestRes.data?.societyInterest?.interestPercentage)
    setTermType(getSocietyInterestRes.data?.societyInterest?.termType)
  },[])

  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="6" sm="12">
            <FormGroup>
              <Label for="floor">Enter Name</Label>
              <Input
                type="text"
                name="name"
                id="floor"
                placeholder="Enter Name"
                value={Name || ''}
                onChange={textHandleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="6" sm="12">
            <FormGroup>
              <Label for="floor">Enter Mobile Number</Label>
              <Input
                type="text"
                name="mobileNumber"
                id="floor"
                maxLength="10"
                placeholder="Enter Mobile Number"
                value={mobileNumber || ''}
                onChange={textHandleChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6" sm="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="flatid" lat>
                Month
              </Label>
              <Select
                type="select"
                showSearch
                value={month}
                placeholder="Select Flat Number"
                optionFilterProp="children"
                onChange={onChangeMonth}
                onSearch={onSearchMOnth}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Month
                </Option>
                {monthObj.map((d, idx) => (
                  <Option key={idx} className="loginSelect" value={d.month}>
                    {d.month}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="6" sm="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="flatid" lat>
                Year
              </Label>
              <Select
                type="select"
                showSearch
                value={year}
                placeholder="Select Year"
                optionFilterProp="children"
                onChange={onChangeYear}
                onSearch={onSearchYear}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Year
                </Option>
                {yearObj.map((d, idx) => (
                  <Option key={idx} className="loginSelect" value={d.year}>
                    {d.year}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6" sm="12">
            <FormGroup>
              <label>Bill Date</label>
              <Input
                name="billDate"
                value={billDate || ''}
                type="date"
                onChange={textHandleChange}
              // min={moment().format('DD-MM/YYYY')}
              />
            </FormGroup>
          </Col>
          <Col md="6" sm="12">
            <FormGroup>
              <label>Due Date</label>
              <Input
                name="dueDate"
                value={dueDate || ''}
                type="date"
                onChange={textHandleChange}
              // min={moment().format('DD/MM/YYYY')}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6" sm="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="city">Building Name</Label>
              <Select
                type="select"
                value={bldgName}
                showSearch
                placeholder="Select Building Name"
                optionFilterProp="children"
                onChange={onChangeSearch}
                onSearch={onSearch}
                style={{ width: '100%', borderRadius: '25px' }}
              // loading={buildingNames.isLoading}
              >
                <Option className="loginSelect" value="">
                  Select Building name
                </Option>

                {serachAreaName != '' &&
                  options != undefined &&
                  options.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.areaId},${d.areaName}`}
                    >
                      {d.areaName}
                    </Option>
                  ))}
                {serachAreaName === '' &&
                  options2.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.areaId},${d.areaName}`}
                    >
                      {d.areaName}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>
          {areaType === 'Building' && (
            <Col md="6" sm="12">
              <FormGroup style={{ margin: '10px 1px' }}>
                <Label for="flatid" lat>
                  Flat
                </Label>
                <Select
                  type="select"
                  showSearch
                  value={flatName}
                  placeholder="Select Flat Number"
                  optionFilterProp="children"
                  onChange={onChangeSearchFlatId}
                  onSearch={onSearchFlatId}
                  style={{ width: '100%' }}
                >
                  <Option className="loginSelect" value="">
                  {getFlat.data.message === 'Not Found' ? 'No Flat Found ' : "Select Flat Number"}
                  </Option>
                  {searchFlat != '' && optionsUnit != undefined
                    ? optionsUnit.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={`${d.id}, ${d.unit}`}
                      >
                        {d.unit}
                      </Option>
                    ))
                    : getFlat.success &&
                    getFlat.data.activeUnitList.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={`${d.id}, ${d.unit}`}
                      >
                        {d.unit}
                      </Option>
                    ))}
                </Select>
              </FormGroup>
            </Col>
          )}
          <Col md="6" sm="12">
            <FormGroup>
              <Label for="floor">Enter Bill Number</Label>
              <Input
                type="text"
                name="billNumber"
                id="floor"
                maxLength="10"
                placeholder="Enter Bill Number"
                value={billNumber || ''}
                onChange={textHandleChange}
                required
              />
            </FormGroup>
          </Col>
          {/* <Col md="6" sm="12">
            <FormGroup>
              <Label for="floor">Interest %</Label>
              <Input
                type="text"
                name="interest"
                id="floor"
                maxLength="10"
                placeholder="Interest %"
                value={interest || ''}
                disabled
                // onChange={textHandleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="6" sm="12">
            <FormGroup>
              <Label for="floor">Term Type</Label>
              <Input
                type="text"
                name="termType"
                id="floor"
                maxLength="10"
                placeholder="Term Type"
                value={termType || ''}
                disabled
                // onChange={textHandleChange}
                required
              />
            </FormGroup>
          </Col> */}

          <Col md="12" sm="12">
            <Table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {socMntType.success &&
                  socMntType.data?.societyMntList.map((d, idx) => (
                    // console.log(d,"daatta")
                    <tr key={idx}>
                      <td>{d.name}</td>
                      <td>
                        <Input
                          type="text"
                          name={d.name}
                          id={d.name}
                          placeholder=""
                          value={MntObj[idx] && MntObj[idx].price}
                          onChange={(e) => {
                            handleChange([e, d, idx])
                          }}
                          required
                        />
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td>Total Amount</td>
                  <td>
                    <Input type="text" value={totalAmount} disabled />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col md="12" sm="12" style={{ textAlign: "center" }}>
            <Button
              className="cust-button"
              style={{ marginTop: '22px', width: '25%', textAlign: "center" }}
              onClick={handleSubmit}
            // disabled={addedBuilding.isLoading || disableButton}
            >
              Add
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default AddMntBilling
