import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import classnames from 'classnames'

import AddAreaType from 'views/Categories/AddAreaType'
import AddFlatAreatype from 'views/Categories/AddFlatAreaType'
import AddNoticeType from 'views/Categories/AddNoticeType'
import AddDailyHelperType from 'views/Categories/AddDailyHelperType'
import AddCompanyType from 'views/Categories/AddCompanyType'
import AddVehicleType from 'views/Categories/AddVehicleType'
import AddCompany from './AddCompany'

import AreaType from './AreaType'
import FlatAreaType from './FlatAreaType'
import NoticeType from './NoticeType'
import DailyHelpType from './DailyHelpType'
import CompanyType from './CompanyType'
import VehicleType from './VehicleType'
import Company from './Company'

import ProfessionTypeList from './ProfessionType/PofessionTypeList'

//actions
// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

// ant design components
import { Tooltip } from 'antd'
import Guard from './Guard/GuardList'
import SearchHelper from './Helper/SearchHelper'
import HelperList from './Helper/HelperList'
import GuardList from './Guard/GuardList'
import SearchGuard from './Guard/SearchGuard'

import CategoriesIcon from 'assets/img/Icons/Sidebar/category.png'

import HelperIcon from 'assets/img/Icons/Categories/helper.png'
import GuardIcon from 'assets/img/Icons/Categories/guard.png'

import AreaTypeIcon from 'assets/img/Icons/Categories/areatype.png'
import NoticeTypeIcon from 'assets/img/Icons/Categories/noticetype.png'
import CompanyIcon from 'assets/img/Icons/Categories/company.png'
import ProfessionType from 'assets/img/Icons/Categories/professionType.png'
import MultiLan from 'assets/img/Icons/Categories/multiLan.png'
import SearchProfessionType from './ProfessionType/SearchProfessionType'
import MultiLanguageTypeList from './MultiLanguageType/MutiLanguageTypeList'
import FlatArea from 'assets/img/Icons/Categories/flatArea.png'


function Categories() {
  const [category, setCategory] = useState('areaType')
  const [activeTab, setActiveTab] = useState()
  const login = useSelector(({ login }) => login)
  const [sendData, setSendData] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  // console.log(isSearch , 'isSesarch')

  const toggle = (tab, cat) => {
    setCategory(cat)
    if (activeTab !== tab) setActiveTab(tab)
  }
  const searchFilter = (value) => {
    setSendData(value)
  }

  const categoryArr = [
    {
      id: 1,
      name: 'Area Type',
      display: 'SuperAdmin',
      //  icon: 'fas fa-home'
      icon: `${AreaTypeIcon}`,
    },
    {
      id: 11,
      name: 'Flat Area Type',
      display: 'SuperAdmin',
      icon: `${FlatArea}`,
    },
    {
      id: 2,
      name: 'Notice Type',
      display: 'SuperAdmin',
      // icon: 'fas fa-clipboard-list',
      icon: `${NoticeTypeIcon}`,
    },
    {
      id: 5,
      name: 'Company',
      display: 'SuperAdmin',
      // icon: 'fas fa-industry',
      icon: `${CompanyIcon}`,
    },
    {
      id: 6,
      name: ' Helper',
      display: 'Society',
      // icon: 'fas fa-people-carry',
      icon: `${HelperIcon}`,
    },
    {
      id: 7,
      name: ' Guard',
      display: 'Society',
      icon: `${GuardIcon}`,
    },
    // {
    //   id: 8,
    //   name: 'Members Profession',
    //   display: 'Society',
    //   icon: `${GuardIcon}`,
    // },
    {
      id: 9,
      name: 'Profession Type',
      display: 'SuperAdmin',
      icon: `${ProfessionType}`,
    },
    {
      id: 10,
      name: 'Multi Language Type',
      display: 'SuperAdmin',
      icon: `${MultiLan}`,
    },

  ]

  const typeComponentArr = (t) => {
    switch (t) {
      case 'Area Type':
        return <AddAreaType />
      case 'Flat Area Type':
        return <AddFlatAreatype />
      case 'Notice Type':
        return <AddNoticeType />
      case 'Helper Type':
        return <AddDailyHelperType />
      case 'Company Type':
        return <AddCompanyType />
      case 'Company':
        return <AddCompany />
      case ' Helper':
        return <SearchHelper searchFilter={searchFilter} setIsSearch={() => setIsSearch(!isSearch)} />
      case ' Guard':
        return <SearchGuard searchFilter={searchFilter} setIsSearch={() => setIsSearch(!isSearch)} />
      case 'Profession Type':
        return <SearchProfessionType searchFilter={searchFilter} setIsSearch={() => setIsSearch(!isSearch)} />
      default:
        return null
    }
  }

  const displayComponentArr = (t) => {
    switch (t) {
      case 'Area Type':
        return <AreaType />
      case 'Flat Area Type':
        return < FlatAreaType />
      case 'Notice Type':
        return <NoticeType />
      case 'Helper Type':
        return <DailyHelpType />
      case 'Company Type':
        return <CompanyType />
      case 'Company':
        return <Company />
      case ' Helper':
        return <HelperList data={sendData} onTap={isSearch} />
      case ' Guard':
        return <GuardList data={sendData} onTap={isSearch} />
      case 'Profession Type':
        return <ProfessionTypeList data={sendData} onTap={isSearch} />
      case 'Multi Language Type':
        return <MultiLanguageTypeList />
      default:
        return null
    }
  }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content society-details-container">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  {/* <i className={'now-ui-icons loader_gear'} /> */}
                  <img
                    src={CategoriesIcon}
                    alt="CategoriesIcon"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <span className="head-title">Categories</span>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12" style={{ padding: '20px' }}>
                    <Nav tabs>
                      {categoryArr.map((d, id) => (
                        <>
                          {login.data.user.role === d.display ? (
                            <NavItem>
                              <NavLink
                                data-id={id}
                                className={classnames({
                                  active: activeTab === d.id,
                                })}
                                onClick={() => {
                                  toggle(d.id)
                                  console.log(d.name)
                                }}
                                key={id}
                              >
                                <div className={`sd-col`}>
                                  <div className="sd-ic">
                                    {/* <i className={d.icon}></i> */}
                                    <img src={d.icon} alt={d.icon} />
                                  </div>
                                  <p className="sd-label">{d.name}</p>
                                </div>
                              </NavLink>
                            </NavItem>
                          ) : (
                            ''
                          )}
                        </>
                      ))}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      {categoryArr.map((d, id) => (
                        <TabPane tabId={d.id} data-id={id} key={id}>
                          {typeComponentArr(d.name)}
                        </TabPane>
                      ))}
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <TabContent activeTab={activeTab}>
          {categoryArr.map((d, id) => (
            <TabPane tabId={d.id} data-id={id} key={id}>
              {displayComponentArr(d.name)}
            </TabPane>
          ))}
        </TabContent>
      </div>
    </>
  )
}

export default Categories

//
