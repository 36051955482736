import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Label, Input, Button } from 'reactstrap'
import {
  resetaddSocietyMnt,
  addSocietyMnt,
} from 'redux/actions/addSocietyMntTypeAction'
import {
  resetgetMaintenanceType,
  getMaintenanceType,
} from 'redux/actions/getMntTypeAction'
import Util from 'validations/index'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { Select, Switch } from 'antd'

const EditFeeConfig = ({ editId, dataEdit, closeModal }) => {
  const [mntTypeId, setMntTypeId] = useState(dataEdit.mntTypeId)
  const [mntName, setMntName] = useState(dataEdit.name)
  const [mntType, setMntType] = useState(dataEdit.mntType)
  const [mntColNum, setMntColNum] = useState(dataEdit.columnNumber)
  const [mntCalLogic, setMntCalLogic] = useState(dataEdit.calculationLogic)
  const [mntMonthly, setMntMonthly] = useState(dataEdit.isMonthly)
  const [mntTotalAmt, setMntTotalAmt] = useState(dataEdit.totalAmount)

  const login = useSelector(({ login }) => login)
  const addSociety = useSelector(({ addSocietyMnt }) => addSocietyMnt)
  const getmntType = useSelector(({ getMntType }) => getMntType)

  const dispatch = useDispatch()

  const { Option } = Select
  const CalculationsDropDown = [
    {
      id: 1,
      type: 'Amount to be added',
    },
    {
      id: 2,
      type: 'Amount to be deducted',
    },
    {
      id: 3,
      type: 'Based on amount',
    },
  ]

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'mntName')
      Util.alphabetsOnly(value) ? setMntName(value) : setMntName(mntName)
    if (name === 'mntType')
      Util.alphabetsOnly(value) ? setMntType(value) : setMntType(mntType)
    if (name === 'mntColNum')
      Util.alphabetsOnly(value) ? setMntColNum(value) : setMntColNum(mntColNum)
    if (name === 'mntCalLogic')
      Util.alphabetsOnly(value)
        ? setMntCalLogic(value)
        : setMntCalLogic(mntCalLogic)
    if (name === 'mntTotalAmt')
      Util.verifyMobile(value)
        ? setMntTotalAmt(value)
        : setMntTotalAmt(mntTotalAmt)
  }
  const editMntRequest = () => {
    const editMntReq = {
      initiatedBy: login.data.user.role,
      name: mntName.trim(),
      // columnNumber: mntColNum,
      mntTypeId,
      action: 'update',
      columnType: 'fee',
      id: editId,
      isMonthly: mntMonthly,
      calculationLogic: mntCalLogic.trim(),
      totalAmount: mntTotalAmt,
    }
    dispatch(addSocietyMnt(editMntReq))
    // closeModal()
  }

  const getMntRequest = () => {
    const editMntReq = {
      initiatedBy: login.data.user.role,
      action: 'fee',
      isFee: true,
    }
    dispatch(getMaintenanceType(editMntReq))
  }

  const handleMntEdit = () => {
    editMntRequest()
  }
  const onChange = (checked) => {
    console.log(checked, 'This is checked !!!')
    setMntMonthly(checked)
  }
  const onChangeCal = (value) => {
    setMntCalLogic(value)
  }
  const onSearchCal = (val) => {
    setMntCalLogic(val)
  }

  const onChangeMntType = (value) => {
    console.log(value,"vvvvvvvv")
    setMntType(value.split(',')[1])
    setMntTypeId(value.split(',')[0])
  }
  const onSearchMntType = (val) => {
    setMntType(val)
  }

  useEffect(() => {
    getMntRequest()
  }, [])

  useEffect(() => {
    if (addSociety.success) {
      // setMntTypeId('')
      setMntName('')
      setMntType('')
      setMntColNum('')
      setMntCalLogic('')
      setMntMonthly('')
      setMntTotalAmt('')
      closeModal()
      // NotificationPopups('success', 'Success', addSociety.data.message)
    }
    // if (addSociety.failed) {
    //   NotificationPopups('error', 'Failed', addSociety.data.message)
    // }
    // if (addSociety.network) {
    //   NotificationPopups('error', 'Network Error', addSociety.data.message)
    // }
    return () => {
      resetaddSocietyMnt()
    }
  }, [addSociety.success, addSociety.failed, addSociety.network])
  return (
    <>
      <FormGroup>
        <Label for="mntName">Name</Label>
        <Input
          type="text"
          name="mntName"
          placeholder=" Name"
          id="mntName"
          value={mntName || ''}
          onChange={handleChange}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label for="type">Type</Label>
        <Select
          type="select"
          showSearch
          value={mntType}
          placeholder="Select Fee Data"
          optionFilterProp="children"
          onChange={onChangeMntType}
          onSearch={onSearchMntType}
          style={{ width: '100%' }}
        >
          <Option className="loginSelect" value="">
            Select Fee Data
          </Option>
          {getmntType.success &&
            getmntType.data?.maintenanceTypeList.map((data, id) => (
              <Option key={id} value={`${data.id},${data.name}`}>
                {data.name}
              </Option>
            ))}
        </Select>
      </FormGroup>
      {/* <FormGroup>
        <Label for="mntColNum">Column Number</Label>
        <Input
          type="text"
          name="mntColNum"
          placeholder="Column Number"
          id="mntColNum"
          value={mntColNum || ''}
          onChange={handleChange}
          required
        />
      </FormGroup> */}
      <FormGroup>
        <Label for="mntCalLogic">Calculation Logic</Label>
        <Select
          type="select"
          showSearch
          value={mntCalLogic}
          placeholder="Select Calculation Logic"
          optionFilterProp="children"
          onChange={onChangeCal}
          onSearch={onSearchCal}
          style={{ width: '100%' }}
        >
          <Option className="loginSelect" value="">
            Select Calculation Logic
          </Option>
          {CalculationsDropDown.map((data, id) => (
            <Option key={id} value={data.type}>
              {data.type}
            </Option>
          ))}
        </Select>
      </FormGroup>
      <FormGroup>
        <Label>Monthly Fee</Label>
        <br />
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          defaultChecked={mntMonthly}
          onChange={onChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="mntName">Total Amount</Label>
        <Input
          type="text"
          name="mntTotalAmt"
          placeholder="Total Amount"
          id="mntTotalAmt"
          value={mntTotalAmt || ''}
          onChange={handleChange}
          required
          maxlength = '100000000'
        />
      </FormGroup>

      <Button
        color="secondary"
        className="modalCustomButton"
        onClick={handleMntEdit}
        style={{ position: 'relative', left: '4rem', width: '40%' }}
      >
        Edit
      </Button>
    </>
  )
}

export default EditFeeConfig
