import axios from '../../config/axios'
import {
  GETAMENITYLISTCALENDAR_SUCCESS,
  GETAMENITYLISTCALENDAR_FAILED,
  LOADING_GETAMENITYLISTCALENDAR,
  RESET_GETAMENITYLISTCALENDAR,
  GETAMENITYLISTCALENDAR_NETWORKERROR,
} from '../types'

const resetgetAmenityListCalendar = () => {
  return {
    type: RESET_GETAMENITYLISTCALENDAR,
  }
}

const getAmenityListCalendar = (userData, isRead = false) => (dispatch, getState) => {
  let pendingCount;
  let count;
  dispatch({ type: LOADING_GETAMENITYLISTCALENDAR })
  console.log(userData, isRead, count, "Amenitysssss")
  axios
    .post('amenity/getAmenityListCalendar', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          isRead ? count = getState().getAmenityList.data?.count : count = getState().getAmenityList.oldValue;
          userData?.amenityStatus === 'pending' ? pendingCount = res.data?.count : pendingCount = getState().getAmenityList.pendingCount;
          dispatch(success(res.data, count,pendingCount))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GETAMENITYLISTCALENDAR_NETWORKERROR, payload: err })
    )
}

function success(data, oldValue,pendingCount) {
  return {
    type: GETAMENITYLISTCALENDAR_SUCCESS,
    oldValue,
    payload: data,
    pendingCount
  }
}

function failed(data) {
  return {
    type: GETAMENITYLISTCALENDAR_FAILED,
    payload: data,
  }
}

export { resetgetAmenityListCalendar, getAmenityListCalendar }
