import { combineReducers } from 'redux'
import addSocietyReducer from './addSocietyReducer'
import getSocietyListReducer from './getSocietyListReducer'
import addNoticeReducer from './addNoticeReducer'
import getNoticeListReducer from './getNoticeListReducer'
import addBuildingReducer from './addBuildingReducer'
import getAreaSearchList from './getAreaSearchListReducer'
import addFlatListReducer from './addFlatListReducer'
import adminSignupReducer from './adminSignupReducer'
import getMemberListReducer from './getMemberListReducer'
import getPendingMemberListReducer from './getPendingMemberListReducer'
import addUserStatusReducer from './addUserStatusReducer'
import getAdminListReducer from './getAdminListReducer'
import addAdminStatusReducer from './addAdminStatusReducer'
import getAmenityListReducer from './getAmenityListReducer'
import addAmenityStatusReducer from './addAmenityStatusReducer'
import getEmergencyNumberReducer from './getEmergencyNumberReducer'
import addEmergencyNumberReducer from './addEmergencyNumberReducer'
import getComplaintListReducer from './getComplaintListReducer'
import addComplaintStatusRedux from './addComplaintStatusRedux'
import loginReducer from './loginReducer'
import addDailyHelpTypeReducer from './addDailyHelpTypeReducer'
import getBuildingListReducer from './getBuildingListReducer'
import validateSignupOtpReducer from './validateSignupOtpReducer'
import linkSocietyReducer from './linkSocietyReducer'
import getNoticeTypeReducer from './getNoticeTypeReducer'
import addAreaTypeReducer from './addAreaTypeReducer'
import addNoticeTypeReducer from './addNoticeTypeReducer'
import addCompanyTypeReducer from './addCompanyTypeReducer'
import addVehicleTypeReducer from './addVehicleTypeReducer'
import getAreaTypeReducer from './getAreaTypeReducer'
import getDailyHelpReducer from './getDailyHelpReducer'
import getCompanyTypeReducer from './getCompanyTypeReducer'
import getVehicleTypeReducer from './getVehicleTypeReducer'
import updateAreaTypeReducer from './updateAreaTypeReducer'
import updateNoticeTypeReducer from './updateNoticeTypeReducer'
import updateDailyHelpTypeReducer from './updateDailyHelpTypeReducer'
import updateCompanyTypeReducer from './updateCompanyTypeReducer'
import updateVehicleTypeReducer from './updateVehicleTypeReducer'
import deleteAreaTypeReducer from './deleteAreaTypeReducer'
import deleteNoticeTypeReducer from './deleteNoticeTypeReducer'
import deleteDailyHelpTypeReducer from './deleteDailyHelpTypeReducer'
import deleteCompanyTypeReducer from './deleteCompanyTypeReducer'
import deleteVehicleTypeReducer from './deleteVehicleTypeReducer'
import addCompanyReducer from './addCompanyReducer'
import getCompanyListReducer from './getCompanyListReducer'
import updateCompanyReducer from './updateCompanyReducer'
import deleteCompanyReducer from './deleteCompanyReducer'
import addAmenityTypeReducer from './addAmenityTypeReducer'
import getAmenityTypeReducer from './getAmenityTypeReducer'
import updateAmenityTypeReducer from './updateAmenityTypeReducer'
import deleteAmenityTypeReducer from './deleteAmenityTypeReducer'
import resendSignupOTPReducer from './resendSignupOTPReducer'
import pincodeReducer from './pincodeReducer'
import deleteEmergencyNoReducer from './deleteEmergencyNoReducer'
import updateEmergencyNoReducer from './updateEmergencyNoReducer'
import updateNoticeReducer from './updateNoticeReducer'
import deleteNoticeReducer from './deleteNoticeReducer'
import addComplaintMessageReducer from './addComplaintMessageReducer'
import getComplaintMessageReducer from './getComplaintMessageReducer'
import getFlatListReducer from './getFlatListReducer'
import getBuildingNamesReducer from './getBuildingNamesReducer'
import addAmenityMessageReducer from './addAmenityMessageReducer'
import getAmenityMessageReducer from './getAmenityMessageReducer'
import addDocTypeReducer from './addDocTypeReducer'
import getDocTypeReducer from './getDocTypeReducer'
import updateDocTypeReducer from './updateDocTypeReducer'
import deleteDocTypeReducer from './deleteDocTypeReducer'
import getSmsProviderReducer from './getSmsProviderReducer'
import changeSmsProviderReducer from './changeSmsProviderReducer'
import updateSocietyDocReducer from './updateSocietyDocReducer'
import addSocietyDocReducer from './addSocietyDocReducer'
import getSocietyDocReducer from './getSocietyDocReducer'
import updateBuildingReducer from './updateBuildingReducer'
import deleteBuildingReducer from './deleteBuildingReducer'
import updateDocumentStatusReducer from './updateDocumentStatusReducer'
import addUnitDocTypeReducer from './addUnitDocTypeReducer'
import getUnitDocTypeReducer from './getUnitDocTypeReducer'
import updateUnitDocTypeReducer from './updateUnitDocTypeReducer'
import deleteUnitDocTypeReducer from './deleteUnitDocTypeReducer'
import updateMemberDetailReducer from './updateMemberDetailReducer'
import addHelperReducer from './addHelperReducer'
import getHelperListReducer from './getHelperListReducer'
import getHelperDetailsReducer from './getHelperDetailsReducer'
import changePasswordReducer from './changePasswordReducer'
import addServiceTypeReducer from './addServiceTypeReducer'
import getServiceTypeReducer from './getServiceTypeReducer'
import addServiceTypeCategoryReducer from './addServiceTypeCategoryReducer'
import addHelperTypeReducer from './addHelperTypeReducer'
import getHelperTypeReducer from './getHelperTypeReducer'
import updateServiceTypeReducer from './updateServiceTypeReducer'
import updateHelperTypeReducer from './updateHelperTypeReducer'
import addUnitFlatListReducer from './addUnitFlatListReducer'
import getUnitFlatListReducer from './getUnitFlatListReducer'
import updateAmenityStatusReducer from './updateAmenityStatusReducer'
import forgetPasswordReducer from './forgetPasswordReducer'
import updateUnitFlatListReducer from './updateUnitFlatListReducer'
import addMandatoryDocReducer from './addMandatoryDocReducer'
import updateProfileReducer from './updateProfileReducer'
import applicationMessageReducer from './applicationMessageReducer'
import updateSocietyDocumentStatusReducer from './updateSocietyDocumentStatusReducer'
import addGuardReducer from './addGuardReducer'
import getGuardListReducer from './getGuardListReducer'
import updateMemberRoleReducer from './updateMemberRoleReducer'
// import { updateUnitDocumenhtStatus } from 'redux/actions/updateUnitDocumentStatusAction'
import updateUnitDocumentStatusReducer from './updateUnitDocumentStatusReducer'
import updateOwnerStatusReducer from './updateOwnerStatusReducer'
import getHelperDetailReducer from './getHelperDetailReducer'
import updateGuardReducer from './updateGuardReducer'
import getHelperAttendanceReducer from './getHelperAttendanceReducer'
import updateHelperReducer from './updateHelperReducer'
import getVisitorDetailsReducer from './getVisitorDetailsReducer'
import getGuardAttendanceReducer from './getGuardAttendanceReducer'
import getCityListReducer from './getCityListReducer'
import addDocumentReducer from './addDocumentReducer'
import getDocumentReducer from './getDocumentReducer'
import updateDocumentReducer from './updateDocumentReducer'
import addHelperDocReducer from './addHelperDocReducer'
import getHelperDocReducer from './getHelperDocReducer'
import addGuardDocRedcuer from './addGuardDocRedcuer'
import getGuardDocReducer from './getGuardDocReducer'
import updateHelperDocReducer from './updateHelperDocReducer'
import getSocietyAdminReducer from './getSocietyAdminReducer'
import addFeatureReducer from './addFeatureReducer'
import addSubCategoryReducer from './addSubCategoryReducer'
import getFeatureReducer from './getFeatureReducer'
import updateFeatureReducer from './updateFeatureReducer'
import getSubCategoryReducer from './getSubCategoryReducer'
import updateSubCategoryReducer from './updateSubCategoryReducer'
import addFeatureTypeReducer from './addFeatureTypeReducer'
import getFeatureTypeReducer from './getFeatureTypeReducer'
import getHelperDocMandatoryReducer from './getHelperDocMandatoryReducer'
import addDashboardReducer from './addDashboardReducer'
import getDashboardReducer from './getDashboardReducer'
import updateDashboardReducer from './updateDashboardReducer'
import addHelperDocumentsReducer from './addHelperDocumentsReducer'
import addDashboardFeatureTypeReducer from './addDashboardFeatureTypeReducer'
import updateFeatureTypeReducer from './updateFeatureTypeReducer'
import getVisitorDashBoardReducer from './getVisitorDashBoardReducer'
import addProductServiceCategoryReducer from './addProductServiceCategoryReducer'
import getProductServiceCategoryReducer from './getProductServiceCategoryReducer'
import addProductServiceSubCategoryReducer from './addProductServiceSubCategoryReducer'
import getProductServiceSubCategoryReducer from './getProductServiceSubCategoryReducer'
import updateSocietyDetailsReducer from './updateSocietyDetailsReducer'
import getAmenityDashboardChartCountReducer from './getAmenityDashboardChartCountReducer'
import addProfessionTypeReducer from './addProfessionTypeReducer'
import getProfessionTypeReducer from './getProfessionTypeReducer'
import getComplainGraphReducer from './getComplainGraphReducer'
import addTypeLanguageReducer from './addTypeLanguageReducer'
import getTypeLanguageReducer from './getTypeLanguageReducer'
import getAreaSearchListReducer from './getAreaSearchListReducer'
import getHelperDocumentReducer from './getHelperDocumentReducer'
import getRecentActivityReducer from './getRecentActivityReducer'
import addMntTypeReducer from './addMntTypeReducer'
import getMntTypeReducer from './getMntTypeReducer'
import addSocietyMntTypeReducer from './addSocietyMntTypeReducer'
import getSocietyMntTypeReducer from './getSocietyMntTypeReducer'
import addMntInvoiceReducer from './addMntInvoiceReducer'
import getMntInvoiceReducer from './getMntInvoiceReducer'
import generateMntInvoiceReducer from './generateMntInvoiceReducer'
import deleteMntInvoiceReducer from './deleteMntInvoiceReducer'
import addInvoiceReducer from './addInvoiceReducer'
import addParkingReducer from './addParkingReducer'
import addSocietyTemplate from './addSocietyTemplateReducer'
import getSocietyTemplate from './getSocietyTemplateReducer'
import getParkingReducer from './getParkingReducer'
import updateParkingSlotReducer from './updateParkingSlotReducer'
import addGatewayReducer from './addGatewayReducer'
import getGatewayReducer from './getGatewayReducer'
import getPaymentModesReducer from './getPaymentModesReducer'
import processPayReducer from './processPayReducer'
import deleteGatewayReducer from './deleteGatewayReducer'
import singleMntInvoiceReducer from './singleMntInvoiceReducer'
import deleteSocMntTypeReducer from './deleteSocMntTypeReducer'
import getTransactionDetailsReducer from './getTransactionDetailsReducer'
import getTransactionListReducer from './getTransactionListReducer'
import getAllSecurityAlertListReducer from './getAllSecurityAlertListReducer'
import getGuestListReducer from './getGuestListReducer'
import exportExcelInvoiceReducer from './exportExcelInvoiceReducer'
import updateAdminUserReducer from './updateAdminUserReducer'
import updateAdminSocietyRoleReducer from './updateAdminSocietyRoleReducer'
import refreshTokenReducer from './refreshTokenReducer'
import getBadgeCountReducer from './getBadgeCountReducer'
import logoutReducer from './logoutReducer'
import { LOGOUT_SUCCESS } from 'redux/types'
import NotificationPopups from '../../components/NotificationPopups/NotificationPopups'
import { LOGOUT_FAILED } from 'redux/types'
import { LOGOUT_NETWORKERROR } from 'redux/types'
import isRememberReducer from './isRememberTrueReducer'
import addUnitTypeReducer from './addUnitTypeReducer'
import updateUnitTypeReducer from './updateUnitTypeReducer'
import getAllUnitTypeReducer from './getAllUnitTypeReducer'
import addUnitSizeReducer from './addUnitSizeReducer'
import updateUnitSizeReducer from './updateUnitSizeReducer'
import getAllUnitSizeReducer from './getAllUnitSizeReducer'
import addSocietyInterestReducer from './addSocietyInterestReducer'
import updateSocietyInterestReducer from './updateSocietyInterestReducer'
import getSocietyInterestReducer from './getSocietyInterestReducer'
import deleteSocietyInterestReducer from './deleteSocietyInterestReducer'
import getAmenityListCalendarReducer from './getAmenityListCalendarReducer'

const appReducer = combineReducers({
  login: loginReducer,
  adminSignup: adminSignupReducer,
  validateSignupOTP: validateSignupOtpReducer,
  linkSociety: linkSocietyReducer,
  addSocietyDocs: addSocietyDocReducer,
  getSocietyDocs: getSocietyDocReducer,
  updateSocietyDocs: updateSocietyDocReducer,
  addSociety: addSocietyReducer,
  getSocietyList: getSocietyListReducer,
  addNotice: addNoticeReducer,
  getNoticeList: getNoticeListReducer,
  addBuilding: addBuildingReducer,
  getAreaSearchList: getAreaSearchList,
  updateBuilding: updateBuildingReducer,
  deleteBuilding: deleteBuildingReducer,
  addFlatList: addFlatListReducer,
  getFlatList: getFlatListReducer,
  getMemberList: getMemberListReducer,
  getPendingMemberList: getPendingMemberListReducer,
  addUserStatus: addUserStatusReducer,
  adminList: getAdminListReducer,
  addAdminStatus: addAdminStatusReducer,
  updateSocDocStatus: updateDocumentStatusReducer,
  getAmenityList: getAmenityListReducer,
  addAmenityStatus: addAmenityStatusReducer,
  getEmergencyNumber: getEmergencyNumberReducer,
  addEmergencyNumber: addEmergencyNumberReducer,
  complaintList: getComplaintListReducer,
  addComplainStatus: addComplaintStatusRedux,
  adddailyHelpType: addDailyHelpTypeReducer,
  buildingList: getBuildingListReducer,
  buildingNames: getBuildingNamesReducer,
  noticeTypes: getNoticeTypeReducer,
  addareaType: addAreaTypeReducer,
  addnoticetype: addNoticeTypeReducer,
  addcompanyType: addCompanyTypeReducer,
  addVehicleType: addVehicleTypeReducer,
  areaType: getAreaTypeReducer,
  dailyHelpType: getDailyHelpReducer,
  companyType: getCompanyTypeReducer,
  vehicleType: getVehicleTypeReducer,
  updateAreaType: updateAreaTypeReducer,
  updateNoticeType: updateNoticeTypeReducer,
  updateDailyHelpType: updateDailyHelpTypeReducer,
  updateCompanyType: updateCompanyTypeReducer,
  updateVehicleType: updateVehicleTypeReducer,
  deleteAreaType: deleteAreaTypeReducer,
  deleteNoticeType: deleteNoticeTypeReducer,
  deleteDailyHelpType: deleteDailyHelpTypeReducer,
  deleteCompanyType: deleteCompanyTypeReducer,
  deleteVehicleType: deleteVehicleTypeReducer,
  addCompany: addCompanyReducer,
  getCompany: getCompanyListReducer,
  updateCompany: updateCompanyReducer,
  deleteCompany: deleteCompanyReducer,
  addAmenityType: addAmenityTypeReducer,
  amenityType: getAmenityTypeReducer,
  updateAmenityType: updateAmenityTypeReducer,
  deleteAmenityType: deleteAmenityTypeReducer,
  resetOTP: resendSignupOTPReducer,
  pincode: pincodeReducer,
  deleteEmergencyNumber: deleteEmergencyNoReducer,
  updateEmergencyNumber: updateEmergencyNoReducer,
  updateNotice: updateNoticeReducer,
  deleteNotice: deleteNoticeReducer,
  addComplaintMessage: addComplaintMessageReducer,
  getComplaintMessage: getComplaintMessageReducer,
  addAmenityMessage: addAmenityMessageReducer,
  getAmenityMessage: getAmenityMessageReducer,
  addDocType: addDocTypeReducer,
  getDocType: getDocTypeReducer,
  updateDocType: updateDocTypeReducer,
  deleteDocType: deleteDocTypeReducer,
  getSmsProvider: getSmsProviderReducer,
  changeSmsProvider: changeSmsProviderReducer,
  addUnitDocType: addUnitDocTypeReducer,
  getUnitDoctype: getUnitDocTypeReducer,
  updateUnitDocType: updateUnitDocTypeReducer,
  deleteUnitDocType: deleteUnitDocTypeReducer,
  updateMemberDetail: updateMemberDetailReducer,
  addHelper: addHelperReducer,
  gethelperList: getHelperListReducer,
  getHelperDetails: getHelperDetailsReducer,
  changepassword: changePasswordReducer,
  addservicetype: addServiceTypeReducer,
  getservicetype: getServiceTypeReducer,
  addservicetypecategory: addServiceTypeCategoryReducer,
  addHelperType: addHelperTypeReducer,
  getHelperType: getHelperTypeReducer,
  updateServiceType: updateServiceTypeReducer,
  updateHelperType: updateHelperTypeReducer,
  addUnitFlatList: addUnitFlatListReducer,
  getUnitFlatList: getUnitFlatListReducer,
  updateAmenityStatus: updateAmenityStatusReducer,
  forgetPassword: forgetPasswordReducer,
  updateUnitFlatList: updateUnitFlatListReducer,
  addMandatoryDocument: addMandatoryDocReducer,
  updateProfile: updateProfileReducer,
  applicationMessage: applicationMessageReducer,
  updateSocietyDocumentStatus: updateSocietyDocumentStatusReducer,
  addGuardDetail: addGuardReducer,
  getGuardList: getGuardListReducer,
  updateMemberRole: updateMemberRoleReducer,
  updateUnitDocumentStatus: updateUnitDocumentStatusReducer,
  updateOwnerStatus: updateOwnerStatusReducer,
  getHelperDetail: getHelperDetailReducer,
  updateGuardDetail: updateGuardReducer,
  getHelperAttendance: getHelperAttendanceReducer,
  updateHelper: updateHelperReducer,
  getVisitorDetails: getVisitorDetailsReducer,
  getGuardAttendance: getGuardAttendanceReducer,
  getCityList: getCityListReducer,
  addDocument: addDocumentReducer,
  getDocument: getDocumentReducer,
  updateDocument: updateDocumentReducer,
  addHelperDoc: addHelperDocReducer,
  getHelperDoc: getHelperDocReducer,
  updateHelperDoc: updateHelperDocReducer,
  addGuardDoc: addGuardDocRedcuer,
  getGuardDoc: getGuardDocReducer,
  getSocietyAdmin: getSocietyAdminReducer,
  addFeature: addFeatureReducer,
  getFeature: getFeatureReducer,
  updateFeature: updateFeatureReducer,
  addSubCategory: addSubCategoryReducer,
  getSubCategory: getSubCategoryReducer,
  updateSubCategory: updateSubCategoryReducer,
  addFeatureType: addFeatureTypeReducer,
  getFeatureType: getFeatureTypeReducer,
  updateFeatureType: updateFeatureTypeReducer,
  getHelperDocMandatory: getHelperDocMandatoryReducer,
  addDashboard: addDashboardReducer,
  getDashboard: getDashboardReducer,
  updateDashboard: updateDashboardReducer,
  addHelperDocument: addHelperDocumentsReducer,
  addDashboardFeatureType: addDashboardFeatureTypeReducer,
  getVisitorDashBoards: getVisitorDashBoardReducer,
  addProductServiceCategory: addProductServiceCategoryReducer,
  getProductServiceCategory: getProductServiceCategoryReducer,
  addProductServiceSubCategory: addProductServiceSubCategoryReducer,
  getProductServiceSubCategory: getProductServiceSubCategoryReducer,
  updateSocietyDetails: updateSocietyDetailsReducer,
  getAmenityDashboardChartCount: getAmenityDashboardChartCountReducer,
  addProfessionType: addProfessionTypeReducer,
  getProfessionType: getProfessionTypeReducer,
  getComplainGraph: getComplainGraphReducer,
  addLanguage: addTypeLanguageReducer,
  getTypeLanguage: getTypeLanguageReducer,
  getAreaSearchList: getAreaSearchListReducer,
  getHelperDocument: getHelperDocumentReducer,
  getRecentActivity: getRecentActivityReducer,
  addMntType: addMntTypeReducer,
  getMntType: getMntTypeReducer,
  addSocietyMnt: addSocietyMntTypeReducer,
  getSocietyMnt: getSocietyMntTypeReducer,
  addMntInvoice: addMntInvoiceReducer,
  getMntInvoice: getMntInvoiceReducer,
  generateMntInvoice: generateMntInvoiceReducer,
  deleteMntInvoice: deleteMntInvoiceReducer,
  addInvoice: addInvoiceReducer,
  addParking: addParkingReducer,
  getParking: getParkingReducer,
  addSocietyTemplate,
  getSocietyTemplate,
  updateParkingSlot: updateParkingSlotReducer,
  singleMntInvoice: singleMntInvoiceReducer,
  getGateway: getGatewayReducer,
  addGateway: addGatewayReducer,
  getPaymentModes: getPaymentModesReducer,
  processPay: processPayReducer,
  deleteGateway: deleteGatewayReducer,
  deleteSocMntType: deleteSocMntTypeReducer,
  getTransactionDetails: getTransactionDetailsReducer,
  getTransactionList: getTransactionListReducer,
  getAllSecurityAlertList: getAllSecurityAlertListReducer,
  getGuestList: getGuestListReducer,
  exportExcelInvoice: exportExcelInvoiceReducer,
  updateAdminUser: updateAdminUserReducer,
  updateAdminSocietyRole: updateAdminSocietyRoleReducer,
  refreshToken: refreshTokenReducer,
  getBadgeCount: getBadgeCountReducer,
  logout: logoutReducer,
  isRemember: isRememberReducer,
  addUnitType: addUnitTypeReducer,
  updateUnitType: updateUnitTypeReducer,
  getAllUnitType: getAllUnitTypeReducer,
  addUnitSize: addUnitSizeReducer,
  updateUnitSize: updateUnitSizeReducer,
  getAllUnitSize: getAllUnitSizeReducer,
  addSocietyInterest: addSocietyInterestReducer,
  updateSocietyInterest: updateSocietyInterestReducer,
  getSocietyInterest: getSocietyInterestReducer,
  deleteSocietyInterest: deleteSocietyInterestReducer,
  getAmenityListCalendar: getAmenityListCalendarReducer
})

const rootReducer = (state, action) => {
  if (state && state.login) {

    if (action.type === LOGOUT_SUCCESS) {
      NotificationPopups('success', 'Success', "Logout Successfully")
      setTimeout(() => {
        sessionStorage.removeItem('persist:root')
        if (state.login.data.user) {
          state.login.data.user.role === "SuperAdmin" ? window.location.href = '/SuperAdmin/SignIN' : window.location.href = '/'
        }
        // if(state.login.success===false){
        //   window.location.href= '/'
        // }

        return appReducer(undefined, action)
      }, 500)
    }
    // if (state.login.data.user === "SuperAdmin") {
    //   console.log(state.login.data.user.role, "actionrfregj")
    // }


  }

  if (action.type === LOGOUT_FAILED) {
    NotificationPopups('error', 'Failed', "Logout Failed")
    return appReducer(state, action)
  }
  if (action.type === LOGOUT_NETWORKERROR) {
    NotificationPopups('error', 'Failed', "Logout Failed")
    return appReducer(state, action)
  }
  return appReducer(state, action)
}

export default rootReducer
