import axios from '../../config/axios'
import {
  GET_AMENITY_MESSAGE_SUCCESS,
  GET_AMENITY_MESSAGE_FAILED,
  LOADING_GET_AMENITY_MESSAGE,
  RESET_GET_AMENITY_MESSAGE,
  GET_AMENITY_MESSAGE_NETWORK_ERROR,
  ADD_AMENITY_MESSAGE_SUCCESS,
  ADD_AMENITY_MESSAGE_FAILED,
  LOADING_ADD_AMNEITY_MESSAGE,
  RESET_ADD_AMENITY_MESSAGE,
  ADD_AMENITY_MESSAGE_NETWORK_ERROR,
} from '../types'

const addAmenityMessage = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADD_AMNEITY_MESSAGE })
  // console.log('addAmenityMessage request = ', userData)
  axios
    .post('amenity/addAmenityRecord', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: ADD_AMENITY_MESSAGE_NETWORK_ERROR, payload: err })
    )
}

function resetAddAmenityMessage() {
  return {
    type: RESET_ADD_AMENITY_MESSAGE,
  }
}

function success(data) {
  return {
    type: ADD_AMENITY_MESSAGE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: ADD_AMENITY_MESSAGE_FAILED,
    payload: data,
  }
}

const getAmenityMessage = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_AMENITY_MESSAGE })
  axios
    .post('amenity/getAmenityRecord', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(successGetMessage(res.data))
          break
        default:
          dispatch(failedGetMessage(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GET_AMENITY_MESSAGE_NETWORK_ERROR, payload: err })
    )
}

function resetGetAmenityMessage() {
  return {
    type: RESET_GET_AMENITY_MESSAGE,
  }
}

function successGetMessage(data) {
  return {
    type: GET_AMENITY_MESSAGE_SUCCESS,
    payload: data,
  }
}

function failedGetMessage(data) {
  return {
    type: GET_AMENITY_MESSAGE_FAILED,
    payload: data,
  }
}

export {
  addAmenityMessage,
  getAmenityMessage,
  resetAddAmenityMessage,
  resetGetAmenityMessage,
}
