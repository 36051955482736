import React from 'react'
import { NavLink } from 'react-router-dom'

const ErrorPage = (props) => {
  console.log(props.location,"props")
  return (
    <div style={{textAlign:'center',marginTop:'50px'}}>
        <h1>Session Out</h1>
        <p>Sorry, This page doesn't exist</p>
        {/* <NavLink to='/'>Go Back</NavLink> */}
       <NavLink to={props.location.pathname === "/SessionOut" ? '/' : props.history.goBack}>You will be redirect to the Login page.</NavLink>
    </div>
  )
}

export default ErrorPage