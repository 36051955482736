import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Spin, Alert, Switch, Tooltip, Popconfirm, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import FileBase64 from 'components/Custom/UploadFile'
import {
  resetAddSubCategory,
  addSubCategory,
} from 'redux/actions/addSubCategoryAction'
import { getFeature } from 'redux/actions/getFeatureAction'
import Util from 'validations/index'
import axios from 'config/axios'

function AddSubCategory() {
  const [featureId, setFeatureId] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [redirect, setRedirect] = useState('')
  const [categoryPriority, setCategoryPriority] = useState('')
  const [categoryIcon, setCategoryIcon] = useState('')
  const [layoutStyle, setLayoutStyle] = useState('')
  const [layoutSize, setLayoutSize] = useState('')
  //options
  const [optionsFeatureCat, setOptionsFeatureCat] = useState([''])
  const [optionsSubCat, setOptionsSubCat] = useState([''])
  const [searchFeatureCat, setSearchFeatureCat] = useState('')
  const [searchSubCat, setSearchSubCat] = useState('')

  const login = useSelector(({ login }) => login)
  const addedSubCategory = useSelector(({ addSubCategory }) => addSubCategory)
  const getFeatureCat = useSelector(({ getFeature }) => getFeature)
  const dispatch = useDispatch()
  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'categoryName')
      Util.alphabetsOnly(value)
        ? setCategoryName(value)
        : setCategoryName(categoryName)
    if (name === 'redirect')
      Util.alphabetsOnly(value) ? setRedirect(value) : setRedirect(redirect)
  }
  const handleAttachments = (filesList) => {
    setCategoryIcon(filesList[0].base64)
  }
  //feature dropdown
  useEffect(() => {
    if (searchFeatureCat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        name: searchFeatureCat,
      }
      axios.post('dashboard/getFeatureCategoryList', userData).then((res) => {
        setOptionsFeatureCat(res.data.featureCategory)
      })
    }
  }, [searchFeatureCat])
  //sub cat
  useEffect(() => {
    if (searchSubCat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        name: setSearchSubCat,
      }
      axios.post('dashboard/getSubCategoryList', userData).then((res) => {
        setOptionsSubCat(res.data.subCategory)
      })
    }
  }, [searchSubCat])

  const { Option } = Select

  function onChangeSearch(value) {
    setFeatureId(value)
  }
  function onSearch(val) {
    setSearchFeatureCat(val)
  }

  const getFeatureRequest = () => {
    const getFeatureReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getFeature(getFeatureReq))
  }

  useEffect(() => {
    getFeatureRequest()
  }, [])

  //
  const addSubCategoryRequest = () => {
    const addSubCategoryReq = {
      initiatedBy: login.data.user.role,
      featureId,
      name: categoryName.trim(),
      redirect: redirect.trim(),
    }
    return dispatch(addSubCategory(addSubCategoryReq))
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    addSubCategoryRequest()
  }
  
  useEffect(() => {
    if (addedSubCategory.success) {
      setFeatureId('')
      setCategoryName('')
      setRedirect('')
      NotificationPopups('success', 'Success', addedSubCategory.data.message)
    }
    if (addedSubCategory.failed) {
      categoryName === "" || featureId === "" || redirect === "" ? 
      NotificationPopups('error', 'Failed', (addedSubCategory.data?.errorVOList.map((d,id)=><>{id === 0 ? 1:id+1}-{d.errorMessage}<br/></>)))
      :
      NotificationPopups('error', 'Failed', addedSubCategory.data.message)
    }
    if (addedSubCategory.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddSubCategory())
    }
  }, [
    addedSubCategory.success,
    addedSubCategory.failed,
    addedSubCategory.network,
  ])

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Enter Sub Category Name</Label>
              <Input
                type="text"
                placeholder="Sub Category Name"
                name="categoryName"
                id="serviceType"
                value={categoryName || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Select Feature Category</label>
              <Select
                type="select"
                showSearch
                placeholder="Select Feature Category"
                optionFilterProp="children"
                onChange={onChangeSearch}
                onSearch={onSearch}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Feature Category
                </Option>
                {searchFeatureCat != '' &&
                  optionsFeatureCat != undefined &&
                  optionsFeatureCat.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.name}
                    </Option>
                  ))}
                {searchFeatureCat === '' &&
                  getFeatureCat.success &&
                  getFeatureCat.data.featureCategory.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.name}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">App Screen Name</Label>
              <Input
                type="text"
                placeholder="App Screen Name"
                name="redirect"
                id="serviceType"
                value={redirect || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          {/* 
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Enter Priority</Label>
              <Input
                type="text"
                placeholder="Priority"
                name="categoryPriority"
                id="serviceType"
                value={categoryPriority || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Enter Layout Size</Label>
              <Input
                type="text"
                placeholder="Layout Size"
                name="categoryName"
                id="serviceType"
                value={layoutSize || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Enter Layout Style</Label>
              <Input
                type="text"
                placeholder="Layout Style"
                name="categoryName"
                id="serviceType"
                value={layoutStyle || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col> */}
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            // disabled={addedServiceType.isLoading || !serviceType}
            type="submit"
          >
            {/* {addedServiceType.isLoading ? <Spin /> : 'Add'} */}
            Add
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default AddSubCategory
