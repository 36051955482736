import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
  UncontrolledCollapse,
} from 'reactstrap'
import { Alert, Popconfirm, Tooltip, Spin, Select } from 'antd'
import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'
import {
  getAmenityList,
  resetgetAmenityList,
} from 'redux/actions/getAmenityListAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  addAmenityStatus,
  resetAddAmenityStatus,
} from 'redux/actions/addAmenityStatusAction'
import {
  addAmenityMessage,
  getAmenityMessage,
  resetAddAmenityMessage,
  resetGetAmenityMessage,
} from 'redux/actions/amenityMessageAction'
import PaginationComponent from 'react-reactstrap-pagination'

import RightIcon from 'assets/img/Icons/options/right.png'
import WrongIcon from 'assets/img/Icons/options/wrong.png'
import moment from 'moment'
import Send from '../../../../assets/img/send.png'

function AmenityPending() {
  const [message, setMessage] = useState({})
  const [limit] = useState(5)
  const [page, setPage] = useState(1)
  const [amenityId, setAmenityId] = useState('')
  const [viewDetails, setViewDetails] = useState(false)
  const [amenityModal, setAmenityModal] = useState(false)

  const amenityList = useSelector(({ getAmenityList }) => getAmenityList)
  console.log(amenityList, "amenityList")
  const addedAmenityStatus = useSelector(
    ({ addAmenityStatus }) => addAmenityStatus
  )
  const getAmenityMessageData = useSelector(
    ({ getAmenityMessage }) => getAmenityMessage
  )

  const addAmenityMessageData = useSelector(
    ({ addAmenityMessage }) => addAmenityMessage
  )

  const login = useSelector(({ login }) => login)
  const totalItems = amenityList.data.count
  const dispatch = useDispatch()

  const toggle = () => {
    setAmenityModal(!amenityModal)
  }

  const handleAmenityStatus = (id, status) => {
    // setAmenityModal(!amenityModal)
    const amenityStatusReq = {
      initiatedBy: login.data.user.role,
      amenityId: id,
      amenityStatus: status,
    }
    return dispatch(addAmenityStatus(amenityStatusReq))
  }
  const handlePagination = (selectedPage) => {
    setPage(selectedPage)
  }

  const getAmenityListRequest = (status) => {
    const getAmenityListReq = {
      initiatedBy: login.data.user.role,
      amenityStatus: 'pending',
      societyId: login.data.society.id,
      // page,
      // limit,
    }
    return dispatch(getAmenityList(getAmenityListReq))
  }
  useEffect(() => {
    getAmenityListRequest('pending')
  }, [])

  useEffect(() => {
    if (addedAmenityStatus.success) {
      getAmenityList('pending')
      NotificationPopups('success', 'Success', addedAmenityStatus.data.message)
    }
    if (addedAmenityStatus.failed) {
      NotificationPopups('error', 'Failed', addedAmenityStatus.data.message)
    }
    if (addedAmenityStatus.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddAmenityStatus())
    }
  }, [
    addedAmenityStatus.success,
    addedAmenityStatus.failed,
    addedAmenityStatus.network,
  ])

  const handleAmenityMessage = (evt, id) => {
    const {
      target: { value },
    } = evt
    if (Util.checkWhiteSpace(value)) {
      setMessage({
        ...message,
        id: id,
        content: value,
        // attachment,
      })
    }
    console.log('message in content = ', message)
  }

  const handleAddAmenityMessage = (id) => {
    const req = {
      initiatedBy: login.data.user.role,
      role: login.data.user.role,
      amenityId: id,
      adminId: login.data.user.id,
      content: message.id === id && message.content ? message.content : '',
      attachment: message.attachment ? message.attachment : '',
    }
    dispatch(addAmenityMessage(req))
  }
  const openAmenityChat = (id) => {
    setAmenityId(id)
    setViewDetails(!viewDetails)
  }

  const getAmenityMessageList = () => {
    // setAmenityModal(!amenityModal)
    const req = {
      amenityId,
      initiatedBy: 'Society',
    }
    dispatch(getAmenityMessage(req))
  }

  useEffect(() => {
    getAmenityMessageList()
    setMessage('')
  }, [amenityId, addAmenityMessageData.success])

  useEffect(() => {
    if (addedAmenityStatus.success) {
      getAmenityListRequest()
    }
  })

  useEffect(() => {
    if (addAmenityMessageData.success) {
      message[addAmenityMessageData.data.amenityId] = {
        content: '',
      }
      setMessage(message)
    }
    if (addAmenityMessageData.failed) {
      NotificationPopups('error', 'Failed', (addAmenityMessageData.data.errorVOList.map((item, id) => (<>{id === 0 ? 1 : id + 1}-{item.errorMessage}</>))))
    }
    return () => {
      dispatch(resetAddAmenityMessage())
    }
  }, [
    addAmenityMessageData.success,
    addAmenityMessageData.failed,
    addAmenityMessageData.network,
  ])

  return (
    <Table style={{ textAlign: 'center', fontSize: '12px' }}>
      <thead>
        <tr style={{ fontSize: '15px', fontWeight: 'bold' }}>
          <th>Booked By</th>
          {/* <th>Phone</th> */}
          <th>Amenity</th>
          <th>Date</th>
          <th>Time</th>
          <th>Action</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {amenityList.success &&
          amenityList.data.amenityDetailsList.map((d, id) => (
            <>
              <tr
                key={id}
                id={`toggler${id}`}
                onClick={() => openAmenityChat(d.id)}
                style={{ cursor: 'pointer' }}
              >
                <td>
                  {d.userName}
                  <br />
                  {d.areaName} / {d.unit}
                  <br />
                  {d.mobileNumber}
                </td>
                <td>{d.type}</td>
                <td>
                  {Util.dayMonthFormatDate(d.startDate)} -
                  {/* {moment(d.startDate).format('Do MMM,YYYY')}- */}
                  {Util.formatDate(d.endDate)}
                  {/* {moment(d.endDate).format('Do MMM,YYYY')} */}
                </td>
                <td>
                  {moment(d.startDate).format('h:mm:ss a')}-
                  {moment(d.endDate).format('h:mm:ss a')}
                </td>
                <td>
                  {' '}
                  <Popconfirm
                    title="Approve User ?"
                    onConfirm={() => handleAmenityStatus(d.id, 'approved')}
                    okText="Approve"
                    cancelText="Cancel"
                  >
                    <Tooltip
                      placement="leftBottom"
                      title="Approve"
                      color="#108ee9"
                    >
                      {/* <i className="fas fa-check right-menu"></i> */}
                      <img
                        className="amenity_option_icon"
                        src={RightIcon}
                        alt="RightIcon"
                      />
                    </Tooltip>
                  </Popconfirm>
                  {'|'}
                  <Popconfirm
                    title="Reject User ?"
                    onConfirm={() => handleAmenityStatus(d.id, 'rejected')}
                    okText="Reject"
                    cancelText="Cancel"
                  >
                    <Tooltip
                      placement="rightBottom"
                      title="Reject"
                      color="#108ee9"
                    >
                      {/* <i className="fas fa-times right-menu"></i> */}
                      <img
                        className="amenity_option_icon"
                        src={WrongIcon}
                        alt="WrongIcon"
                      />
                    </Tooltip>
                  </Popconfirm>
                </td>
              </tr>
              {viewDetails && amenityId == d.id && (
                <tr className="ml-detail-tr">
                  <td colSpan="6" className="ml-detail-td">
                    <div
                      style={{
                        padding: '20px',
                        textAlign: 'center',
                      }}
                    >
                      <label>Message</label>
                    </div>
                    <Row className="ml-detail-row sidebar-wrapper"
                      style={{
                        maxHeight: '250px',
                        overflow: 'auto',
                        margin: '0',
                      }}
                      id="scrollbar-sidebar"
                    >
                      {getAmenityMessageData.success &&
                        getAmenityMessageData.record[d.id] &&
                        getAmenityMessageData.record[d.id].map(
                          (data, index) => (
                            <>
                              <Col
                                xs="12"
                                sm="2"
                                md="2"
                                lg="2">
                              </Col>
                              <Col
                                xs="12"
                                sm="8"
                                md="8"
                                lg="8"
                                key={index}
                                style={{
                                  // display: 'flex',
                                  padding: '10px 5px',
                                  borderBottom: '1px solid #ebecf1',
                                }}
                              >
                                <div
                                  className="detail-val"
                                  style={{
                                    float:
                                      data.role === 'Society'
                                        ? 'right'
                                        : 'left',
                                  }}
                                >
                                  <div
                                    className={`custom-text-message-${data.role === 'Society' ? 'right' : 'left'}`}
                                  >
                                    {data.content && data.content}
                                  </div>
                                </div>
                                <div
                                  className="detail-time"
                                  style={{
                                    textAlign:
                                      data.role === 'Society'
                                        ? 'right'
                                        : 'left',
                                  }}
                                >
                                  {Util.formatDateTimeHour(data.timestamp)}
                                </div>
                              </Col>
                              <Col
                                xs="12"
                                sm="2"
                                md="2"
                                lg="2">
                              </Col>
                            </>
                          )
                        )}
                    </Row>
                    {d.amenityStatus === 'pending' && (
                      <>
                        <div className="detail-val">
                          <Row
                            style={{
                              justifyContent: 'center',
                            }}
                          >
                            <Col
                              xs="12"
                              sm="12"
                              md="6"
                              lg="6"
                              style={{ padding: '10px' }}
                            >
                              <FormGroup>
                                <Input
                                  type="textarea"
                                  row="1"
                                  name={message.id}
                                  value={
                                    message.id === d.id ? message.content : ''
                                  }
                                  id={d.amenityTypeId}
                                  placeholder="Reply"
                                  onChange={(e) =>
                                    handleAmenityMessage(e, d.id)
                                  }
                                  required
                                  style={{
                                    maxHeight: '80px',
                                    resize: 'none',
                                    border: 'none',
                                    borderBottom: '1px solid #E3E3E3',
                                    borderRadius: 0,
                                    lineHeight: 1
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col
                              xs="12"
                              sm="12"
                              md="1"
                              lg="1"
                              style={{ padding: '20px 0px' }}
                            >
                            
                              <img src={Send}
                                onClick={() => handleAddAmenityMessage(d.id)}
                                style={{ height: '25px', cursor: 'pointer' }}
                              ></img>
                            </Col>
                          </Row>
                        </div>
                        <div className="detail-val">
                          <span
                            className="right-menu"
                            onClick={() =>
                              handleAmenityStatus(d.id, 'approved')
                            }
                          >
                            Approve
                          </span>
                          {' | '}
                          <span
                            className="right-menu"
                            onClick={() =>
                              handleAmenityStatus(d.id, 'rejected')
                            }
                          >
                            Reject
                          </span>
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              )}
            </>
          ))}
      </tbody>
    </Table>
  )
}

export default AmenityPending
