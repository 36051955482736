import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  FormGroup,
  Table,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Tooltip, Popconfirm, Spin, Alert, Switch } from 'antd'
import randomColor from 'randomcolor'
import { resetgetAreaType, getAreaType } from 'redux/actions/getAreaTypeAction'
import {
  resetdeleteAreaType,
  deleteAreaType,
} from 'redux/actions/deleteAreaTypeAction'
import {
  resetupdateAreaType,
  updateAreaType,
} from 'redux/actions/updateAreaTypeAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import PaginationComponent from 'views/Pagination'
import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'

function AreaType() {
  const [modal, setModal] = useState(false)
  const [editareaType, seteditAreaType] = useState('')
  const [id, setId] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [isActive, setisActive] = useState()
  const [isResidential, setIsResidential] = useState('')
  const [limit] = useState(10)
  const [page, setPage] = useState(1)
  const [profilePictureStatus, setProfilePictureStatus] = useState(false)
  const [updatedPhoto, setUpdatedPhoto] = useState('')
  const [areaIcon, setAreaIcon] = useState('')

  const login = useSelector(({ login }) => login)
  const areaType = useSelector(({ areaType }) => areaType)
  const addedareaType = useSelector(({ addareaType }) => addareaType)
  const deletedAreaType = useSelector(({ deleteAreaType }) => deleteAreaType)
  const updatedAreaType = useSelector(({ updateAreaType }) => updateAreaType)
  const [initiatedBy] = useState(login.data.user.role)
  const dispatch = useDispatch()

  // const pageSize = areaType.data.pages
  const totalItems = areaType.data.count

  const getAreaTypeRequest = () => {
    if (initiatedBy === 'SuperAdmin') {
      const areaTypeReq = {
        initiatedBy: login.data.user.role,
        limit,
        page,
      }
      return dispatch(getAreaType(areaTypeReq))
    }
  }
  const handlePagination = (selectedPage) => {
    setPage(selectedPage)
  }
  useEffect(() => {
    if (initiatedBy === 'SuperAdmin') {
      const areaTypeReq = {
        initiatedBy,
        limit,
        page,
      }
      return dispatch(getAreaType(areaTypeReq))
    }
  }, [page])

  useEffect(() => {
    getAreaTypeRequest()
  }, [addedareaType.success])

  useEffect(() => {
    if (updatedAreaType.success) {
      seterrMessage('')
      setModal(!modal)
      getAreaTypeRequest()
      NotificationPopups('success', 'Success', updatedAreaType.data.message)
    }
    if (updatedAreaType.failed) {
      seterrMessage(updatedAreaType.data.message)
      //NotificationPopups('error', 'Failed', updatedAreaType.data.message)
    }
    if (updatedAreaType.network || deletedAreaType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    if (deletedAreaType.success) {
      NotificationPopups('success', 'Success', deletedAreaType.data.message)
      getAreaTypeRequest()
    }
    if (deletedAreaType.failed) {
      NotificationPopups('error', 'Failed', deletedAreaType.data.message)
    }

    return () => {
      dispatch(resetupdateAreaType())
      dispatch(resetdeleteAreaType())
    }
  }, [
    updatedAreaType.success,
    updatedAreaType.failed,
    updatedAreaType.network,
    deletedAreaType.success,
    deletedAreaType.failed,
    deletedAreaType.network,
  ])

  /*const handleDelete = (id) => {
    const deleteAreatypeReq = {
      id: id
    }
    //return dispatch(deleteAreaType(deleteAreatypeReq));
  }*/

  const openEditModal = (type) => {
    setProfilePictureStatus(false)
    setId(type.id)
    seteditAreaType(type.type)
    setisActive(type.isActive)
    setIsResidential(type.isResidential)
    setAreaIcon(type.iconUrl)
    setModal(!modal)
  }

  const toggle = () => {
    seterrMessage('')
    setModal(!modal)
  }

  const handleChange = ({ target: { name, value } }) => {
    console.log(value, "valuess")
    if (name === 'editareaType')
      Util.alphabetsOnly(value)
        ? seteditAreaType(value)
        : seteditAreaType(editareaType)
  }

  const onChange = (checked) => {
    setisActive(checked)
    console.log(`switch to ${checked}`)
  }
  const onChangeResidential = (checked) => {
    setIsResidential(checked)
  }

  const handleEditAreaType = () => {
    const editAreaTypeReq = {
      id: id,
      initiatedBy: 'SuperAdmin',
      type: editareaType.trim(),
      isActive,
      isResidential,
      iconUrl: profilePictureStatus ? areaIcon : ''
    }
    return dispatch(updateAreaType(editAreaTypeReq))
  }

  // Update Icon

  const handleAttachments = (filesList) => {
    setProfilePictureStatus(true)
    const icon = filesList.base64.split(`,`)
    if (icon[0].includes('application')) {
      NotificationPopups("error", 'Failed', "Wrong File format")
    }
    else {
      setAreaIcon(icon[1])
      setUpdatedPhoto(filesList.base64)
    }
  }

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Col>
                    <div className="typeHeading">Area List</div>
                  </Col>
                </div>

                <Col md="12" style={{ padding: '20px' }}>
                  {areaType.success && (
                    <Col md="12" style={{ padding: '20px' }}>
                      <Table responsive style={{ textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th>Icon</th>
                            <th>Name</th>
                            <th>Residential</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        {areaType.success && (
                          <tbody>
                            {areaType.data?.areaTypeList.map((d, id) => (
                              <React.Fragment key={id}>
                                <tr key={id} id={`toggler${id}`}>
                                  <td>
                                    <img
                                      src={`${Util.imageUrl}${d.iconUrl}`}
                                      alt="icon"
                                      style={{
                                        height: '40px',
                                        width: '40px',
                                      }}
                                    />
                                  </td>
                                  <td>{d.type}</td>
                                  <td>
                                    {d.isResidential
                                      ? 'Residential'
                                      : 'Not Residential'}
                                  </td>
                                  <td>{d.isActive ? 'Active' : 'Inactive '}</td>
                                  <td>
                                    <Button
                                      color="secondary"
                                      className="modalCustomButton"
                                      onClick={() => {
                                        openEditModal(d)
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        )}
                      </Table>
                    </Col>
                  )}
                </Col>
                <Col>
                  {areaType.success && (
                    <PaginationComponent
                      limit={limit}
                      totalItems={totalItems}
                      onSelect={handlePagination}
                      defaultPage={page}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {areaType.failed && (
                    <div style={{ textAlign: 'center', marginTop: '30px' }}>
                      <Alert message={areaType.data.message} type="info" />
                    </div>
                  )}
                </Col>
              </Row>
              {modal && (
                <Modal
                  isOpen={modal}
                  toggle={toggle}
                  centered
                  size="sm"
                  style={{ maxWidth: '250px', marginLeft: "auto", marginRight: "auto" }}
                >
                  <ModalHeader toggle={toggle}>Edit</ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <Label for="areaType">Area Type</Label>
                      <Input
                        type="text"
                        name="editareaType"
                        id="editareaType"
                        value={editareaType}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Col xs="12" sm="12" md="12">
                        <label>Icon</label>
                        <div style={{ cursor: 'pointer', textAlign: 'center' }}>
                          {
                            <img
                              src={
                                profilePictureStatus
                                  ? `${updatedPhoto}`
                                  : `${Util.imageUrl}${areaIcon}`
                              }
                              style={{
                                width: '150px',
                                height: '150px',
                              }}
                            />
                          }
                        </div>{' '}
                        <div style={{ marginTop: '10px' }}>
                          <FileBase64
                            multiple={false}
                            id="editNoticeFiles"
                            accept=".jpeg, .png, .jpg , .svg"
                            onDone={handleAttachments}
                          />
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label>Residential</Label>
                      <br />
                      <Switch
                        defaultChecked={isResidential}
                        name="isActive"
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={onChangeResidential}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Status</Label>
                      <br />
                      <Switch
                        defaultChecked={isActive}
                        name="isActive"
                        checkedChildren="Active"
                        unCheckedChildren="Inactive"
                        onChange={onChange}
                      />
                    </FormGroup>
                    <div style={{ textAlign: 'center' }} className="errorText">
                      {errMessage}
                    </div>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={handleEditAreaType}
                      disabled={updatedAreaType.isLoading || !editareaType}
                    >
                      {updatedAreaType.isLoading ? <Spin /> : 'Edit'}
                    </Button>
                    <Button
                      color="secondary"
                      style={{ float: 'right' }}
                      className="modalCustomButton"
                      onClick={toggle}
                    >
                      Close
                    </Button>
                  </ModalBody>
                </Modal>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AreaType
