import axios from "../../config/axios"
import {
    GETCOMPLAINTLIST_SUCCESS,
    GETCOMPLAINTLIST_FAILED,
    LOADING_GETCOMPLAINTLIST,
    RESET_GETCOMPLAINTLIST,
    GETCOMPLAINTLIST_NETWORKERROR
} from "../types";

const resetgetComplaintList = () => {
    return {
        type: RESET_GETCOMPLAINTLIST
    };
};

const getComplaintList = (userData, isRead = false) => (dispatch, getState) => {
    let pendingCount;
    let count;
    dispatch({ type: LOADING_GETCOMPLAINTLIST });
    console.log(userData, isRead, count, "ieRead");
    axios
        .post("complain/getComplainList", userData)
        .then(res => {
            switch (res.data.statusCode) {
                case 200:
                    isRead ? count = getState().complaintList.data?.count : count = getState().complaintList.oldValue;
                    userData?.complainStatus === 'pending' ? pendingCount = res.data?.count : pendingCount = getState().complaintList.pendingCount;
                    dispatch(success(res.data, count, pendingCount));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: GETCOMPLAINTLIST_NETWORKERROR, payload: err }));

};

function success(data, oldValue, pendingCount) {
    return {
        type: GETCOMPLAINTLIST_SUCCESS,
        payload: data,
        oldValue,
        pendingCount
    };
}

function failed(data) {
    return {
        type: GETCOMPLAINTLIST_FAILED,
        payload: data
    };
}

export { resetgetComplaintList, getComplaintList };