import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
//import FileBase64 from 'react-file-base64';
import { Spin, Select } from 'antd'
import FileBase64 from '../../components/Custom/UploadFile'
import { resetAddCompany, addCompany } from 'redux/actions/addCompanyAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetgetCompanyType,
  getComapnyType,
} from 'redux/actions/getCompanyTypeAction'
import {
  resetgetServiceType,
  getServiceType,
} from '../../redux/actions/getServiceTypeAction'
import {
  resetgetHelperType,
  getHelperType,
} from '../../redux/actions/getHelperTypeAction'
import axios from 'config/axios'
import Util from '../../validations/index'

function AddCompany() {
  const [profilePictureStatus, setprofilePictureStatus] = useState(false)
  const [updatedPhoto, setUpdatedPhoto] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyType, setCompanyType] = useState('')
  const [companyLogo, setCompanyLogo] = useState('')
  const [vendorsId, setVendorsId] = useState('')
  const [serviceType, setServiceType] = useState('')
  const [servTypeId, setServTypeId] = useState('')
  const [searchHelperType, setSearchHelperType] = useState('')
  const [type, setType] = useState([''])
  const [optionsHelperType, setOptionsHelperType] = useState([''])

  const helperTypeList = useSelector(({ getHelperType }) => getHelperType)
  const addedCompany = useSelector(({ addCompany }) => addCompany)
  const servType = useSelector(({ getservicetype }) => getservicetype)
  const login = useSelector(({ login }) => login)

  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'companyName')
      Util.alphabetsOnly(value)
        ? setCompanyName(value)
        : setCompanyName(companyName)
    if (name === 'companyType')
      Util.alphaNumeric(value)
        ? setCompanyType(value)
        : setCompanyType(companyType)
    if (name === 'servTypeId')
      Util.verifyMobile(value)
        ? setServTypeId(value)
        : setServTypeId(servTypeId)
  }
  const { Option } = Select

  function onChangeSearchServType(value) {
    // console.log(`selected ${value}`)
    setServTypeId(value)
    setCompanyType('')
  }

  function onSearchServType(val) {
    setServTypeId(val)
  }

  function onChangeSearchHelperType(value) {
    // console.log(`selected ${value}`)
    setCompanyType(value)
    setSearchHelperType('')
  }

  function onSearchHelperType(val) {
    setSearchHelperType(val)
  }
  // console.log('companyType', companyType)
  // console.log(
  //   servType.success &&
  //     servType.data.activeServiceType.map((d) => (d.isVisitor ? d.type : ''))
  // )

  const getServiceTypeRequest = () => {
    const getServiceTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getServiceType(getServiceTypeReq))
  }

  useEffect(() => {
    if (companyLogo != '') {
      NotificationPopups('success', 'Success', 'Image Uploaded')
    }
  }, [companyLogo])

  useEffect(() => {
    getServiceTypeRequest()
  }, [])

  //search helper type
  useEffect(() => {
    if (searchHelperType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        serviceTypeId: servTypeId,
        type: searchHelperType,
      }
      // console.log(userData)
      axios.post('helper/getHelperType', userData).then((res) => {
        if (res.status === 200 && res.data.helperTypeList != '') {
          setOptionsHelperType(res.data.helperTypeList)
        } else {
          setOptionsHelperType([''])
        }
      })
    }
  }, [searchHelperType, servTypeId])

  useEffect(() => {
    // if (login.data.user.role != 'Society') {
    if (servType.success && servType.data.activeServiceType) {
      const serviceTypeId = servType.data.activeServiceType
      const helperType = serviceTypeId.filter((d) => d.isVisitor === true)
      setType(helperType)
    }
    // }
  }, [servType.success])

  useEffect(() => {
    if (servTypeId != '' && login.data.user.role != 'Society') {
      const getHelperTypeReq = {
        initiatedBy: login.data.user.role,
        serviceTypeId: servTypeId,
      }
      return dispatch(getHelperType(getHelperTypeReq))
    }
  }, [servTypeId])

  useEffect(() => {
    if (addedCompany.success) {
      setCompanyName('')
      setCompanyType('')
      setServTypeId('')
      setCompanyLogo('')
      NotificationPopups('success', 'Success', addedCompany.data.message)
    }

    if (addedCompany.failed) {
      NotificationPopups('error', 'Failed', addedCompany.data.message)
    }
    if (addedCompany.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }

    return () => {
      dispatch(resetAddCompany())
    }
  }, [addedCompany.success, addedCompany.failed, addedCompany.network])

  const addCompanyRequest = () => {
    const addCompanyReq = {
      initiatedBy: login.data.user.role,
      name: companyName.trim(),
      companyTypeId: companyType,
      companyLogo: profilePictureStatus ? companyLogo : '',
    }

    // console.log(addCompanyReq)
    return dispatch(addCompany(addCompanyReq))
  }

  const handleAttachments = (filesList) => {
    setprofilePictureStatus(true)
    const icon = filesList.base64.split(`,`)
    if (icon[0].includes('application')) {
      NotificationPopups("error", "Failed", "Wrong File Formate")
    }
    else {
      setCompanyLogo(icon[1])
      setUpdatedPhoto(filesList.base64)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addCompanyRequest()
  }

  const disabledButton = () => {
    return !(companyName && companyType && companyLogo)
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="3" sm="12">
            <FormGroup>
              <Label for="companyName">Company Name</Label>
              <Input
                type="text"
                name="companyName"
                id="companyName"
                placeholder="Company Name"
                value={companyName || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="12">
            <FormGroup>
              <Label for="companyType">Service Type</Label>
              <Select
                type="select"
                showSearch
                value={servTypeId}
                placeholder="Select Service Type"
                optionFilterProp="children"
                onChange={onChangeSearchServType}
                onSearch={onSearchServType}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Service Type
                </Option>
                {servType.success &&
                  type.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.type}
                    </Option>
                  ))}

                {servType.failed && (
                  <Option className="loginSelect" value="">
                    No Data Found
                  </Option>
                )}
              </Select>
            </FormGroup>
          </Col>
          <Col md="3" sm="12">
            <FormGroup>
              <Label for="companyType">Type</Label>
              <Select
                type="select"
                showSearch
                value={companyType}
                placeholder="Select Company Type"
                optionFilterProp="children"
                onChange={onChangeSearchHelperType}
                onSearch={onSearchHelperType}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Company Type
                </Option>
                {searchHelperType != '' && optionsHelperType != undefined
                  ? optionsHelperType.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.type}
                    </Option>
                  ))
                  : helperTypeList.success &&
                  helperTypeList.data.helperTypeList.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.type}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="3" sm="12">
            <FormGroup>
              <Label>Company logo</Label>
              <FileBase64
                multiple={false}
                accept=".jpeg,.png,.jpg"
                id="addCompanyFile"
                onDone={handleAttachments}
              />
            </FormGroup>
            {companyLogo && (
              <img
                src={updatedPhoto}
                width="50%"
                height="150"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            )}
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            disabled={disabledButton() || addedCompany.isLoading}
          >
            {addedCompany.isLoading ? <Spin /> : 'Add'}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default AddCompany
