// reduxMiddleware.js
import axios from 'config/axios'
import { isExpiredJWTToken } from 'config/helperFunctions';
import { refreshAccessToken } from 'config/helperFunctions';
import { REFRESH_AUTH_TOKEN_COMPLETED, LOGOUT_SUCCESS } from 'redux/types';
let retry;
let axiosRequest = [];

function returnAxiosRequest(axiosRequest, store, leftSide, type) {
    return axios(axiosRequest)
        .then(response => {
            switch (response.data.statusCode) {
                case 200:
                    // Dispatch a success action with the response
                    store.dispatch({
                        type: `${leftSide}SUCCESS`,
                        payload: response.data,
                    });
                    break
                default:
                    store.dispatch({
                        type: `${leftSide}FAILED`,
                        payload: response.data,
                    });
                    break
            }
        })
        .catch(error =>
            store.dispatch({ type, payload: error })
        );
}

const apiMiddleware = store => next => action => {
    let exisitingToken = sessionStorage.getItem('authToken') || 'undefined'
    if (action.type === REFRESH_AUTH_TOKEN_COMPLETED) {
        axiosRequest.forEach((fn) => {
            if (exisitingToken) {
                fn[0].headers.Authorization = `Bearer ${exisitingToken}`
            }
            return returnAxiosRequest(fn[0], store, fn[1].leftSide, fn[1].type)
        })
        axiosRequest = []
    }
    else elseWare(next, action)
    function elseWare(next, action) {
        let keyword = "NETWORK";
        if (action.type && action.type.includes(keyword)) {
            console.log("inside network", action)
            const { payload, type } = action;
            const { config, response } = payload
            if (response) {
                if (response.status === 401 || response.status === 403) {
                    console.log("inside 401 or 403")
                    let splitResult = type.split(keyword);
                    let leftSide = splitResult[0];
                    axiosRequest.push([config, { leftSide: leftSide, type }]);
                    if (retry === undefined) {
                        retry = true
                        refreshAccessToken(exisitingToken, store).then((data) => {
                            if (data) {
                                sessionStorage.setItem('authToken', data)
                                exisitingToken = data
                                retry = undefined
                                store.dispatch({ type: REFRESH_AUTH_TOKEN_COMPLETED })
                            }
                        })
                    }
                }
            }
        }
        else {
            return next(action)
        }

    };
}

export default apiMiddleware;
