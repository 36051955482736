import { Badge } from 'reactstrap'
import axios from '../../config/axios'
import {
  GET_BADGE_COUNT_SUCCESS,
  GET_BADGE_COUNT_FAILED,
  LOADING_GET_BADGE_COUNT,
  RESET_GET_BADGE_COUNT,
  GET_BADGE_COUNT_NETWORKERROR,
} from '../types'

const resetGetBadgeCount = () => {
  return {
    type: RESET_GET_BADGE_COUNT,
  }
}

const getBadgeCount = (userData) => (dispatch, getState) => {
  const { value } = getState().getBadgeCount.data;
  dispatch({ type: LOADING_GET_BADGE_COUNT })
  axios
    .post('amenity/badgeCounter', userData)
    .then((res) => {
      // console.log(res, "Badge")
      switch (res.status) {
        case 200:
          dispatch(success(res.data, value))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GET_BADGE_COUNT_NETWORKERROR, payload: err })
    )
}

function success(data, oldValue) {
  // console.log(oldValue,"oldValue")
  return {
    type: GET_BADGE_COUNT_SUCCESS,
    oldValue,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_BADGE_COUNT_FAILED,
    payload: data,
  }
}

export { resetGetBadgeCount, getBadgeCount }
