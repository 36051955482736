import moment from 'moment';
import React, { useState } from 'react'

const ParkingSlots = () => {

    const [currentDate, setCurrentDate] = useState(moment());

    const prevDay = () => {
      setCurrentDate(currentDate.clone().subtract(1, 'days'));
    };
  
    const nextDay = () => {
      setCurrentDate(currentDate.clone().add(1, 'days'));
    };
  return (
    <div>
        {/* <div className="calendar-container">
        <div className="nav">
          <button onClick={prevDay} className="arrow">&lt;</button>
          <div className="dates">
            {[-1, 0, 1, 2, 3, 4, 5].map((offset) => {
              const day = currentDate.clone().add(offset, 'days');
              return (
                <div
                  key={offset}
                  className={`day ${offset === 0 ? 'selected' : ''}`}
                >
                  <div className="day-name">{day.format('ddd')}</div>
                  <div className="day-number">{day.format('DD')}</div>
                  <div className="month-name">{day.format('MMM')}</div>
                </div>
              );
            })}
          </div>
          <button onClick={nextDay} className="arrow">&gt;</button>
        </div>
      </div> */}
      hello
    </div>
  )
}

export default ParkingSlots