import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
//import FileBase64 from 'react-file-base64';
import { Spin, Select, Switch } from 'antd'
import FileBase64 from 'components/Custom/UploadFile'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import {
  resetgetServiceType,
  getServiceType,
} from 'redux/actions/getServiceTypeAction'
import { resetgetAreaType, getAreaType } from 'redux/actions/getAreaTypeAction'
import { resetaddHelper, addHelper } from 'redux/actions/addHelperAction'
import {
  resetgetHelperType,
  getHelperType,
} from 'redux/actions/getHelperTypeAction'
import { resetGetDocument, getDocument } from 'redux/actions/getDocumentAction'
import {
  resetaddHelperDoc,
  addHelperDoc,
} from 'redux/actions/addHelperDocAction'
import {
  resetUpdateHelperDoc,
  updateHelperDoc,
} from 'redux/actions/updateHelperDocAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

import Util from 'validations/index'
import axios from 'config/axios'

function UpdateHelperDoc({ id, data }) {
  console.log(data, 'id')
  const [initiatedBy, setInitiatedBy] = useState('Society')
  const [documentType, setDocumentType] = useState(data.documentTypeId)
  const [helperType, setHelperType] = useState()
  const [serviceTypeId, setServiceTypeId] = useState('')
  const [isActive, setIsActive] = useState(data.active)
  const [isMandatory, setIsMandatory] = useState(data.mandatory)
  const [seacrhDocumentType, setSearchDocumentType] = useState('')
  const [searchServType, setSearchServType] = useState('')
  const [searchHelperType, setSearchHelperType] = useState('')
  const [optionsDocumentType, setOptionsDocumentType] = useState('')
  const [optionsServType, setOptionsServType] = useState([''])
  const [optionsHelperType, setOptionsHelperType] = useState([''])
  const login = useSelector(({ login }) => login)
  const docType = useSelector(({ getDocument }) => getDocument)
  const servType = useSelector(({ getservicetype }) => getservicetype)
  const helperTypeList = useSelector(({ getHelperType }) => getHelperType)
  const addedHelperDoc = useSelector(({ addHelperDoc }) => addHelperDoc)
  const updatedHelperDoc = useSelector(({ updateHelperDoc }) => updateHelperDoc)
  console.log(updatedHelperDoc,"updatedHelperDoc")

  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'serviceTypeId') setServiceTypeId(value)
    if (name === 'helperType') setHelperType(value)
  }

  const onChange = (checked) => {
    setIsActive(checked)
  }
  const onChangeMandatory = (checked) => {
    setIsMandatory(checked)
  }

  const { Option } = Select
  function onChangeDocumentType(value) {
    setDocumentType(value)
  }
  function onSearchDocumentType(value) {
    setSearchDocumentType(value)
  }

  function onChangeSearchServType(value) {
    setServiceTypeId(value)
    setHelperType('')
    setSearchServType('')
  }

  function onSearchServType(val) {
    setSearchServType(val)
  }

  function onChangeSearchHelperType(value) {
    if (value === '') {
      setHelperType('')
    } else {
      setHelperType(value)
      setSearchHelperType('')
    }
  }

  function onSearchHelperType(val) {
    setSearchHelperType(val)
  }
  //search of area type

  //search of flat

  //search service type
  useEffect(() => {
    if (searchServType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        type: searchServType,
      }
      axios.post('helper/getServiceType', userData).then((res) => {
        if (res.status === 200 && res.data.activeType != '') {
          setOptionsServType(res.data.activeType)
        }
      })
    }
  }, [searchServType])

  const getDocTypeRequest = () => {
    if (initiatedBy != 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy,
      }
      return dispatch(getDocument(docTypeReq))
    }
  }

  useEffect(() => {
    getDocTypeRequest()
  }, [])
  //search helper type
  useEffect(() => {
    if (searchHelperType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        serviceTypeId,
        type: searchHelperType,
      }
      axios.post('helper/getHelperType', userData).then((res) => {
        if (res.status === 200 && res.data.activeType != '') {
          setOptionsHelperType(res.data.activeType)
        }
      })
    }
  }, [searchHelperType, serviceTypeId])

  useEffect(() => {
    const getServiceTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getServiceType(getServiceTypeReq))
  }, [])

  useEffect(() => {
    if (login.data.user.role === 'Society' && serviceTypeId != '') {
      setHelperType('')
      const getHelperTypeReq = {
        initiatedBy: login.data.user.role,
        serviceTypeId,
      }
      console.log(getHelperTypeReq)
      return dispatch(getHelperType(getHelperTypeReq))
    }
  }, [serviceTypeId])

  const handleSubmit = (e) => {
    e.preventDefault()
    updateHelperDocRequest()
  }
  const updateHelperDocRequest = () => {
    console.log(id)
    const updateHelperDocReq = {
      id,
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      serviceTypeId: data.serviceTypeId,
      helperTypeId: data.helperTypeId,
      documentTypeId: documentType,
      isMandatory,
      isActive,
    }
    return dispatch(updateHelperDoc(updateHelperDocReq))
  }

  const disabledButton = () => {
    return !(serviceTypeId && helperType)
  }

  useEffect(() => {
    if (addedHelperDoc.success) {
      setServiceTypeId('')
      setDocumentType('')
      setHelperType('')
      NotificationPopups('success', 'Success', addedHelperDoc.data.message)
    }
    if (addedHelperDoc.failed) {
      NotificationPopups('error', 'Failed', addedHelperDoc.data.message)
    }
    if (addedHelperDoc.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetaddHelperDoc())
    }
  }, [addedHelperDoc.success, addedHelperDoc.failed, addedHelperDoc.network])

  useEffect(() =>{
    if(updatedHelperDoc.failed){
      NotificationPopups('error', 'Failed', (updatedHelperDoc.data.errorVOList.map((ele,id) =>(<>{id === 0 ? 1 : id+1}-{ele.errorMessage}<br/></>))))
    }
    if(updatedHelperDoc.network){
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
  },[updatedHelperDoc.success,updatedHelperDoc.failed,updatedHelperDoc.network])

  // console.log(servType)
  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="12" sm="12" xs="12" lg="12">
            <Row>
              <Col md="12" sm="12" xs="12" lg="12">
                <FormGroup>
                  <Label for="helperType">Document Type</Label>
                  <Select
                    type="select"
                    value={documentType}
                    showSearch
                    placeholder="Select Service Type"
                    optionFilterProp="children"
                    onChange={onChangeDocumentType}
                    onSearch={onSearchDocumentType}
                    style={{ width: '100%' }}
                  >
                    <Option className="loginSelect" value="">
                      Select Document Type
                    </Option>

                    {/* {searchServType != '' && optionsServType != undefined
                      ? optionsServType.map((d, id) => (
                          <Option key={id} className="loginSelect" value={d.id}>
                            {d.type}
                          </Option>
                        ))
                      : servType.success &&
                        servType.data.activeType.map((d, id) => (
                          <Option key={id} className="loginSelect" value={d.id}>
                            {d.type}
                          </Option>
                        ))} */}
                    {docType.success &&
                      docType.data.activeType.map((d, id) => (
                        <Option key={id} className="loginSelect" value={d.id}>
                          {d.type}
                        </Option>
                      ))}
                    {docType.failed && (
                      <Option className="loginSelect" value="" disabled>
                        No Data Found
                      </Option>
                    )}
                  </Select>
                </FormGroup>
              </Col>
              <Col md="4" sm="12" xs="12" lg="6">
                <FormGroup>
                  <Label>Mandatory </Label>
                  <br />
                  <Switch
                    defaultChecked={isMandatory}
                    name="isActive"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={onChangeMandatory}
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="12" xs="12" lg="6">
                <FormGroup>
                  <Label>Status</Label>
                  <br />
                  <Switch
                    defaultChecked={isActive}
                    name="isActive"
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            // disabled={disabledButton()}
          >
            {updatedHelperDoc.isLoading ? <Spin /> : 'Update Document'}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default UpdateHelperDoc
