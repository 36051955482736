import React, { useState, useEffect, useRef, memo, use } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from 'reactstrap'
//import FileBase64 from 'react-file-base64';
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import FileBase64 from '../../components/Custom/UploadFile'
import { Spin } from 'antd'
//ant design components
import { Upload, message, Select } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import { addNotice, resetAddNotice } from 'redux/actions/addNoticeAction'
import {
  getNoticeTypes,
  resetGetNoticeTypes,
} from 'redux/actions/getNoticeTypeAction'
import axios from 'config/axios'
import moment from 'moment'

import NoticeBoardIcon from 'assets/img/Icons/Sidebar/noticeboard.png'
import {
  getNoticeList,
  resetgetNoticeList,
} from 'redux/actions/getNoticeListAction'

const { Dragger } = Upload

// const initialState = {
// 	startDate: new Date(),
// 	endDate: new Date(),
// 	title: "",
// 	content: "",
// 	type: ""
// }

function SearchNotice(props) {
  //const [states, setStates] = useState(initialState);

  //const [ societyId, setSocietyId] = useState("");
  const [attachmentsStatus, setattachmentsStatus] = useState(false)
  const [noticeTitle, setNoticeTitle] = useState('')
  const [noticeContent, setNoticeContent] = useState('')
  const [attachment, setAttachment] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [noticeType, setNoticecType] = useState('')
  const [searchNoticeType, setSearchNoticeType] = useState('')
  const [options, setOptions] = useState([])

  const login = useSelector(({ login }) => login)
  const addNewNotice = useSelector(({ addNotice }) => addNotice)
  const noticeTypes = useSelector(({ noticeTypes }) => noticeTypes)
  const noticeList = useSelector(({ getNoticeList }) => getNoticeList)
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'noticeTitle') setNoticeTitle(value)
    if (name === 'noticeContent') setNoticeContent(value)
    if (name === 'startDate') setStartDate(value)

    if (name === 'endDate') setEndDate(value)
    if (name === 'noticeType') setNoticecType(value)
  }
  const { Option } = Select

  function onChangeSearch(value) {
    setNoticecType(value)
  }

  function onSearch(val) {
    setSearchNoticeType(val)
  }

  const handleAttachments = (filesList) => {
    setattachmentsStatus(true)
    setAttachment(filesList[0].base64)
    if (attachment != '') {
      NotificationPopups('success', 'Success', 'Image Uploaded')
    }
  }

  const handleSubmit = (e) => {
    props.setIsSearch()
    e.preventDefault()
    getNoticeListRequest()
    const obj = {
      noticeType: noticeType,
      startDate: startDate,
      EndDate: endDate,
    }
    send(obj)
    return x()
  }

  function x() {
    setNoticecType('')
    setStartDate('')
  }

  const getNoticeTypeRequest = () => {
    const noticeypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getNoticeTypes(noticeypeReq))
  }

  const getNoticeListRequest = () => {
    const getNocListReq = {
      societyId: login.data.society.id,
      initiatedBy: login.data.user.role,
      limit: 9,
      noticeType,
      startDate,
    }

    return dispatch(getNoticeList(getNocListReq))
  }
  useEffect(() => {
    if (searchNoticeType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
      }
      axios.post('notice/getNoticeType', userData).then((res) => {
        // console.log(res.data.activeNoticeType, 'res.data.activeType')
        setOptions(res.data.activeNoticeType)
      })
    }
  }, [searchNoticeType])

  useEffect(() => {
    getNoticeTypeRequest()
  }, [])

  const disabledBtn = () => {
    return !(noticeTitle && noticeContent && noticeType)
  }

  const send = (value) => {
    props.searchFilter(value)
  }
  return (
    <>
      <PanelHeader size="sm" />
      <div className="listcontent">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  {/* <i className={'now-ui-icons ui-1_calendar-60'} /> */}
                  <img
                    src={NoticeBoardIcon}
                    alt="NoticeBoardIcon"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <span className="head-title">Notice Board</span>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" sm="3" md="3">
                    <FormGroup>
                      <label>Start Date</label>{' '}
                      <span className="sub-label">(optional)</span>
                      <Input
                        name="startDate"
                        value={startDate || ''}
                        type="date"
                        onChange={handleChange}
                      // min={moment().format('YYYY-MM-DD')}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="3" md="3">
                    <FormGroup>
                      <label>Type</label>

                      <Select
                        type="select"
                        showSearch
                        value={noticeType}
                        placeholder="Select Notice Type"
                        optionFilterProp="children"
                        onChange={onChangeSearch}
                        onSearch={onSearch}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="">
                          Select Notice Type
                        </Option>
                        {noticeTypes.success &&
                          noticeTypes.data.activeNoticeType.map((d, id) => (
                            <Option
                              key={id}
                              className="loginSelect"
                              value={d.type}
                            >
                              {d.type}
                            </Option>
                          ))}
                      </Select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    style={{ textAlign: 'center', margin: '20px' }}
                  >
                    <Button
                      className="cust-button"
                      onClick={handleSubmit}
                    // disabled={addNewNotice.isLoading || disabledBtn()}
                    >
                      {noticeList.isLoading ? <Spin /> : 'Search'}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default SearchNotice