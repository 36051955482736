import axios from '../../config/axios'
import {
  UPDATE_ADMIN_SOCIETY_ROLE_SUCCESS,
  UPDATE_ADMIN_SOCIETY_ROLE_FAILED,
  LOADING_UPDATE_ADMIN_SOCIETY_ROLE,
  RESET_UPDATE_ADMIN_SOCIETY_ROLE,
  UPDATE_ADMIN_SOCIETY_ROLE_NETWORK_ERROR,
} from '../types'

const resetUpdateAdminSocietyRole = () => {
  return {
    type: RESET_UPDATE_ADMIN_SOCIETY_ROLE
  }
}

const UpdateAdminSocietyRole = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UPDATE_ADMIN_SOCIETY_ROLE })
  axios
    .post('user/updateAdminSocietyRole', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: UPDATE_ADMIN_SOCIETY_ROLE_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: UPDATE_ADMIN_SOCIETY_ROLE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: UPDATE_ADMIN_SOCIETY_ROLE_FAILED,
    payload: data,
  }
}

export { resetUpdateAdminSocietyRole, UpdateAdminSocietyRole }
