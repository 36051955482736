import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// react plugin used to create charts
import { Line, Bar } from 'react-chartjs-2'
import { connect } from 'react-redux'
import UploadDocument from 'views/Documents/UploadDocument'
import { withRouter } from 'react-router'
import { NavLink, Link } from 'react-router-dom'

import BarGraph from 'assets/img/Icons/GraphIcon/bargraph_blue.png'
import LineGraph from 'assets/img/Icons/GraphIcon/linegraph_blue.png'
import TableGraph from 'assets/img/Icons/GraphIcon/tablegraph_blue.png'

import BarGraph_White from 'assets/img/Icons/GraphIcon/bargraph_white.png'
import LineGraph_White from 'assets/img/Icons/GraphIcon/linegraph_white.png'
import TableGraph_White from 'assets/img/Icons/GraphIcon/tablegraph_white.png'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Button,
  Label,
  FormGroup,
  Input,
  UncontrolledTooltip,
  UncontrolledCollapse,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import moment from 'moment'

import {
  dashboardPanelChart,
  dashboardShippedProductsChart,
  dashboardAllProductsChart,
  dashboard24HoursPerformanceChart,
} from 'variables/charts.js'
// import Mumbai from '../../assets/img/mumbai.png'
import Login from 'views/Login'
import {
  resetgetSocietyDoc,
  getSocietyDoc,
} from 'redux/actions/getSocietyDocAction'
import VerifyUser from 'views/Documents/VerifyUser'
import LinkSociety from 'views/Documents/LinkSociety'
//members list
import { Alert, Popconfirm, Tooltip, Spin } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

import MembersPendingList from './Members'
import AmenityPending from './Amenity'
import Complaints from './Complaints/index'
import GetVisitorDashboard from './Visitors'
import GetVisitorLineChartDashboard from './VisitorLineChart'
import VisitorTable from './VisitorTableChart'
import RecentVisitors from './Visitors/RecentVisitors'
import {
  getVisitorDashBoard,
  resetGetDashboard,
} from 'redux/actions/getVisitorDashboardAction'
import {
  resetgetAmenityDashboardChartCount,
  getAmenityDashboardChartCount,
} from 'redux/actions/getAmenityDashboardChartCountAction'
import {
  resetGetComplainGraph,
  getComplainGraph,
} from 'redux/actions/getComplainGraphAction'
// import {
//   resetGetRecentActivity, getRecentActivity,
// } from 'redux/actions/getRecentActivityAction'

//Icons
import Visitor from 'assets/img/Icons/Sidebar/visitor.png'
import Members from 'assets/img/Icons/Sidebar/member.png'
import ComplaintsIcons from 'assets/img/Icons/Sidebar/complain.png'

import AmenityBarChart from './Amenity/AmenityBarChart/AmenityBarChart'
import AmenityLineChart from './Amenity/AmenityLineChart/AmenityLineChart'
import GetAmenityTableChartDashboard from './Amenity/AmenityTable/AmenityTable'

import GetComplainBarDashboard from './Complaints/ComplainBarChart/index'
import GetComplainLineChartDashboard from './Complaints/ComplainLineChart/index'
import GetComplainTableChartDashboard from './Complaints/ComplainTableChart/index'

function Dashboard() {
  const [openDoc, setopenDoc] = useState(false)
  const [counterValue, setCounterValue] = useState([])

  const [chartBtn, setChartBtn] = useState('barchart')
  const [dataCounterBtn, setDataCounterBtn] = useState('weekly')

  //Amenity Chart
  const [counterValueAmenity, setCounterValueAmenity] = useState([])
  const [chartBtnAmenity, setChartBtnAmenity] = useState('barchart')
  const [dataCounterBtnAmenity, setDataCounterBtnAmenity] = useState('weekly')

  // Complain Chart
  const [counterValueComplain, setCounterValueComplain] = useState([])
  const [chartBtnComplain, setChartBtnComplain] = useState('barchart')
  const [dataCounterBtnComplain, setDataCounterBtnComplain] = useState('weekly')

  const [userStatus, setUserStatus] = useState(false)
  const [verifyOtp, setVerifyOtp] = useState(false)
  const [linkSociety, setLinkSociety] = useState(false)
  const login = useSelector(({ login }) => login)
  const [initiatedBy] = useState('Society')
  // const getSocietyDocs = useSelector(({ getSocietyDocs }) => getSocietyDocs)
  const dispatch = useDispatch()
  console.log(Object.keys(login.data.society).length != 0)

  const amenityList = useSelector(({ getAmenityList }) => getAmenityList)
  const complaintList = useSelector(({ complaintList }) => complaintList)
  const visitorDetail = useSelector(
    ({ getVisitorDetails }) => getVisitorDetails
  )
  const getVisitorDashBoards = useSelector(
    ({ getVisitorDashBoards }) => getVisitorDashBoards
  )
  const amenityChart = useSelector(
    ({ getAmenityDashboardChartCount }) => getAmenityDashboardChartCount
  )
  const complainChart = useSelector(({ getComplainGraph }) => getComplainGraph)

  // console.log(getVisitorDashBoards);
  useEffect(() => {
    getVisitorDashboards()
    getAmenityDashboardChartCountRequest()
    getComplainDashboardChartCountRequest()
  }, [])

  console.log(complainChart, 'complainChart')

  useEffect(() => {
    if (
      login.data.adminStatus &&
      (login.data.adminStatus === 'document' ||
        login.data.adminStatus === 'pending' ||
        login.data.adminStatus === 'rejected') &&
      login.data.user.role === 'Society'
    ) {
      setopenDoc(true)
      // console.log(login.data.adminStatus)
    }
    if (login.data.user.userStatus) {
      setUserStatus(login.data.user.userStatus)
    }
    if (
      login.data.user.role === 'Society' &&
      login.data.user.userStatus === 'created'
    ) {
      setVerifyOtp(true)
    }
    if (login.success && login.isAvailable != true && login.isSociety != true) {
      sessionStorage.removeItem('persist:root')
      window.location.href = '/'
    }
  }, [login.success])

  //get items

  useEffect(() => {
    const value = JSON.parse(sessionStorage.getItem('login'))
    console.log(value, 'value')
  }, [])

  const getVisitorDashboards = () => {
    if (initiatedBy != 'SuperAdmin') {
      const visitorDashboard = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        fromDate: moment(new Date()).format('YYYY-MM-DD'),
      }
      return dispatch(getVisitorDashBoard(visitorDashboard))
    }
  }

  //Amenity
  const getAmenityDashboardChartCountRequest = () => {
    const getAmenityDashboardChartCountReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      fromDate: moment(new Date()).format('YYYY-MM-DD'),
      amenityStatus: 'pending',
      // page:
    }
    return dispatch(
      getAmenityDashboardChartCount(getAmenityDashboardChartCountReq)
    )
  }

  const getComplainDashboardChartCountRequest = () => {
    const getComplainDashboardChartCountReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      fromDate: moment(new Date()).format('YYYY-MM-DD'),
      complainStatus: 'pending',
    }
    return dispatch(getComplainGraph(getComplainDashboardChartCountReq))
  }

  useEffect(() => {
    if (getVisitorDashBoards.success) {
      setCounterValue(getVisitorDashBoards.data.weeklyCount)
    }
  }, [getVisitorDashBoards.success])

  useEffect(() => {
    if ((amenityChart.success, amenityChart.failed)) {
      setCounterValueAmenity(amenityChart.data.amenityWeeklyCount)
    }
  }, [amenityChart.success, amenityChart.failed])

  useEffect(() => {
    if (complainChart.success) {
      setCounterValueComplain(complainChart.data.complainWeeklyCount)
    }
  }, [complainChart.success])

  const barChartBtn = () => {
    setChartBtn('barchart')
  }

  const lineChartBtn = () => {
    setChartBtn('linechart')
  }

  const tableChartBtn = () => {
    setChartBtn('tablechart')
  }

  const weeklyChartDataBtn = () => {
    setDataCounterBtn('weekly')
    setCounterValue(getVisitorDashBoards.data.weeklyCount)
    // setCounterValue(obj.weeklyCount)
  }

  const monthlyChartDataBtn = () => {
    setDataCounterBtn('monthly')
    setCounterValue(getVisitorDashBoards.data.monthlyCount)
    //  setCounterValue(obj.monthlyCount)
  }

  const yearlyChartDataBtn = () => {
    setDataCounterBtn('yearly')
    setCounterValue(getVisitorDashBoards.data.yearlyCount)
    //setCounterValue(obj.yearlyCount)
  }

  // Complain Chart
  const barChartBtnAmenity = () => {
    setChartBtnAmenity('barchart')
  }

  const lineChartBtnAmenity = () => {
    setChartBtnAmenity('linechart')
  }

  const TableChartBtnAmenity = () => {
    setChartBtnAmenity('tablechart')
  }

  const weeklyChartDataBtnAmenity = () => {
    setDataCounterBtnAmenity('weekly')
    setCounterValueAmenity(amenityChart.data.amenityWeeklyCount)
    // setCounterValue(obj.weeklyCount)
  }

  const monthlyChartDataBtnAmenity = () => {
    setDataCounterBtnAmenity('monthly')
    setCounterValueAmenity(amenityChart.data.amenityMonthlyCount)
    //  setCounterValue(obj.monthlyCount)
  }

  const yearlyChartDataBtnAmenity = () => {
    setDataCounterBtnAmenity('yearly')
    setCounterValueAmenity(amenityChart.data.amenityYearlyCount)
    //setCounterValue(obj.yearlyCount)
  }

  // Complain Chart
  const barChartBtnComplain = () => {
    setChartBtnComplain('barchart')
  }

  const lineChartBtnComplain = () => {
    setChartBtnComplain('linechart')
  }

  const TableChartBtnComplain = () => {
    setChartBtnComplain('tablechart')
  }

  const weeklyChartDataBtnComplain = () => {
    setDataCounterBtnComplain('weekly')
    setCounterValueComplain(complainChart.data.complainWeeklyCount)
    // setCounterValue(obj.weeklyCount)
  }

  const monthlyChartDataBtnComplain = () => {
    setDataCounterBtnComplain('monthly')
    setCounterValueComplain(complainChart.data.complainMonthlyCount)
    //  setCounterValue(obj.monthlyCount)
  }

  const yearlyChartDataBtnComplain = () => {
    setDataCounterBtnComplain('yearly')
    setCounterValueComplain(complainChart.data.complainYearlyCount)
    //setCounterValue(obj.yearlyCount)
  }

  return (
    <>
      <PanelHeader size="lg" />
      <div className="content">
        {/* Visitors */}
        <Row>
          <Col xs={12} sm={12} md={8}>
            <Card
              style={{
                height: '600px',
                overflowY: 'auto',
              }}
            >
              <CardHeader style={{ marginTop: '15px' }}>
                <div className="head-icon">
                  <img
                    src={Visitor}
                    alt="Visitor"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <NavLink to="/Society/Visitor" className='nav-links'>
                  <span className="head-title">Visitor Details</span>
                </NavLink>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12" sm="12">
                    <Row>
                      <Col md="6" sm="6">
                        <div
                          className="btn-group chart-btn-grup"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className={`btn btn-outline-info ${chartBtn === 'barchart' ? `active` : ``
                              }`}
                            onClick={barChartBtn}
                          >
                            {chartBtn === 'barchart' ? (
                              <img
                                src={BarGraph_White}
                                alt="Bar"
                                style={{ width: '16px' }}
                              />
                            ) : (
                              <img
                                src={BarGraph}
                                alt="Bar"
                                style={{ width: '16px' }}
                              />
                            )}
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-info ${chartBtn === 'linechart' ? `active` : ``
                              }`}
                            onClick={lineChartBtn}
                          >
                            {chartBtn === 'linechart' ? (
                              <img
                                src={LineGraph_White}
                                alt="Line"
                                style={{ width: '16px' }}
                              />
                            ) : (
                              <img
                                src={LineGraph}
                                alt="line"
                                style={{ width: '16px' }}
                              />
                            )}
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-info ${chartBtn === 'tablechart' ? `active` : ``
                              }`}
                            onClick={tableChartBtn}
                          >
                            {chartBtn === 'tablechart' ? (
                              <img
                                src={TableGraph_White}
                                alt="Line"
                                style={{ width: '16px' }}
                              />
                            ) : (
                              <img
                                src={TableGraph}
                                alt="line"
                                style={{ width: '16px' }}
                              />
                            )}
                          </button>
                        </div>
                      </Col>
                      <Col md="6" sm="6">
                        <div
                          className="btn-group graph__btn"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className={`btn btn-outline-info ${dataCounterBtn === 'weekly' ? `active` : ``
                              }`}
                            onClick={weeklyChartDataBtn}
                          >
                            Week
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-info ${dataCounterBtn === 'monthly' ? `active` : ``
                              }`}
                            onClick={monthlyChartDataBtn}
                          >
                            Month
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-info ${dataCounterBtn === 'yearly' ? `active` : ``
                              }`}
                            onClick={yearlyChartDataBtn}
                          >
                            Year
                          </button>
                        </div>
                      </Col>
                    </Row>

                    {chartBtn == 'barchart' ? (
                      <GetVisitorDashboard
                        counterValue={counterValue}
                        chartBtn={chartBtn}
                        dataCounterBtn={dataCounterBtn}
                      />
                    ) : chartBtn == 'linechart' ? (
                      <GetVisitorLineChartDashboard
                        counterValue={counterValue}
                        chartBtn={chartBtn}
                        dataCounterBtn={dataCounterBtn}
                      />
                    ) : (
                      <VisitorTable
                        counterValue={counterValue}
                        chartBtn={chartBtn}
                        dataCounterBtn={dataCounterBtn}
                      />
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xs={12} sm={12} md={4}>
            <Card style={{ height: '600px', overflowY: 'auto' }}>
              <CardHeader style={{ marginTop: '15px' }}>
                <div className="head-icon">
                  <img
                    src={Visitor}
                    alt="Visitor"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <NavLink to="/Society/Visitor" className='nav-links'>
                  <span className="head-title">Recent Visitor</span>
                </NavLink>
              </CardHeader>
              {/* </NavLink> */}

              <CardBody>
                <RecentVisitors />
                {/* {visitorDetail.failed && (
                  <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    <Alert message="No Visitors Found" type="info" />
                  </div>
                )} */}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          {/* Members */}
          <Col xs={12} md={12}>
            <Card style={{overflow: 'auto'}}>
              <CardHeader style={{ marginTop: '15px' }}>
                <div className="head-icon">
                  <img
                    src={Members}
                    alt="Visitor"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <div>
                  <NavLink to="/Society/members" className="nav-links">
                    <span className="head-title">Members</span>
                  </NavLink>
                </div>
              </CardHeader>
              <CardBody class="sidebar-wrapper"
                id='scrollbar-sidebar' style={{ maxHeight: '450px', overflowY: 'auto' }}>
                <MembersPendingList />
              </CardBody>
            </Card>
          </Col>

          {/* Amenity Table */}
          <Col xs={12} md={12}>
            <Card style={{ overflow: 'auto' }}>
              <CardHeader style={{ marginTop: '18px', marginBottom: '20px' }}>
                <div className="head-icon">
                  <i className={'now-ui-icons shopping_shop'} />
                </div>
                <div>
                  <NavLink to="/Society/amenity-booking" className="nav-links">
                    <span className="head-title" >Amenity Booking Request</span>
                  </NavLink>
                </div>
              </CardHeader>
              <CardBody
                class="sidebar-wrapper"
                id='scrollbar-sidebar'
                //  className='AmenityBooking'
                style={{ overflow: 'auto', maxHeight: '400px' }}
                autofocus>
                <AmenityPending />

                {amenityList.failed && (
                  <div style={{ textAlign: 'center', margin: '10px' }}>
                    <Alert message={amenityList.data.message} type="info" />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
          {/* Amenity Graph start */}
          <Col xs={12} sm={12} md={12}>
            <Card style={{ height: '600', overflowY: 'auto' }}>
              <CardHeader style={{ marginTop: '15px' }}>
                <div className="head-icon">
                  <i className={'now-ui-icons shopping_shop'} />
                </div>
                <div>
                  <NavLink to="/Society/amenity-booking" className="nav-links">
                    <span className="head-title">Amenity Details</span>
                  </NavLink>
                </div>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col md="12" sm="12">
                    <Row>
                      <Col md="6" sm="6">
                        <div
                          className="btn-group chart-btn-grup"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className={`btn btn-outline-info ${chartBtnAmenity === 'barchart' ? `active` : ``
                              }`}
                            onClick={barChartBtnAmenity}
                          >
                            {chartBtnAmenity === 'barchart' ? (
                              <img
                                src={BarGraph_White}
                                alt="Bar"
                                style={{ width: '16px' }}
                              />
                            ) : (
                              <img
                                src={BarGraph}
                                alt="Bar"
                                style={{ width: '16px' }}
                              />
                            )}
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-info ${chartBtnAmenity === 'linechart' ? `active` : ``
                              }`}
                            onClick={lineChartBtnAmenity}
                          >
                            {chartBtnAmenity === 'linechart' ? (
                              <img
                                src={LineGraph_White}
                                alt="Line"
                                style={{ width: '16px' }}
                              />
                            ) : (
                              <img
                                src={LineGraph}
                                alt="line"
                                style={{ width: '16px' }}
                              />
                            )}
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-info ${chartBtnAmenity === 'tablechart' ? `active` : ``
                              }`}
                            onClick={TableChartBtnAmenity}
                          >
                            {chartBtnAmenity === 'tablechart' ? (
                              <img
                                src={TableGraph_White}
                                alt="Line"
                                style={{ width: '16px' }}
                              />
                            ) : (
                              <img
                                src={TableGraph}
                                alt="line"
                                style={{ width: '16px' }}
                              />
                            )}
                          </button>
                        </div>
                      </Col>
                      <Col md="6" sm="6">
                        <div
                          className="btn-group graph__btn"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className={`btn btn-outline-info ${dataCounterBtnAmenity === 'weekly' ? `active` : ``
                              }`}
                            onClick={weeklyChartDataBtnAmenity}
                          >
                            Week
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-info ${dataCounterBtnAmenity === 'monthly'
                              ? `active`
                              : ``
                              }`}
                            onClick={monthlyChartDataBtnAmenity}
                          >
                            Month
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-info ${dataCounterBtnAmenity === 'yearly' ? `active` : ``
                              }`}
                            onClick={yearlyChartDataBtnAmenity}
                          >
                            Year
                          </button>
                        </div>
                      </Col>
                    </Row>

                    {chartBtnAmenity == 'barchart' ? (
                      <AmenityBarChart
                        counterValue={counterValueAmenity}
                        chartBtn={chartBtnAmenity}
                        dataCounterBtn={dataCounterBtnAmenity}
                      />
                    ) : chartBtnAmenity == 'linechart' ? (
                      <AmenityLineChart
                        counterValue={counterValueAmenity}
                        chartBtn={chartBtnAmenity}
                        dataCounterBtn={dataCounterBtnAmenity}
                      />
                    ) : (
                      <GetAmenityTableChartDashboard
                        counterValue={counterValueAmenity}
                        chartBtn={chartBtnAmenity}
                        dataCounterBtn={dataCounterBtnAmenity}
                      />
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {/* Amenity Graph end */}
        </Row>

        {/* <Col xs={12} sm={12} md={8}>
          <Card style={{ height: '600', overflowY: 'auto' }}>
            <NavLink to="/Society/amenity-booking" className="nav-links">
              <CardHeader style={{ marginTop: '15px' }}>
                <div className="head-icon">
                  <i className={'now-ui-icons shopping_shop'} />
                </div>
                <div>
                  <span className="head-title">Amenity</span>
                </div>
              </CardHeader>
            </NavLink>

            <CardBody>
              <Row>
                <Col md="12" sm="12">
                  <Row>
                    <Col md="6" sm="6">
                      <div
                        className="btn-group chart-btn-grup"
                        role="group"
                        aria-label="Basic example"
                      >
                        <button
                          type="button"
                          className={`btn btn-outline-info ${
                            chartBtnAmenity === 'barchart' ? `active` : ``
                          }`}
                          onClick={barChartBtnAmenity}
                        >
                          {chartBtnAmenity === 'barchart' ? (
                            <img
                              src={BarGraph_White}
                              alt="Bar"
                              style={{ width: '16px' }}
                            />
                          ) : (
                            <img
                              src={BarGraph}
                              alt="Bar"
                              style={{ width: '16px' }}
                            />
                          )}
                        </button>
                        <button
                          type="button"
                          className={`btn btn-outline-info ${
                            chartBtnAmenity === 'linechart' ? `active` : ``
                          }`}
                          onClick={lineChartBtnAmenity}
                        >
                          {chartBtnAmenity === 'linechart' ? (
                            <img
                              src={LineGraph_White}
                              alt="Line"
                              style={{ width: '16px' }}
                            />
                          ) : (
                            <img
                              src={LineGraph}
                              alt="line"
                              style={{ width: '16px' }}
                            />
                          )}
                        </button>
                        <button
                          type="button"
                          className={`btn btn-outline-info ${
                            chartBtnAmenity === 'tablechart' ? `active` : ``
                          }`}
                          onClick={TableChartBtnAmenity}
                        >
                          {chartBtnAmenity === 'tablechart' ? (
                            <img
                              src={TableGraph_White}
                              alt="Line"
                              style={{ width: '16px' }}
                            />
                          ) : (
                            <img
                              src={TableGraph}
                              alt="line"
                              style={{ width: '16px' }}
                            />
                          )}
                        </button>
                      </div>
                    </Col>
                    <Col md="6" sm="6">
                      <div
                        className="btn-group graph__btn"
                        role="group"
                        aria-label="Basic example"
                      >
                        <button
                          type="button"
                          className={`btn btn-outline-info ${
                            dataCounterBtnAmenity === 'weekly' ? `active` : ``
                          }`}
                          onClick={weeklyChartDataBtnAmenity}
                        >
                          Week
                        </button>
                        <button
                          type="button"
                          className={`btn btn-outline-info ${
                            dataCounterBtnAmenity === 'monthly' ? `active` : ``
                          }`}
                          onClick={monthlyChartDataBtnAmenity}
                        >
                          Month
                        </button>
                        <button
                          type="button"
                          className={`btn btn-outline-info ${
                            dataCounterBtnAmenity === 'yearly' ? `active` : ``
                          }`}
                          onClick={yearlyChartDataBtnAmenity}
                        >
                          Year
                        </button>
                      </div>
                    </Col>
                  </Row>

                  {chartBtnAmenity == 'barchart' ? (
                    <AmenityBarChart
                      counterValue={counterValueAmenity}
                      chartBtnComplain={chartBtnAmenity}
                      dataCounterBtn={dataCounterBtnAmenity}
                    />
                  ) : chartBtnAmenity == 'linechart' ? (
                    <AmenityLineChart
                      counterValue={counterValueAmenity}
                      chartBtnComplain={chartBtnAmenity}
                      dataCounterBtn={dataCounterBtnAmenity}
                    />
                  ) : (
                    <GetAmenityTableChartDashboard
                      counterValue={counterValueAmenity}
                      chartBtnComplain={chartBtnAmenity}
                      dataCounterBtn={dataCounterBtnAmenity}
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col> */}

        {/* Complain Graph and Booking Request */}
        <Row>
          <Col xs={12} sm={12} md={12}>
            <Card style={{ height: '600', overflowY: 'auto' }}>
              <CardHeader style={{ marginTop: '15px' }}>
                <div className="head-icon">
                  <img
                    src={ComplaintsIcons}
                    alt="complains"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <div>
                  <NavLink to="/Society/complaints" className="nav-links">
                    <span className="head-title">Complain Details</span>
                  </NavLink>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12" sm="12">
                    <Row>
                      <Col md="6" sm="6">
                        <div
                          className="btn-group chart-btn-grup"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className={`btn btn-outline-info ${chartBtnComplain === 'barchart' ? `active` : ``
                              }`}
                            onClick={barChartBtnComplain}
                          >
                            {chartBtnComplain === 'barchart' ? (
                              <img
                                src={BarGraph_White}
                                alt="Bar"
                                style={{ width: '16px' }}
                              />
                            ) : (
                              <img
                                src={BarGraph}
                                alt="Bar"
                                style={{ width: '16px' }}
                              />
                            )}
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-info ${chartBtnComplain === 'linechart' ? `active` : ``
                              }`}
                            onClick={lineChartBtnComplain}
                          >
                            {chartBtnComplain === 'linechart' ? (
                              <img
                                src={LineGraph_White}
                                alt="Line"
                                style={{ width: '16px' }}
                              />
                            ) : (
                              <img
                                src={LineGraph}
                                alt="line"
                                style={{ width: '16px' }}
                              />
                            )}
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-info ${chartBtnComplain === 'tablechart' ? `active` : ``
                              }`}
                            onClick={TableChartBtnComplain}
                          >
                            {chartBtnComplain === 'tablechart' ? (
                              <img
                                src={TableGraph_White}
                                alt="Line"
                                style={{ width: '16px' }}
                              />
                            ) : (
                              <img
                                src={TableGraph}
                                alt="line"
                                style={{ width: '16px' }}
                              />
                            )}
                          </button>
                        </div>
                      </Col>
                      <Col md="6" sm="6">
                        <div
                          className="btn-group graph__btn"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className={`btn btn-outline-info ${dataCounterBtnComplain === 'weekly'
                              ? `active`
                              : ``
                              }`}
                            onClick={weeklyChartDataBtnComplain}
                          >
                            Week
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-info ${dataCounterBtnComplain === 'monthly'
                              ? `active`
                              : ``
                              }`}
                            onClick={monthlyChartDataBtnComplain}
                          >
                            Month
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-info ${dataCounterBtnComplain === 'yearly'
                              ? `active`
                              : ``
                              }`}
                            onClick={yearlyChartDataBtnComplain}
                          >
                            Year
                          </button>
                        </div>
                      </Col>
                    </Row>

                    {chartBtnComplain == 'barchart' ? (
                      <GetComplainBarDashboard
                        counterValue={counterValueComplain}
                        chartBtnComplain={chartBtnComplain}
                        dataCounterBtn={dataCounterBtnComplain}
                      />
                    ) : chartBtnComplain == 'linechart' ? (
                      <GetComplainLineChartDashboard
                        counterValue={counterValueComplain}
                        chartBtnComplain={chartBtnComplain}
                        dataCounterBtn={dataCounterBtnComplain}
                      />
                    ) : (
                      <GetComplainTableChartDashboard
                        counterValue={counterValueComplain}
                        chartBtnComplain={chartBtnComplain}
                        dataCounterBtn={dataCounterBtnComplain}
                      />
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          {/* complaints  */}
          <Col xs={12} md={12}>
            <Card style={{ height: '600', overflowY: 'auto' }}>

              <CardHeader style={{ marginTop: '15px' }}>
                <div className="head-icon">
                  <img
                    src={ComplaintsIcons}
                    alt="Complains"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <div>
                  <NavLink to="/Society/complaints" className="nav-links">
                    <span className="head-title">Complain Raised</span>
                  </NavLink>
                </div>
              </CardHeader>

              <CardBody
                class="sidebar-wrapper"
                id='scrollbar-sidebar'
                style={{ overflow: 'auto', maxHeight: '400px' }}
                autofocus
              >
                <Complaints />
                {complaintList.failed && (
                  <div style={{ textAlign: 'center', margin: '10px' }}>
                    <Alert message={complaintList.data.message} type="info" />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {openDoc && <UploadDocument />}
    </>
  )
}

export default Dashboard
