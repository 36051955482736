import axios from "../../config/axios"
import {
	UPDATE_UNIT_TYPE_SUCCESS,
	UPDATE_UNIT_TYPE_FAILED,
	LOADING_UPDATE_UNIT_TYPE,
	RESET_UPDATE_UNIT_TYPE,
	UPDATE_UNIT_TYPE_NETWORKERROR
} from "../types";

const resetUpdateUnitType = () => {
    return {
        type: RESET_UPDATE_UNIT_TYPE
    };
};

const updateUnitType = userData => dispatch => {
    dispatch({ type: LOADING_UPDATE_UNIT_TYPE });
    axios
        .post("society/updateUnitType", userData)
        .then(res => {
            console.log(res,"resssss")
            switch (res.data.statusCode) {
               
                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: UPDATE_UNIT_TYPE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: UPDATE_UNIT_TYPE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: UPDATE_UNIT_TYPE_FAILED,
        payload: data
    };
}

export { resetUpdateUnitType, updateUnitType } ;