import axios from "../../config/axios"
import {
	GET_ALL_UNIT_TYPE_SUCCESS,
	GET_ALL_UNIT_TYPE_FAILED,
	LOADING_GET_ALL_UNIT_TYPE,
	RESET_GET_ALL_UNIT_TYPE,
	GET_ALL_UNIT_TYPE_NETWORKERROR
} from "../types";

const resetGetAllUnitType = () => {
    return {
        type: RESET_GET_ALL_UNIT_TYPE
    };
};

const getAllUnitType = userData => dispatch => {
    dispatch({ type: LOADING_GET_ALL_UNIT_TYPE });
    axios
        .post("society/getAllUnitType", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: GET_ALL_UNIT_TYPE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: GET_ALL_UNIT_TYPE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: GET_ALL_UNIT_TYPE_FAILED,
        payload: data
    };
}

export { getAllUnitType, resetGetAllUnitType } ;