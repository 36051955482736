import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Tooltip, Popconfirm, Alert } from 'antd'
import { Spin, Switch, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import FileBase64 from 'components/Custom/UploadFile'
import {
  addProductServiceSubCategory,
  resetAddProductServiceSubCategory,
} from 'redux/actions/addProductServiceSubCategoryAction'
import Util from 'validations/index'

function AddProductServiceSubCategory() {
  const [type, setType] = useState('')
  const [names, setName] = useState('')
  const [featureIcon, setFeatureIcon] = useState('')
  const [updatedPhoto, setUpdatedPhoto] = useState('')
  const [productCategoryId, setProductCategoryId] = useState('')
  const [productCategoryName, setProductCategoryName] = useState('')
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const productServiceCat = useSelector(
    ({ getProductServiceCategory }) => getProductServiceCategory
  )
  const addedProductServCat = useSelector(
    ({ addProductServiceSubCategory }) => addProductServiceSubCategory
  )

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'name')
      Util.alphabetsOnly(value) ? setName(value) : setName(names)
  }
  const labelOptionsDropDown = [
    {
      id: 1,
      name: 'Product',
    },
    {
      id: 2,
      name: 'Service',
    },
  ]

  const handleAttachments = (filesList) => {
    const icon = filesList[0].base64.split(`,`)
    if (icon[0].includes('application')) {
      NotificationPopups('error', "Failed", "Wrong File Formate")
    }
    else {
      setFeatureIcon(icon[1])
      setUpdatedPhoto(filesList[0].base64)
    }
  }

  const { Option } = Select
  function onChangeFeature(value) {
    setType(value)
  }

  function onSearchFeature(val) {
    setType(val)
  }
  function onChangeProductCat(value) {
    const val = value.split(',')
    setProductCategoryId(val[0])
    setProductCategoryName(val[1])
  }

  function onSearchProductCat(val) {
    setProductCategoryName(val[1])
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    addProductServiceCategoryRequest()
  }

  const addProductServiceCategoryRequest = () => {
    const addProductServiceCategoryReq = {
      initiatedBy: login.data.user.role,
      type,
      name: names.trim(),
      iconUrl: featureIcon,
      productCategoryId,
      action: 'add',
    }
    return dispatch(addProductServiceSubCategory(addProductServiceCategoryReq))
  }
  const disableBtn = () => {
    return !(type, names, featureIcon, productCategoryId)
  }

  useEffect(() => {
    if (addedProductServCat.success) {
      setFeatureIcon('')
      setName('')
      setType('')
      NotificationPopups('success', 'Success', addedProductServCat.data.message)
    }
    if (addedProductServCat.failed) {
      NotificationPopups('error', 'Failed', addedProductServCat.data.message)
    }
    if (addedProductServCat.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddProductServiceSubCategory())
    }
  }, [
    addedProductServCat.success,
    addedProductServCat.failed,
    addedProductServCat.network,
  ])

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="4" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Enter Name</Label>
              <span className='required'>*</span>
              <Input
                type="text"
                placeholder="Enter Name"
                name="name"
                id="serviceType"
                value={names || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>

          <Col md="4" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Select Product Category</label>
              <span className='required'>*</span>
              <Select
                type="select"
                value={productCategoryName}
                showSearch
                placeholder="Select Feature Category"
                optionFilterProp="children"
                onChange={onChangeProductCat}
                onSearch={onSearchProductCat}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Product Category
                </Option>
                {productServiceCat.success &&
                  productServiceCat.data.productCategoryList.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.id},${d.name}`}
                    >
                      {d.name}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>

          <Col md="4" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Select Type</label>
              <span className='required'>*</span>
              <Select
                type="select"
                value={type}
                showSearch
                placeholder="Select Feature Category"
                optionFilterProp="children"
                onChange={onChangeFeature}
                onSearch={onSearchFeature}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Type
                </Option>
                {labelOptionsDropDown.map((d, id) => (
                  <Option key={id} className="loginSelect" value={d.name}>
                    {d.name}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>

          <Col md="4" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Select Icon</Label>
              <span className='required'>*</span>
              <FileBase64
                multiple={true}
                id="AddProductService"
                accept=".jpeg, .png, .jpg, .svg"
                onDone={handleAttachments}
              />
            </FormGroup>
            {featureIcon && (
              <img
                src={updatedPhoto}
                width="40px"
                height="40px"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            )}
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            disabled={
              addedProductServCat.isLoading ||
              !(type && names && featureIcon && productCategoryId)
            }
            type="submit"
          >
            {addedProductServCat.isLoading ? <Spin /> : 'Add'}
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default AddProductServiceSubCategory
