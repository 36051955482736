import axios from "../../config/axios"
import {
    GETPENDINGMEMBERLIST_SUCCESS,
    GETPENDINGMEMBERLIST_FAILED,
    LOADING_GETPENDINGMEMBERLIST,
    RESET_GETPENDINGMEMBERLIST,
    GETPENDINGMEMBERLIST_NETWORKERROR
} from "../types";

const resetgetPendingMemberList = () => {
    return {
        type: RESET_GETPENDINGMEMBERLIST
    };
};

const getPendingMemberList = (userData, isRead = false) => (dispatch, getState) => {
    let pendingCount;
    let count;
    dispatch({ type: LOADING_GETPENDINGMEMBERLIST });
    console.log(userData, isRead, count, "new");
    axios
        .post("society/getOwnerList", userData)
        .then(res => {
            switch (res.data.statusCode) {
                case 200:
                    isRead ? count = getState().getPendingMemberList.data?.count : count = getState().getPendingMemberList.oldValue
                    userData?.userStatus === 'pending' ? pendingCount = res.data?.count : pendingCount = getState().getPendingMemberList.pendingCount;
                    dispatch(success(res.data, count, pendingCount));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch((err) =>
            dispatch({ type: GETPENDINGMEMBERLIST_NETWORKERROR, payload: err })
        )
};

function success(data, oldValue, pendingCount) {
    return {
        type: GETPENDINGMEMBERLIST_SUCCESS,
        payload: data,
        oldValue,
        pendingCount
    };
}

function failed(data) {
    return {
        type: GETPENDINGMEMBERLIST_FAILED,
        payload: data
    };
}

export { resetgetPendingMemberList, getPendingMemberList };