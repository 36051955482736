import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Table,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Tooltip, Popconfirm, Alert, Switch, Select } from 'antd'
import { Spin } from 'antd'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { useSelector, useDispatch } from 'react-redux'
import {
  resetgetDocType,
  getDocUnitType,
} from 'redux/actions/getUnitDocTypeAction'

import {
  resetDeleteUnitDocType,
  deleteUnitDocType,
} from 'redux/actions/deleteUnitDocTypeAction'
import {
  resetUpdateUnitDocType,
  updateUnitDocType,
} from 'redux/actions/updateUnitDocTypeAction'
import Util from 'validations'
import PaginationComponent from 'views/Pagination'
import AddUnitDocumentType from './AddUnitDocumentType'
import { resetGetDocument, getDocument } from 'redux/actions/getDocumentAction'

function UnitDocumentType(onTap) {
  const [documentTypeId, setDocumentTypeId] = useState('')
  const [documentType, setDocumentType] = useState('')
  const [searchDocumentType, setSearchDocumentType] = useState('')
  const [modal, setModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [isActive, setisActive] = useState()
  const [id, setId] = useState('')
  const [errMessage, seterrMessage] = useState('')

  const [limit] = useState(6)
  const [page, setPage] = useState(1)

  //Form Upadte Values
  const [editresidentType, setEditResidentType] = useState('')
  const [isMandatory, setIsMandatory] = useState('')

  //select Options
  const selectOptions = [
    { id: 1, residentType: 'Owner' },
    { id: 2, residentType: 'Tenant' },
  ]

  const addedUnitDocType = useSelector(({ addUnitDocType }) => addUnitDocType)
  const deletedDocType = useSelector(
    ({ deleteUnitDocType }) => deleteUnitDocType
  )
  const updatedUnitDocType = useSelector(
    ({ updateUnitDocType }) => updateUnitDocType
  )
  const docType = useSelector(({ getUnitDoctype }) => getUnitDoctype)
  const docTypes = useSelector(({ getDocument }) => getDocument)
  const login = useSelector(({ login }) => login)
  const [initiatedBy, setIntiatedBy] = useState(login.data.user.role)
  const dispatch = useDispatch()

  console.log(docTypes, "docType")

  const pageSize = docType.data.pages
  const totalItems = docType.data.count

  const { Option } = Select

  function onChangeDocumentType(value) {
    const val = value.split(',')
    setDocumentTypeId(val[0])
    setDocumentType(val[1])
  }
  function onSearchDocumentType(value) {
    setSearchDocumentType(value)
  }
  function onChangeResidentType(value) {
    setEditResidentType(value)
  }
  function onSearchResidentType(value) {
    setEditResidentType(value)
  }
  //request created

  const getUnitDocTypeRequest = () => {
    if (initiatedBy === 'Society') {
      const getUnitDocTypeReq = {
        initiatedBy,
        societyId: login.data.society.id,
        limit,
        page,
      }
      return dispatch(getDocUnitType(getUnitDocTypeReq))
    }
  }

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  const openEditModal = (data) => {
    setId(data.id)
    setDocumentTypeId(data.documentTypeId)
    setEditResidentType(data.residentType.charAt(0).toUpperCase() + data.residentType.slice(1))
    setDocumentType(data.documentType)
    setIsMandatory(data.isMandatory)
    setEditModal(!editModal)
    setisActive(data.isActive)
  }
  
  const handleDelete = (id) => {
    console.log(id)
    const deleteUnitDocTypeReq = {
      id: id,
    }
    return dispatch(deleteUnitDocType(deleteUnitDocTypeReq))
  }

  //form edit
  const handleEditDocType = () => {
    const editUnitDocTypeReq = {
      id: id,
      residentType: editresidentType,
      // documentType: documentType.trim(),
      documentTypeId,
      isMandatory: isMandatory,
      initiatedBy: login.data.user.role,
      isActive,
    }
    console.log(editUnitDocTypeReq)
    return dispatch(updateUnitDocType(editUnitDocTypeReq))
  }
  const toggleEdit = () => {
    setEditModal(!editModal)
  }
  const toggle = () => {
    seterrMessage('')
    setModal(!modal)
  }

  const onChange = (checked) => {
    setisActive(checked)
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'isMandatory') setIsMandatory(Boolean(value))
    if (name === 'documentType')
      Util.checkWhiteSpace(value) ? setDocumentType(value) : setDocumentType('')
  }

  useEffect(() => {
    getUnitDocTypeRequest()
  }, [])

  useEffect(() => {
    if (initiatedBy === 'Society') {
      const getUnitDocTypeReq = {
        initiatedBy,
        societyId: login.data.society.id,
        limit,
        page,
      }
      return dispatch(getDocUnitType(getUnitDocTypeReq))
    }
  }, [page])

  useEffect(() => {
    setPage(1)
  }, [onTap])

  useEffect(() => {
    if (addedUnitDocType.success) {
      getUnitDocTypeRequest()
      setModal(!modal)
    }
  }, [addedUnitDocType.success])

  useEffect(() => {
    if (updatedUnitDocType.success) {
      seterrMessage('')
      setModal(false)
      setEditModal(!editModal)
      NotificationPopups('success', 'Success', updatedUnitDocType.data.message)
      getUnitDocTypeRequest()
    }
    if (updatedUnitDocType.failed !== "Mandatory Parameter Missing") {
      seterrMessage(updatedUnitDocType.data.message)
    }
    if (updatedUnitDocType.data.message === "Mandatory Parameter Missing"){
      NotificationPopups('error', 'Failed', updatedUnitDocType.data.errorVOList.map((item,id)=>(<>{id === 0?1:id+1}-{item.errorMessage}<br/></>)))
    }
    if (updatedUnitDocType.network || deletedDocType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    if (deletedDocType.success) {
      NotificationPopups('success', 'Success', deletedDocType.data.message)
      getUnitDocTypeRequest()
    }
    if (deletedDocType.failed) {
      NotificationPopups('error', 'Failed', deletedDocType.data.message)
    }

    return () => {
      dispatch(resetUpdateUnitDocType())
      dispatch(resetDeleteUnitDocType())
    }
  }, [
    updatedUnitDocType.success,
    updatedUnitDocType.failed,
    updatedUnitDocType.network,
    deletedDocType.success,
    deletedDocType.failed,
    deletedDocType.network,
  ])

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Row>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={{ marginRight: '15px' }}>
                  <Button
                    color="secondary"
                    className="modalCustomButton"
                    onClick={() => {
                      setModal(!modal)
                    }}
                  >
                    Add Document
                  </Button>
                </div>
                <Col>
                  <div className="typeHeading" id="onTop">
                    User Document
                  </div>
                </Col>
              </div>

              {docType.success && (
                <Col md="12" style={{ padding: '20px' }}>
                  <Table responsive style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>Document </th>
                        <th>Resident</th>
                        <th>Mandatory</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    {docType.success && (
                      <tbody>
                        {docType.data.activeUnitDocumentType.map((d, id) => (
                          <React.Fragment key={id}>
                            <tr key={id} id={`toggler${id}`}>
                              <td>{d.documentType}</td>
                              <td style={{ textTransform: 'capitalize' }}>
                                {d.residentType}
                              </td>
                              <td>{d.isMandatory ? 'Yes' : 'No'}</td>
                              <td>{d.isActive ? 'Active' : 'Inactive'}</td>
                              <td>
                                <Button
                                  color="secondary"
                                  className="modalCustomButton"
                                  onClick={() => {
                                    openEditModal(d)
                                  }}
                                >
                                  Edit
                                </Button>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    )}
                  </Table>
                </Col>
              )}

              <Col md="12">
                {docType.failed && (
                  <div style={{ textAlign: 'center' }}>
                    <Alert message={docType.data.message} type="info" style={{ margin: "20px 0" }} />
                  </div>
                )}
              </Col>
              <Col>
                {docType.success && (
                  <PaginationComponent
                    limit={limit}
                    totalItems={totalItems}
                    onSelect={handlePagination}
                    defaultPage={page}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      {editModal && (
        <Modal
          isOpen={editModal}
          toggle={toggleEdit}
          size="sm"
          style={{ maxWidth: '350px' }}
        >
          <ModalHeader toggle={toggleEdit}>Edit</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="helperType">Document Type</Label>
              <Select
                type="select"
                value={documentType}
                showSearch
                placeholder="Select Service Type"
                optionFilterProp="children"
                onChange={onChangeDocumentType}
                onSearch={onSearchDocumentType}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Document Type
                </Option>

                {docTypes.success &&
                  docTypes.data.activeType.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.id},${d.type}`}
                    >
                      {d.type}
                    </Option>
                  ))}
                {docTypes.failed && (
                  <Option className="loginSelect" value="" disabled>
                    No Data Found
                  </Option>
                )}
              </Select>
            </FormGroup>

            <FormGroup>
              <Label for="helperType">Resident Type</Label>
              <Select
                type="select"
                value={editresidentType}
                showSearch
                placeholder="Select Service Type"
                optionFilterProp="children"
                onChange={onChangeResidentType}
                onSearch={onSearchResidentType}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Resident Type
                </Option>

                {selectOptions.map((d, id) => (
                  <Option
                    key={id}
                    className="loginSelect"
                    value={d.residentType}
                  >
                    {d.residentType}
                  </Option>
                ))}
              </Select>
            </FormGroup>

            <FormGroup tag="fieldset">
              <Label>Mandatory</Label>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="isMandatory"
                    defaultChecked={isMandatory ? 'true' : null}
                    value="True"
                    onChange={handleChange}
                  />{' '}
                  Yes
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    name="isMandatory"
                    defaultChecked={isMandatory ? null : 'false'}
                    value=""
                    onChange={handleChange}
                  />{' '}
                  No
                </Label>
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <br />
              <Switch
                defaultChecked={isActive}
                name="isActive"
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={onChange}
              />
            </FormGroup>

            <div style={{ textAlign: 'center' }} className="errorText">
              {errMessage}
            </div>
            <Button
              color="secondary"
              className="modalCustomButton"
              onClick={handleEditDocType}
              disabled={updatedUnitDocType.isLoading}
            >
              {updatedUnitDocType.isLoading ? <Spin /> : 'Edit'}
            </Button>
            <Button
              color="secondary"
              style={{ float: 'right' }}
              className="modalCustomButton"
              onClick={toggleEdit}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>
      )}
      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="lg"
          style={{ maxWidth: '1000px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toggle}>Add Unit Document </ModalHeader>
          <ModalBody>
            <AddUnitDocumentType />
          </ModalBody>
        </Modal>
      )}
    </Row>
  )
}

export default UnitDocumentType
