import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch, Popconfirm, Spin, Tooltip } from 'antd'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  UncontrolledCollapse,
} from 'reactstrap'
import { Alert, Select } from 'antd'
import DisplayAttachment from '../../components/Custom/DisplayAttachment'
import Util from 'validations/index'
import {
  resetgetPendingMemberList,
  getPendingMemberList,
} from 'redux/actions/getPendingMemberListAction'
import {
  getMemberList,
  resetgetMemberList,
} from 'redux/actions/getMemberListAction'
import {
  resetUpdateMemberRole,
  updateMemberRole,
} from 'redux/actions/updateMemberRoleAction'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import PaginationComponent from 'views/Pagination'
import axios from 'config/axios'

function MemberRejectedList() {
  const [fileModal, setFileModal] = useState(false)
  const [attachment, setAttachment] = useState(false)
  const [id, setId] = useState('')
  const [isCom, setIsCom] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  //filter
  const [areaType, setareaType] = useState('Building')
  const [areaId, setareaId] = useState()
  const [flatid, setflatid] = useState()
  const [serachAreaName, setSearchAreaName] = useState('')
  const [optionsUnit, setOptionsUnit] = useState([''])
  const [flatName, setFlatName] = useState('')
  const [searchFlat, setSearchFlat] = useState('')
  const [buildingId, setBuildingId] = useState()
  const [bldgName, setBldgName] = useState('')
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [memberName, setMemberName] = useState('')
  const [phone, setPhone] = useState('')
  const [residentType, setResidentType] = useState('')
  const [occupancyStatus, setOccupancyStatus] = useState('')

  const dispatch = useDispatch()
  const [limit] = useState(10)
  const [page, setPage] = useState(1)
  const [ids, setIds] = useState('')
  //const memberList = useSelector(({ getMemberList }) => getMemberList);
  const memberList = useSelector(
    ({ getPendingMemberList }) => getPendingMemberList
  )
  const login = useSelector(({ login }) => login)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  const updateRole = useSelector(({ updateMemberRole }) => updateMemberRole)
  const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)
  //filter
  const selectOptions = [
    { id: 1, residentType: 'Owner' },
    { id: 2, residentType: 'Tenant' },
  ]

  const { Option } = Select

  function onChangeSearch(value) {
    let val = value.split(',')
    let val1 = val[0]
    let val2 = val[1]
    setBuildingId(val1)
    setBldgName(val2)
    setSearchAreaName('')
    setflatid('')
    setFlatName('')
  }

  function onSearch(value) {
    setSearchAreaName(value)
  }
  function onChangeSearchFlatId(value) {
    let flatval = value.split(",")
    let flatval1 = flatval[0]
    let flatval2 = flatval[1]
    setflatid(flatval1)
    setFlatName(flatval2)
    setSearchFlat('')
  }
  function onSearchFlatId(val) {
    setSearchFlat(val)
  }
  function onChangeResidentType(value) {
    setResidentType(value)
  }
  function onSearchResidentType(value) {
    setResidentType(value)
  }

  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        // console.log(res.data.activeType, 'res.data.activeType')
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName])

  useEffect(() => {
    if (login.data.user.role !== 'SuperAdmin' && areaType != '') {
      const getBuildingListReq = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      return dispatch(getBuildingList(getBuildingListReq))
    }
  }, [areaType])

  useEffect(() => {
    if (areaType === 'Building' && buildingId) {
      const getUnitFlatListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
      }
      return dispatch(getUnitFlatList(getUnitFlatListReq))
    }
  }, [buildingId])
  useEffect(() => {
    if (areaType === 'Building' && buildingId && searchFlat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
        unit: searchFlat,
      }
      axios.post('society/getUnitList', userData).then((res) => {
        // if (res.status === 200 && res.data.activeUnitList != '') {
        setOptionsUnit(res.data.activeUnitList)
        // }
      })
    }
  }, [searchFlat])

  useEffect(() => {
    if (serachAreaName === '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        // areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions2(res.data.activeAreaList)
      })
    }
  }, [])

  const handleChange = ({ target: { name, value } }) => { }

  const handleSubmit = (id) => { }
  //search filter
  const searchSubmit = (e) => {
    e.preventDefault()
    getMemberListSearchRequest()
    setIsSearch(!isSearch)
  }

  useEffect(() => {
    if (buildingId === '') {
      dispatch(resetGetUnitFlatList())
    }
  }, [buildingId])

  useEffect(() => {
    getMemberListSearchRequest()
  }, [page])
  const getMemberListSearchRequest = () => {
    const getPendingMemberListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      userStatus: 'rejected',
      areaId: buildingId,
      unitId: flatid,
      type: residentType,
      occupancyStatus,
      limit,
      page,
    }
    return dispatch(getPendingMemberList(getPendingMemberListReq))
  }

  // console.log(id)

  // const pageSize = memberList.data.pages
  const totalItems = memberList.data.count

  useEffect(() => {
    // getMemberListRequest()
    return () => {
      resetgetPendingMemberList()
    }
  }, [])

  const onChangeSwitch = (checked) => {
    setIsCom(checked)
  }

  const updateMemberRoleRequest = () => {
    if (isCom === true) {
      const updateMemberRoleReq = {
        initiatedBy: login.data.user.role,
        id,
        societyRole: 'Committee Member',
      }
      // console.log(updateMemberRoleReq)
      return dispatch(updateMemberRole(updateMemberRoleReq))
    }
    if (isCom != true) {
      const updateMemberRoleReq = {
        initiatedBy: login.data.user.role,
        id,
        societyRole: 'NA',
      }
      // console.log(updateMemberRoleReq)
      return dispatch(updateMemberRole(updateMemberRoleReq))
    }
  }

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  useEffect(() => {
    setPage(1)
  }, [isSearch])

  useEffect(() => {
    const getPendingMemberListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      userStatus: 'rejected',
      limit,
      page,
    }
    return dispatch(getPendingMemberList(getPendingMemberListReq))
  }, [page])

  useEffect(() => {
    updateMemberRoleRequest()
  }, [isCom])

  const getMemberListRequest = () => {
    const getMemberListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      userStatus: 'approved',
      limit,
      page,
    }
    return dispatch(getPendingMemberList(getMemberListReq))
  }

  //Open Files
  const handleOpenFile = (a) => {
    setAttachment(a)
    setFileModal(true)
  }

  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }
  useEffect(() => {
    if (updateRole.success) {
      getMemberListRequest()
      NotificationPopups('success', 'Success', updateRole.data.message)
    }
    // if (updateRole.failed) {
    //   NotificationPopups('error', 'Failed', updateRole.data.message)
    // }
    if (updateRole.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetUpdateMemberRole())
    }
  }, [updateRole.success, updateRole.failed, updateRole.network])

  useEffect(() => {
    if (buildingId === '') {
      dispatch(resetGetUnitFlatList())
    }
  }, [buildingId])

  return (
    <div className="table-full-width table-responsive" id="onTop">
      {buildingList.success && (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="3" sm="12">
              <FormGroup style={{ margin: '10px 1px' }}>
                <Label for="city">Building Name</Label>
                <Select
                  type="select"
                  value={bldgName}
                  showSearch
                  placeholder="Select Building Name"
                  optionFilterProp="children"
                  onChange={onChangeSearch}
                  onSearch={onSearch}
                  style={{ width: '100%', borderRadius: '25px' }}
                // loading={buildingNames.isLoading}
                >
                  <Option className="loginSelect" value="" >
                    Select Building Name
                  </Option>

                  {serachAreaName != '' &&
                    options != undefined &&
                    options.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={`${d.areaId},${d.areaName}`}
                      >
                        {d.areaName}
                      </Option>
                    ))}
                  {serachAreaName === '' &&
                    options2.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={`${d.areaId},${d.areaName}`}
                      >
                        {d.areaName}
                      </Option>
                    ))}
                </Select>
              </FormGroup>
            </Col>
            {areaType === 'Building' && (
              <Col md="3" sm="12">
                <FormGroup style={{ margin: '10px 1px' }}>
                  <Label for="flatid" lat>
                    Flat
                  </Label>
                  <Select
                    // disabled={bldgName ? false : true}
                    type="select"
                    showSearch
                    value={flatName}
                    placeholder="Select Flat Number"
                    optionFilterProp="children"
                    onChange={onChangeSearchFlatId}
                    onSearch={onSearchFlatId}
                    style={{ width: '100%' }}
                  >
                    <Option className="loginSelect" value="" >
                      {getFlat.data.message === 'Not Found' ? 'No Flat Found ' : "Select Flat Number"}
                    </Option>
                    {searchFlat != '' && optionsUnit != undefined
                      ? optionsUnit.map((d, id) => (
                        <Option
                          key={id}
                          className="loginSelect"
                          value={`${d.id}, ${d.unit}`}
                        >
                          {d.unit}
                        </Option>
                      ))
                      : getFlat.success &&
                      getFlat.data.activeUnitList.map((d, id) => (
                        <Option
                          key={id}
                          className="loginSelect"
                          value={`${d.id}, ${d.unit}`}
                        >
                          {d.unit}
                        </Option>
                      ))}
                  </Select>
                </FormGroup>
              </Col>
            )}

            {/* <Col md="3" sm="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="societyName">Member Name</Label>
              <Input
                type="text"
                placeholder="Member Name"
                name="MemberName"
                id="city"
                value={memberName || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="pincode">Phone Number</Label>
              <Input
                type="tel"
                placeholder="Phone Number"
                name="phone"
                id="phone"
                value={phone || ''}
                onChange={handleChange}
              />
            </FormGroup>
          </Col> */}
            <Col md="3" sm="12">
              <FormGroup style={{ margin: '10px 1px' }}>
                <Label for="city"> Select Resident Type</Label>
                <Select
                  type="select"
                  value={residentType}
                  showSearch
                  placeholder="Select Building Name"
                  optionFilterProp="children"
                  onChange={onChangeResidentType}
                  onSearch={onSearchResidentType}
                  style={{ width: '100%', borderRadius: '25px' }}
                // loading={buildingNames.isLoading}
                >
                  <Option className="loginSelect" value="">
                    Select Resident Type
                  </Option>
                  {selectOptions.map((d, id) => (
                    <Option value={d.residentType}>{d.residentType}</Option>
                  ))}
                </Select>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ justifyContent: 'center' }}>
            <Col sm="12" style={{ textAlign: 'center' }}>
              <Button
                className="cust-button"
                onClick={searchSubmit}
                disabled={memberList.isLoading}
              >
                {memberList.isLoading ? <Spin /> : 'Search'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      <Table responsive style={{ textAlign: 'center' }}>
        <thead>
          <tr>
            <th>Flat</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Occupancy Status</th>
            <th>Resident </th>
          </tr>
        </thead>
        {memberList.success && (
          <tbody>
            {memberList.data.ownerList.map((d, i) => (
              <React.Fragment key={i}>
                <tr
                  key={i}
                  id={`toggler${i}`}
                  style={{
                    backgroundColor: '#e8f5fd',
                    color: 'black',
                    fontWeight: 'semi-bold',
                    boxShadow: 'none',
                  }}
                  onClick={() => { setIds(i) }}
                >
                  <td>
                    {d.areaName} / {d.unit}
                  </td>
                  <td>{d.name}</td>
                  <td>{d.mobileNumber}</td>
                  <td>{d.email}</td>
                  <td>{d.occupancyStatus}</td>
                  <td>{d.type}</td>
                  {/* <Switch
                    // defaultChecked={isCom}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    name="isCom"
                    onChange={onChangeSwitch}
                    style={{ marginTop: 10 }}
                  /> */}
                </tr>

                <tr className="ml-detail-tr">
                  <td colSpan="6" className="ml-detail-td">
                    <UncontrolledCollapse toggler={`toggler${i}`}>
                      {ids === i ?
                        <Row className="ml-detail-row">
                          {d.documentList.length !== 0 ?
                            d.documentList.map((doc, id) => (
                              <Col
                                xs="12"
                                sm="6"
                                md="4"
                                lg="3"
                                style={{ padding: '20px' }}
                                key={id}
                              >
                                <label>{doc.documentType}</label>
                                {doc.document && (
                                  <div
                                    style={{
                                      cursor: 'pointer',
                                      padding: '5px',
                                      height: '130px',
                                    }}
                                    onClick={() => handleOpenFile(doc.document)}
                                  >
                                    {doc.document && (
                                      <img
                                        // src={doc.document}
                                        src={`${Util.imageUrl}${doc.document}`}
                                        // alt={`${Util.imageUrl}${doc.document}`}
                                        alt="images"
                                        style={{
                                          width: '100px',
                                          height: '100px',
                                        }}
                                      />
                                    )}
                                  </div>
                                )}
                              </Col>
                            )) : <div style={{ width: "100%", textAlign: 'center' }}>No Documents to show.</div>}
                        </Row>
                        : ''}
                    </UncontrolledCollapse>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        )}
      </Table>

      {memberList.failed && (
        <div style={{ textAlign: 'center', paddingTop: '20px' }}>
          <Alert message={memberList.data.message} type="info" />
        </div>
      )}
      {fileModal && (
        <DisplayAttachment
          attachment={`${Util.imageUrl}${attachment}`}
          fileModal={fileModal}
          toggleModal={toggleFileModal}
        />
      )}
      {memberList.success && (
        <PaginationComponent
          limit={limit}
          totalItems={totalItems}
          onSelect={handlePagination}
          defaultPage={page}
        />
      )}
    </div>
  )
}

export default MemberRejectedList
