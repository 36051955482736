import {
    GETCOMPLAINTLIST_SUCCESS,
    GETCOMPLAINTLIST_FAILED,
    LOADING_GETCOMPLAINTLIST,
    RESET_GETCOMPLAINTLIST,
    GETCOMPLAINTLIST_NETWORKERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    network: false,
    failed: false,
    data: {},
    message: "",
    networkError: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_GETCOMPLAINTLIST:
            return {
                ...state,
                isLoading: true,
                success: false,
                failed: false,
                network: false,
                message: "",
                networkError: ""
            };
        case GETCOMPLAINTLIST_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                network: false,
                failed: true,
                data: action.payload,
                message: "",
                networkError: ""

            };
        case GETCOMPLAINTLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                failed: false,
                network: false,
                data: action.payload,
                oldValue: action.oldValue,
                pendingCount:action.pendingCount,
                message: "",
                networkError: ""
            };
        case GETCOMPLAINTLIST_NETWORKERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                failed: false,
                network: true,
                data: action.payload,
                message: "",
                networkError: "Network Error"
            };
        case RESET_GETCOMPLAINTLIST:
            return (state = initialState);

        default:
            return state;
    }
};