import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import { Tooltip, Popconfirm, Spin, Switch, Select, Checkbox, Tag } from 'antd'

import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetAddUnitFlatList,
  addUnitFlatList,
} from 'redux/actions/addUnitFlatListAction'
import axios from 'config/axios'
import Util from 'validations/index'

import Add from 'assets/img/Icons/flat/add.png'
import Delete from 'assets/img/Icons/flat/delete.png'
import { getAreaSearchList } from 'redux/actions/getAreaSearchListAction'
import { getAllUnitType, resetGetAllUnitType } from 'redux/actions/getAllUnitTypeAction'
import { getAllUnitSize, resetGetAllUnitSize } from 'redux/actions/getAllUnitSizeAction'
import { addUnitSize, resetAddUnitSize } from 'redux/actions/addUnitSizeAction'

function AddCommonAreaFlatList({ areaTypeId, areaType, areaId }) {
  const [buildingId, setBuildingId] = useState('')
  const [noOfFlatsPF, setNoOfFlatsPF] = useState(0)
  const [noOfFloors, setNoOfFloors] = useState(0)
  const [startRange, setStartRange] = useState('')
  const [bldgName, setBldgName] = useState('')
  const [prefix, setPrefix] = useState('')
  const [floors, setFloors] = useState([])
  const [startFloor, setStartFloor] = useState(0)
  const [selectArea, setSelectArea] = useState("")
  const [flatAreas, setFlatAreas] = useState("")
  const [squareFeet, setSquareFeet] = useState('')

  const [allFlat, setAllFlat] = useState([])

  const getareaType = useSelector(({ areaType }) => areaType)
  const addedFlatList = useSelector(({ addUnitFlatList }) => addUnitFlatList)
  const buildingNames = useSelector(({ buildingNames }) => buildingNames)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  const login = useSelector(({ login }) => login)
  const updatedBuilding = useSelector(({ updateBuilding }) => updateBuilding)
  const getAllUnitTypeRes = useSelector(({ getAllUnitType }) => getAllUnitType)
  const getAllUnitSizeRes = useSelector(({ getAllUnitSize }) => getAllUnitSize)
  const addUnitSizeRes = useSelector(({ addUnitSize }) => addUnitSize)
  console.log(addUnitSizeRes, "addUnitSizeRes")

  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [serachAreaName, setSearchAreaName] = useState('')


  const dispatch = useDispatch()

  function handleChange({ target: { name, value, max } }) {
    if (name === 'noOfFlatsPF') {
      Util.verifyMobile(value) 
        ? setNoOfFlatsPF(Number(value))
        : setNoOfFlatsPF(noOfFlatsPF)
    }
    if (name === 'prefix')
      // Util.alphabetsOnly(value) ? setPrefix(value.toUpperCase().trim()) : setPrefix(prefix.trim())
      setPrefix(value.toUpperCase().trim())

    if (name === 'noOfFloors') {
      Util.verifyMobile(value) && value >= max
        ? setNoOfFloors(0)
        : setNoOfFloors(Math.max(0, value))
    }
    if (name === 'startRange')
      Util.verifyMobile(value)
        ? setStartRange(Math.max(0, value))
        : setStartRange(0)
    if (name === 'startFloor')
      Util.verifyMobile(value)
        ? setStartFloor(value)
        : setStartFloor(startFloor)
    if (name === 'squareFeet')
      Util.verifyMobile(value)
        ? setSquareFeet(value)
        : setSquareFeet(squareFeet)
    // setStartFloor(value)
  }
  const { Option } = Select

  function onChangeSearch(value) {
    console.log(value, "valuevalue")
    let val = value.split(",")
    setBuildingId(val[0])
    setBldgName(val[1])
    setNoOfFloors(val[2])
    setSearchAreaName('')
    if (bldgName !== val[1]) {
      setNoOfFlatsPF('')
      setStartRange('')
    }
  }

  function onSearch(val) {
    setSearchAreaName(val)
  }

  useEffect(() => {
    if (updatedBuilding.success) {
      setBldgName('')
      setNoOfFloors('')
    }
    if (serachAreaName != '') {
      const userData = {
        areaType: areaType === "Flat" ? 'building' : areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName, updatedBuilding.success, buildingList.success])

  useEffect(() => {
    if (updatedBuilding.success) {
      setBldgName('')
      setNoOfFloors('')
    }
    if (serachAreaName === '') {
      const userData = {
        areaType: areaType === "Flat" ? 'building' : areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions2(res.data.activeAreaList)
      })
      setBldgName('')
      setNoOfFloors('')
    }
  }, [areaType, updatedBuilding.success, buildingList.success])

  function AddFlatBtn(floorIndex) {
    const newFloors = floors.map((rooms, floorIdx) => {
      if (floorIndex === floorIdx) {
        return [
          ...rooms,
          {
            isParking: false,
            unit: startRange
              ? `${prefix}` + `-` +
              parseInt(startRange + floorIndex * 100 + rooms.length)
              : null,
            floor: floorIdx + 1,
            initiatedBy: login.data.user.role,
            societyId: login.data.society.id,
            areaId: buildingId,
            areaTypeId,
          },
        ]
      }
      return rooms
    })
    setFloors(newFloors)
  }

  function EditUnit(floorIndex, roomIndex, name, e, checked) {
    const mainObj = [...floors]
    const floorsMap = [...mainObj[floorIndex]]
    floorsMap[roomIndex] = {
      ...floorsMap[roomIndex],
      floor: floorIndex + 1,
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      areaId: buildingId,
      areaTypeId,
      [name]: name === 'unit' ? e.target.value : checked,
    }
    mainObj[floorIndex] = floorsMap
    setFloors(mainObj)
  }

  const addFlatListRequest = () => {
    // const obj = allFlat.length ? [...floors, ...allFlat].flat().filter((el) => { return el.unitType != null }) : floors.flat().filter((el) => { return el.unitType != null })
    const obj = floors.flat()
    return dispatch(addUnitFlatList(obj))
  }

  function handleSubmit(e) {
    e.preventDefault()
    addFlatListRequest()
    setPrefix('')
    if (flatArea.style.display === "flex") {
      flatDetails.setAttribute("style", "display:block");
      flatArea.setAttribute("style", "display:none");
    }
    document.getElementById('Add').style.display = 'none'
    document.getElementById('Next').style.display = 'inline'
    setSelectArea('')
    setSquareFeet('')
  }

  function RemoveFlatBtn(floorIndex) {
    const newFloors = floors.map((rooms, i) => {
      if (floorIndex === i) {
        return rooms.splice(0, rooms.length - 1)
      }
      return rooms
    })

    setFloors(newFloors)
  }

  useEffect(() => {
    const increment = startRange < 99 ? 10 : 100

    if (
      noOfFloors &&
      noOfFlatsPF
    ) {
      // let len = parseInt(noOfFloors - startFloor + 1)
      let len = startFloor >noOfFloors ? 0: noOfFloors - startFloor + 1 
      console.log(len,noOfFloors,noOfFlatsPF,"lenlen")
      const newFloors = Array(len).fill(null).map((_, floorIdx) =>
        Array(noOfFlatsPF)
          .fill(null)
          .map((_, roomIdx) => {
            let checkunit = (prefix && `${prefix}-`) + (startRange < 99 ? parseInt(startRange + floorIdx * increment * (floorIdx === 0 ? '' : 10) + roomIdx) : parseInt(startRange + floorIdx * increment + roomIdx))
            return {
              // unit: startRange ? prefix + - + parseInt(startRange + floorIdx * increment + roomIdx) : 0,
              unit: startRange ? (prefix && `${prefix}-`) + (startRange < 99 ? parseInt(startRange + floorIdx * increment * (floorIdx === 0 ? '' : 10) + roomIdx) : parseInt(startRange + floorIdx * increment + roomIdx)) : '',
              isParking: false,
              floor: `${parseInt(startFloor) + floorIdx}`,
              initiatedBy: login.data.user.role,
              societyId: login.data.society.id,
              areaId: buildingId,
              areaTypeId,
              //unitType: ???
            }
          })
      )
      console.log(newFloors, "newFloors")

      newFloors ? setFloors(newFloors) : setFloors([])
    }
  }, [noOfFloors, noOfFlatsPF, startRange, prefix,startFloor])

  useEffect(() => {
    if (addedFlatList.success) {
      setNoOfFloors('')
      setNoOfFlatsPF(0)
      setStartRange('')
      setBuildingId('')
      setBldgName('')
      setStartFloor('')
      setFloors([])
      NotificationPopups('success', 'Success', addedFlatList.data.message)
    }
    if (addedFlatList.failed) {
      setNoOfFloors('')
      setNoOfFlatsPF(0)
      setStartRange('')
      setBldgName('')
      setBuildingId('')
      setStartFloor('')
      setFloors([])
      NotificationPopups('error', 'Failed', addedFlatList.data.message)
    }
    if (addedFlatList.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
        // addedFlatList.data.message
      )
    }

    return () => {
      dispatch(resetAddUnitFlatList())
    }
  }, [addedFlatList.success, addedFlatList.failed, addedFlatList.network])

  const disableButton = () => {
    return !(buildingId && noOfFloors && noOfFlatsPF && startRange)
  }

  const getAreaSearchListRes = useSelector(({ getAreaSearchList }) => getAreaSearchList)
  const getAreaSearchListReq = () => {
    const userData = {
      areaType: areaType,
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
    }
    return dispatch(getAreaSearchList(userData))
  }

  //Flat Area List

  // const getAllUnitTypeRequest = () => {
  //   const flatAreaTypeReq = {
  //     initiatedBy: login.data.user.role
  //   }
  //   return dispatch(getAllUnitType(flatAreaTypeReq))
  // }

  // useEffect(() => {
  //   getAllUnitTypeRequest()
  // }, [])

  const getAllUnitSizRequest = () => {
    const unitSizeReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id
    }
    return dispatch(getAllUnitSize(unitSizeReq))
  }

  useEffect(() => {
    getAllUnitSizRequest()
  }, [])

  const onChangeArea = (value) => {
    setSelectArea(value)

  }

  let flatDetails = document.getElementById("flatDetails");
  let flatArea = document.getElementById("flatArea");
  const onClickNone = () => {
    if (flatArea.style.display === "none") {
      flatDetails.setAttribute("style", "display:none");
      flatArea.setAttribute("style", "display:flex");
      document.getElementById('prev').style.display = "inline"
      document.getElementById('Add').style.display = "inline"
      document.getElementById('Next').style.display = "none"
    }
  }

  const onClickPrev = () => {
    if (flatArea.style.display === "flex") {
      flatDetails.setAttribute("style", "display:block");
      flatArea.setAttribute("style", "display:none");
      document.getElementById('prev').style.display = "none"
      document.getElementById('Add').style.display = "none"
      document.getElementById('Next').style.display = "inline"
    }
    setSelectArea('')
  }

  const selectedFlat = (unit, id, floorIndex) => {
    console.log("first", unit, id, floorIndex)
    if (floors[floorIndex][id].unitType) delete floors[floorIndex][id].unitType; else floors[floorIndex][id].unitType = selectArea
    if (floors[floorIndex][id].unitSize) delete floors[floorIndex][id].unitSize; else floors[floorIndex][id].unitSize = squareFeet + "sqft"
    setFloors([...floors])
  }

  useEffect(() => {
    if (addUnitSizeRes.success) {
      getAllUnitSizRequest()
    }
  }, [addUnitSizeRes.success])

  useEffect(() => {
    if (bldgName !== "" && noOfFloors !== "" && startFloor !== "" && noOfFlatsPF !== "" && startRange !== "") {
      document.getElementById("btn").style.display = 'flex'
      document.getElementById("prev").style.display = 'none'
    } else {
      document.getElementById("btn").style.display = 'none'
    }
  }, [bldgName, noOfFloors, startFloor, noOfFlatsPF, startRange])

  return (
    <div style={{ marginTop: '20px' }}>
      <div id='flatDetails'>
        <Row>
          <Col lg="3" md="3" sm="12" xs="12">
            {options2 ? (
              <FormGroup>
                <Label for="buildingId">{areaType === "Flat" ? "Building" : areaType} Name</Label>
                <span className='required'>*</span>
                <Select
                  type="select"
                  showSearch
                  value={bldgName}
                  placeholder={`Select ${areaType} Name`}
                  optionFilterProp="children"
                  onChange={onChangeSearch}
                  onSearch={onSearch}
                  style={{ width: '100%' }}
                >
                  <Option className="loginSelect" value="">
                    Select {areaType === "Flat" ? "Building" : areaType} Name
                  </Option>
                  {serachAreaName != '' &&
                    options != undefined &&
                    options.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={`${d.areaId},${d.areaName},${d.floor}`}
                      >
                        {`${d.parentName}` === 'undefined' ? `${d.areaName.split('-')[0]}` : `${d.parentName}-${d.areaName.split('-')[0]}`}
                      </Option>
                    ))}
                  {serachAreaName === '' &&
                    options2.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={`${d.areaId},${d.areaName},${d.floor}`}
                      >
                        {`${d.parentName}` === 'undefined' ? `${d.areaName.split('-')[0]}` : `${d.parentName}-${d.areaName.split('-')[0]}`}
                      </Option>
                    ))}
                </Select>
              </FormGroup>
            ) : (
              <FormGroup>
                <Label for="buildingId">Please Add {areaType}</Label>
                <span className='required'>*</span>
                <Select
                  type="select"
                  showSearch
                  value={bldgName}
                  placeholder={`Please Add ${areaType}`}
                  optionFilterProp="children"
                  onChange={onChangeSearch}
                  onSearch={onSearch}
                  style={{ width: '100%' }}
                  disabled
                >
                  <Option className="loginSelect" value="">
                    Please Add {areaType}
                  </Option>
                </Select>
              </FormGroup>
            )}
          </Col>
          <Col lg="3" md="3" sm="12" xs="12">
            <FormGroup>
              <Label for="floors">Number of Floors</Label>
              <span className='required'>*</span>
              <Input
                type="text"
                name="noOfFloors"
                id="floors"
                placeholder="Floors"
                value={noOfFloors || ''}
                onChange={handleChange}
                max="99"
                disabled
                maxLength="3"
                required
              />
            </FormGroup>
          </Col>
          <Col lg="3" md="3" sm="12" xs="12">
            <FormGroup>
              <Label for="startRange">Start Floor</Label>
              <span className='required'>*</span>
              <Input
                type="text"
                name="startFloor"
                id="startFloor"
                placeholder="Start Floor"
                value={startFloor || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
            {noOfFloors < startFloor ? <span style={{ color: 'red' }}>Start floor should not be greater than no. of floors</span> : ''}
          </Col>
          <Col lg="3" md="3" sm="12" xs="12">
            <FormGroup>
              <Label for="noOfFlatsPF">Number of {areaType} per Floor</Label>
              <span className='required'>*</span>
              <Input
                type="text"
                name="noOfFlatsPF"
                id="noOfFlatsPF"
                placeholder={`Number of ${areaType} per Floor`}
                value={noOfFlatsPF || ''}
                onChange={handleChange}
                max="99"
                required
                disabled={noOfFloors < startFloor ? true : false}
              />
            </FormGroup>
          </Col>
          <Col lg="3" md="3" sm="12" xs="12">
            <FormGroup>
              <Label for="prefix">Enter Default Prefix</Label>
              <Input
                type="text"
                name="prefix"
                id="prefix"
                placeholder="Enter Prefix"
                value={prefix || ''}
                onChange={handleChange}
                maxLength="5"
              />
            </FormGroup>
          </Col>
          <Col lg="3" md="3" sm="12" xs="12">
            <FormGroup>
              <Label for="startRange">Start Range</Label>
              <span className='required'>*</span>
              <Input
                type="text"
                name="startRange"
                id="startRange"
                placeholder="Start Range"
                value={startRange || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>

        <div style={{ overflowY: 'auto', height: 'auto', overflowX: 'hidden' }}>
          {floors.length
            ? floors.map((floor, floorIndex) => {
              return (
                <Row key={floorIndex}>
                  {noOfFlatsPF && startFloor ?
                    <>
                      <div style={{
                        width: "100%",
                        padding: "15px",
                        textDecorationLine: 'underline',
                        fontSize: '18px'
                      }}>Floor : {`${parseInt(startFloor) + floorIndex}`}</div>

                      {
                        floor.map((room, roomIndex) => (
                          <Col
                            xs={12}
                            sm={12}
                            md={Math.ceil(12 / noOfFlatsPF)}
                            lg={Math.ceil(12 / noOfFlatsPF)}
                            key={roomIndex}
                          >
                            <FormGroup>
                              <Label>{areaType}</Label>
                              <Input
                                placeholder={`${areaType} Number`}
                                type="text"
                                name="unit"
                                value={room.unit}
                                onChange={(e) => {
                                  EditUnit(floorIndex, roomIndex, 'unit', e, null)
                                }}
                              />
                            </FormGroup>
                            <FormGroup tag="fieldset">
                              <Label>Parking</Label>
                              <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                // defaultunChecked
                                // defaultChecked
                                onChange={(checked, e) => {
                                  EditUnit(
                                    floorIndex,
                                    roomIndex,
                                    'isParking',
                                    e,
                                    checked
                                  )
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ))
                      }
                      {
                        floor.length !== 0 ? (
                          <>
                            <div style={{ padding: '10px', margin: '5px' }}>
                              <Tooltip
                                placement="leftBottom"
                                title={`Add ${areaType}`}
                                color="#108ee9"
                              >
                                <Button
                                  className="sd-add-icons"
                                  onClick={() => {
                                    AddFlatBtn(floorIndex)
                                  }}
                                >
                                  <img src={Add} alt="add" style={{ width: '20px' }} />
                                </Button>
                              </Tooltip>
                              <Tooltip
                                placement="rightBottom"
                                title={`Delete ${areaType}`}
                                color="#108ee9"
                              >
                                <Button
                                  className="sd-add-icons"
                                  onClick={() => {
                                    RemoveFlatBtn(floorIndex)
                                  }}
                                >
                                  <img
                                    src={Delete}
                                    alt="delete"
                                    style={{ width: '20px' }}
                                  />
                                </Button>
                              </Tooltip>
                            </div>
                          </>
                        ) : null
                      }
                    </>
                    :
                    null
                  }
                </Row>
              )
            })
            : null}
        </div>
      </div>

      {floors.length
        ?
        <Row id="flatArea" style={{ display: 'none' }}>
          <>
            <p className='selectArea'>Select Area</p>
            <Col className='col-md-3'>
              <FormGroup>
                <Label>Select Area</Label>
                <span className='required'>*</span>
                <Select
                  type="select"
                  showSearch
                  value={selectArea}
                  placeholder="Select Area"
                  optionFilterProp="children"
                  onChange={onChangeArea}
                  // onSearch={onSearchArea}
                  style={{ width: '100%' }}
                >
                  <Option className="loginSelect" value="">
                    Select Area
                  </Option>
                  {getAllUnitSizeRes && getAllUnitSizeRes.data?.unitSizeList && getAllUnitSizeRes.data?.unitSizeList.map((item, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={item.unitType}
                    >
                      {item.unitType}
                    </Option>
                  ))}
                </Select>
              </FormGroup>
            </Col>
            <Col lg="3" md="3" sm="12" xs="12">
              <FormGroup>
                <Label for="floors">Area Sqaure Feet</Label>
                <span className='required'>*</span>
                <Input
                  type="text"
                  name="squareFeet"
                  id="squareFeet"
                  placeholder="Enter Area Sqaure Feet"
                  value={squareFeet || ''}
                  onChange={handleChange}
                  max="99"
                  required
                />
              </FormGroup>
            </Col>

            <Col className='col-md-12' style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
              {floors.map((floor, floorIndex) => {
                return (
                  <div style={{ marginBottom: '10px' }}>
                    <p>Floor: {`${parseInt(startFloor) + floorIndex}`}</p>
                    {floor.map((room, roomIndex) => (
                      <Tag key={roomIndex} onClick={() => selectedFlat(room.unit, roomIndex, floorIndex)} value={flatAreas} style={{ cursor: 'pointer', background: room.unitType ? "#E6E6E9" : '#E8F5E9', marginRight: '10px', marginBottom: '10px', border: '1px solid black' }}>{room.unit}</Tag>
                    ))}
                  </div>
                )
              })}
            </Col>
          </>
        </Row>
        : null
      }

      <Row style={{ justifyContent: 'center', textAlign: "center" }} id="btn">
        <Col lg="4" md="4" sm="12" xs="12">
          <Button
            id='prev'
            className="cust-button"
            style={{ marginTop: '22px', width: '25%' }}
            onClick={onClickPrev}
            disabled={addedFlatList.isLoading || disableButton()}
          >
            {addedFlatList.isLoading ? <Spin /> : "Prev"}
          </Button>


          <Button
            id='Add'
            className="cust-button"
            style={{ marginTop: '22px', width: '25%', display: 'none' }}
            onClick={handleSubmit}
            disabled={addedFlatList.isLoading || !selectArea || !squareFeet}
          >
            {addedFlatList.isLoading ? <Spin /> : 'Add'}
          </Button>

          <Button
            id="Next"
            className="cust-button"
            style={{ marginTop: '22px', width: '25%' }}
            onClick={onClickNone}
            disabled={addedFlatList.isLoading || disableButton()}
          >
            {addedFlatList.isLoading ? <Spin /> : 'Next'}
          </Button>

        </Col>
      </Row>
    </div >
  )
}

export default AddCommonAreaFlatList
