import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Spin, Alert, Switch, Tooltip, Popconfirm, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import FileBase64 from 'components/Custom/UploadFile'
import {
  resetAddSubCategory,
  addSubCategory,
} from 'redux/actions/addSubCategoryAction'
import { getFeature } from 'redux/actions/getFeatureAction'
import { getSubCategory } from 'redux/actions/getSubCategoryAction'

function SearchSubCategory(props) {
  const [featureId, setFeatureId] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [redirect, setRedirect] = useState('')
  const [categoryPriority, setCategoryPriority] = useState('')
  const [categoryIcon, setCategoryIcon] = useState('')
  const [layoutStyle, setLayoutStyle] = useState('')
  const [layoutSize, setLayoutSize] = useState('')
  const login = useSelector(({ login }) => login)
  const addedSubCategory = useSelector(({ addSubCategory }) => addSubCategory)
  const getFeatureCat = useSelector(({ getFeature }) => getFeature)
  const getSubCat = useSelector(({ getSubCategory }) => getSubCategory)
  const dispatch = useDispatch()
  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'categoryName') setCategoryName(value)
    if (name === 'redirect') setRedirect(value)
  }
  const handleAttachments = (filesList) => {
    setCategoryIcon(filesList[0].base64)
  }
  const { Option } = Select
  function onChangeSearch(value) {
    setFeatureId(value)
  }

  function onSearch(val) { }
  const getFeatureRequest = () => {
    const getFeatureReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getFeature(getFeatureReq))
  }

  useEffect(() => {
    getFeatureRequest()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    getSubCategoryRequest()
    props.setIsSearch()
  }
  const getSubCategoryRequest = () => {
    const getSubCategoryReq = {
      initiatedBy: login.data.user.role,
      featureId,
      name: categoryName,
      redirect,
      limit: 6
    }
    return dispatch(getSubCategory(getSubCategoryReq))
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center', width: "100%", margin: "auto" }}>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Sub Category Name</Label>
              <Input
                type="text"
                placeholder="Enter Sub Category Name"
                name="categoryName"
                id="serviceType"
                value={categoryName || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Feature Category</label>
              <Select
                type="select"
                showSearch
                placeholder="Select Feature Category"
                optionFilterProp="children"
                onChange={onChangeSearch}
                onSearch={onSearch}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Feature Category
                </Option>
                {getFeatureCat.success &&
                  getFeatureCat.data.featureCategory.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.name}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">App Screen Name</Label>
              <Input
                type="text"
                placeholder="App Screen Name"
                name="redirect"
                id="serviceType"
                value={redirect || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            // disabled={addedServiceType.isLoading || !serviceType}
            type="submit"
          >
            {getSubCat.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default SearchSubCategory
