import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import thunk from 'redux-thunk'
import { logger } from 'redux-logger'
import rootReducer from '../reducers'
import apiMiddleware from './middleware'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
}

const pReducer = persistReducer(persistConfig, rootReducer)

let middleware = [];

const initialState = {}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, thunk, logger, apiMiddleware];
} else {
  middleware = [...middleware, thunk,logger, apiMiddleware];
}

export const store = createStore(
  pReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
)

export const persistor = persistStore(store)
