import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
//import FileBase64 from 'react-file-base64';
import { Spin, Upload, message, Select } from 'antd'
import FileBase64 from 'components/Custom/UploadFile'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { addGuard, resetAddGuard } from 'redux/actions/addGuardAction'
import Util from 'validations/index'

import {
  resetgetServiceType,
  getServiceType,
} from 'redux/actions/getServiceTypeAction'
import { getHelperDoc } from 'redux/actions/getHelperDocAction'
import { resetGetDocument, getDocument } from 'redux/actions/getDocumentAction'

function AddGuard() {
  const [initiatedBy, setInitiatedBy] = useState('Society')
  const [guardName, setGuardName] = useState('')
  const [guardMobile, setGuardMobile] = useState('')
  const [guardPhoto, setGuardPhoto] = useState('')
  const [photo, setPhoto] = useState('')
  const [panCard, setPanCard] = useState('')
  const [aadhar, setAadhar] = useState('')
  //docs
  const [documentType, setDocumentType] = useState('')
  const [seacrhDocumentType, setSearchDocumentType] = useState('')
  const [guardDocument, setGuardDocument] = useState('')
  const [document, setDocument] = useState('')
  const [documentNumber, setDocumentNumber] = useState('')
  const [documentTypeId, setDocumentTypeId] = useState('')
  const [helperType, setHelperType] = useState()
  const [serviceType, setServiceType] = useState('')
  const [serviceTypeId, setServiceTypeId] = useState('')
  const [helperUpdatePhoto, setHelperUpdatePhoto] = useState(false)
  const docType = useSelector(({ getDocument }) => getDocument)

  const servType = useSelector(({ getServiceType }) => getServiceType)
  const helperTypeList = useSelector(({ getHelperType }) => getHelperType)
  const login = useSelector(({ login }) => login)
  const addedGuard = useSelector(({ addGuardDetail }) => addGuardDetail)
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'guardName')
      Util.alphabetsOnly(value) ? setGuardName(value) : setGuardName(guardName)
    if (name === 'guardMobile')
      Util.verifyMobile(value)
        ? setGuardMobile(value)
        : setGuardMobile(guardMobile)
    if (name === 'documentNumber')
      Util.alphaNumeric(value)
        ? setDocumentNumber(value)
        : setDocumentNumber(documentNumber)
  }

  const handleAttachments = (filesList) => {
    console.log(filesList,"file")
    let img = filesList.base64.split(`,`)
    setPhoto(img[1])
    setGuardPhoto(filesList.base64)
  }
  const handleAttachmentsDoc = (filesList) => {
    let img = filesList.base64.split(`,`)
    setGuardDocument(filesList.base64)
    setDocument(img[1])
  }

  const { Option } = Select

  function onChangeDocumentType(value) {
    const DocType = value.split(',')
    const DocTypeId = DocType[0]
    const DocTypeName = DocType[1]
    setDocumentTypeId(DocTypeId)
    setDocumentType(DocTypeName)
  }
  function onSearchDocumentType(value) {
    setSearchDocumentType(value)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    addGuardRequest()
    getServiceTypeRequest()
    getDocTypeRequest()
    getDocTypeRequests()
  }

  const addGuardRequest = () => {
    if (initiatedBy === 'Society') {
      const addGuardReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        name: guardName.trim(),
        photo,
        action: 'add',
        mobileNumber: guardMobile,
        role: 'Guard',
        guardDocumentList: [
          {
            societyId: login.data.society.id,
            document,
            documentType,
            documentTypeId,
            documentNumber,
          },
        ],
      }

      return dispatch(addGuard(addGuardReq))
    }
  }

  const getServiceTypeRequest = () => {
    if (initiatedBy === 'Society') {
      const getServiceTypeReq = {
        initiatedBy: login.data.user.role,
      }

      return dispatch(getServiceType(getServiceTypeReq))
    }
  }

  const getDocTypeRequest = () => {
    if (initiatedBy != 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy,
        societyId: login.data.society.id,
      }
      return dispatch(getHelperDoc(docTypeReq))
    }
  }
  useEffect(() => {
    getDocTypeRequest()
  }, [])

  const disabledButton = () => {
    return !(guardName && guardMobile && guardPhoto)
  }

  const getDocTypeRequests = () => {
    if (initiatedBy != 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy,
      }
      return dispatch(getDocument(docTypeReq))
    }
  }
  useEffect(() => {
    getDocTypeRequests()
  }, [])
  // useEffect(() => {
  //   if (guardPhoto != '') {
  //     NotificationPopups('success', 'Success', 'Image Uploaded')
  //   }
  // }, [guardPhoto])

  // //Upload docs
  // useEffect(() => {
  //   if (servType.success && servType.data.activeServiceType) {
  //     const serviceTypeId = servType.data.activeServiceType.filter(
  //       (d) => d.type === 'Society Maintenance Staff'
  //     )
  //     setServiceTypeId(serviceTypeId[0].id)
  //     setServiceType(serviceTypeId[0].type)
  //   }
  // }, [servType.success])

  // useEffect(() => {
  //   if (addedGuard.success) {
  //     setGuardMobile('')
  //     setGuardName('')
  //     setGuardPhoto('')
  //     NotificationPopups('success', 'Success', addedGuard.data.message)
  //   }
  //   if (addedGuard.failed) {
  //     NotificationPopups('error', 'Failed', addedGuard.data.message)
  //   }
  //   if (addedGuard.network) {
  //     NotificationPopups(
  //       'error',
  //       'Network Error',
  //       'Check your internet connection'
  //     )
  //   }
  //   return () => {
  //     dispatch(resetAddGuard())
  //   }
  // }, [addedGuard.success, addedGuard.failed, addedGuard.network])

  const panCardDetail = () => {
    let regex = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);

    if (documentNumber == '') {
      setPanCard("")
    }

    if (regex.test(documentNumber) == true) {
      setPanCard("Valid")
    }
    else if (documentNumber === "") {
      setPanCard("")
    }
    else {
      setPanCard("Error : Invaild PanCard Number")
    }
  }

  const aadharDetail = () => {
    let regex = new RegExp(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/);

    if (documentNumber == '') {
      setAadhar("")
    }

    if (regex.test(documentNumber) == true) {
      setAadhar("Valid")
    }
    else if (documentNumber === "") {
      setAadhar("")
    }
    else {
      setAadhar("Error : Invaild Aadhar Number")
    }
  }

  useEffect(() => {
    panCardDetail()
    aadharDetail()
  }, [documentNumber])

  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="12" sm="12" xs="12" lg="12">
            <FormGroup>
              <Label for="helperName">Name</Label>
              <span className='required'>*</span>
              <Input
                type="text"
                name="guardName"
                id="guardName"
                placeholder="Name"
                value={guardName || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>

          <Col md="12" sm="12" xs="12" lg="12">
            <FormGroup>
              <Label for="helperMobile">Mobile</Label>
              <span className='required'>*</span>
              <Input
                type="text"
                name="guardMobile"
                id="guardMobile"
                placeholder="Mobile"
                maxLength="10"
                value={guardMobile || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>

          <Col md="12" sm="12" xs="12" lg="12">
            <label>Profile Picture</label>
            <span className='required'>*</span>
            <FileBase64
              multiple={false}
              // name={false}
              id="addGuardPhoto"
              accept=".jpeg, .png, .jpg, .webp"
              onDone={handleAttachments}
              showUploadList={false}
            />
            {guardPhoto && (
              <img
                src={guardPhoto}
                width="50%"
                height="150"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            )}
          </Col>
          <Col md="12" sm="12" xs="12" lg="12">
            <FormGroup>
              <Label for="helperType">Document Type</Label>
              <Select
                type="select"
                value={documentType}
                showSearch
                placeholder="Select Service Type"
                optionFilterProp="children"
                onChange={onChangeDocumentType}
                onSearch={onSearchDocumentType}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Document Type
                </Option>
                {docType.success &&
                  docType.data.activeType.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.id},${d.type}`}
                    >
                      {d.type}
                    </Option>
                  ))}
                {docType.failed && (
                  <Option className="loginSelect" value="">
                    No Data Found
                  </Option>
                )}
              </Select>
            </FormGroup>
          </Col>
          {documentType && (
            <Col>
              <label>Upload {documentType}</label>
              <FileBase64
                multiple={false}
                name={false}
                id="documentGuard"
                accept=".jpeg, .png, .jpg"
                onDone={handleAttachmentsDoc}
                showUploadList={false}
              />
              {guardDocument && (
                <img
                  src={guardDocument}
                  width="50%"
                  height="150"
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
              )}
            </Col>
          )}
          {documentType === "Aadhar Card" && (
            <>
              <Col md="12" sm="12" xs="12" lg="12">
                <FormGroup>
                  <Label for="helperName">Enter {documentType} Number</Label>
                  <Input
                    type="text"
                    name="documentNumber"
                    id="documentNumber"
                    placeholder="Enter Document Number"
                    value={documentNumber || ''}
                    onChange={handleChange}
                    maxLength={12}
                    required
                  />
                </FormGroup>
              </Col>
              <span style={{ color: "red", margin: "auto" }}>{aadhar}</span>
            </>
          )}

          {documentType === "Pan Card" && (
            <>
              <Col md="12" sm="12" xs="12" lg="12">
                <FormGroup>
                  <Label for="helperName">Enter {documentType} Number</Label>
                  <Input
                    // style={{text}}
                    type="text"
                    name="documentNumber"
                    id="documentNumber"
                    placeholder="Enter Document Number"
                    value={documentNumber.toUpperCase() || ''}
                    onChange={handleChange}
                    maxLength={10}
                    required
                  />
                </FormGroup>
              </Col>
              <span style={{ color: "red", margin: "auto" }}>{panCard}</span>
            </>
          )}
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            disabled={disabledButton()}
          >
            {addedGuard.isLoading ? <Spin /> : "Add Guard"}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default AddGuard
