import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  Table,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  TabContent,
  TabPane,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Spin, Alert, Switch, Tooltip, Popconfirm, Select, Divider } from 'antd'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import {
  resetUpdateUnitFlatList,
  updateUnitFLatList,
} from 'redux/actions/updateUnitFlatListAction'

import PaginationComponent from 'views/Pagination'
import { resetGetParking, getParking } from 'redux/actions/getParkingAction'
import {
  resetUpdateParking,
  updateParking,
} from 'redux/actions/updateParkingSlotAction'
import { resetAddParking, addParking } from 'redux/actions/addParkingAction'
import { getAllUnitType, resetGetAllUnitType } from 'redux/actions/getAllUnitTypeAction'
import { addUnitSize, resetAddUnitSize } from 'redux/actions/addUnitSizeAction'
import { getAllUnitSize, resetGetAllUnitSize } from 'redux/actions/getAllUnitSizeAction'

import axios from 'config/axios'

import AddImg from 'assets/img/Icons/flat/add.png'
import Delete from 'assets/img/Icons/flat/delete.png'

import TrashBtn from 'assets/img/Brand/buttons/trash-icon.png'

function DisplayFlats({ type, areaType, areaTypeId }) {
  const [buildingId, setBuildingId] = useState('')
  const [id, setId] = useState('')
  const [limit, setLimit] = useState(10)
  const [modal, setModal] = useState(false)
  const [unitEdit, setUnitEdit] = useState('')
  const [floor, setFloor] = useState('')
  const [bldgName, setBldgName] = useState('')
  const [parkingName, setParkingName] = useState('')
  const [parkingId, setParkingId] = useState('')
  const [parkingSlot, setParkingSlot] = useState('')
  const [parkingSlotId, setParkingSlotId] = useState([])
  const [serachAreaName, setSearchAreaName] = useState('')
  const [serachAreaName2, setSearchAreaName2] = useState('')
  const [searchparking, setSearchParking] = useState('')
  const [showParking, setShowParking] = useState(false)
  const [parkingEditSlotAdded, setEditParkingSlotsAdded] = useState([])
  const [originalParkingData, setOriginalParkingData] = useState([])
  const [parkingSlotsAdded, setPArkingSlotsAdded] = useState([])
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [options3, setOptions3] = useState([])
  const [options4, setOptions4] = useState([])
  const [options5, setOptions5] = useState([])
  const [options6, setOptions6] = useState([])
  const [parkingSlotName, setParkingSlotName] = useState('')
  const [submit, setSubmit] = useState(false)
  const [page, setPage] = useState(1)
  const [idToRemove, setIdToRemove] = useState([])
  const [deleteData, setDeleteData] = useState([])
  const [isParkingEdit, setIsParkingEdit] = useState(false)
  const [isActiveEdit, setisActiveEdit] = useState(false)
  const [addUnitmodal, setAddUnitModal] = useState(false)
  const [unitSize, setUnitSize] = useState('')
  const [selectArea, setSelectArea] = useState('')
  const [squareFeet, setSquareFeet] = useState('')
  const [areaUnit, setAreaUnit] = useState('')

  const buildingNames = useSelector(({ buildingNames }) => buildingNames)
  const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)
  const addedFlatList = useSelector(({ addUnitFlatList }) => addUnitFlatList)
  const login = useSelector(({ login }) => login)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  const updatedUnitFlat = useSelector(({ updateUnitFlatList }) => updateUnitFlatList)
  const ParkingList = useSelector(({ getParking }) => getParking)
  const getAllUnitTypeRes = useSelector(({ getAllUnitType }) => getAllUnitType)
  const addUnitSizeRes = useSelector(({ addUnitSize }) => addUnitSize)
  const getAllUnitSizeRes = useSelector(({ getAllUnitSize }) => getAllUnitSize)

  const dispatch = useDispatch()

  const totalItems = getFlat.data.count
  // const pageSize = Math.floor(totalItems / limit)

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'buildingId') setBuildingId(value)
    if (name === 'floor') setFloor(value)
    if (name === 'unitSize') setUnitSize(value)
    if (name === 'selectArea') setSelectArea(value)
    if (name === "squareFeet") setSquareFeet(value)
  }
  const handleEditChange = ({ target: { name, value } }) => {
    if (name === 'unitEdit') setUnitEdit(value)
  }

  // const handleUnitSize = ({ target: { name, value } }) => {

  // }
  const onChangeSwitch = (checked) => {
    setIsParkingEdit(checked)
  }
  const onChangeSwitch2 = (checked) => {
    setisActiveEdit(checked)
  }

  const { Option } = Select

  function onChangeSearch(value) {
    let val = value.split(',')
    let val1 = val[0]
    let val2 = val[1]
    setBuildingId(val1)
    setBldgName(val2)
    setSearchAreaName('')
    setPage(1)
  }

  function onSearch(val) {
    setSearchAreaName(val)
  }
  function onChangeParking(value) {
    let val = value.split(',')
    let parkingId = val[0]
    let parkingName = val[1]
    setParkingName(parkingName)
    setParkingId(parkingId)
    setSearchAreaName2('')
    setParkingSlotId('')
    setParkingSlot('')
    setParkingSlotName('')
  }
  function onSearchParking(val) {
    setSearchAreaName2('')
  }
  function onChangeParkingSlot(value) {
    let val = value.split(',')
    let parkingSlotId = val[0]
    let parkingSlot = val[1]
    setParkingSlotId(parkingSlotId)
    setParkingSlot(parkingSlot)
    setSearchAreaName2('')
  }
  function onSearchParkingSlot(val) {
    setParkingSlotName(val)
  }
  function toggleParking() {
    setShowParking(true)
  }

  function getParkingRequest() {
    const getParkingReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      areaId: parkingId,
      action: 'occupied',
      filter: 'visitor',
    }
    return dispatch(getParking(getParkingReq))
  }

  useEffect(() => {
    if (parkingId !== '') {
      getParkingRequest()
    }
  }, [parkingId])

  function addParkingSlot() {
    if (parkingSlotId && parkingName && parkingSlot) {
      const obj = {
        id: parkingSlotId,
        areaName: parkingName.split('-')[0],
        name: parkingSlot,
      }
      setParkingName('')
      setParkingId('')
      setParkingSlot('')
      setParkingSlotId('')
      setPArkingSlotsAdded([...parkingSlotsAdded, obj])
      setEditParkingSlotsAdded([...parkingEditSlotAdded, obj])
    }
  }

  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName])

  useEffect(() => {
    if (serachAreaName2 != '') {
      const userData = {
        areaType: 'parking',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName2,
      }
      axios.post('society/getAreaList', userData).then((res) => {
        setOptions3(res.data.areaListActive)
      })
    }
  }, [serachAreaName2])

  useEffect(() => {
    if (serachAreaName2 === '') {
      const userData = {
        areaType: 'parking',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      axios.post('society/getAreaList', userData).then((res) => {
        setOptions4(res.data.areaListActive)
      })
    }
  }, [])

  useEffect(() => {
    if (parkingSlot !== '') {
      const userData = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        unitId: parkingId,
        name: parkingSlotName,
      }
      axios.post('society/getParkingSlot', userData).then((res) => {
        setOptions5(res.data.parkingSlotVOList)
      })
    }
  }, [parkingSlot])

  const getUnitFlatListRequest = () => {
    const getUnitFlatListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      areaId: buildingId,
      areaType: type,
      page,
      limit,
    }
    return dispatch(getUnitFlatList(getUnitFlatListReq))
  }

  const handleSubmit = (e) => {
    // setSubmit(!submit)
    e.preventDefault()
    getUnitFlatListRequest()
  }

  const toggle = () => {
    setModal(!modal)
    setParkingName('')
    setParkingSlot('')
    return () => (
      dispatch(resetGetParking())
    )
  }

  const openEditModal = (data) => {
    setAreaUnit(data.unitType)
    setSquareFeet(data.unitSize)
    setFloor(data.floor)
    setModal(!modal)
    setUnitEdit(data.unit)
    setIsParkingEdit(data.isParking)
    setId(data.id)
    setisActiveEdit(data.isActive)
    setPArkingSlotsAdded(data.parking)
    setOriginalParkingData(data.parking)
  }
  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  const handleUnitEditFlatList = (e) => {
    e.preventDefault()
    updateUnitFlatListRequest()
  }

  const updateUnitFlatListRequest = () => {
    const updateUnitFlatListReq = {
      initiatedBy: login.data.user.role,
      floor,
      id,
      unit: unitEdit,
      isParking: isParkingEdit,
      isActive: isActiveEdit,
      unitSize: squareFeet.includes("sqft") ? squareFeet : squareFeet + "sqft",
      unitType: areaUnit,
      addParkingSlots: parkingEditSlotAdded.map((d) => d.id),
      removeParkingSlots: idToRemove
    }
    return dispatch(updateUnitFLatList(updateUnitFlatListReq))
  }

  const removeParkingSlot = (data, areaName, name, id) => {

    const parkId = [...parkingSlotsAdded]
    parkId.splice(id, 1)
    setPArkingSlotsAdded(parkId)
    if (!idToRemove.includes(data)) idToRemove.push(data)
    if (!deleteData.includes([areaName, name])) deleteData.push([areaName, name])
    console.log(idToRemove, data, parkId, areaName, name, "delete", deleteData, "parkingSlotsAdded")
  }

  const moveToassigned = (id) => {
    deleteData.splice(id, 1)
  }

  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: type === "Flat" ? 'building' : type,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName])

  useEffect(() => {
    setBldgName('')
    if (serachAreaName === '') {
      const userData = {
        areaType: type === "Shop" || type === "Bungalow" ? type : 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions2(res.data.activeAreaList)
      })
    }
  }, [type, buildingList.success])

  useEffect(() => {
    if (getFlat.success) {
      getUnitFlatListRequest()
      // setBuildingId('')
    }
    return () => {
      dispatch(resetGetUnitFlatList())
    }
  }, [page, type])

  useEffect(() => {
    if (updatedUnitFlat.success) {
      setModal(false)
      setParkingId('')
      setParkingSlot('')
      setParkingName('')
      setParkingSlotId([])
      setEditParkingSlotsAdded([])
      getUnitFlatListRequest()
      setDeleteData([])
      setIdToRemove([])
      // NotificationPopups('success', 'Success', updatedUnitFlat.data.message)
    }
    if (updatedUnitFlat.failed) {
      getUnitFlatListRequest()
      setParkingSlotId([])
      // NotificationPopups('error', 'Failed', updatedUnitFlat.data.message)
    }
    return () => {
      dispatch(resetUpdateUnitFlatList())
      dispatch(resetGetUnitFlatList())
      dispatch(resetGetParking())
    }
  }, [updatedUnitFlat.success, updatedUnitFlat.failed, updatedUnitFlat.network])

  useEffect(() => {
    if (type === 'Flat') {
      if (updatedUnitFlat.success) {
        NotificationPopups('success', 'Success', updatedUnitFlat.data.message)
      }
      if (updatedUnitFlat.failed) {
        NotificationPopups('error', 'Failed', updatedUnitFlat.data.message)
      }
    }
  }, [updatedUnitFlat.success, updatedUnitFlat.failed])

  useEffect(() => {
    if (getFlat.data.message === 'Mandatory Parameter Missing') {
      if (type === 'Shop' || type === "Bungalow" || type === "Flat") {
        setBuildingId('')
        NotificationPopups("error", "Failed", `Select ${type === "Flat" ? "Building" : type} ${type === "Flat" ? '' : "Area"} Name`)
      }
    }
    else if (getFlat.data.message === "Not Found") {
      NotificationPopups("error", "Failed", getFlat.data.message)
    }

    return () => {
      dispatch(resetGetUnitFlatList())
    }
  }, [getFlat.failed, type])

  console.log(getFlat,"getFlat.data.message")

  // Add Unit Size

  const toggleAdd = () => {
    setAddUnitModal(!addUnitmodal)
  }


  const getAllUnitTypeRequest = () => {
    const flatAreaTypeReq = {
      initiatedBy: login.data.user.role
    }
    return dispatch(getAllUnitType(flatAreaTypeReq))
  }

  useEffect(() => {
    getAllUnitTypeRequest()
  }, [])

  const handleUnitSubmit = () => {
    const unitSizeReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      // "areaId":31,
      unitType: selectArea,
      unitSize: unitSize
    }
    return dispatch(addUnitSize(unitSizeReq))
  }

  useEffect(() => {
    if (addUnitSizeRes.success) {
      setAddUnitModal(false)
      setSelectArea('')
      setUnitSize('')
      NotificationPopups('success', 'Success', addUnitSizeRes.data.message)
    }
    if (addUnitSizeRes.failed) {
      NotificationPopups('error', 'Failed', addUnitSizeRes.data.message)
    }
    if (addedFlatList.network) {
      NotificationPopups('error', 'Network Error', 'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddUnitSize())
    }
  }, [addUnitSizeRes.success, addUnitSizeRes.failed, addUnitSizeRes.network])

  // Get Unit Area Size

  const getAllUnitSizRequest = () => {
    const unitSizeReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id
    }
    return dispatch(getAllUnitSize(unitSizeReq))
  }

  useEffect(() => {
    getAllUnitSizRequest()
  }, [])

  const onChangeArea = (value) => {
    setAreaUnit(value)

  }

  return (
    <>
      <div className="listcontent">
        <Card>
          <div style={{ padding: '25px' }}>
            <Row>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Col><div className="typeHeading" id="onTop">{`${type}s`}</div></Col>

                <div style={{ marginRight: '15px' }}>
                  <Button
                    color="secondary"
                    className="modalCustomButton"
                    onClick={() => {
                      setAddUnitModal(!addUnitmodal)
                    }}
                  >
                    Add {type} Type
                  </Button>
                </div>
              </div>
            </Row>

            {options2 ?
              <Form onSubmit={handleSubmit}>
                <Row style={{ justifyContent: 'center' }}>
                  <Col md="4" sm="12">
                    <FormGroup>
                      <label>{type === "Flat" ? "Building" : type} {type === "Flat" ? null : "Area"} Name</label>
                      <Select
                        type="select"
                        value={bldgName}
                        showSearch
                        placeholder={`Select ${type === "Flat" ? "Building" : type} ${type === "Flat" ? '' : "Area"} Name`}
                        onChange={onChangeSearch}
                        onSearch={onSearch}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="" >
                          Select {type === "Flat" ? "Building" : type} {type === "Flat" ? null : "Area"} Name
                        </Option>
                        {serachAreaName != '' &&
                          options != undefined &&
                          options.map((d, id) => (
                            <Option
                              key={id}
                              className="loginSelect"
                              value={`${d.areaId},${d.areaName}`}
                            >
                              {d.parentName}/{d.areaName}
                            </Option>
                          ))}
                        {serachAreaName === '' &&
                          options2.map((d, id) => (
                            <Option
                              key={id}
                              className="loginSelect"
                              value={`${d.areaId},${d.areaName}`}
                            >
                              {d.parentName ? `${d.parentName}/${d.areaName}` : `${d.areaName}`}
                            </Option>
                          ))}
                      </Select>
                    </FormGroup>
                  </Col>

                  <Col md="4" sm="12">
                    <FormGroup>
                      <Button
                        className="cust-button"
                        style={{ marginTop: '22px' }}
                        onClick={handleSubmit}
                        disabled={getFlat.isLoading}
                      >
                        {getFlat.isLoading ? <Spin /> : 'Search'}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
              :
              <Form onSubmit={handleSubmit}>
                <Row style={{ justifyContent: 'center' }}>
                  <Col md="4" sm="12">
                    <FormGroup>
                      <label>Add {type}</label>
                      <Select
                        type="select"
                        value={bldgName}
                        showSearch
                        placeholder={`Add ${type}`}
                        onChange={onChangeSearch}
                        onSearch={onSearch}
                        style={{ width: '100%' }}
                        disabled
                      >
                        <Option className="loginSelect" value="" >
                          Add {type}
                        </Option>
                      </Select>
                    </FormGroup>
                  </Col>

                  <Col md="4" sm="12">
                    <FormGroup>
                      <Button
                        className="cust-button"
                        style={{ marginTop: '22px' }}
                        onClick={handleSubmit}
                        disabled={getFlat.isLoading}
                      >
                        {getFlat.isLoading ? <Spin /> : 'Search'}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            }
            {getFlat.success && (
              <Col md="12" style={{ padding: '20px' }}>
                <Table responsive style={{ textAlign: 'center' }}>
                  <thead>
                    <tr>
                      <th>{type} Number</th>
                      <th>Floor</th>
                      <th>Type</th>
                      <th>Size</th>
                      <th>Parking</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  {getFlat.success && (
                    <tbody>
                      {getFlat.data.activeUnitList.map((d, id) => (
                        <React.Fragment key={id}>
                          <tr key={id} id={`toggler${id}`}>
                            <td>
                              <span
                                style={{
                                  marginLeft: '20px',
                                  textTransform: 'capitalize',
                                  fontWeight: 'semi-bold',
                                  color: ' #00326c',
                                }}
                              >
                                {d.unit}
                              </span>
                            </td>
                            <td>{d.floor ? d.floor : 'G'}</td>
                            <td>{d.unitType ? d.unitType : '-'}</td>
                            <td>{d.unitSize ? d.unitSize : '-'}</td>
                            <td>
                              {d.isParking
                                ? d.parking.length === 0
                                  ? 'Available'
                                  : d.parking.map((x, idx) => <span>{x.name}{idx !== (d.parking.length - 1) ? "," : ''}</span>)
                                : 'Unavailable'}
                            </td>
                            <td>{d.isActive ? 'Active' : 'Inactive '}</td>
                            <td>
                              <Button
                                color="secondary"
                                className="modalCustomButton"
                                onClick={() => {
                                  openEditModal(d)
                                }}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  )}
                </Table>
              </Col>
            )}
            <Col>
              {getFlat.success && !getFlat.data.activeUnitList.length && (
                <div style={{ textAlign: 'center', padding: '20px 0px' }}>
                  <Alert message={getFlat.data.message} type="info" />
                </div>
              )}
              {getFlat.data.message === 'Mandatory Parameter Missing' ? null : getFlat.failed && (
                <div style={{ textAlign: 'center', marginTop: '40px' }}>
                  <Alert message={getFlat.data.message} type="info" />
                </div>
              )}
            </Col>
            <Col>
              {getFlat.success && (
                <PaginationComponent
                  limit={limit}
                  totalItems={totalItems}
                  onSelect={handlePagination}
                  defaultPage={page}
                />
              )}
            </Col>

            {modal && (
              <Modal
                isOpen={modal}
                toggle={toggle}
                centered
                size="sm"
                style={{ maxWidth: '300px' }}
              >
                <ModalHeader toggle={toggle}>Edit</ModalHeader>
                <ModalBody>
                  <FormGroup>
                    <Label for="unit">{type} Number <span className='required'>*</span></Label>
                    <Input
                      type="text"
                      name="unitEdit"
                      id="unitEdit"
                      value={unitEdit}
                      onChange={handleEditChange}
                      required
                    />
                  </FormGroup>
                  {unitEdit === '' ? <p className='required' style={{ fontSize: '12px' }}>{type} Number cannot be blank.</p> : ''}
                  <FormGroup>
                    <Label for="floor">Floor</Label>
                    <Input
                      type="text"
                      name="floor"
                      id="floor"
                      placeholder="floor"
                      value={floor || ''}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Select Area</Label>
                    <span className='required'>*</span>
                    <Select
                      type="select"
                      showSearch
                      value={areaUnit}
                      placeholder="Select Area"
                      optionFilterProp="children"
                      onChange={onChangeArea}
                      // onSearch={onSearchArea}
                      style={{ width: '100%' }}
                    >
                      <Option className="loginSelect" value="">
                        Select Area
                      </Option>
                      {getAllUnitSizeRes && getAllUnitSizeRes.data.unitSizeList.map((item, id) => (
                        <Option
                          key={id}
                          className="loginSelect"
                          value={item.unitType}
                        >
                          {item.unitType}
                        </Option>
                      ))}
                    </Select>
                  </FormGroup>
                  <FormGroup>
                    <Label for="squareFeet">Area Sqaure Feet</Label>
                    <Input
                      type="text"
                      name="squareFeet"
                      id="squareFeet"
                      placeholder="Area Sqaure Feet"
                      value={squareFeet || ''}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Parking</Label>
                    <Switch
                      defaultChecked={isParkingEdit}
                      name="isParking"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={onChangeSwitch}
                      style={{ marginLeft: '10px' }}
                    />
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <Label>Active</Label>
                    <Switch
                      defaultChecked={isActiveEdit}
                      name="isActive"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={onChangeSwitch2}
                      style={{ marginLeft: '10px' }}
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <Button
                      color="secondary"
                      style={{ float: 'left' }}
                      className="modalCustomButton"
                      onClick={() => toggleParking()}
                    >
                      Add Parking
                    </Button>
                  </FormGroup>
                  */}
                  {isParkingEdit &&
                    (options4 !== undefined ?
                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <Label for="buildingId">Parking Area</Label>
                            <Select
                              type="select"
                              showSearch
                              value={parkingName}
                              placeholder="Select Parking name"
                              optionFilterProp="children"
                              onChange={onChangeParking}
                              onSearch={onSearchParking}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              style={{ width: '100%' }}
                            >
                              <Option className="loginSelect" value="">
                                Select Parking Area
                              </Option>
                              {serachAreaName != '' &&
                                options3 != undefined &&
                                options3.map((d, id) => (
                                  <Option
                                    key={id}
                                    className="loginSelect"
                                    value={`${d.areaId},${d.areaName}`}
                                  >
                                    {d.areaName}
                                  </Option>
                                ))}
                              {serachAreaName === '' &&
                                options4 != undefined &&
                                options4.map((d, id) => (
                                  <Option
                                    key={id}
                                    className="loginSelect"
                                    value={`${d.areaId},${d.areaName}`}
                                  >
                                    {d.areaName}
                                  </Option>
                                ))}
                            </Select>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          {options5 != undefined ?
                            <FormGroup>
                              <Label for="buildingId">Parking Slot</Label>
                              <Select
                                type="select"
                                // mode="multiple"
                                showSearch
                                value={parkingSlot}
                                placeholder="Select Parking name"
                                optionFilterProp="children"
                                onChange={onChangeParkingSlot}
                                onSearch={onSearchParkingSlot}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: '100%' }}
                              >
                                <Option className="loginSelect" value="">
                                  {ParkingList.data.message === "Not Found" ? "No Slots Found " : "Select Parking Slot"}
                                </Option>
                                {ParkingList.success &&
                                  ParkingList.data.parkingSlotVOList.map((d) => (
                                    <Option
                                      className="loginSelect"
                                      value={`${d.id},${d.name}`}
                                    >
                                      {d.name}
                                    </Option>
                                  ))}
                                {parkingSlotName != '' &&
                                  options5 != undefined &&
                                  options5.map((d, id) => (
                                    <Option
                                      key={id}
                                      className="loginSelect"
                                      value={`${d.id},${d.name}`}
                                    >
                                      {d.name}
                                    </Option>
                                  ))}
                              </Select>
                            </FormGroup> :
                            <Row style={{ justifyContent: 'center' }}>
                              <span style={{ color: "red" }}>Please add parking slots to assign parking.</span>
                            </Row>}
                        </Col>
                        <Col sm="12">
                          <FormGroup>
                            <Button
                              color="secondary"
                              className="modalCustomButton"
                              // style={{ float: 'left' }}
                              onClick={addParkingSlot}
                              disabled={
                                !parkingName || !parkingSlot || !parkingSlotId
                              }
                            >
                              Add
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                      :
                      <Row style={{ justifyContent: 'center' }}>
                        <span style={{ color: "red" }}>Please add parking slots to assign parking.</span>
                      </Row>)}
                  <Row>
                    <Col>
                      {parkingSlotsAdded.map((data, idx) => {
                        return (
                          <div key={idx} style={{ padding: '5px' }}>
                            <span> {`${data.areaName} / ${data.name}`}</span>

                            <span style={{ marginLeft: '15px', cursor: "pointer" }}>
                              <img
                                src={TrashBtn}
                                alt="edit"
                                width="20px"
                                height="20px"
                                onClick={() => removeParkingSlot(data.id, data.areaName, data.name, idx)}
                              />
                            </span>
                          </div>
                        )
                      })}
                      {deleteData.length !== 0 ? <>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span style={{ paddingLeft: 5 }}>To Be Removed</span>
                          <span style={{ cursor: "pointer", color: '#1488CC' }} onClick={() => {
                            setDeleteData([])
                            setIdToRemove([])
                            setPArkingSlotsAdded(originalParkingData)
                          }}>Undo</span>
                        </div>
                        <Divider style={{ borderWidth: 0.5, margin: 0, backgroundColor: 'grey' }} />
                        {deleteData.map((del, id) => {
                          return (
                            <div key={id} style={{ padding: '5px' }}>
                              <span> {`${del[0]} / ${del[1]}`}</span>
                            </div>
                          )
                        })}
                      </> : null}
                    </Col>
                  </Row>
                  <Row>
                    <FormGroup style={{ width: '100%' }}>
                      <Button
                        color="secondary"
                        className="modalCustomButton"
                        style={{ marginLeft: '20px' }}
                        onClick={handleUnitEditFlatList}
                        disabled={updatedUnitFlat.isLoading}
                      >
                        {updatedUnitFlat.isLoading ? <Spin /> : 'Edit'}
                      </Button>
                      <Button
                        color="secondary"
                        style={{ float: 'right', marginRight: '20px' }}
                        className="modalCustomButton"
                        onClick={toggle}
                      >
                        Close
                      </Button>
                    </FormGroup>
                  </Row>
                </ModalBody>
              </Modal>
            )}

            {addUnitmodal && (
              <Modal
                isOpen={addUnitmodal}
                toggle={toggleAdd}
                size="sm"
                style={{ maxWidth: '350px' }}
              >
                <ModalHeader toggle={toggleAdd}>Add {type} Type</ModalHeader>
                <ModalBody>
                  <Form onSubmit={handleUnitSubmit}>
                    <FormGroup>
                      <Label>Select Area <span className='required'>*</span></Label>
                      <Input
                        type="text"
                        name="selectArea"
                        id="selectArea"
                        value={selectArea}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="unitSize">Unit Size <span className='required'>*</span></Label>
                      <Input
                        type="text"
                        name="unitSize"
                        id="unitSize"
                        value={unitSize}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>

                    <Row style={{ justifyContent: 'center' }}>
                      <Button
                        className="cust-button"
                        style={{ marginTop: '22px', width: '25%' }}
                        disabled={!selectArea || !unitSize || addedFlatList.isLoading}
                        onClick={handleUnitSubmit}
                      >
                        {addedFlatList.isLoading ? <Spin /> : 'Add'}
                      </Button>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
            )}
          </div>
        </Card >
      </div >
    </>
  )
}

export default DisplayFlats
