import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Bar, Line } from 'react-chartjs-2'

import moment from 'moment'

function AmenityBarChart({ counterValue, chartBtn, dataCounterBtn }) {
  const [chartData, setChartData] = useState({})

  const Chart = () => {
    var dateArray = []

    var counterDataResolvedArray = []
    var counterDataRejectedArray = []
    var counterDataPendingArray = []

    if (dataCounterBtn == 'weekly' || dataCounterBtn == 'monthly') {
      counterValue.forEach(function (element, index) {
        !element.toDate
          ? dateArray.push(moment(element.fromDate).format('DD MMM'))
          : dateArray.push(
            moment(element.toDate).format('DD MMM') +
            ' - ' +
            moment(element.fromDate).format('DD MMM')
          )
        counterDataPendingArray.push(element.pendingCount)
        counterDataRejectedArray.push(element.rejectedCount)
        counterDataResolvedArray.push(element.approvedCount)
      })
    } else {
      counterValue.forEach(function (element, index) {
        dateArray.push(
          element.month.slice(0, 3) +
          ' ' +
          Number(String(element.year).slice(2, 4))
        )

        counterDataPendingArray.push(element.pendingCount)
        counterDataRejectedArray.push(element.rejectedCount)
        counterDataResolvedArray.push(element.resolvedCount)
      })
    }

    var bar_ctx = document.getElementById('bar-chart').getContext('2d');
    var white_pink_gradient = bar_ctx.createLinearGradient(0, 0, 0, 600);
    white_pink_gradient.addColorStop(0, 'white');
    white_pink_gradient.addColorStop(1, 'yellow');

    var white_red_gradient = bar_ctx.createLinearGradient(0, 0, 0, 600);
    white_red_gradient.addColorStop(0, 'white');
    white_red_gradient.addColorStop(1, '#fd4b2f');

    var white_green_gradient = bar_ctx.createLinearGradient(0, 0, 0, 600);
    white_green_gradient.addColorStop(0, 'white');
    white_green_gradient.addColorStop(1, 'green');


    setChartData({
      labels: Object.values(dateArray),
      datasets: [
        {
          label: 'Pending',
          data: counterDataPendingArray,
          // backgroundColor: 'rgba(240, 135, 0,0.2)',
          borderColor: 'yellow',
          borderWidth: 1,
          backgroundColor: white_pink_gradient,
          hoverBackgroundColor: white_pink_gradient,
          hoverBorderWidth: 2,
          hoverBorderColor: '#CB9D06'
        },
        {
          label: 'Rejected',
          data: counterDataRejectedArray,
          // backgroundColor: 'rgba(211, 63, 73,0.2)',
          borderColor: '#fd4b2f',
          borderWidth: 1,
          backgroundColor: white_red_gradient,
          hoverBackgroundColor: white_red_gradient,
          hoverBorderWidth: 2,
          hoverBorderColor: 'red'
        },
        {
          label: 'Approved',
          data: counterDataResolvedArray,
          // backgroundColor: 'rgba(66, 147, 51, 0.2)',
          borderColor: 'green',
          borderWidth: 1,
          backgroundColor: white_green_gradient,
          hoverBackgroundColor: white_green_gradient,
          hoverBorderWidth: 2,
          hoverBorderColor: 'green'
        },
      ],
    })
  }
  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Bar Chart - Stacked',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }

  useEffect(() => {
    Chart()
  }, [])

  useEffect(() => {
    Chart()
  }, [counterValue])

  console.log(chartData)

  return (
    <>
      <div className="App">
        <div>
          <Bar
            data={chartData}
            options={{
              responsive: true,
              title: { text: 'THICCNESS SCALE', display: true },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
              title: {
                display: true,
              },
            }}
          />
          <canvas id="bar-chart" width={0} height={0} />
        </div>
      </div>
    </>
  )
}

export default AmenityBarChart
