import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  resetAddAdminStatus,
  addAdminStatus,
} from 'redux/actions/addAdminStatusAction'
import {
  Button,
  FormGroup,
  Input,
  Row,
  Col,
  Table,
  UncontrolledCollapse,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback
} from 'reactstrap'
import { Alert, Popconfirm, Spin, Tooltip, Select, Collapse } from 'antd'
import Util from 'validations/index'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import DisplayAttachment from '../../components/Custom/DisplayAttachment'
import {
  getAdminList,
  resetgetAdminList,
} from 'redux/actions/getAdminListAction'
import {
  resetUpdateSocietyDocumentStatus,
  updateSocietyDocumentStatus,
} from 'redux/actions/updateSocietyDocumentStatusAction'
import { UpdateAdminUser, resetUpdateAdminUser } from 'redux/actions/updateAdminUserAction'
// import { updateMemberRole,resetUpdateMemberRole } from 'redux/actions/updateMemberRoleAction'
import { UpdateAdminSocietyRole, resetUpdateAdminSocietyRole } from 'redux/actions/updateAdminSocietyRoleAction'
import PaginationComponent from 'views/Pagination'

import RightIcon from 'assets/img/Icons/options/right.png'
import WrongIcon from 'assets/img/Icons/options/wrong.png'
import EditBtn from 'assets/img/Brand/buttons/edit-icon.png'

function AdminsPendingList({ adminList, handleStatus, onTap, city, socname, status }) {
  const [userId, setUserId] = useState('')
  const [docId, setdocId] = useState('')
  const [docRemark, setdocRemark] = useState('')
  const [rejectRemark, setRejectRemark] = useState('')
  const [limit] = useState(9)
  const [page, setPage] = useState(1)
  const [showMsgField, setshowMsgField] = useState(false)
  const [showRejectMsg, setShowRejectMsg] = useState(false)
  const [file, setFile] = useState('')
  const [viewDetails, setViewDetails] = useState(false)
  const [updateSocietyUserModal, setUpdateSocietyUserModal] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [id, setId] = useState('')
  const [societyId, setSocietyId] = useState('')
  const [role, setRole] = useState('')
  const [societyRole, setSocietyRole] = useState('')
  const [otherRole, setOtherRole] = useState('')
  const [societyUserId, setSocietyUserId] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [showTextArea, setShowTextArea] = useState(false)
  // const [adminStatus,se]
  const [fileModal, setFileModal] = useState(false)
  const [docStatus, setDocStatus] = useState('Pending')
  const [statusChanged, setStatusChanged] = useState(true)
  const dispatch = useDispatch()
  const login = useSelector(({ login }) => login)
  // const adminList = useSelector(({ adminList }) => adminList)
  const adminStatus = useSelector(({ addAdminStatus }) => addAdminStatus)
  const updatedSocDocStatus = useSelector(
    ({ updateSocietyDocumentStatus }) => updateSocietyDocumentStatus
  )

  const updateAdminSocietyRoleRes = useSelector(({ updateAdminSocietyRole }) => updateAdminSocietyRole)
  console.log(updateAdminSocietyRoleRes,"updateAdminSocietyRoleRes")

  const updateAdminUserRes = useSelector(({ updateAdminUser }) => updateAdminUser)

  const totalItems = adminList.data.count
  const pageSize = Math.floor(totalItems / limit)

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  useEffect(() => {
    setPage(1)
  }, [onTap])

  const handleAdminStatus = (id, status) => {
    setUserId(id)
    if (status === 'approved') {
      const adminStatus = {
        id: id,
        adminStatus: status,
        initiatedBy: login.data.user.role,
      }
      return dispatch(addAdminStatus(adminStatus))
    }
    if (status === 'rejected') {
      setShowRejectMsg(true)
    }
  }

  const adminStatusRequest = (id, status) => {
    const adminStatus = {
      id,
      adminStatus: status,
      initiatedBy: login.data.user.role,
      message: rejectRemark,
    }
    return dispatch(addAdminStatus(adminStatus))
  }

  const adminListRequest = () => {
    const adminListReq = {
      adminStatus: status,
      initiatedBy: login.data.user.role,
      limit,
      page,
    }

    return dispatch(getAdminList(adminListReq))
  }
  useEffect(() => {
    adminListRequest()
  }, [page,updateAdminUserRes.success,updateAdminSocietyRoleRes.success])

  const handleOpenFile = (a) => {
    setFile(a)
    setFileModal(true)
  }

  //Toggle For Attachment Modal
  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }
  // console.log(adminList)
  const handleRemark = ({ target: { name, value } }) => {
    // console.log(id)
    if (name === 'docRemark') setdocRemark(value)
    if (name === 'rejectRemark') setRejectRemark(value)
  }

  // const handleClick = (id) => {
  //   console.log(id)
  //   if (userId != id) {
  //     setRejectRemark('')
  //     setdocRemark('')
  //   }
  // }

  const handleDocStatus = (id, status) => {
    setdocId(id)
    setdocRemark('')
    if (status === 'rejected') {
      setshowMsgField(true)
      setDocStatus(status)
    } else {
      setshowMsgField(false)
      setDocStatus(status)
      updateSocietyDocumentStatusRequest(id, status)
    }
  }

  const handleRejectDoc = (e, id, status) => {
    setshowMsgField(false)
    e.preventDefault()

    if (docRemark != '') {
      const updateSocietyDocumentStatusReq = {
        id: id,
        documentStatus: status,
        initiatedBy: login.data.user.role,
        remark: docRemark,
      }
      return dispatch(
        updateSocietyDocumentStatus(updateSocietyDocumentStatusReq)
      )
    }
  }

  const updateSocietyDocumentStatusRequest = (docId, docStatus) => {
    const updateSocietyDocumentStatusReq = {
      id: docId,
      documentStatus: docStatus,
      initiatedBy: login.data.user.role,
    }
    return dispatch(updateSocietyDocumentStatus(updateSocietyDocumentStatusReq))
  }

  useEffect(() => {
    setPage(1)
  }, [adminList.failed])
console.log(adminStatus,"adminStatus")
  useEffect(() => {
    if (adminStatus.success) {
      adminListRequest('pending')
      setdocRemark('')
      setRejectRemark('')
      NotificationPopups('success', 'Success', adminStatus.data.message)
    }
    if (adminStatus.failed) {
      NotificationPopups('error', 'Failed', (adminStatus.data.message === "Mandatory Parameter Missing" ? adminStatus.data.errorVOList.map((item,id) =>(<>{id === 0 ? 1 : id+1}-{item.errorMessage}</>)):adminStatus.data.message))
    }
    return () => {
      dispatch(resetAddAdminStatus())
    }
  }, [adminStatus.success, adminStatus.failed, adminStatus.network])

  useEffect(() => {
    if (updatedSocDocStatus.success) {
      setdocId('')
      setdocRemark('')
      setRejectRemark('')
      setShowRejectMsg(false)
      setshowMsgField(false)
      setStatusChanged(false)
      adminListRequest()
      NotificationPopups('success', 'Success', updatedSocDocStatus.data.message)
    }
    if (updatedSocDocStatus.failed) {
      NotificationPopups('error', 'Failed', updatedSocDocStatus.data.message)
    }
    return () => {
      dispatch(resetUpdateSocietyDocumentStatus())
    }
  }, [
    updatedSocDocStatus.success,
    updatedSocDocStatus.failed,
    updatedSocDocStatus.network,
    adminList,
  ])


  // UpdateAdminUser

  const updateAdminUserReq = () => {
    const obj = {
      initiatedBy: login.data.user.role,
      societyId: societyId,
      id: societyUserId,
      name: name,
      mobileNumber: phone,
      email: email
    }
    return dispatch(UpdateAdminUser(obj))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    updateAdminUserReq()
  }

  const openEditModel = (value) => {
    setUpdateSocietyUserModal(!updateSocietyUserModal)
    setName(value.name)
    setEmail(value.email)
    setPhone(value.mobileNumber)
    setId(value.id)
    setSocietyId(value.societyId)
    setSocietyRole(value.societyRole)
    setSocietyUserId(value.societyUserId)
  }

  const toogleEdit = () => {
    setUpdateSocietyUserModal(!updateSocietyUserModal)
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'name') {
      Util.alphabetsOnly(value) ? setName(value) : setName(name)
    }

    if (name === 'email') {
      setIsEmailValid(Util.verifyEmail(value))
      setEmail(value)
    }

    if (name === 'phone') {
      Util.verifyMobile(value) ? setPhone(value) : setPhone(phone)
    }
    if (name === 'role') setSocietyRole(value)
    if (name === 'otherRole') setOtherRole(value)
  }

  const updateAdminSocietyRoleReq = () => {
    const obj = {
      initiatedBy: login.data.user.role,
      id: societyUserId,
      societyRole: societyRole === "Others" ? otherRole : societyRole,
      societyId: societyId
    }
    return dispatch(UpdateAdminSocietyRole(obj))
  }
  const hamdleSubmitRole = (e) => {
    e.preventDefault()
    updateAdminSocietyRoleReq()
  }

  useEffect(() => {
    if (updateAdminUserRes.success) {
      setUpdateSocietyUserModal(false)
      NotificationPopups('success', 'Success', updateAdminUserRes.data.message)
    }
    if (updateAdminUserRes.failed) {
      setUpdateSocietyUserModal(true)
      NotificationPopups('error', 'Failed', updateAdminUserRes.data.message)
    }
    if (updateAdminUserRes.network) {
      setUpdateSocietyUserModal(true)
      NotificationPopups('error', 'Network', updateAdminUserRes.data.message)
    }
    if (updateAdminSocietyRoleRes.success) {
      setUpdateSocietyUserModal(false)
      NotificationPopups('success', 'Success', updateAdminSocietyRoleRes.data.message)
      setIsEmailValid(true)
    }
    if (updateAdminSocietyRoleRes.failed) {
      setUpdateSocietyUserModal(true)
      {societyRole === '' ? 
        NotificationPopups('error', 'Failed', updateAdminSocietyRoleRes.data.errorVOList.map((d)=>d.errorMessage))
        :
        NotificationPopups('error', 'Failed', updateAdminSocietyRoleRes.data.message)
      }
    }
    if (updateAdminSocietyRoleRes.network) {
      setUpdateSocietyUserModal(true)
      NotificationPopups('error', 'Error', updateAdminSocietyRoleRes.data.message)
    }
    return ()=>{
      dispatch(resetUpdateAdminSocietyRole())
      dispatch(resetUpdateAdminUser())
    }
  }, [updateAdminUserRes.success, updateAdminUserRes.failed, updateAdminUserRes.network,
  updateAdminSocietyRoleRes.success, updateAdminSocietyRoleRes.failed, updateAdminSocietyRoleRes.network
  ])

  const RoleData = [
    { role: 'Chairman', id: 1 },
    { role: 'Secretary', id: 2 },
    { role: 'Treasurer', id: 3 },
    { role: 'Committee Member', id: 4 },
    { role: 'Others', id: 5 }
  ]

  const { Option } = Select

  const onChangeRole = (value) => {
    if (value === "Others") {
      setSocietyRole(value)
      setShowTextArea(true)
    }
    else {
      setSocietyRole(value)
      setShowTextArea(false)
    }
  }

  const onSearchRole = () => {

  }

  const items = [
    {
      key: '1',
      label: 'Edit Admin Society Role',
      children: <div>
        <FormGroup style={{ margin: '20px 0' }}>
          <label>Role in Society</label>
          <Select
            type="select"
            showSearch
            value={societyRole}
            placeholder='Select Role'
            optionFilterProp="children"
            onChange={onChangeRole}
            onSearch={onSearchRole}
            style={{ width: '100%' }}
          >
            <Option className="loginSelect" value=''>
              Select Role
            </Option>
            {RoleData.map((d, id) => (
              <Option
                key={id}
                className="loginSelect"
                value={d.role}
              >
                {d.role}
              </Option>
            ))}
          </Select>
          <br />
          <br />
          <Input
            style={{ padding: "8px", borderRadius: "6px" }}
            type="text"
            name="otherRole"
            id="role"
            placeholder="Others"
            value={otherRole}
            onChange={handleChange}
            required
            disabled={showTextArea === true ? false : true}
          />
        </FormGroup>
        <FormGroup style={{ textAlign: 'center' }}>
          <Button
            onClick={hamdleSubmitRole}
            className="cust-button"
          >
            Edit
          </Button>
        </FormGroup>
      </div>,
    },
  ];

  console.log(adminList,"adminList")

  return (
    <>
      <div className="table-full-width table-responsive" id="onTop">
        <Table responsive style={{ textAlign: 'center' }}>
          <thead>
            <tr>
              <th>Society Name</th>
              <th>Name</th>
              <th>Phone</th>
              <th>City</th>
              <th>Pincode</th>
              <th>Designation</th>
              <th style={{ width: "100px" }}></th>
            </tr>
          </thead>
          {adminList.success && (
            <tbody>
              {adminList.data.societyUserList.map((d, id) => (
                <React.Fragment key={id}>

                  <Tooltip
                    placement="bottom"
                    title="Edit"
                    color="#108ee9"

                  >
                    <img
                      src={EditBtn}
                      alt="edit"
                      width="20px"
                      height="20px"
                      style={{ position: "absolute", zIndex: 999, left: '93%', marginTop: '12px', cursor: 'pointer' }}
                      onClick={() =>
                        openEditModel(d)
                      }
                    />
                  </Tooltip>
                  <tr
                    key={id}
                    id={`toggler${id}`}
                    style={{
                      backgroundColor: '#e8f5fd',
                      color: 'black',
                      fontWeight: 'semi-bold',
                      boxShadow: 'none',
                    }}
                  >

                    <td>{d.societyName}</td>
                    <td>{d.name}</td>
                    <td>{d.mobileNumber}</td>
                    <td>{d.city}</td>
                    <td>{d.pincode}</td>
                    <td>{d.societyRole ? d.societyRole : "NA"}</td>
                    <td></td>
                  </tr>


                  <tr className="ml-detail-tr">
                    <td colSpan="6" className="ml-detail-td">
                      <UncontrolledCollapse toggler={`toggler${id}`}>
                        <Row className="ml-detail-row">
                          <Col
                            xs="12"
                            sm="6"
                            md="4"
                            lg="3"
                            style={{ padding: '20px' }}
                          >
                            <label>Address</label>
                            <div className="detail-val">{d.address}</div>
                          </Col>
                          <Col
                            xs="12"
                            sm="6"
                            md="4"
                            lg="3"
                            style={{ padding: '20px' }}
                          >
                            <label>Landmark</label>
                            <div className="detail-val">{d.landmark}</div>
                          </Col>
                          <Col
                            xs="12"
                            sm="6"
                            md="4"
                            lg="3"
                            style={{ padding: '20px' }}
                          >
                            <label>Block</label>
                            <div className="detail-val">{d.societyBlock}</div>
                          </Col>
                          <Col
                            xs="12"
                            sm="6"
                            md="4"
                            lg="3"
                            style={{ padding: '20px' }}
                          >
                            <label>State</label>
                            <div className="detail-val">{d.state}</div>
                          </Col>
                        </Row>
                        <Row>
                          {d.societyDocument &&
                            d.societyDocument.map((doc, id) => (
                              <Col
                                xs="12"
                                sm="6"
                                md="4"
                                lg="3"
                                style={{ padding: '20px' }}
                                key={id}
                              >
                                <label>{doc.documentType}</label>
                                {doc.document && (
                                  <div
                                    style={{
                                      cursor: 'pointer',
                                      padding: '5px',
                                      height: '130px',
                                    }}
                                    onClick={() => handleOpenFile(doc.document)}
                                  >
                                    {doc.document &&
                                      Util.attachmentType(doc.document) ? (
                                      <img
                                        src={`${Util.imageUrl}${doc.document}`}
                                        style={{
                                          width: '100px',
                                          height: '100px',
                                        }}
                                      />
                                    ) : (
                                      <i
                                        className={Util.iconType(doc.document)}
                                        style={{ fontSize: '100px' }}
                                      ></i>
                                    )}
                                  </div>
                                )}
                                <div
                                  className="showStatus"
                                  style={{
                                    color: Util.statusColor(doc.documentStatus),
                                  }}
                                >
                                  {doc.documentStatus.toUpperCase()}
                                </div>
                                {doc.remark && (
                                  <Col
                                    xs="12"
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    style={{ padding: '10px' }}
                                  >
                                    <label>Remark</label>
                                    <div className="detail-val">{doc.remark}</div>
                                  </Col>
                                )}
                                {
                                  // statusChanged &&
                                  doc.documentStatus === 'pending' ? (
                                    <>
                                      <div style={{ marginTop: '15px' }}>
                                        <span style={{ padding: '10px 20px' }}>
                                          <Popconfirm
                                            title="Approve Document?"
                                            onConfirm={
                                              () =>
                                                handleDocStatus(
                                                  doc.id,
                                                  'approved'
                                                )
                                            }
                                            okText="Approve"
                                            cancelText="Cancel"
                                          >
                                            <Tooltip
                                              placement="leftBottom"
                                              title="Approve"
                                              color="#108ee9"
                                            >
                                              <img
                                                src={RightIcon}
                                                alt="RightIcon"
                                                className="amenity_option_icon"
                                              />
                                            </Tooltip>
                                          </Popconfirm>
                                        </span>{' '}
                                        {'|'}
                                        <span style={{ padding: '10px 20px' }}>
                                          <Popconfirm
                                            title="Reject Document?"
                                            onConfirm={() =>
                                              handleDocStatus(doc.id, 'rejected')
                                            }
                                            okText="Reject"
                                            cancelText="Cancel"
                                          >
                                            <Tooltip
                                              placement="rightBottom"
                                              title="Reject"
                                              color="#108ee9"
                                            >
                                              <img
                                                src={WrongIcon}
                                                alt="WrongIcon"
                                                className="amenity_option_icon"
                                              />
                                            </Tooltip>
                                          </Popconfirm>
                                        </span>
                                      </div>
                                      {showMsgField && docId === doc.id && (
                                        <div>
                                          <FormGroup>
                                            <Input
                                              type="textarea"
                                              row="1"
                                              name="docRemark"
                                              id={docRemark}
                                              placeholder="Rejection Remark"
                                              onChange={handleRemark}
                                              required
                                            />
                                          </FormGroup>
                                          <Button
                                            className="cust-button"
                                            style={{ minWidth: '100%' }}
                                            onClick={(e) =>
                                              handleRejectDoc(
                                                e,
                                                doc.id,
                                                'rejected'
                                              )
                                            }
                                          >
                                            Send
                                          </Button>
                                        </div>
                                      )}
                                    </>
                                  ) : null
                                }
                              </Col>
                            ))}
                        </Row>
                        <Row>
                          {d.adminStatus === 'pending' && (
                            <>
                              <Col
                                xs="12"
                                sm="12"
                                md="12"
                                lg="12"
                              >
                                <label>Review</label>
                                <div className="detail-val">
                                  <span
                                    className="right-menu"
                                    onClick={() =>
                                      handleAdminStatus(d.id, 'approved')
                                    }
                                  >
                                    Approve
                                  </span>
                                  {' | '}
                                  <span
                                    className="right-menu"
                                    onClick={() =>
                                      handleAdminStatus(d.id, 'rejected')
                                    }
                                  >
                                    Reject
                                  </span>
                                </div>
                                {showRejectMsg && userId === d.id && (
                                  <>
                                      <Row style={{ justifyContent: 'center' }}>
                                        <Col
                                          xs="12"
                                          sm="12"
                                          md="9"
                                          lg="9"
                                          style={{ padding: '10px' }}
                                        >
                                          <FormGroup>
                                            <Input
                                              style={{ height: '50px' }}
                                              type="textarea"
                                              row="1"
                                              name="rejectRemark"
                                              id={docRemark}
                                              placeholder="Rejection Remark"
                                              onChange={handleRemark}
                                              required
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col
                                          xs="12"
                                          sm="12"
                                          md="3"
                                          lg="3"
                                          style={{ padding: '17px 10px' }}
                                        >
                                          <Button
                                           style={{ minWidth: '100%' }}
                                            className="cust-button"
                                            onClick={(e) =>
                                              adminStatusRequest(d.id, 'rejected')
                                            }
                                          >
                                            {adminStatus.isLoading ? <Spin /> : 'Send'}
                                          </Button>
                                        </Col>

                                      </Row>
                                  </>

                                )}
                              </Col>
                            </>
                          )}
                        </Row>
                      </UncontrolledCollapse>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          )}
        </Table>
        {adminList.failed && (
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <Alert message={adminList.data.message} type="info" />
          </div>
        )}

        {fileModal && (
          <DisplayAttachment
            attachment={`${Util.imageUrl}${file}`}
            fileModal={fileModal}
            toggleModal={toggleFileModal}
          />
        )}
        {adminList.success && (
          <PaginationComponent
            limit={limit}
            totalItems={totalItems}
            onSelect={handlePagination}
            defaultPage={page}
          />
        )}
      </div>

      <Modal isOpen={updateSocietyUserModal} toggle={toogleEdit}>
        <ModalHeader toggle={toogleEdit}>Edit Admin Profile</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              name="name"
              placeholder=" Name"
              id="email"
              value={name || ''}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              invalid={email && !isEmailValid}
              placeholder="Email"
              type="email"
              name="email"
              value={email || ''}
              onChange={handleChange}
            />
            <FormFeedback style={{ textAlign: 'center' }}>
              {email && !isEmailValid ? 'Invalid Email' : ''}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="phone">Phone</Label>
            <Input
              type="text"
              name="phone"
              placeholder="Phone"
              id="phone"
              value={phone || ''}
              onChange={handleChange}
              maxlength='10'
            />
          </FormGroup>
          <p style={{ textAlign: 'center' , color:'red'}}>{phone === '' ?updateAdminUserRes.data.message:''}</p>
          <FormGroup style={{ textAlign: 'center' }}>
            <Button
              onClick={handleSubmit}
              className="cust-button"
            >
              Edit
            </Button>
          </FormGroup>
          <Collapse accordion expandIconPosition={'end'} items={items} />

        </ModalBody>
      </Modal>
    </>
  )
}

export default AdminsPendingList
