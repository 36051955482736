import axios from "../../config/axios"
import {
    GET_ALL_UNIT_SIZE_SUCCESS,
	GET_ALL_UNIT_SIZE_FAILED,
	LOADING_GET_ALL_UNIT_SIZE,
	RESET_GET_ALL_UNIT_SIZE,
	GET_ALL_UNIT_SIZE_NETWORKERROR
} from "../types";

const resetGetAllUnitSize = () => {
    return {
        type: RESET_GET_ALL_UNIT_SIZE
    };
};

const getAllUnitSize = userData => dispatch => {
    dispatch({ type: LOADING_GET_ALL_UNIT_SIZE });
    axios
        .post("society/getAllUnitSize", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: GET_ALL_UNIT_SIZE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: GET_ALL_UNIT_SIZE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: GET_ALL_UNIT_SIZE_FAILED,
        payload: data
    };
}

export { getAllUnitSize, resetGetAllUnitSize } ;