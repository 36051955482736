import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Alert, Select, Spin } from 'antd'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Form,
  Input,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  UncontrolledCollapse,
} from 'reactstrap'
import classnames from 'classnames'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetgetSocietyList,
  getSocietyList,
} from 'redux/actions/getSocietyListAction'
import { resetGetCityList, getCityList } from 'redux/actions/getCityListAction'
import {
  resetGetSocietyAdmin,
  getSocietyAdmin,
} from 'redux/actions/getSocietyAdminAction'
import {
  getAdminList,
  resetgetAdminList,
} from 'redux/actions/getAdminListAction'
import {
  resetAddAdminStatus,
  addAdminStatus,
} from 'redux/actions/addAdminStatusAction'
import AdminsAcceptedList from './AdminsAcceptedList'
import AdminsPendingList from './AdminsPendingList'
import AdminsRejectedList from './AdminsRejectedList'
import AddSocietyUser from './AddSocietyUser'
import axios from 'config/axios'
// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import loginReducer from 'redux/reducers/loginReducer'

import SocietyUserIcon from 'assets/img/Icons/Sidebar/Societyuser.png'
import Util from 'validations/index'

import { UpdateAdminUser, resetUpdateAdminUser } from 'redux/actions/updateAdminUserAction'
import { updateMemberRole, resetUpdateMemberRole } from 'redux/actions/updateMemberRoleAction'
import { UpdateAdminSocietyRole, resetUpdateAdminSocietyRole } from 'redux/actions/updateAdminSocietyRoleAction'

function Admin() {
  const [status, setStatus] = useState('pending')
  const [newSocUser, setnewSocUser] = useState(false)
  const [activeTab, setActiveTab] = useState('3')
  const adminUserList = useSelector(({ adminList }) => adminList)
  const addAdminStatus = useSelector(({ addAdminStatus }) => addAdminStatus)
  const login = useSelector(({ login }) => login)
  const [limit] = useState(9)
  const [page, setPage] = useState(1)
  const linkSociety = useSelector(({ linkSociety }) => linkSociety)
  const updatedSocDocStatus = useSelector(
    ({ updateSocDocStatus }) => updateSocDocStatus
  )
  const updateAdminSocietyRoleRes = useSelector(({ updateAdminSocietyRole }) => updateAdminSocietyRole)

  const dispatch = useDispatch()

  const toggle = (tab, status) => {
    //console.log(status)
    if (
      status === 'approved' ||
      status === 'rejected' ||
      status === 'pending'
    ) {
      //console.log("abc")
      // adminListRequest(status)
    }
    setStatus(status)
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handleStatus = (status) => {
    console.log(status)
    setStatus(status)
    // adminListRequest(status)
  }

  // console.log(adminUserList)

  useEffect(() => {
    const adminListReq = {
      adminStatus: status,
      initiatedBy: login.data.user.role,
      limit,
      page,
    }
    return dispatch(getAdminList(adminListReq))
  }, [status])

  useEffect(() => {
    return () => {
      resetgetAdminList()
    }
  }, [status])

  //Search filter
  const [isSearch, setIsSearch] = useState(false)
  const [societyName, setSocietyName] = useState('')
  const [pincode, setPincode] = useState('')
  const [city, setCity] = useState('')
  const [cityName, setCItyName] = useState('')
  const [landmark, setLandmark] = useState('')
  const [viewSocietyAdmin, setViewSocietyAdmin] = useState(false)
  const [societyId, setSocietyId] = useState('')

  const [openModal, setOpenModal] = useState(false)
  const [details, setDetails] = useState('')
  const [searchCityName, setSearchCityName] = useState('')
  // const [options, setOptions] = useState([])

  const [searchSocietyName, setSearchSocietyName] = useState('')
  const [socName, setSocName] = useState('')

  const [options, setOptions] = useState([''])
  const [options2, setOptions2] = useState([''])

  const societyList = useSelector(({ getSocietyList }) => getSocietyList)
  const cityList = useSelector(({ getCityList }) => getCityList)
  const societyAdmin = useSelector(({ getSocietyAdmin }) => getSocietyAdmin)
  const updateAdminUserRes = useSelector(({ updateAdminUser }) => updateAdminUser)
  const updateMemberRoleRes = useSelector(({ updateMemberRole }) => updateMemberRole)

  const totalItems = societyList.data.count
  const { Option } = Select

  function onChangeSearch(value) {
    setSocName(value)
    setSearchSocietyName('')
    setSocietyName('')
  }

  function onSearch(value) {
    setSearchSocietyName(value)
  }

  function onChangeCity(value) {
    setCity(value)
    setSearchCityName('')
    setSocName('')
    setSearchSocietyName('')
  }

  function onSearchCity(value) {
    setSearchCityName(value)
  }

  function onChangeSocName(value) {
    setSocName(value)
    setSearchSocietyName('')
  }

  function onSearchSocName(value) {
    setSearchSocietyName(value)
  }

  const getCityListRequest = () => {
    const getCityListReq = {
      initiatedBy: 'Society',
    }
    return dispatch(getCityList(getCityListReq))
  }

  useEffect(() => {
    getCityListRequest()
  }, [])

  const getSocietyAdminRequest = () => {
    const getSocietyAdminReq = {
      initiatedBy: login.data.user.role,
      id: societyId,
    }
    return dispatch(getSocietyAdmin(getSocietyAdminReq))
  }

  useEffect(() => {
    getSocietyAdminRequest()
  }, [societyId])

  const getSocietyAdminSearchRequest = () => {
    const getSocietyAdminReq = {
      initiatedBy: login.data.user.role,
      id: societyId,
    }
    return dispatch(getSocietyAdmin(getSocietyAdminReq))
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'societyName') setSocietyName(value)
    if (name === 'socName') setSocName(value)
    if (name === 'pincode')
      Util.verifyMobile(value) ? setPincode(value) : setPincode(null)
    if (name === 'city') setCity(value)
    if (name === 'landmark') setLandmark(value)
  }

  useEffect(() => {
    if (searchCityName != '') {
      const userData = {
        initiatedBy: 'Society',
        type: searchCityName,
      }
      axios.post('society/allCityList', userData).then((res) => {
        setOptions2(res.data.city)
      })
    }
  }, [searchCityName])

  useEffect(() => {
    if (searchSocietyName != '') {
      const userData = {
        city,
        initiatedBy: 'Society',
        societyName: searchSocietyName,
      }
      axios.post('society/getSocietyList', userData).then((res) => {
        setOptions(res.data.societyList)
      })
    }
  }, [searchSocietyName, city])

  const getSocietyListRequest = () => {
    const getSocListReq = {
      city,
      societyName,
      pincode,
      initiatedBy: login.data.user.role,
      page,
      limit,
    }
    return dispatch(getSocietyList(getSocListReq))
  }

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }
  useEffect(() => {
    getSocietyListRequest()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    getSocietyListRequest()
  }
  const adminListRequestSearch = () => {
    const adminListReq = {
      adminStatus: status,
      initiatedBy: login.data.user.role,
      city,
      societyName: socName,
      pincode,
      limit,
      page,
    }
    return dispatch(getAdminList(adminListReq))
  }
  const searchSubmit = (e) => {
    e.preventDefault()
    adminListRequestSearch()
    setIsSearch(!isSearch)
  }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content members-list" id="onTop">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  <img
                    src={SocietyUserIcon}
                    alt="SocietyListIcons"
                    style={{ width: '50%', height: '50%' }}
                  />
                </div>
                <div>
                  <span className="head-title">
                    {status === 'approved' && 'Approved Society User'}
                    {status === 'rejected' && 'Rejected Society User'}
                    {status === 'pending' && 'Pending Society User'}
                    {status === 'addSocUser' && 'Society User'}
                  </span>
                  <span style={{ float: 'right', paddingTop: '10px' }}>
                    <span
                      className="right-menu"
                      onClick={() => {
                        toggle('4', 'addSocUser')
                      }}
                    >
                      {' '}
                      Add Society User{' '}
                    </span>
                  </span>
                </div>
                <div style={{ float: 'right', paddingTop: '10px' }}>
                  <span
                    className="right-menu"
                    onClick={() => {
                      toggle('1', 'approved')
                    }}
                  >
                    {' '}
                    Approved List{' '}
                  </span>{' '}
                  |
                  <span
                    className="right-menu"
                    onClick={() => {
                      toggle('2', 'rejected')
                    }}
                  >
                    {' '}
                    Rejected List{' '}
                  </span>{' '}
                  |
                  <span
                    className="right-menu"
                    onClick={() => {
                      toggle('3', 'pending')
                    }}
                  >
                    {' '}
                    Pending List{' '}
                  </span>
                </div>
              </CardHeader>
              <CardBody>
                {(status === 'approved' ||
                  status === 'rejected' ||
                  status === 'pending') && (
                    <Form onSubmit={searchSubmit}>
                      <Row>
                        <Col md="4" sm="12">
                          <FormGroup style={{ margin: '10px 1px' }}>
                            <Label for="city">City</Label>
                            <Select
                              type="select"
                              value={city}
                              showSearch
                              placeholder="Select City Name"
                              optionFilterProp="children"
                              onChange={onChangeCity}
                              onSearch={onSearchCity}
                              style={{ width: '100%' }}
                            >
                              <Option value="">Select City Name</Option>
                              {searchCityName != '' &&
                                options2 != undefined &&
                                options2.map((d, id) => (
                                  <Option
                                    key={id}
                                    className="loginSelect"
                                    value={d}
                                  >
                                    {d}
                                  </Option>
                                ))}
                              {cityList.success &&
                                searchCityName === '' &&
                                cityList.data.city.map((d, id) => (
                                  <Option
                                    key={id}
                                    className="loginSelect"
                                    value={d}
                                  >
                                    {d}
                                  </Option>
                                ))}
                            </Select>
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="12">
                          <FormGroup style={{ margin: '10px 1px' }}>
                            <Label for="societyName">Society Name</Label>
                            <Input
                              type="text"
                              placeholder="Select Society Name"
                              name="socName"
                              id="socName"
                              value={socName || ''}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="12">
                          <FormGroup style={{ margin: '10px 1px' }}>
                            <Label for="pincode">Pincode</Label>
                            <Input
                              type="text"
                              placeholder="Pincode"
                              maxLength="6"
                              name="pincode"
                              id="pincode"
                              value={pincode || ''}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row style={{ justifyContent: 'center' }}>
                        <Col sm="12" style={{ textAlign: 'center' }}>
                          <Button
                            className="cust-button"
                            onClick={searchSubmit}
                            disabled={societyList.isLoading}
                          >
                            {societyList.isLoading ? <Spin /> : 'Search'}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <AdminsAcceptedList status={status} adminList={adminUserList} onTap={isSearch} />
                  </TabPane>
                  <TabPane tabId="2">
                    <AdminsRejectedList status={status} adminList={adminUserList} onTap={isSearch} />
                  </TabPane>
                  <TabPane tabId="3">
                    <AdminsPendingList
                      status={status}
                      socname={socName}
                      city={city}
                      adminList={adminUserList}
                      onTap={isSearch}
                    // handleStatus={handleStatus}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <AddSocietyUser />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Admin
