//Login
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOADING_LOGIN = 'LOADING_LOGIN'
export const RESET_LOGIN = 'RESET_LOGIN'
export const LOGIN_NETWORKERROR = 'LOGIN_NETWORKERROR'

//Society Signup
export const ADMINSIGNUP_SUCCESS = 'ADMINSIGNUP_SUCCESS'
export const ADMINSIGNUP_FAILED = 'ADMINSIGNUP_FAILED'
export const LOADING_ADMINSIGNUP = 'LOADING_ADMINSIGNUP'
export const RESET_ADMINSIGNUP = 'RESET_ADMINSIGNUP'
export const ADMINSIGNUP_NETWORKERROR = 'ADMINSIGNUP_NETWORKERROR'

//Validate Signup OTP
export const VALIDATESIGNUPOTP_SUCCESS = 'VALIDATESIGNUPOTP_SUCCESS'
export const VALIDATESIGNUPOTP_FAILED = 'VALIDATESIGNUPOTP_FAILED'
export const LOADING_VALIDATESIGNUPOTP = 'LOADING_VALIDATESIGNUPOTP'
export const RESET_VALIDATESIGNUPOTP = 'RESET_VALIDATESIGNUPOTP'
export const VALIDATESIGNUPOTP_NETWORKERROR = 'VALIDATESIGNUPOTP_NETWORKERROR'

//Resend Signup OTP
export const RESENDSIGNUPOTP_SUCCESS = 'RESENDSIGNUPOTP_SUCCESS'
export const RESENDSIGNUPOTP_FAILED = 'RESENDSIGNUPOTP_FAILED'
export const LOADING_RESENDSIGNUPOTP = 'LOADING_RESENDSIGNUPOTP'
export const RESET_RESENDSIGNUPOTP = 'RESET_RESENDSIGNUPOTP'
export const RESENDSIGNUPOTP_NETWORKERROR = 'RESENDSIGNUPOTP_NETWORKERROR'

//Link Society
export const LINKSOCIETY_SUCCESS = 'LINKSOCIETY_SUCCESS'
export const LINKSOCIETY_FAILED = 'LINKSOCIETY_FAILED'
export const LOADING_LINKSOCIETY = 'LOADING_LINKSOCIETY'
export const RESET_LINKSOCIETY = 'RESET_LINKSOCIETY'
export const LINKSOCIETY_NETWORKERROR = 'LINKSOCIETY_NETWORKERROR'

//Pincode Api
export const PINCODE_SUCCESS = 'PINCODE_SUCCESS'
export const PINCODE_FAILED = 'PINCODE_FAILED'
export const LOADING_PINCODE = 'LOADING_PINCODE'
export const RESET_PINCODE = 'RESET_PINCODE'
export const PINCODE_NETWORKERROR = 'PINCODE_NETWORKERROR'

//Add Society Documents
export const ADD_SOCIETYDOC_SUCCESS = 'ADD_SOCIETYDOC_SUCCESS'
export const ADD_SOCIETYDOC_FAILED = 'ADD_SOCIETYDOC_FAILED'
export const LOADING_ADD_SOCIETYDOC = 'LOADING_ADD_SOCIETYDOC'
export const RESET_ADD_SOCIETYDOC = 'RESET_ADD_SOCIETYDOC'
export const ADD_SOCIETYDOC_NETWORKERROR = 'ADD_SOCIETYDOC_NETWORKERROR'

//Get Society Documents
export const GET_SOCIETYDOC_SUCCESS = 'GET_SOCIETYDOC_SUCCESS'
export const GET_SOCIETYDOC_FAILED = 'GET_SOCIETYDOC_FAILED'
export const LOADING_GET_SOCIETYDOC = 'LOADING_GET_SOCIETYDOC'
export const RESET_GET_SOCIETYDOC = 'RESET_GET_SOCIETYDOC'
export const GET_SOCIETYDOC_NETWORKERROR = 'GET_SOCIETYDOC_NETWORKERROR'

//Update Society Documents
export const UPDATE_SOCIETYDOC_SUCCESS = 'UPDATE_SOCIETYDOC_SUCCESS'
export const UPDATE_SOCIETYDOC_FAILED = 'UPDATE_SOCIETYDOC_FAILED'
export const LOADING_UPDATE_SOCIETYDOC = 'LOADING_UPDATE_SOCIETYDOC'
export const RESET_UPDATE_SOCIETYDOC = 'RESET_UPDATE_SOCIETYDOC'
export const UPDATE_SOCIETYDOC_NETWORKERROR = 'UPDATE_SOCIETYDOC_NETWORKERROR'

//Add Society
export const ADDSOCIETY_SUCCESS = 'ADDSOCIETY_SUCCESS'
export const ADDSOCIETY_FAILED = 'ADDSOCIETY_FAILED'
export const LOADING_ADDSOCIETY = 'LOADING_ADDSOCIETY'
export const RESET_ADDSOCIETY = 'RESET_ADDSOCIETY'
export const ADDSOCIETY_NETWORKERROR = 'ADDSOCIETY_NETWORKERROR'

//Get Society List
export const GETSOCIETYLIST_SUCCESS = 'GETSOCIETYLIST_SUCCESS'
export const GETSOCIETYLIST_FAILED = 'GETSOCIETYLIST_FAILED'
export const LOADING_GETSOCIETYLIST = 'LOADING_GETSOCIETYLIST'
export const RESET_GETSOCIETYLIST = 'RESET_GETSOCIETYLIST'
export const GETSOCIETYLIST_NETWORKERROR = 'GETSOCIETYLIST_NETWORKERROR'

//Add Notice
export const ADDNOTICE_SUCCESS = 'ADDNOTICE_SUCCESS'
export const ADDNOTICE_FAILED = 'ADDNOTICE_FAILED'
export const LOADING_ADDNOTICE = 'LOADING_ADDNOTICE'
export const RESET_ADDNOTICE = 'RESET_ADDNOTICE'
export const ADDNOTICE_NETWORKERROR = 'ADDNOTICE_NETWORKERROR'

//Get Notice List
export const GETNOTICELIST_SUCCESS = 'GETNOTICELIST_SUCCESS'
export const GETNOTICELIST_FAILED = 'GETNOTICELIST_FAILED'
export const LOADING_GETNOTICELIST = 'LOADING_GETNOTICELIST'
export const RESET_GETNOTICELIST = 'RESET_GETNOTICELIST'
export const GETNOTICELIST_NETWORKERROR = 'GETNOTICELIST_NETWORKERROR'

//Update Notice
export const UPDATENOTICE_SUCCESS = 'UPDATENOTICE_SUCCESS'
export const UPDATENOTICE_FAILED = 'UPDATENOTICE_FAILED'
export const LOADING_UPDATENOTICE = 'LOADING_UPDATENOTICE'
export const RESET_UPDATENOTICE = 'RESET_UPDATENOTICE'
export const UPDATENOTICE_NETWORKERROR = 'UPDATENOTICE_NETWORKERROR'

//Delete Notice
export const DELETENOTICE_SUCCESS = 'DELETENOTICE_SUCCESS'
export const DELETENOTICE_FAILED = 'DELETENOTICE_FAILED'
export const LOADING_DELETENOTICE = 'LOADING_DELETENOTICE'
export const RESET_DELETENOTICE = 'RESET_DELETENOTICE'
export const DELETENOTICE_NETWORKERROR = 'DELETENOTICE_NETWORKERROR'

//Add Building
export const ADDBUILDING_SUCCESS = 'ADDBUILDING_SUCCESS'
export const ADDBUILDING_FAILED = 'ADDBUILDING_FAILED'
export const LOADING_ADDBUILDING = 'LOADING_ADDBUILDING'
export const RESET_ADDBUILDING = 'RESET_ADDBUILDING'
export const ADDBUILDING_NETWORKERROR = 'ADDBUILDING_NETWORKERROR'

//Get Building List
export const GETBUILDINGLIST_SUCCESS = 'GETBUILDINGLIST_SUCCESS'
export const GETBUILDINGLIST_FAILED = 'GETBUILDINGLIST_FAILED'
export const LOADING_GETBUILDINGLIST = 'LOADING_GETBUILDINGLIST'
export const RESET_GETBUILDINGLIST = 'RESET_GETBUILDINGLIST'
export const GETBUILDINGLIST_NETWORKERROR = 'GETBUILDINGLIST_NETWORKERROR'

//Get Building Name
export const GETBUILDINGNAME_SUCCESS = 'GETBUILDINGNAME_SUCCESS'
export const GETBUILDINGNAME_FAILED = 'GETBUILDINGNAME_FAILED'
export const LOADING_GETBUILDINGNAME = 'LOADING_GETBUILDINGNAME'
export const RESET_GETBUILDINGNAME = 'RESET_GETBUILDINGNAME'
export const GETBUILDINGNAME_NETWORKERROR = 'GETBUILDINGNAME_NETWORKERROR'

//Update Building (Update addBuilding)
export const UPDATEBUILDING_SUCCESS = 'UPDATEBUILDING_SUCCESS'
export const UPDATEBUILDING_FAILED = 'UPDATEBUILDING_FAILED'
export const LOADING_UPDATEBUILDING = 'LOADING_UPDATEBUILDING'
export const RESET_UPDATEBUILDING = 'RESET_UPDATEBUILDING'
export const UPDATEBUILDING_NETWORKERROR = 'UPDATEBUILDING_NETWORKERROR'

//Delete Building (Delete addBuilding)
export const DELETEBUILDING_SUCCESS = 'DELETEBUILDING_SUCCESS'
export const DELETEBUILDING_FAILED = 'DELETEBUILDING_FAILED'
export const LOADING_DELETEBUILDING = 'LOADING_DELETEBUILDING'
export const RESET_DELETEBUILDING = 'RESET_DELETEBUILDING'
export const DELETEBUILDING_NETWORKERROR = 'DELETEBUILDING_NETWORKERROR'

//Add Flat List
export const ADDFLATLIST_SUCCESS = 'ADDFLATLIST_SUCCESS'
export const ADDFLATLIST_FAILED = 'ADDFLATLIST_FAILED'
export const LOADING_ADDFLATLIST = 'LOADING_ADDFLATLIST'
export const RESET_ADDFLATLIST = 'RESET_ADDFLATLIST'
export const ADDFLATLIST_NETWORKERROR = 'ADDFLATLIST_NETWORKERROR'

//Get Flat List
export const GETFLATLIST_SUCCESS = 'GETFLATLIST_SUCCESS'
export const GETFLATLIST_FAILED = 'GETFLATLIST_FAILED'
export const LOADING_GETFLATLIST = 'LOADING_GETFLATLIST'
export const RESET_GETFLATLIST = 'RESET_GETFLATLIST'
export const GETFLATLIST_NETWORKERROR = 'GETFLATLIST_NETWORKERROR'

//Get Member List
export const GETMEMBERLIST_SUCCESS = 'GETMEMBERLIST_SUCCESS'
export const GETMEMBERLIST_FAILED = 'GETMEMBERLIST_FAILED'
export const LOADING_GETMEMBERLIST = 'LOADING_GETMEMBERLIST'
export const RESET_GETMEMBERLIST = 'RESET_GETMEMBERLIST'
export const GETMEMBERLIST_NETWORKERROR = 'GETMEMBERLIST_NETWORKERROR'

//Get Pending Member List
export const GETPENDINGMEMBERLIST_SUCCESS = 'GETPENDINGMEMBERLIST_SUCCESS'
export const GETPENDINGMEMBERLIST_FAILED = 'GETPENDINGMEMBERLIST_FAILED'
export const LOADING_GETPENDINGMEMBERLIST = 'LOADING_GETPENDINGMEMBERLIST'
export const RESET_GETPENDINGMEMBERLIST = 'RESET_GETPENDINGMEMBERLIST'
export const GETPENDINGMEMBERLIST_NETWORKERROR =
  'GETPENDINGMEMBERLIST_NETWORKERROR'

//Add User Status
export const ADDUSERSTATUS_SUCCESS = 'ADDUSERSTATUS_SUCCESS'
export const ADDUSERSTATUS_FAILED = 'ADDUSERSTATUS_FAILED'
export const LOADING_ADDUSERSTATUS = 'LOADING_ADDUSERSTATUS'
export const RESET_ADDUSERSTATUS = 'RESET_ADDUSERSTATUS'
export const ADDUSERSTATUS_NETWORKERROR = 'ADDUSERSTATUS_NETWORKERROR'

//Get Admin List
export const GETADMINLIST_SUCCESS = 'GETADMINLIST_SUCCESS'
export const GETADMINLIST_FAILED = 'GETADMINLIST_FAILED'
export const LOADING_GETADMINLIST = 'LOADING_GETADMINLIST'
export const RESET_GETADMINLIST = 'RESET_GETADMINLIST'
export const GETADMINLIST_NETWORKERROR = 'GETADMINLIST_NETWORKERROR'

//Add Admin Status
export const ADDADMINSTATUS_SUCCESS = 'ADDADMINSTATUS_SUCCESS'
export const ADDADMINSTATUS_FAILED = 'ADDADMINSTATUS_FAILED'
export const LOADING_ADDADMINSTATUS = 'LOADING_ADDADMINSTATUS'
export const RESET_ADDADMINSTATUS = 'RESET_ADDADMINSTATUS'
export const ADDADMINSTATUS_NETWORKERROR = 'ADDADMINSTATUS_NETWORKERROR'

//Update Society Document Status
export const UPDATESOCDOC_STATUS_SUCCESS = 'UPDATESOCDOC_STATUS_SUCCESS'
export const UPDATESOCDOC_STATUS_FAILED = 'UPDATESOCDOC_STATUS_FAILED'
export const LOADING_UPDATESOCDOC_STATUS = 'LOADING_UPDATESOCDOC_STATUS'
export const RESET_UPDATESOCDOC_STATUS = 'RESET_UPDATESOCDOC_STATUS'
export const UPDATESOCDOC_STATUS_NETWORKERROR =
  'UPDATESOCDOC_STATUS_NETWORKERROR'

//Get Amenity List
export const GETAMENITYLIST_SUCCESS = 'GETAMENITYLIST_SUCCESS'
export const GETAMENITYLIST_FAILED = 'GETAMENITYLIST_FAILED'
export const LOADING_GETAMENITYLIST = 'LOADING_GETAMENITYLIST'
export const RESET_GETAMENITYLIST = 'RESET_GETAMENITYLIST'
export const GETAMENITYLIST_NETWORKERROR = 'GETAMENITYLIST_NETWORKERROR'

//Add Amenity Status
export const ADDAMENITYSTATUS_SUCCESS = 'ADDAMENITYSTATUS_SUCCESS'
export const ADDAMENITYSTATUS_FAILED = 'ADDAMENITYSTATUS_FAILED'
export const LOADING_ADDAMENITYSTATUS = 'LOADING_ADDAMENITYSTATUS'
export const RESET_ADDAMENITYSTATUS = 'RESET_ADDAMENITYSTATUS'
export const ADDAMENITYSTATUS_NETWORKERROR = 'ADDAMENITYSTATUS_NETWORKERROR'

//update Amenity Status

export const UPDATE_AMENITY_STATUS_SUCCESS = 'UPDATE_AMENITY_STATUS_SUCCESS'
export const UPDATE_AMENITY_STATUS_FAILED = 'UPDATE_AMENITY_STATUS_FAILED'
export const LOADING_UPDATE_AMENITY_STATUS = 'LOADING_UPDATE_AMENITY_STATUS'
export const RESET_UPDATE_AMENITY_STATUS = 'RESET_UPDATE_AMENITY_STATUS'
export const UPDATE_AMENITY_STATUS_NETWORK_ERROR =
  'UPDATE_AMENITY_STATUS_NETWORK_ERROR'

//Get Emergency Number
export const GETEMERGENCYLIST_SUCCESS = 'GETEMERGENCYLIST_SUCCESS'
export const GETEMERGENCYLIST_FAILED = 'GETEMERGENCYLIST_FAILED'
export const LOADING_GETEMERGENCYLIST = 'LOADING_GETEMERGENCYLIST'
export const RESET_GETEMERGENCYLIST = 'RESET_GETEMERGENCYLIST'
export const GETEMERGENCYLIST_NETWORKERROR = 'GETEMERGENCYLIST_NETWORKERROR'

//Add Emergency Number
export const ADDEMERGENCYNUMBER_SUCCESS = 'ADDEMERGENCYNUMBER_SUCCESS'
export const ADDEMERGENCYNUMBER_FAILED = 'ADDEMERGENCYNUMBER_FAILED'
export const LOADING_ADDEMERGENCYNUMBER = 'LOADING_ADDEMERGENCYNUMBER'
export const RESET_ADDEMERGENCYNUMBER = 'RESET_ADDEMERGENCYNUMBER'
export const ADDEMERGENCYNUMBER_NETWORKERROR = 'ADDEMERGENCYNUMBER_NETWORKERROR'

//Update Emergency Number
export const UPDATEEMERGENCYNUMBER_SUCCESS = 'UPDATEEMERGENCYNUMBER_SUCCESS'
export const UPDATEEMERGENCYNUMBER_FAILED = 'UPDATEEMERGENCYNUMBER_FAILED'
export const LOADING_UPDATEEMERGENCYNUMBER = 'LOADING_UPDATEEMERGENCYNUMBER'
export const RESET_UPDATEEMERGENCYNUMBER = 'RESET_UPDATEEMERGENCYNUMBER'
export const UPDATEEMERGENCYNUMBER_NETWORKERROR =
  'UPDATEEMERGENCYNUMBER_NETWORKERROR'

//Delete Emergency Number
export const DELETEEMERGENCYNUMBER_SUCCESS = 'DELETEEMERGENCYNUMBER_SUCCESS'
export const DELETEEMERGENCYNUMBER_FAILED = 'DELETEEMERGENCYNUMBER_FAILED'
export const LOADING_DELETEEMERGENCYNUMBER = 'LOADING_DELETEEMERGENCYNUMBER'
export const RESET_DELETEEMERGENCYNUMBER = 'RESET_DELETEEMERGENCYNUMBER'
export const DELETEEMERGENCYNUMBER_NETWORKERROR =
  'DELETEEMERGENCYNUMBER_NETWORKERROR'

//Get Complaint Number
export const GETCOMPLAINTLIST_SUCCESS = 'GETCOMPLAINTLIST_SUCCESS'
export const GETCOMPLAINTLIST_FAILED = 'GETCOMPLAINTLIST_FAILED'
export const LOADING_GETCOMPLAINTLIST = 'LOADING_GETCOMPLAINTLIST'
export const RESET_GETCOMPLAINTLIST = 'RESET_GETCOMPLAINTLIST'
export const GETCOMPLAINTLIST_NETWORKERROR = 'GETCOMPLAINTLIST_NETWORKERROR'

//Add Complaint Status
export const ADDCOMPLAINTSTATUS_SUCCESS = 'ADDCOMPLAINTSTATUS_SUCCESS'
export const ADDCOMPLAINTSTATUS_FAILED = 'ADDCOMPLAINTSTATUS_FAILED'
export const LOADING_ADDCOMPLAINTSTATUS = 'LOADING_ADDCOMPLAINTSTATUS'
export const RESET_ADDCOMPLAINTSTATUS = 'RESET_ADDCOMPLAINTSTATUS'
export const ADDCOMPLAINTSTATUS_NETWORKERROR = 'ADDCOMPLAINTSTATUS_NETWORKERROR'

//Add Complaint Message
export const ADD_COMPLAINT_MESSAGE_SUCCESS = 'ADD_COMPLAINT_MESSAGE_SUCCESS'
export const ADD_COMPLAINT_MESSAGE_FAILED = 'ADD_COMPLAINT_MESSAGE_FAILED'
export const LOADING_ADD_COMPLAINT_MESSAGE = 'LOADING_ADD_COMPLAINT_MESSAGE'
export const RESET_ADD_COMPLAINT_MESSAGE = 'RESET_ADD_COMPLAINT_MESSAGE'
export const ADD_COMPLAINT_MESSAGE_NETWORK_ERROR =
  'ADD_COMPLAINT_MESSAGE_NETWORK_ERROR'

//Get Complaint Message
export const GET_COMPLAINT_MESSAGE_SUCCESS = 'GET_COMPLAINT_MESSAGE_SUCCESS'
export const GET_COMPLAINT_MESSAGE_FAILED = 'GET_COMPLAINT_MESSAGE_FAILED'
export const LOADING_GET_COMPLAINT_MESSAGE = 'LOADING_GET_COMPLAINT_MESSAGE'
export const RESET_GET_COMPLAINT_MESSAGE = 'RESET_GET_COMPLAINT_MESSAGE'
export const GET_COMPLAINT_MESSAGE_NETWORK_ERROR =
  'GET_COMPLAINT_MESSAGE_NETWORK_ERROR'

//Add Daily Help Type
export const ADDDAILYHELPTYPE_SUCCESS = 'ADDDAILYHELPTYPE_SUCCESS'
export const ADDDAILYHELPTYPE_FAILED = 'ADDDAILYHELPTYPE_FAILED'
export const LOADING_ADDDAILYHELPTYPE = 'LOADING_ADDDAILYHELPTYPE'
export const RESET_ADDDAILYHELPTYPE = 'RESET_ADDDAILYHELPTYPE'
export const ADDDAILYHELPTYPE_NETWORKERROR = 'ADDDAILYHELPTYPE_NETWORKERROR'

//Add Area Type
export const ADDAREATYPE_SUCCESS = 'ADDAREATYPE_SUCCESS'
export const ADDAREATYPE_FAILED = 'ADDAREATYPE_FAILED'
export const LOADING_ADDAREATYPE = 'LOADING_ADDAREATYPE'
export const RESET_ADDAREATYPE = 'RESET_ADDAREATYPE'
export const ADDAREATYPE_NETWORKERROR = 'ADDAREATYPE_NETWORKERROR'

//Add Notice Type
export const ADDNOTICETYPE_SUCCESS = 'ADDNOTICETYPE_SUCCESS'
export const ADDNOTICETYPE_FAILED = 'ADDNOTICETYPE_FAILED'
export const LOADING_ADDNOTICETYPE = 'LOADING_ADDNOTICETYPE'
export const RESET_ADDNOTICETYPE = 'RESET_ADDNOTICETYPE'
export const ADDNOTICETYPE_NETWORKERROR = 'ADDNOTICETYPE_NETWORKERROR'

//Add Company Type
export const ADDCOMPANYTYPE_SUCCESS = 'ADDCOMPANYTYPE_SUCCESS'
export const ADDCOMPANYTYPE_FAILED = 'ADDCOMPANYTYPE_FAILED'
export const LOADING_ADDCOMPANYTYPE = 'LOADING_ADDCOMPANYTYPE'
export const RESET_ADDCOMPANYTYPE = 'RESET_ADDCOMPANYTYPE'
export const ADDCOMPANYTYPE_NETWORKERROR = 'ADDCOMPANYTYPE_NETWORKERROR'

//Add Vehicle Type
export const ADDVEHICLETYPE_SUCCESS = 'ADDVEHICLETYPE_SUCCESS'
export const ADDVEHICLETYPE_FAILED = 'ADDVEHICLETYPE_FAILED'
export const LOADING_ADDVEHICLETYPE = 'LOADING_ADDVEHICLETYPE'
export const RESET_ADDVEHICLETYPE = 'RESET_ADDVEHICLETYPE'
export const ADDVEHICLETYPE_NETWORKERROR = 'ADDVEHICLETYPE_NETWORKERROR'

//Get NoticeType List
export const GETNOTICETYPE_SUCCESS = 'GETNOTICETYPE_SUCCESS'
export const GETNOTICETYPE_FAILED = 'GETNOTICETYPE_FAILED'
export const LOADING_GETNOTICETYPE = 'LOADING_GETNOTICETYPE'
export const RESET_GETNOTICETYPE = 'RESET_GETBUILDINGLIST'
export const GETNOTICETYPE_NETWORKERROR = 'GETBUILDINGLIST_NETWORKERROR'

//Get AreaType List
export const GETAREATYPE_SUCCESS = 'GETAREATYPE_SUCCESS'
export const GETAREATYPE_FAILED = 'GETAREATYPE_FAILED'
export const LOADING_GETAREATYPE = 'LOADING_GETAREATYPE'
export const RESET_GETAREATYPE = 'RESET_GETAREATYPE'
export const GETAREATYPE_NETWORKERROR = 'GETAREATYPE_NETWORKERROR'

//Get Daily Help Type List
export const GETDAILYHELPTYPE_SUCCESS = 'GETDAILYHELPTYPE_SUCCESS'
export const GETDAILYHELPTYPE_FAILED = 'GETDAILYHELPTYPE_FAILED'
export const LOADING_GETDAILYHELPTYPE = 'LOADING_GETDAILYHELPTYPE'
export const RESET_GETDAILYHELPTYPE = 'RESET_GETDAILYHELPTYPE'
export const GETDAILYHELPTYPE_NETWORKERROR = 'GETDAILYHELPTYPE_NETWORKERROR'

//Get Company Type List
export const GETCOMPANYTYPE_SUCCESS = 'GETCOMPANYTYPE_SUCCESS'
export const GETCOMPANYTYPE_FAILED = 'GETCOMPANYTYPE_FAILED'
export const LOADING_GETCOMPANYTYPE = 'LOADING_GETCOMPANYTYPE'
export const RESET_GETCOMPANYTYPE = 'RESET_GETCOMPANYTYPE'
export const GETCOMPANYTYPE_NETWORKERROR = 'GETCOMPANYTYPE_NETWORKERROR'

//Get Vehicle Type List
export const GETVEHICLETYPE_SUCCESS = 'GETVEHICLETYPE_SUCCESS'
export const GETVEHICLETYPE_FAILED = 'GETVEHICLETYPE_FAILED'
export const LOADING_GETVEHICLETYPE = 'LOADING_GETVEHICLETYPE'
export const RESET_GETVEHICLETYPE = 'RESET_GETVEHICLETYPE'
export const GETVEHICLETYPE_NETWORKERROR = 'GETVEHICLETYPE_NETWORKERROR'

//Update Area Type
export const UPDATEAREATYPE_SUCCESS = 'UPDATEAREATYPE_SUCCESS'
export const UPDATEAREATYPE_FAILED = 'UPDATEAREATYPE_FAILED'
export const LOADING_UPDATEAREATYPE = 'LOADING_UPDATEAREATYPE'
export const RESET_UPDATEAREATYPE = 'RESET_UPDATEAREATYPE'
export const UPDATEAREATYPE_NETWORKERROR = 'UPDATEAREATYPE_NETWORKERROR'

//Update Notice Type
export const UPDATENOTICETYPE_SUCCESS = 'UPDATENOTICETYPE_SUCCESS'
export const UPDATENOTICETYPE_FAILED = 'UPDATENOTICETYPE_FAILED'
export const LOADING_UPDATENOTICETYPE = 'LOADING_UPDATENOTICETYPE'
export const RESET_UPDATENOTICETYPE = 'RESET_UPDATENOTICETYPE'
export const UPDATENOTICETYPE_NETWORKERROR = 'UPDATENOTICETYPE_NETWORKERROR'

//Update Daily Help Type
export const UPDATEDAILYHELPTYPE_SUCCESS = 'UPDATEDAILYHELPTYPE_SUCCESS'
export const UPDATEDAILYHELPTYPE_FAILED = 'UPDATEDAILYHELPTYPE_FAILED'
export const LOADING_UPDATEDAILYHELPTYPE = 'LOADING_UPDATEDAILYHELPTYPE'
export const RESET_UPDATEDAILYHELPTYPE = 'RESET_UPDATEDAILYHELPTYPE'
export const UPDATEDAILYHELPTYPE_NETWORKERROR =
  'UPDATEDAILYHELPTYPE_NETWORKERROR'

//Update Company Type
export const UPDATECOMPANYTYPE_SUCCESS = 'UPDATECOMPANYTYPE_SUCCESS'
export const UPDATECOMPANYTYPE_FAILED = 'UPDATECOMPANYTYPE_FAILED'
export const LOADING_UPDATECOMPANYTYPE = 'LOADING_UPDATECOMPANYTYPE'
export const RESET_UPDATECOMPANYTYPE = 'RESET_UPDATECOMPANYTYPE'
export const UPDATECOMPANYTYPE_NETWORKERROR = 'UPDATECOMPANYTYPE_NETWORKERROR'

//Update Vehicle Type
export const UPDATEVEHICLETYPE_SUCCESS = 'UPDATEVEHICLETYPE_SUCCESS'
export const UPDATEVEHICLETYPE_FAILED = 'UPDATEVEHICLETYPE_FAILED'
export const LOADING_UPDATEVEHICLETYPE = 'LOADING_UPDATEVEHICLETYPE'
export const RESET_UPDATEVEHICLETYPE = 'RESET_UPDATEVEHICLETYPE'
export const UPDATEVEHICLETYPE_NETWORKERROR = 'UPDATEVEHICLETYPE_NETWORKERROR'

//Delete Area Type
export const DELETEAREATYPE_SUCCESS = 'DELETEAREATYPE_SUCCESS'
export const DELETEAREATYPE_FAILED = 'DELETEAREATYPE_FAILED'
export const LOADING_DELETEAREATYPE = 'LOADING_DELETEAREATYPE'
export const RESET_DELETEAREATYPE = 'RESET_DELETEAREATYPE'
export const DELETEAREATYPE_NETWORKERROR = 'DELETEAREATYPE_NETWORKERROR'

//Delete Notice Type
export const DELETENOTICETYPE_SUCCESS = 'DELETENOTICETYPE_SUCCESS'
export const DELETENOTICETYPE_FAILED = 'DELETENOTICETYPE_FAILED'
export const LOADING_DELETENOTICETYPE = 'LOADING_DELETENOTICETYPE'
export const RESET_DELETENOTICETYPE = 'RESET_DELETENOTICETYPE'
export const DELETENOTICETYPE_NETWORKERROR = 'DELETENOTICETYPE_NETWORKERROR'

//Delete Daily Help Type
export const DELETEDAILYHELPTYPE_SUCCESS = 'DELETEDAILYHELPTYPE_SUCCESS'
export const DELETEDAILYHELPTYPE_FAILED = 'DELETEDAILYHELPTYPE_FAILED'
export const LOADING_DELETEDAILYHELPTYPE = 'LOADING_DELETEDAILYHELPTYPE'
export const RESET_DELETEDAILYHELPTYPE = 'RESET_DELETEDAILYHELPTYPE'
export const DELETEDAILYHELPTYPE_NETWORKERROR =
  'DELETEDAILYHELPTYPE_NETWORKERROR'

//Delete Company Type
export const DELETECOMPANYTYPE_SUCCESS = 'DELETECOMPANYTYPE_SUCCESS'
export const DELETECOMPANYTYPE_FAILED = 'DELETECOMPANYTYPE_FAILED'
export const LOADING_DELETECOMPANYTYPE = 'LOADING_DELETECOMPANYTYPE'
export const RESET_DELETECOMPANYTYPE = 'RESET_DELETECOMPANYTYPE'
export const DELETECOMPANYTYPE_NETWORKERROR = 'DELETECOMPANYTYPE_NETWORKERROR'

//Delete Vehicle Type
export const DELETEVEHICLETYPE_SUCCESS = 'DELETEVEHICLETYPE_SUCCESS'
export const DELETEVEHICLETYPE_FAILED = 'DELETEVEHICLETYPE_FAILED'
export const LOADING_DELETEVEHICLETYPE = 'LOADING_DELETEVEHICLETYPE'
export const RESET_DELETEVEHICLETYPE = 'RESET_DELETEVEHICLETYPE'
export const DELETEVEHICLETYPE_NETWORKERROR = 'DELETEVEHICLETYPE_NETWORKERROR'

//Add Company
export const ADDCOMPANY_SUCCESS = 'ADDCOMPANY_SUCCESS'
export const ADDCOMPANY_FAILED = 'ADDCOMPANY_FAILED'
export const LOADING_ADDCOMPANY = 'LOADING_ADDCOMPANY'
export const RESET_ADDCOMPANY = 'RESET_ADDCOMPANY'
export const ADDCOMPANY_NETWORKERROR = 'ADDCOMPANY_NETWORKERROR'

//Get Company
export const GETCOMPANY_SUCCESS = 'GETCOMPANY_SUCCESS'
export const GETCOMPANY_FAILED = 'GETCOMPANY_FAILED'
export const LOADING_GETCOMPANY = 'LOADING_GETCOMPANY'
export const RESET_GETCOMPANY = 'RESET_GETCOMPANY'
export const GETCOMPANY_NETWORKERROR = 'GETCOMPANY_NETWORKERROR'

//Update Company
export const UPDATECOMPANY_SUCCESS = 'UPDATECOMPANY_SUCCESS'
export const UPDATECOMPANY_FAILED = 'UPDATECOMPANY_FAILED'
export const LOADING_UPDATECOMPANY = 'LOADING_UPDATECOMPANY'
export const RESET_UPDATECOMPANY = 'RESET_UPDATECOMPANY'
export const UPDATECOMPANY_NETWORKERROR = 'UPDATECOMPANY_NETWORKERROR'

//Delete Company
export const DELETECOMPANY_SUCCESS = 'DELETECOMPANY_SUCCESS'
export const DELETECOMPANY_FAILED = 'DELETECOMPANY_FAILED'
export const LOADING_DELETECOMPANY = 'LOADING_DELETECOMPANY'
export const RESET_DELETECOMPANY = 'RESET_DELETECOMPANY'
export const DELETECOMPANY_NETWORKERROR = 'DELETECOMPANY_NETWORKERROR'

//Add Amenity Type
export const ADDAMENITYTYPE_SUCCESS = 'ADDAMENITYTYPE_SUCCESS'
export const ADDAMENITYTYPE_FAILED = 'ADDAMENITYTYPE_FAILED'
export const LOADING_ADDAMENITYTYPE = 'LOADING_ADDAMENITYTYPE'
export const RESET_ADDAMENITYTYPE = 'RESET_ADDAMENITYTYPE'
export const ADDAMENITYTYPE_NETWORKERROR = 'ADDAMENITYTYPE_NETWORKERROR'

//Get Amenity Type
export const GETAMENITYTYPE_SUCCESS = 'GETAMENITYTYPE_SUCCESS'
export const GETAMENITYTYPE_FAILED = 'GETAMENITYTYPE_FAILED'
export const LOADING_GETAMENITYTYPE = 'LOADING_GETAMENITYTYPE'
export const RESET_GETAMENITYTYPE = 'RESET_GETAMENITYTYPE'
export const GETAMENITYTYPE_NETWORKERROR = 'GETAMENITYTYPE_NETWORKERROR'

//Update Amenity Type
export const UPDATEAMENITYTYPE_SUCCESS = 'UPDATEAMENITYTYPE_SUCCESS'
export const UPDATEAMENITYTYPE_FAILED = 'UPDATEAMENITYTYPE_FAILED'
export const LOADING_UPDATEAMENITYTYPE = 'LOADING_UPDATEAMENITYTYPE'
export const RESET_UPDATEAMENITYTYPE = 'RESET_UPDATEAMENITYTYPE'
export const UPDATEAMENITYTYPE_NETWORKERROR = 'UPDATEAMENITYTYPE_NETWORKERROR'

//Delete Amenity Type
export const DELETEAMENITYTYPE_SUCCESS = 'DELETEAMENITYTYPE_SUCCESS'
export const DELETEAMENITYTYPE_FAILED = 'DELETEAMENITYTYPE_FAILED'
export const LOADING_DELETEAMENITYTYPE = 'LOADING_DELETEAMENITYTYPE'
export const RESET_DELETEAMENITYTYPE = 'RESET_DELETEAMENITYTYPE'
export const DELETEAMENITYTYPE_NETWORKERROR = 'DELETEAMENITYTYPE_NETWORKERROR'

//Add Amenity Message
export const ADD_AMENITY_MESSAGE_SUCCESS = 'ADD_AMENITY_MESSAGE_SUCCESS'
export const ADD_AMENITY_MESSAGE_FAILED = 'ADD_AMENITY_MESSAGE_FAILED'
export const LOADING_ADD_AMNEITY_MESSAGE = 'LOADING_ADD_AMNEITY_MESSAGE'
export const RESET_ADD_AMENITY_MESSAGE = 'RESET_ADD_AMENITY_MESSAGE'
export const ADD_AMENITY_MESSAGE_NETWORK_ERROR =
  'ADD_AMENITY_MESSAGE_NETWORK_ERROR'

//Get Amenity Message
export const GET_AMENITY_MESSAGE_SUCCESS = 'GET_AMENITY_MESSAGE_SUCCESS'
export const GET_AMENITY_MESSAGE_FAILED = 'GET_AMENITY_MESSAGE_FAILED'
export const LOADING_GET_AMENITY_MESSAGE = 'LOADING_GET_AMENITY_MESSAGE'
export const RESET_GET_AMENITY_MESSAGE = 'RESET_GET_AMENITY_MESSAGE'
export const GET_AMENITY_MESSAGE_NETWORK_ERROR =
  'GET_AMENITY_MESSAGE_NETWORK_ERROR'

//Add Document Type
export const ADD_DOCTYPE_SUCCESS = 'ADD_DOCTYPE_SUCCESS'
export const ADD_DOCTYPE_FAILED = 'ADD_DOCTYPE_FAILED'
export const LOADING_ADD_DOCTYPE = 'LOADING_ADD_DOCTYPE'
export const RESET_ADD_DOCTYPE = 'RESET_ADD_DOCTYPE'
export const ADD_DOCTYPE_NETWORK_ERROR = 'ADD_DOCTYPE_NETWORK_ERROR'

//Get Document Type
export const GET_DOCTYPE_SUCCESS = 'GET_DOCTYPE_SUCCESS'
export const GET_DOCTYPE_FAILED = 'GET_DOCTYPE_FAILED'
export const LOADING_GET_DOCTYPE = 'LOADING_GET_DOCTYPE'
export const RESET_GET_DOCTYPE = 'RESET_GET_DOCTYPE'
export const GET_DOCTYPE_NETWORK_ERROR = 'GET_DOCTYPE_NETWORK_ERROR'

//Update Document Type
export const UPDATE_DOCTYPE_SUCCESS = 'UPDATE_DOCTYPE_SUCCESS'
export const UPDATE_DOCTYPE_FAILED = 'UPDATE_DOCTYPE_FAILED'
export const LOADING_UPDATE_DOCTYPE = 'LOADING_UPDATE_DOCTYPE'
export const RESET_UPDATE_DOCTYPE = 'RESET_UPDATE_DOCTYPE'
export const UPDATE_DOCTYPE_NETWORK_ERROR = 'UPDATE_DOCTYPE_NETWORK_ERROR'

//Delete Document Type
export const DELETE_DOCTYPE_SUCCESS = 'DELETE_DOCTYPE_SUCCESS'
export const DELETE_DOCTYPE_FAILED = 'DELETE_DOCTYPE_FAILED'
export const LOADING_DELETE_DOCTYPE = 'LOADING_DELETE_DOCTYPE'
export const RESET_DELETE_DOCTYPE = 'RESET_DELETE_DOCTYPE'
export const DELETE_DOCTYPE_NETWORK_ERROR = 'DELETE_DOCTYPE_NETWORK_ERROR'

//Get SMS Provider
export const GET_SMSPROVIDER_SUCCESS = 'GET_SMSPROVIDER_SUCCESS'
export const GET_SMSPROVIDER_FAILED = 'GET_SMSPROVIDER_FAILED'
export const LOADING_GET_SMSPROVIDER = 'LOADING_GET_SMSPROVIDER'
export const RESET_GET_SMSPROVIDER = 'RESET_GET_SMSPROVIDER'
export const GET_SMSPROVIDER_NETWORK_ERROR = 'GET_SMSPROVIDER_NETWORK_ERROR'

//Change SMS Provider
export const CHANGE_SMSPROVIDER_SUCCESS = 'CHANGE_SMSPROVIDER_SUCCESS'
export const CHANGE_SMSPROVIDER_FAILED = 'CHANGE_SMSPROVIDER_FAILED'
export const LOADING_CHANGE_SMSPROVIDER = 'LOADING_CHANGE_SMSPROVIDER'
export const RESET_CHANGE_SMSPROVIDER = 'RESET_CHANGE_SMSPROVIDER'
export const CHANGE_SMSPROVIDER_NETWORK_ERROR =
  'CHANGE_SMSPROVIDER_NETWORK_ERROR'

//Add Unit Document Type
export const ADD_UNIT_DOCTYPE_SUCCESS = 'ADD_UNIT_DOCTYPE_SUCCESS'
export const ADD_UNIT_DOCTYPE_FAILED = 'ADD_UNIT_DOCTYPE_FAILED'
export const LOADING_ADD_UNIT_DOCTYPE = 'LOADING_ADD_UNIT_DOCTYPE'
export const RESET_ADD_UNIT_DOCTYPE = 'RESET_ADD_UNIT_DOCTYPE'
export const ADD_UNIT_DOCTYPE_NETWORK_ERROR = 'ADD_UNIT_DOCTYPE_NETWORK_ERROR'

//Get Unit Document Type
export const GET_UNIT_DOCTYPE_SUCCESS = 'GET_UNIT_DOCTYPE_SUCCESS'
export const GET_UNIT_DOCTYPE_FAILED = 'GET_UNIT_DOCTYPE_FAILED'
export const LOADING_GET_UNIT_DOCTYPE = 'LOADING_GET_UNIT_DOCTYPE'
export const RESET_GET_UNIT_DOCTYPE = 'RESET_GET_UNIT_DOCTYPE'
export const GET_UNIT_DOCTYPE_NETWORK_ERROR = 'GET_UNIT_DOCTYPE_NETWORK_ERROR'

//Update Unit Document Type
export const UPDATE_UNIT_DOCTYPE_SUCCESS = 'UPDATE_UNIT_DOCTYPE_SUCCESS'
export const UPDATE_UNIT_DOCTYPE_FAILED = 'UPDATE_UNIT_DOCTYPE_FAILED'
export const LOADING_UPDATE_UNIT_DOCTYPE = 'LOADING_UNIT_UPDATE_DOCTYPE'
export const RESET_UPDATE_UNIT_DOCTYPE = 'RESET_UPDATE_UNIT_DOCTYPE'
export const UPDATE_UNIT_DOCTYPE_NETWORK_ERROR =
  'UPDATE_UNIT_DOCTYPE_NETWORK_ERROR'

//Delete Unit Document Type
export const DELETE_UNIT_DOCTYPE_SUCCESS = 'DELETE_UNIT_DOCTYPE_SUCCESS'
export const DELETE_UNIT_DOCTYPE_FAILED = 'DELETE_UNIT_DOCTYPE_FAILED'
export const LOADING_DELETE_UNIT_DOCTYPE = 'LOADING_DELETE_UNIT_DOCTYPE'
export const RESET_DELETE_UNIT_DOCTYPE = 'RESET_DELETE_UNIT_DOCTYPE'
export const DELETE_UNIT_DOCTYPE_NETWORK_ERROR =
  'DELETE_UNIT_DOCTYPE_NETWORK_ERROR'

//Update Member Details Type
export const UPDATE_MEMBER_DETAIL_SUCCESS = 'UPDATE_MEMBER_DETAIL_SUCCESS'
export const UPDATE_MEMBER_DETAIL_FAILED = 'UPDATE_MEMBER_DETAIL_FAILED'
export const LOADING_UPDATE_MEMBER_DETAIL = 'LOADING_UPDATE_MEMBER_DETAIL'
export const RESET_UPDATE_MEMBER_DETAIL = 'RESET_UPDATE_MEMBER_DETAIL'
export const UPDATE_MEMBER_DETAIL_NETWORK_ERROR =
  'UPDATE_MEMBER_DETAIL_NETWORK_ERROR'

//Add Helper
export const ADD_HELPER_SUCCESS = 'ADD_HELPER_SUCCESS'
export const ADD_HELPER_FAILED = 'ADD_HELPER_FAILED'
export const LOADING_ADD_HELPER = 'LOADING_ADD_HELPER'
export const RESET_ADD_HELPER = 'RESET_ADD_HELPER'
export const ADD_HELPER_NETWORK_ERROR = 'ADD_HELPER_NETWORK_ERROR'

//Get Helper List
export const GET_HELPERLIST_SUCCESS = 'GET_HELPERLIST_SUCCESS'
export const GET_HELPERLIST_FAILED = 'GET_HELPERLIST_FAILED'
export const LOADING_GET_HELPERLIST = 'LOADING_GET_HELPERLIST'
export const RESET_GET_HELPERLIST = 'RESET_GET_HELPERLIST'
export const GET_HELPERLIST_NETWORK_ERROR = 'GET_HELPERLIST_NETWORK_ERROR'

//Get Helper Details
export const GET_HELPERDETAILS_SUCCESS = 'GET_HELPERDETAILS_SUCCESS'
export const GET_HELPERDETAILS_FAILED = 'GET_HELPERDETAILS_FAILED'
export const LOADING_GET_HELPERDETAILS = 'LOADING_GET_HELPERDETAILS'
export const RESET_GET_HELPERDETAILS = 'RESET_GET_HELPERDETAILS'
export const GET_HELPERDETAILS_NETWORK_ERROR = 'GET_HELPERDETAILS_NETWORK_ERROR'

//Change Password
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'
export const LOADING_CHANGE_PASSWORD = 'LOADING_CHANGE_PASSWORD'
export const RESET_CHANGE_PASSWORD = 'RESET_CHANGE_PASSWORD'
export const CHANGE_PASSWORD_NETWORK_ERROR = 'CHANGE_PASSWORD_NETWORK_ERROR'

//Forgot Password
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED'
export const LOADING_FORGET_PASSWORD = 'LOADING_FORGET_PASSWORD'
export const RESET_FORGET_PASSWORD = 'RESET_FORGET_PASSWORD'
export const FORGET_PASSWORD_NETWORK_ERROR = 'FORGET_PASSWORD_NETWORK_ERROR'

//Add Service Type
export const ADDSERVICETYPE_SUCCESS = 'ADDSERVICETYPE_SUCCESS'
export const ADDSERVICETYPE_FAILED = 'ADDSERVICETYPE_FAILED'
export const LOADING_ADDSERVICETYPE = 'LOADING_ADDSERVICETYPE'
export const RESET_ADDSERVICETYPE = 'RESET_ADDSERVICETYPE'
export const ADDSERVICETYPE_NETWORK_ERROR = 'ADDSERVICETYPE_NETWORK_ERROR'

//Get Service Type

export const GETSERVICETYPE_SUCCESS = 'GETSERVICETYPE_SUCCESS'
export const GETSERVICETYPE_FAILED = 'GETSERVICETYPE_FAILED'
export const LOADING_GETSERVICETYPE = 'LOADING_GETSERVICETYPE'
export const RESET_GETSERVICETYPE = 'RESET_GETSERVICETYPE'
export const GETSERVICETYPE_NETWORK_ERROR = 'GETSERVICETYPE_NETWORK_ERROR'

//Add Service Type Category

export const ADD_SERVICETYPECATEGORY_SUCCESS = 'ADD_SERVICETYPECATEGORY_SUCCESS'
export const ADD_SERVICETYPECATEGORY_FAILED = 'ADD_SERVICETYPECATEGORY_FAILED'
export const LOADING_ADD_SERVICETYPECATEGORY = 'LOADING_ADD_SERVICETYPECATEGORY'
export const RESET_ADD_SERVICETYPECATEGORY = 'RESET_ADD_SERVICETYPECATEGORY'
export const ADD_SERVICETYPECATEGORY_NETWORK_ERROR =
  'ADD_SERVICETYPECATEGORY_NETWORK_ERROR'

//update service type

export const UPDATE_SERVICE_TYPE_SUCCESS = 'UPDATE_SERVICE_TYPE_SUCCESS'
export const UPDATE_SERVICE_TYPE_FAILED = 'UPDATE_SERVICE_TYPE_FAILED'
export const LOADING_UPDATE_SERVICE_TYPE = 'LOADING_UPDATE_SERVICE_TYPE'
export const RESET_UPDATE_SERVICE_TYPE = 'RESET_UPDATE_SERVICE_TYPE'
export const UPDATE_SERVICE_TYPE_NETWORK_ERROR =
  'UPDATE_SERVICE_TYPE_NETWORK_ERROR'
//Add helper Type

export const ADD_HELPER_TYPE_SUCCESS = 'ADD_HELPER_TYPE_SUCCESS'
export const ADD_HELPER_TYPE_FAILED = 'ADD_HELPER_TYPE_FAILED'
export const LOADING_ADD_HELPER_TYPE = 'LOADING_ADD_HELPER_TYPE'
export const RESET_ADD_HELPER_TYPE = 'RESET_ADD_HELPER_TYPE'
export const ADD_HELPER_TYPE_NETWORK_ERROR = 'ADD_HELPER_TYPE_NETWORK_ERROR'

//Get Helper Type

export const GET_HELPER_TYPE_SUCCESS = 'GET_HELPER_TYPE_SUCCESS'
export const GET_HEPLER_TYPE_FAILED = 'GET_HEPLER_TYPE_FAILED'
export const LOADING_GET_HEPLER_TYPE = 'LOADING_GET_HEPLER_TYPE'
export const RESET_GET_HELPER_TYPE = 'RESET_GET_HELPER_TYPE'
export const GET_HELPER_TYPE_NETWORK_ERROR = 'GET_HELPER_TYPE_NETWORK_ERROR'

//Update helper type
export const UPDATE_HELPER_TYPE_SUCCESS = 'UPDATE_HELPER_TYPE_SUCCESS'
export const UPDATE_HELPER_TYPE_FAILED = 'UPDATE_HELPER_TYPE_FAILED'
export const LOADING_UPDATE_HELPER_TYPE = 'LOADING_UPDATE_HELPER_TYPE'
export const RESET_UPDATE_HELPER_TYPE = 'RESET_UPDATE_HELPER_TYPE'
export const UPDATE_HELPER_TYPE_NETWORK_ERROR =
  'UPDATE_HELPER_TYPE_NETWORK_ERROR'

//Add Flat Unit List
export const ADD_UNIT_FLATLIST_SUCCESS = 'ADD_UNIT_FLATLIST_SUCCESS'
export const ADD_UNIT_FLATLIST_FAILED = 'ADD_UNIT_FLATLIST_FAILED'
export const LOADING_ADD_UNIT_FLATLIST = 'LOADING_ADD_UNIT_FLATLIST'
export const RESET_ADD_UNIT_FLATLIST = 'RESET_ADD_UNIT_FLATLIST'
export const ADD_UNIT_FLATLIST_NETWORK_ERROR = 'ADD_UNIT_FLATLIST_NETWORK_ERROR'

//Get Unit List
export const GET_UNIT_FLATLIST_SUCCESS = 'GET_UNIT_FLATLIST_SUCCESS'
export const GET_UNIT_FLATLIST_FAILED = 'GET_UNIT_FLATLIST_FAILED'
export const LOADING_GET_UNIT_FLATLIST = 'LOADING_GET_UNIT_FLATLIST'
export const RESET_GET_UNIT_FLATLIST = 'RESET_GET_UNIT_FLATLIST'
export const GET_UNIT_FLATLIST_NETWORK_ERROR = 'GET_UNIT_FLATLIST_NETWORK_ERROR'

//Update Unit flat
export const UPDATE_UNIT_FLATLIST_SUCCESS = 'UPDATE_UNIT_FLATLIST_SUCCESS'
export const UPDATE_UNIT_FLATLIST_FAILED = 'UPDATE_UNIT_FLATLIST_FAILED'
export const LOADING_UPDATE_UNIT_FLATLIST = 'LOADING_UPDATE_UNIT_FLATLIST'
export const RESET_UPDATE_UNIT_FLATLIST = 'RESET_UPDATE_UNIT_FLATlIST'
export const UPDATE_UNIT_FLATLIST_NETWORK_ERROR =
  'UPDATE_UNIT_FLATLIST_NETWORK_ERROR'

//Add Mandatory Documents
export const ADD_MANDATORY_DOC_SUCCESS = 'ADD_MANDATORY_DOC_SUCCESS'
export const ADD_MANDATORY_DOC_FAILED = 'ADD_MANDATORY_DOC_FAILED'
export const LOADING_ADD_MANDATORY_DOC = 'LOADING_ADD_MANDATORY_DOC'
export const RESET_ADD_MANDATORY_DOC = 'RESET_ADD_MANDATORY_DOC'
export const ADD_MANDATORY_DOC_NETWORK_ERROR = 'ADD_MANDATORY_DOC_NETWORK_ERROR'

//Update Profile
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED'
export const LOADING_UPDATE_PROFILE = 'LOADING_UPDATE_PROFILE'
export const RESET_UPDATE_PROFILE = 'RESET_UPDATE_PROFILE'
export const UPDATE_PROFILE_NETWORK_ERROR = 'UPDATE_PROFILE_NETWORK_ERROR'

//Application Message from Society User to User
export const APPLICATION_MESSAGE_SUCCESS = 'APPLICATION_MESSAGE_SUCCESS'
export const APPLICATION_MESSAGE_FAILED = 'APPLICATION_MESSAGE_FAILED'
export const LOADING_APPLICATION_MESSAGE = 'LOADING_APPLICATION_MESSAGE'
export const RESET_APPLICATION_MESSAGE = 'RESET_APPLICATION_MESSAGE'
export const APPLICATION_MESSAGE_NETWORK_ERROR =
  'APPLICATION_MESSAGE_NETWORK_ERROR'

//Update Society Document Status
export const UPDATE_SOCIETY_DOCUMENT_STATUS_SUCCESS =
  'UPDATE_SOCIETY_DOCUMENT_STATUS_SUCCESS'
export const UPDATE_SOCIETY_DOCUMENT_STATUS_FAILED =
  'UPDATE_SOCIETY_DOCUMENT_STATUS_FAILED'
export const LOADING_UPDATE_SOCIETY_DOCUMENT_STATUS =
  'LOADING_UPDATE_SOCIETY_DOCUMENT_STATUS'
export const RESET_UPDATE_SOCIETY_DOCUMENT_STATUS =
  'RESET_UPDATE_SOCIETY_DOCUMENT_STATUS'
export const UPDATE_SOCIETY_DOCUMENT_STATUS_NETWORK_ERROR =
  'UPDATE_SOCIETY_DOCUMENT_STATUS_NETWORK_ERROR'

//Add Guard
export const ADD_GUARD_SUCCESS = 'ADD_GUARD_SUCCESS'
export const ADD_GUARD_FAILED = 'ADD_GUARD_FAILED'
export const LOADING_ADD_GUARD = 'LOADING_ADD_GUARD'
export const RESET_ADD_GUARD = 'RESET_ADD_GUARD'
export const ADD_GUARD_NETWORK_ERROR = 'ADD_GUARD_NETWORK_ERROR'

//get guard list
export const GET_GUARD_LIST_SUCCESS = 'GET_GUARD_LIST_SUCCESS'
export const GET_GUARD_LIST_FAILED = 'GET_GUARD_LIST_FAILED'
export const RESET_GUARD_LIST = 'RESET_GUARD_LIST'
export const LOADING_GET_GUARD_LIST = 'LOADING_GET_GUARD_LIST'
export const GET_GUARD_LIST_NETWORK_ERROR = 'GUARD_LIST_NETWORK_ERROR'

//update Member role
export const UPDATE_MEMBER_ROLE_SUCCESS = 'UPDATE_MEMBER_ROLE_SUCCESS'
export const UPDATE_MEMBER_ROLE_FAILED = 'UPDATE_MEMBER_ROLE_FAILED'
export const RESET_UPDATE_MEMBER_ROLE = 'RESET_UPDATE_MEMBER_ROLE'
export const LOADING_UPDATE_MEMBER_ROLE = 'LOADING_UPDATE_MEMBER_ROLE'
export const UPDATE_MEMBER_ROLE_NETWORK_ERROR =
  'UPADTE_MEMBER_ROLE_NETWORK_ERROR'

//update unit document
export const UPDATE_UNIT_DOCUMENT_STATUS_SUCCESS =
  'UPDATE_UNIT_DOCUMENT_STATUS_SUCCESS'
export const UPDATE_UNIT_DOCUMENT_STATUS_FAILED = 'UPDATE_UNIT_STATUS_FAILED'
export const RESET_UPDATE_UNIT_DOCUMENT_STATUS =
  'RESET_UPDATE_UNIT_DOCUMENT_STATUS'
export const LOADING_UPDATE_UNIT_DOCUMENT_STATUS =
  'LOADING_UPDATE_UNIT_DOCUMENT_STATUS'
export const UPDATE_UNIT_DOCUMENT_STATUS_NETWORK_ERROR =
  'UPDATE_UNIT_DOCUMENT_NETWORK_ERROR'

// update member status
export const UPDATE_OWNER_STATUS_SUCCESS = 'UPDATE_OWNER_STATUS_SUCCESS'
export const UPDATE_OWNER_STATUS_FAILED = 'UPDATE_OWNER_STATUS_FAILED'
export const UPDATE_OWNER_STATUS_NETWORK_ERROR =
  'UPDATE_OWNER_STATUS_NETWORK_ERROR'
export const LOADING_UPDATE_OWNER_STATUS = 'LOADING_UPDATE_OWNER_STATUS'
export const RESET_UPDATE_OWNER_STATUS = 'RESET_UPDATE_OWNER_STATUS'

//get helper details

export const GET_HELEPER_DETAILS_SUCCESS = 'GET_HELEPER_DETAILS_SUCCESS'
export const GET_HELEPR_DETAILS_FAILED = 'GET_HELEPR_DETAILS_FAILED'
export const LOADING_GET_HELPER_DETAILS = 'LOADING_GET_HELPER_DETAILS'
export const GET_HELER_DETAILS_NETWORK_ERROR = 'GET_HELER_DETAILS_NETWORK_ERROR'
export const RESET_GET_HELPER_DETAILS = 'RESET_GET_HELPER_DETAILS'

//update Guard
export const UPDATE_GUARD_DETAILS_SUCCESS = 'UPDATE_GUARD_DETAILS_SUCCESS'
export const UPDATE_GUARD_DETAILS_FAILED = 'UPDATE_GUARD_DETAILS_FAILED'
export const UPDATE_GUARD_DETAILS_NETWORK_ERROR =
  'UPDATE_GUARD_DETAILS_NETWORK_ERROR'
export const LOADING_UPDATE_GUARD_DETAILS = 'LOADING_UPDATE_GUARD_DETAILS'
export const RESET_UPDATE_GUARD_DETAILS = 'RESET_UPDATE_GUARD_DETAILS'

//get Attendance

export const GET_HELPER_ATTENDANCE_SUCCESS = 'GET_HELPER_ATTENDANCE_SUCCESS'
export const GET_HELPER_ATTENDANCE_FAILED = 'GET_HELPER_ATTENDANCE_FAILED'
export const GET_HELPER_ATTENDANCE_NETWORK_ERROR =
  'GET_HELPER_ATTENDANCE_NETWORK_ERROR'
export const LOADING_GET_HELPER_ATTENDANCE = 'LOADING_GET_HELPER_ATTENDANCE'
export const RESET_GET_HELPER_ATTENDANCE = 'RESET_GET_HELPER_ATTENDANCE'

//Update Helper

export const UPDATE_HELPER_SUCCESS = 'UPDATE_HELPER_SUCCESS'
export const UPDATE_HELPER_FAILED = 'UPDATE_HELPER_FAILED'
export const UPDATE_HELPER_NETWORK_ERROR = 'UPDATE_HELPER_NETWORK_ERROR'
export const LOADING_UPDATE_HELPER = 'LOADING_UPDATE_HELPER'
export const RESET_UPDATE_HELPER = 'RESET_UPDATE_HELPER'

//Get Visitor Details

export const GET_VISITOR_DETAILS_SUCCESS = 'GET_VISITOR_DETAILS_SUCCESS'
export const GET_VISITOR_DETAILS_FAILED = 'GET_VISITOR_DETAILS_FAILED'
export const GET_VISITOR_DETAILS_NETWORK_ERR = 'GET_VISITOR_DETAILS_NETWORK_ERR'
export const LOADING_GET_VISITOR_DETAILS = 'LOADING_GET_VISITOR_DETAILS'
export const RESET_GET_VISITOR_DETAILS = 'RESET_GET_VISITOR_DETAILS'

//Get guard Attendance

export const GET_GUARD_ATTENDANCE_SUCCESS = 'GET_GUARD_ATTENDANCE_SUCCESS'
export const GET_GUARD_ATTENDANCE_FAILED = 'GET_GUARD_ATTENDANCE_FAILED'
export const GET_GUARD_ATTENDANCE_NETWORK_ERROR =
  'GET_GUARD_ATTENDANCE_NETWORK_ERROR'
export const LOADING_GET_GUARD_ATTENDANCE = 'LOADING_GET_GUARD_ATTENDANCE'
export const RESET_GET_GUARD_ATTENDANCE = 'RESET_GET_GUARD_ATTENDANCE'

//Get City List
export const GET_CITY_LIST_SUCCESS = 'GET_CITY_LIST_SUCCESS'
export const GET_CITY_LIST_FAILED = 'GET_CITY_LIST_FAILED'
export const GET_CITY_LIST_NETWORK_ERROR = 'GET_CITY_LIST_NETWORK_ERROR'
export const LOADING_GET_CITY_LIST = 'LOADING_GET_CITY_LIST'
export const RESET_GET_CITY_LIST = 'RESET_GET_CITY_LIST'

//Add Document
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS'
export const ADD_DOCUMENT_FAILED = 'ADD_DOCUMENT_FAILED'
export const ADD_DOCUMENT_NETWORK = 'ADD_DOCUMENT_NETWORK'
export const LOADING_ADD_DOCUMENT = 'LOADING_ADD_DOCUMENT'
export const RESET_ADD_DOCUMENT = 'RESET_ADD_DOCUMENT'
//Get Document
export const GET_DOCMENT_SUCCESS = 'GET_DOCMENT_SUCCESS'
export const GET_DOCUMENT_FAILED = 'GET_DOCUMENT_FAILED'
export const GET_DOCUMENT_NETWORK_ERROR = 'GET_DOCUMENT_NETWORK_ERROR'
export const LOADING_GET_DOCUMENT = 'LOADING_GET_DOCUMENT'
export const RESET_GET_DOCUMENT = 'RESET_GET_DOCUMENT'
// Update Document
export const UPDATE_DOCMENT_SUCCESS = 'UPDATE_DOCMENT_SUCCESS'
export const UPDATE_DOCUMENT_FAILED = 'UPDATE_DOCUMENT_FAILED'
export const UPDATE_DOCUMENT_NETWORK_ERROR = 'UPDATE_DOCUMENT_NETWORK_ERROR'
export const LOADING_UPDATE_DOCUMENT = 'LOADING_UPDATE_DOCUMENT'
export const RESET_UPDATE_DOCUMENT = 'RESET_UPDATE_DOCUMENT'
//Add Helper Document
export const ADD_HELPER_DOC_SUCCESS = 'ADD_HELPER_DOC_SUCCESS'
export const ADD_HELPER_DOC_FAILED = 'ADD_HELPER_DOC_FAILED'
export const ADD_HELPER_DOC_NETWORK_ERROR = 'ADD_HELPER_DOC_NETWORK_ERROR'
export const LOADING_ADD_HELPER_DOC = 'LOADING_ADD_HELPER_DOC'
export const RESET_ADD_HELPER_DOC = 'RESET_ADD_HELPER_DOC'
//Get Helper Document
export const GET_HELPER_DOC_SUCCESS = 'GET_HELPER_DOC_SUCCESS'
export const GET_HELPER_DOC_FAILED = 'GET_HELPER_DOC_FAILED'
export const GET_HELPER_DOC_NETWORK_ERROR = 'GET_HELPER_DOC_NETWORK_ERROR'
export const LOADING_GET_HELPER_DOC = 'LOADING_GET_HELPER_DOC'
export const RESET_GET_HELPER_DOC = 'RESET_GET_HELPER_DOC'
//Update Helper Document
export const UPDATE_HELPER_DOC_SUCCESS = 'UPDATE_HELPER_DOC_SUCCESS'
export const UPDATE_HELPER_DOC_FAILED = 'UPDATE_HELPER_DOC_FAILED'
export const UPDATE_HELPER_DOC_NETWORK_ERROR = 'UPDATE_HELPER_DOC_NETWORK_ERROR'
export const LOADING_UPDATE_HELPER_DOC = 'LOADING_UPDATE_HELPER_DOC'
export const RESET_UPDATE_HELPER_DOC = 'RESET_UPDATE_HELPER_DOC'
//Add guard Document
export const ADD_GUARD_DOC_SUCCESS = 'ADD_GUARD_DOC_SUCCESS'
export const ADD_GUARD_DOC_FAILED = 'ADD_GUARD_DOC_FAILED'
export const ADD_GUARD_DOC_NETWORK_ERROR = 'ADD_GUARD_DOC_NETWORK_ERROR'
export const LOADING_ADD_GUARD_DOC = 'LOADING_ADD_GUARD_DOC'
export const RESET_ADD_GUARD_DOC = 'RESET_ADD_GUARD_DOC'
//Get guard Document
export const GET_GUARD_DOC_SUCCESS = 'GET_GUARD_DOC_SUCCESS'
export const GET_GUARD_DOC_FAILED = 'GET_GUARD_DOC_FAILED'
export const GET_GUARD_DOC_NETWORK_ERROR = 'GET_GUARD_DOC_NETWORK_ERROR'
export const LOADING_GET_GUARD_DOC = 'LOADING_GET_GUARD_DOC'
export const RESET_GET_GUARD_DOC = 'RESET_GET_GUARD_DOC'
//Update guard Document
export const UPDATE_GUARD_DOC_SUCCESS = 'UPDATE_GUARD_DOC_SUCCESS'
export const UPDATE_GUARD_DOC_FAILED = 'UPDATE_GUARD_DOC_FAILED'
export const UPDATE_GUARD_DOC_NETWORK_ERROR = 'UPDATE_GUARD_DOC_NETWORK_ERROR'
export const LOADING_UPDATE_GUARD_DOC = 'LOADING_UPDATE_GUARD_DOC'
export const RESET_UPDATE_GUARD_DOC =
  'RESET_UPDARESET_UPDATE_GUARD_DOCTE_HELPER_DOC'
//Add feature
export const ADD_FEATURE_SUCCESS = 'ADD_FEATURE_SUCCESS'
export const ADD_FEATURE_FAILED = 'ADD_FEATURE_FAILED'
export const ADD_FEATURE_NETWORK_ERROR = 'ADD_FEATURE_NETWORK_ERROR'
export const LOADING_ADD_FEATURE = 'LOADING_ADD_FEATURE'
export const RESET_ADD_FEATURE = 'RESET_ADD_FEATURE'
//Get Feature
export const GET_FEATURE_SUCCESS = 'GET_FEATURE_SUCCESS'
export const GET_FEATURE_FAILED = 'GET_FEATURE_FAILED'
export const GET_FEATURE_NETWORK_ERROR = 'GET_FEATURE_NETWORK_ERROR'
export const LOADING_GET_FEATURE = 'LOADING_GET_FEATURE'
export const RESET_GET_FEATURE = 'RESET_GET_FEATURE'
//Update Feature
export const UPDATE_FEATURE_SUCCESS = 'UPDATE_FEATURE_SUCCESS'
export const UPDATE_FEATURE_FAILED = 'UPDATE_FEATURE_FAILED'
export const UPDATE_FEATURE_NETWORK_ERROR = 'UPDATE_FEATURE_NETWORK_ERROR'
export const LOADING_UPDATE_FEATURE = 'LOADING_UPDATE_FEATURE'
export const RESET_UPDATE_FEATURE = 'RESET_UPDATE_FEATURE'
//Add Sub Category
export const ADD_SUB_CATEGORY_SUCCESS = 'ADD_SUB_CATEGORY_SUCCESS'
export const ADD_SUB_CATEGORY_FAILED = 'ADD_SUB_CATEGORY_FAILED'
export const ADD_SUB_CATEGORY_NETWORK_ERROR = 'ADD_SUB_CATEGORY_NETWORK_ERROR'
export const LOADING_ADD_SUB_cATEGORY = 'LOADING_ADD_SUB_cATEGORY'
export const RESET_ADD_SUB_CATEGORY = 'RESET_ADD_SUB_CATEGORY'
//Get Sub Category
export const GET_SUB_CATEGORY_SUCCESS = 'GET_SUB_CATEGORY_SUCCESS'
export const GET_SUB_CATEGORY_FAILED = 'GET_SUB_CATEGORY_FAILED'
export const GET_SUB_CATEGORY_NETWORK_ERROR = 'GET_SUB_CATEGORY_NETWORK_ERROR'
export const LOADING_GET_SUB_cATEGORY = 'LOADING_GET_SUB_cATEGORY'
export const RESET_GET_SUB_CATEGORY = 'RESET_GET_SUB_CATEGORY'
//Update Sub category
export const UPDATE_SUB_CATEGORY_SUCCESS = 'UPDATE_SUB_CATEGORY_SUCCESS'
export const UPDATE_SUB_CATEGORY_FAILED = 'UPDATE_SUB_CATEGORY_FAILED'
export const UPDATE_SUB_CATEGORY_NETWORK_ERROR =
  'UPDATE_SUB_CATEGORY_NETWORK_ERROR'
export const LOADING_UPDATE_SUB_cATEGORY = 'LOADING_UPDATE_SUB_cATEGORY'
export const RESET_UPDATE_SUB_CATEGORY = 'RESET_UPDATE_SUB_CATEGORY'
//Add Feature type
export const ADD_FEATURE_TYPE_SUCCESS = 'ADD_FEATURE_TYPE_SUCCESS'
export const ADD_FEATURE_TYPE_FAILED = 'ADD_FEATURE_TYPE_FAILED'
export const ADD_FEATURE_TYPE_NETWORK_ERROR = 'ADD_FEATURE_TYPE_NETWORK_ERROR'
export const LOADING_ADD_FEATURE_TYPE = 'LOADING_ADD_FEATURE_TYPE'
export const RESET_ADD_FEATURE_TYPE = 'RESET_ADD_FEATURE_TYPE'
//Get Feature Type
export const GET_FEATURE_TYPE_SUCCESS = 'GET_FEATURE_TYPE_SUCCESS'
export const GET_FEATURE_TYPE_FAILED = 'GET_FEATURE_TYPE_FAILED'
export const GET_FEATURE_TYPE_NETWORK_ERROR = 'GET_FEATURE_TYPE_NETWORK_ERROR'
export const LOADING_GET_FEATURE_TYPE = 'LOADING_GET_FEATURE_TYPE'
export const RESET_GET_FEATURE_TYPE = 'RESET_GET_FEATURE_TYPE'
//Update Feature Type
export const UPDATE_FEATURE_TYPE_SUCCESS = 'UPDATE_FEATURE_TYPE_SUCCESS'
export const UPDATE_FEATURE_TYPE_FAILED = 'UPDATE_FEATURE_TYPE_FAILED'
export const UPDATE_FEATURE_TYPE_NETWORK_ERROR =
  'UPDATE_FEATURE_TYPE_NETWORK_ERROR'
export const LOADING_UPDATE_FEATURE_TYPE = 'LOADING_UPDATE_FEATURE_TYPE'
export const RESET_UPDATE_FEATURE_TYPE = 'RESET_UPDATE_FEATURE_TYPE'
//Get Society Admin
export const GET_SOCIETY_ADMIN_SUCCESS = 'GET_SOCIETY_ADMIN_SUCCESS'
export const GET_SOCIETY_ADMIN_FAILED = 'GET_SOCIETY_ADMIN_FAILED'
export const GET_SOCIETY_ADMIN_NETWORK_ERROR = 'GET_SOCIETY_ADMIN_NETWORK_ERROR'
export const LOADING_GET_SOCIETY_ADMIN = 'LOADING_GET_SOCIETY_ADMIN'
export const RESET_GET_SOCIETY_ADMIN = 'RESET_GET_SOCIETY_ADMIN'
//Get helper Doc mandatory
export const GET_HELPER_DOC_MANDATORY_SUCCESS =
  'GET_HELPER_DOC_MANDATORY_SUCCESS'
export const GET_HELPER_DOC_MANDATORY_FAILED = 'GET_HELPER_DOC_MANDATORY_FAILED'
export const GET_HELPER_DOC_MANDATORY_NETWORK_ERROR =
  'GET_HELPER_DOC_MANDATORY_NETWORK_ERROR'
export const LOADING_GET_HELPER_DOC_MANDATORY =
  'LOADING_GET_HELPER_DOC_MANDATORY'
export const RESET_GET_HELPER_DOC_MANDATORY = 'RESET_GET_HELPER_DOC_MANDATORY'
//Add Dashboard
export const ADD_DASHBOARD_SUCCESS = 'ADD_DASHBOARD_SUCCESS'
export const ADD_DASHBOARD_FAILED = 'ADD_DASHBOARD_FAILED'
export const ADD_DASHBOARD_NETWORK_ERROR = 'ADD_DASHBOARD_NETWORK_ERROR'
export const LOADING_ADD_DASHBOARD = 'LOADING_ADD_DASHBOARD'
export const RESET_ADD_DASHBOARD = 'RESET_ADD_DASHBOARD'
//Get Dashboard
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS'
export const GET_DASHBOARD_FAILED = 'GET_DASHBOARD_FAILED'
export const GET_DASHBOARD_NETWORK_ERROR = 'GET_DASHBOARD_NETWORK_ERROR'
export const LOADING_GET_DASHBOARD = 'LOADING_GET_DASHBOARD'
export const RESET_GET_DASHBOARD = 'RESET_GET_DASHBOARD'
//Update Dashboard
export const UPDATE_DASHBOARD_LIST_SUCCESS = 'UPDATE_DASHBOARD_LIST_SUCCESS'
export const UPDATE_DASHBOARD_LIST_FAILED = 'UPDATE_DASHBOARD_LIST_FAILED'
export const UPDATE_DASHBOARD_LIST_NETWORK_ERROR =
  'UPDATE_DASHBOARD_LIST_NETWORK_ERROR'
export const LOADING_DASHBOARD_LIST = 'LOADING_DASHBOARD_LIST'
export const RESET_DASHBOARD_LIST = 'RESET_DASHBOARD_LIST'
//Add Helper Docs
export const ADD_HELPER_DOCUMENT_SUCCESS = 'ADD_HELPER_DOCUMENT_SUCCESS'
export const ADD_HELPER_DOCUMENT_FAILED = 'ADD_HELPER_DOCUMENT_FAILED'
export const ADD_HELPER_DOCUMENT_NETWORK_ERROR =
  'ADD_HELPER_DOCUMENT_NETWORK_ERROR'
export const LOADING_HELPER_DOCUMENT = 'LOADING_HELPER_DOCUMENT'
export const RESET_HELPER_DOCUMENT = 'RESET_HELPER_DOCUMENT'
//Add Dashboard Feature Type
export const ADD_DASHBOARD_FEATURETYPE_SUCCESS =
  'ADD_DASHBOARD_FEATURETYPE_SUCCESS'
export const ADD_DASHBOARD_FEATURETYPE_FAILED =
  'ADD_DASHBOARD_FEATURETYPE_FAILED'
export const ADD_DASHBOARD_FEATURETYPE_NETWORK_ERROR =
  'ADD_DASHBOARD_FEATURETYPE_NETWORK_ERROR'
export const LOADING_ADD_DASHBOARD_FEATURETYPE =
  'LOADING_ADD_DASHBOARD_FEATURETYPE'
export const RESET_ADD_DASHBOARD_FEATURETYPE = 'RESET_ADD_DASHBOARD_FEATURETYPE'
//Get VISITOR DASHBOARD
export const GET_VISITOR_DASHBOARD_SUCCESS = 'GET_VISITOR_DASHBOARD_SUCCESS'
export const GET_VISITOR_DASHBOARD_FAILED = 'GET_VISITOR_DASHBOARD_FAILED'
export const GET_VISITOR_DASHBOARD_NETWORK_ERROR =
  'GET_VISITOR_DASHBOARD_NETWORK_ERROR'
export const LOADING_GET_VISITOR_DASHBOARD = 'LOADING_GET_VISITOR_DASHBOARD'
export const RESET_GET_VISITOR_DASHBOARD = 'RESET_GET_VISITOR_DASHBOARD'

//Add Product Service Category
export const ADD_PRODUCT_SERVICE_CATEGORY_SUCCESS =
  'ADD_PRODUCT_SERVICE_CATEGORY_SUCCESS'
export const ADD_PRODUCT_SERVICE_CATEGORY_FAILED =
  'ADD_PRODUCT_SERVICE_CATEGORY_FAILED'
export const ADD_PRODUCT_SERVICE_CATEGORY_NETWORK_ERROR =
  'ADD_PRODUCT_SERVICE_CATEGORY_NETWORK_ERROR'
export const LOADING_ADD_PRODUCT_SERVICE_CATEGORY =
  'LOADING_ADD_PRODUCT_SERVICE_CATEGORY'
export const RESET_ADD_PRODUCT_SERVICE_CATEGORY =
  'RESET_ADD_PRODUCT_SERVICE_CATEGORY'

//Get Product service Category
export const GET_PRODUCT_SERVICE_CATEGORY_SUCCESS =
  'GET_PRODUCT_SERVICE_CATEGORY_SUCCESS'
export const GET_PRODUCT_SERVICE_CATEGORY_FAILED =
  'GET_PRODUCT_SERVICE_CATEGORY_FAILED'
export const GET_PRODUCT_SERVICE_CATEGORY_NETWORK_ERROR =
  'GET_PRODUCT_SERVICE_CATEGORY_NETWORK_ERROR'
export const LOADING_GET_PRODUCT_SERVICE_CATEGORY =
  'LOADING_GET_PRODUCT_SERVICE_CATEGORY'
export const RESET_GET_PRODUCT_SERVICE_CATEGORY =
  'RESET_GET_PRODUCT_SERVICE_CATEGORY'

//Add Product Service Category
export const ADD_PRODUCT_SERVICE_SUB_CATEGORY_SUCCESS =
  'ADD_PRODUCT_SERVICE_SUB_CATEGORY_SUCCESS'
export const ADD_PRODUCT_SERVICE_SUB_CATEGORY_FAILED =
  'ADD_PRODUCT_SERVICE_SUB_CATEGORY_FAILED'
export const ADD_PRODUCT_SERVICE_SUB_CATEGORY_NETWORK_ERROR =
  'ADD_PRODUCT_SERVICE_SUB_CATEGORY_NETWORK_ERROR'
export const LOADING_ADD_PRODUCT_SERVICE_SUB_CATEGORY =
  'LOADING_ADD_PRODUCT_SERVICE_SUB_CATEGORY'
export const RESET_ADD_PRODUCT_SERVICE_SUB_CATEGORY =
  'RESET_ADD_PRODUCT_SERVICE_SUB_CATEGORY'

//Get Product service Category
export const GET_PRODUCT_SERVICE_SUB_CATEGORY_SUCCESS =
  'GET_PRODUCT_SERVICE_SUB_CATEGORY_SUCCESS'
export const GET_PRODUCT_SERVICE_SUB_CATEGORY_FAILED =
  'GET_PRODUCT_SERVICE_SUB_CATEGORY_FAILED'
export const GET_PRODUCT_SERVICE_SUB_CATEGORY_NETWORK_ERROR =
  'GET_PRODUCT_SERVICE_SUB_CATEGORY_NETWORK_ERROR'
export const LOADING_GET_PRODUCT_SERVICE_SUB_CATEGORY =
  'LOADING_GET_PRODUCT_SERVICE_SUB_CATEGORY'
export const RESET_GET_PRODUCT_SERVICE_SUB_CATEGORY =
  'RESET_GET_PRODUCT_SERVICE_SUB_CATEGORY'

// Update Society-List in SuperAdmin
export const UPDATE_SOCIETY_DETAILS_SUCCESS = 'UPDATE_SOCIETY_DETAILS_SUCCESS'
export const UPDATE_SOCIETY_DETAILS_FAILED = 'UPDATE_SOCIETY_DETAILS_FAILED'
export const UPDATE_SOCIETY_DETAILS_NETWORK_ERROR =
  'UPDATE_SOCIETY_DETAILS_NETWORK_ERROR'
export const LOADING_UPDATE_SOCIETY_DETAILS = 'LOADING_UPDATE_SOCIETY_DETAILS'
export const RESET_UPDATE_SOCIETY_DETAILS = 'RESET_UPDATE_SOCIETY_DETAILS'

// Add Members Profession
export const ADD_MEMBERS_PROFESSION_SUCCESS = 'ADD_MEMBERS_PROFESSION_SUCCESS'
export const ADD_MEMBERS_PROFESSION_FAILED = 'ADD_MEMBERS_PROFESSION_FAILED'
export const ADD_MEMBERS_PROFESSION_NETWORK_ERROR =
  'ADD_MEMBERS_PROFESSION_NETWORK_ERROR'
export const LOADING_ADD_MEMBERS_PROFESSION = 'LOADING_ADD_MEMBERS_PROFESSION'
export const RESET_ADD_MEMBERS_PROFESSION = 'RESET_ADD_MEMBERS_PROFESSION'

//GET MEMBERS PROFESSION
export const GET_MEMBERS_PROFESSION_SUCCESS = 'GET_MEMBERS_PROFESSION_SUCCESS'
export const GET_MEMBERS_PROFESSION_FAILED = 'GET_MEMBERS_PROFESSION_FAILED'
export const GET_MEMBERS_PROFESSION_NETWORK_ERROR =
  'GET_MEMBERS_PROFESSION_NETWORK_ERROR'
export const LOADING_GET_MEMBERS_PROFESSION = 'LOADING_GET_MEMBERS_PROFESSION'
export const RESET_GET_MEMBERS_PROFESSION = 'RESET_GET_MEMBERS_PROFESSION'

//ADD PROFESSION TYPE
export const ADD_PROFESSION_TYPE_SUCCESS = 'ADD_PROFESSION_TYPE_SUCCESS'
export const ADD_PROFESSION_TYPE_FAILED = 'ADD_PROFESSION_TYPE_FAILED'
export const ADD_PROFESSION_TYPE_NETWORK_ERROR =
  'ADD_PROFESSION_TYPE_NETWORK_ERROR'
export const LOADING_ADD_PROFESSION_TYPE = 'LOADING_ADD_PROFESSION_TYPE'
export const RESET_ADD_PROFESSION_TYPE = 'RESET_ADD_PROFESSION_TYPE'

//GET PROFESSION TYPE
export const GET_PROFESSION_TYPE_SUCESS = 'GET_PROFESSION_TYPE_SUCESS'
export const GET_PROFESSION_TYPE_FAILED = 'GET_PROFESSION_TYPE_FAILED'
export const GET_PROFESSION_TYPE_NETWORK_ERROR =
  'GET_PROFESSION_TYPE_NETWORK_ERROR'
export const LOADING_GET_PROFESSION_TYPE = 'LOADING_GET_PROFESSION_TYPE'
export const RESET_GET_PROFESSION_TYPE = 'RESET_GET_PROFESSION_TYPE'

//Amenity Chart Dashboard
export const GET_AMENITY_DASHBOARD_CHART_COUNT_SUCCESS =
  'GET_AMENITY_DASHBOARD_CHART_COUNT_SUCCESS'
export const GET_AMENITY_DASHBOARD_CHART_COUNT_FAILED =
  'GET_AMENITY_DASHBOARD_CHART_COUNT_FAILED'
export const GET_AMENITY_DASHBOARD_CHART_COUNT_NETWORK_ERROR =
  'GET_AMENITY_DASHBOARD_CHART_COUNT_NETWORK_ERROR'
export const LOADING_GET_AMENITY_DASHBOARD_CHART_COUNT =
  'LOADING_GET_AMENITY_DASHBOARD_CHART_COUNT'
export const RESET_GET_AMENITY_DASHBOARD_CHART_COUNT =
  'RESET_GET_AMENITY_DASHBOARD_CHART_COUNT'

//compalin graph

export const GET_COMPLAIN_GRAPH_SUCCESS = 'GET_COMPLAIN_GRAPH_SUCCESS'
export const GET_COMPLAIN_GRAPH_FAILED = 'GET_COMPLAIN_GRAPH_FAILED'
export const GET_COMPLAIN_GRAPH_NETWORK_ERROR =
  'GET_COMPLAIN_GRAPH_NETWORK_ERROR'
export const LOADING_GET_COMPLAIN_GRAPH = 'LOADING_GET_COMPLAIN_GRAPH'
export const RESET_GET_COMPLAIN_GRAPH = 'RESET_GET_COMPLAIN_GRAPH'

//Add Language
export const ADD_TYPE_LANGUAGE_SUCCESS = 'ADD_TYPE_LANGUAGE_SUCCESS'
export const ADD_TYPE_LANGUAGE_FAILED = 'ADD_TYPE_LANGUAGE_FAILED'
export const ADD_TYPE_LANGUAGE_NETWORK_ERROR = 'ADD_TYPE_LANGUAGE_NETWORK_ERROR'
export const LOADING_ADD_TYPE_LANGUAGE = 'LOADING_ADD_TYPE_LANGUAGE'
export const RESET_ADD_TYPE_LANGUAGE = 'RESET_ADD_TYPE_LANGUAGE'

//GET LANGUAGE
export const GET_TYPE_LANGUAGE_SUCCESS = 'GET_TYPE_LANGUAGE_SUCCESS'
export const GET_TYPE_LANGUAGE_FAILED = 'GET_TYPE_LANGUAGE_FAILED'
export const GET_TYPE_LANGUAGE_NETWORK_ERROR = 'GET_TYPE_LANGUAGE_NETWORK_ERROR'
export const LOADING_GET_TYPE_LANGUAGE_ERROR = 'LOADING_GET_TYPE_LANGUAGE_ERROR'
export const RESET_GET_TYPE_LANGUAGE = 'RESET_GET_TYPE_LANGUAGE'

//Get area search list
export const GET_AREA_SEARCH_LIST_SUCCESS = 'GET_AREA_SEARCH_LIST_SUCCESS'
export const GET_AREA_SEARCH_LIST_FAILED = 'GET_AREA_SEARCH_LIST_FAILED'
export const GET_AREA_SEARCH_LIST_NETWORK_ERROR =
  'GET_AREA_SEARCH_LIST_NETWORK_ERROR'
export const LOADING_GET_AREA_SEARCH_LIST = 'LOADING_GET_AREA_SEARCH_LIST'
export const RESET_GET_AREA_SEARCH_LIST = 'RESET_GET_AREA_SEARCH_LIST'

// RECENT ACTIVITY
export const GET_RECENT_ACTIVITY_SUCCESS = 'GET_RECENT_ACTIVITY_SUCCESS'
export const GET_RECENT_ACTIVITY_FAILED = 'GET_RECENT_ACTIVITY_FAILED'
export const LOADING_GET_RECENT_ACTIVITY = 'LOADING_GET_RECENT_ACTIVITY'
export const RESET_GET_RECENT_ACTIVITY = 'RESET_GET_RECENT_ACTIVITY'
export const GET_RECENT_ACTIVITY_NETWORK_ERROR =
  'GET_RECENT_ACTIVITY_NETWORK_ERROR'

// hELPER DOCUMENTS
export const GET_HELPER_DOCUMENT_SUCCESS = 'GET_HELPER_DOCUMENT_SUCCESS'
export const GET_HELPER_DOCUMENT_FAILED = 'GET_HELPER_DOCUMENT_FAILED'
export const GET_HELPER_DOCUMENT_NETWORK_ERROR =
  'GET_HELPER_DOCUMENT_NETWORK_ERROR'
export const LOADING_GET_HELPER_DOCUMENT = 'LOADING_GET_HELPER_DOCUMENT'
export const RESET_GET_HELPER_DOCUMENT = 'RESET_GET_HELPER_DOCUMENT'

// ADD MAINTENANCE TYPE
export const ADD_MAINTENANCE_TYPE_SUCCESS = 'ADD_MAINTENANCE_TYPE_SUCCESS'
export const ADD_MAINTENANCE_TYPE_FAILED = 'ADD_MAINTENANCE_TYPE_FAILED'
export const LOADING_ADD_MAINTENANCE_TYPE = 'LOADING_ADD_MAINTENANCE_TYPE'
export const RESET_ADD_MAINTENANCE_TYPE = 'RESET_ADD_MAINTENANCE_TYPE'
export const ADD_MAINTENANCE_TYPE_NETWORK_ERROR =
  'ADD_MAINTENANCE_TYPE_NETWORK_ERROR'

// GET MAINTENANCE TYPE
export const GET_MAINTENANCE_TYPE_SUCCESS = 'GET_MAINTENANCE_TYPE_SUCCESS'
export const GET_MAINTENANCE_TYPE_FAILED = 'GET_MAINTENANCE_TYPE_FAILED'
export const LOADING_GET_MAINTENANCE_TYPE = 'LOADING_GET_MAINTENANCE_TYPE'
export const RESET_GET_MAINTENANCE_TYPE = 'RESET_GET_MAINTENANCE_TYPE'
export const GET_MAINTENANCE_TYPE_NETWORK_ERROR =
  'GET_MAINTENANCE_TYPE_NETWORK_ERROR'

// ADD SOCIETY MAINTENANCE TYPE
export const ADD_SOCIETY_MNT_TYPE_SUCCESS = 'ADD_SOCIETY_MNT_TYPE_SUCCESS'
export const ADD_SOCIETY_MNT_TYPE_FAILED = 'ADD_SOCIETY_MNT_TYPE_FAILED'
export const LOADING_ADD_SOCIETY_MNT_TYPE = 'LOADING_ADD_SOCIETY_MNT_TYPE'
export const RESET_ADD_SOCIETY_MNT_TYPE = 'RESET_ADD_SOCIETY_MNT_TYPE'
export const ADD_SOCIETY_MNT_TYPE_NETWORKERROR =
  'ADD_SOCIETY_MNT_TYPE_NETWORKERROR'

// GET SOCIETY MAINTENANCE TYPE
export const GET_SOCIETY_MNT_TYPE_SUCCESS = 'GET_SOCIETY_MNT_TYPE_SUCCESS'
export const GET_SOCIETY_MNT_TYPE_FAILED = 'GET_SOCIETY_MNT_TYPE_FAILED'
export const LOADING_GET_SOCIETY_MNT_TYPE = 'LOADING_GET_SOCIETY_MNT_TYPE'
export const RESET_GET_SOCIETY_MNT_TYPE = 'RESET_GET_SOCIETY_MNT_TYPE'
export const GET_SOCIETY_MNT_TYPE_NETWORKERROR =
  'GET_SOCIETY_MNT_TYPE_NETWORKERROR'

//Single mnt invoice
export const SINGLE_MNT_INVOICE_SUCCESS = 'SINGLE_MNT_INVOICE_SUCCESS'
export const SINGLE_MNT_INVOICE_FAILED = 'SINGLE_MNT_INVOICE_FAILED'
export const LOADING_SINGLE_MNT_INVOICE = 'LOADING_SINGLE_MNT_INVOICE'
export const NETWORK_SINGLE_MNT_INVOICE = 'NETWORK_SINGLE_MNT_INVOICE'
export const RESET_SINGLE_MNT_INVOICE = 'RESET_SINGLE_MNT_INVOICE'
// ADD SOCIETY MAINTENANCE BILLING
export const ADD_MAINTENANCE_INVOICE_SUCCESS = 'ADD_MAINTENANCE_INVOICE_SUCCESS'
export const ADD_MAINTENANCE_INVOICE_FAILED = 'ADD_MAINTENANCE_INVOICE_FAILED'
export const LOADING_ADD_MAINTENANCE_INVOICE = 'LOADING_ADD_MAINTENANCE_INVOICE'
export const RESET_ADD_MAINTENANCE_INVOICE = 'RESET_ADD_MAINTENANCE_INVOICE'
export const ADD_MAINTENANCE_INVOICE_NETWORK_ERROR =
  'ADD_MAINTENANCE_INVOICE_NETWORK_ERROR'

//ADD INVOICE
export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS'
export const ADD_INVOICE_FAILED = 'ADD_INVOICE_FAILED'
export const ADD_INVOICE_NETWORK = 'ADD_INVOICE_NETWORK'
export const ADD_INVOICE_LOADING = 'ADD_INVOICE_LOADING'
export const ADD_INVOICE_RESET = 'ADD_INVOICE_RESET'

// GET SOCIETY MAINTENANCE BILLING
export const GET_MAINTENANCE_INVOICE_SUCCESS = 'GET_MAINTENANCE_INVOICE_SUCCESS'
export const GET_MAINTENANCE_INVOICE_FAILED = 'GET_MAINTENANCE_INVOICE_FAILED'
export const GET_MAINTENANCE_INVOICE_NETWORK_ERROR =
  'GET_MAINTENANCE_INVOICE_NETWORK_ERROR'
export const LOADING_GET_MAINTENANCE_INVOICE = 'LOADING_GET_MAINTENANCE_INVOICE'
export const RESET_GET_MAINTENANCE_INVOICE = 'RESET_GET_MAINTENANCE_INVOICE'

//generate Mnt Invoice
export const GENERATE_MNT_INVOICE_SUCCESS = 'GENERATE_MNT_INVOICE_SUCCESS'
export const GENERATE_MNT_INVOICE_FAILED = 'GENERATE_MNT_INVOICE_FAILED'
export const GENERATE_MNT_INVOICE_NETWORK_ERROR =
  'GENERATE_MNT_INVOICE_NETWORK_ERROR'
export const LOADING_GENERATE_MNT_INVOICE = 'LOADING_GENERATE_MNT_INVOICE'
export const RESET_GENERATE_MNT_INOVICE = 'RESET_GENERATE_MNT_INOVICE'

//Delete Mnt Invoice

export const DELETE_MNT_INVOICE_SUCCESS = 'DELETE_MNT_INVOICE_SUCCESS'
export const DELETE_MNT_INVOICE_FAILED = 'DELETE_MNT_INVOICE_FAILED'
export const DELETE_MNT_INVOICE_NETWORK_ERROR =
  'DELETE_MNT_INVOICE_NETWORK_ERROR'
export const LOADING_DELETE_MNT_INVOICE = 'LOADING_DELETE_MNT_INVOICE'
export const RESET_DELETE_MNT_INVOICE = 'RESET_DELETE_MNT_INVOICE'

//Add Parking
export const ADD_PARKING_SUCCESS = 'ADD_PARKING_SUCCESS'
export const ADD_PARKING_FAILED = 'ADD_PARKING_FAILED'
export const ADD_PARKING_NETWORK_ERROR = 'ADD_PARKING_NETWORK_ERROR'
export const LOADING_ADD_PARKING = 'LOADING_ADD_PARKING'
export const RESET_ADD_PARKING = 'RESET_ADD_PARKING'

//Get Parking
export const GET_PARKING_SUCCESS = 'GET_PARKING_SUCCESS'
export const GET_PARKING_FAILED = 'GET_PARKING_FAILED'
export const GET_PARKING_NETWORK_ERROR = 'GET_PARKING_NETWORK_ERROR'
export const LOADING_GET_PARKING = 'LOADING_GET_PARKING'
export const RESET_GET_PARKING = 'RESET_GET_PARKING'

//update Parking
export const UPDATE_PARKING_SUCCESS = 'UPDATE_PARKING_SUCCESS'
export const UPDATE_PARKING_FAILED = 'UPDATE_PARKING_FAILED'
export const UPDATE_PARKING_NETWORK_ERROR = 'UPDATE_PARKING_NETWORK_ERROR'
export const LOADING_UDPATE_PARKING = 'LOADING_UDPATE_PARKING'
export const RESET_UPDATE_PARKING = 'RESET_UPDATE_PARKING'

//Add Society Template
export const ADD_SOCIETY_TEMPLATE_SUCCESS = 'ADD_SOCIETY_TEMPLATE_SUCCESS'
export const ADD_SOCIETY_TEMPLATE_FAILED = 'ADD_SOCIETY_TEMPLATE_FAILED'
export const ADD_SOCIETY_TEMPLATE_NETWORK_ERROR =
  'ADD_SOCIETY_TEMPLATE_NETWORK_ERROR'
export const LOADING_ADD_SOCIETY_TEMPLATE = 'LOADING_ADD_SOCIETY_TEMPLATE'
export const RESET_ADD_SOCIETY_TEMPLATE = 'RESET_ADD_SOCIETY_TEMPLATE'

//Get Society Template
export const GET_SOCIETY_TEMPLATE_SUCCESS = 'GET_SOCIETY_TEMPLATE_SUCCESS'
export const GET_SOCIETY_TEMPLATE_FAILED = 'GET_SOCIETY_TEMPLATE_FAILED'
export const GET_SOCIETY_TEMPLATE_NETWORK_ERROR =
  'GET_SOCIETY_TEMPLATE_NETWORK_ERROR'
export const LOADING_GET_SOCIETY_TEMPLATE = 'LOADING_GET_SOCIETY_TEMPLATE'
export const RESET_GET_SOCIETY_TEMPLATE = 'RESET_GET_SOCIETY_TEMPLATE'

export const DELETE_SOC_MNT_TYPE_SUCCESS = 'DELETE_SOC_MNT_TYPE_SUCCESS'
export const DELETE_SOC_MNT_TYPE_FAILED = 'DELETE_SOC_MNT_TYPE_FAILED'
export const DELETE_SOC_MNT_TYPE_LOADING = 'DELETE_SOC_MNT_TYPE_LOADING'
export const DELETE_SOC_MNT_TYPE_NETWORK_ERROR =
  'DELETE_SOC_MNT_TYPE_NETWORK_ERROR'
export const DELETE_SOC_MNT_TYPE_RESET = 'DELETE_SOC_MNT_TYPE_RESET'
// Add Gateway
export const ADD_GATEWAY_SUCCESS = 'ADD_GATEWAY_SUCCESS'
export const ADD_GATEWAY_FAILED = 'ADD_GATEWAY_FAILED'
export const RESET_ADD_GATEWAY = 'RESET_ADD_GATEWAY'
export const LOADING_ADD_GATEWAY = 'LOADING_ADD_GATEWAY'
export const ADD_GATEWAY_NETWORK_ERROR = 'ADD_GATEWAY_NETWORK_ERROR'

// Get Gateway
export const GET_GATEWAY_SUCCESS = 'GET_GATEWAY_SUCCESS'
export const GET_GATEWAY_FAILED = 'GET_GATEWAY_FAILED'
export const RESET_GET_GATEWAY = 'RESET_GET_GATEWAY'
export const LOADING_GET_GATEWAY = 'LOADING_GET_GATEWAY'
export const GET_GATEWAY_NETWORK_ERROR = 'GET_GATEWAY_NETWORK_ERROR'

// Get Payment Modes
export const GET_PAYMENT_MODES_SUCCESS = 'GET_PAYMENT_MODES_SUCCESS'
export const GET_PAYMENT_MODES_FAILED = 'GET_PAYMENT_MODES_FAILED'
export const RESET_GET_PAYMENT_MODES = 'RESET_GET_PAYMENT_MODES'
export const LOADING_GET_PAYMENT_MODES = 'LOADING_GET_PAYMENT_MODES'
export const GET_PAYMENT_MODES_NETWORK_ERROR = 'GET_PAYMENT_MODES_NETWORK_ERROR'

// Process Pay
export const PROCESS_PAY_SUCCESS = 'PROCESS_PAY_SUCCESS'
export const PROCESS_PAY_FAILED = 'PROCESS_PAY_FAILED'
export const RESET_PROCESS_PAY = 'RESET_PROCESS_PAY'
export const LOADING_PROCESS_PAY = 'LOADING_PROCESS_PAY'
export const PROCESS_PAY_NETWORK_ERROR = 'PROCESS_PAY_NETWORK_ERROR'

// Delete Gateway
export const DELETE_GATEWAY_SUCCESS = 'DELETE_GATEWAY_SUCCESS'
export const DELETE_GATEWAY_FAILED = 'DELETE_GATEWAY_FAILED'
export const LOADING_DELETE_GATEWAY = 'LOADING_DELETE_GATEWAY'
export const RESET_DELETE_GATEWAY = 'RESET_DELETE_GATEWAY'
export const DELETE_GATEWAY_NETWORK_ERROR = 'DELETE_GATEWAY_NETWORK_ERROR'

// transaction details
export const GET_TRANSACTION_LIST_SUCCESS = 'GET_TRANSACTION_LIST_SUCCESS'
export const GET_TRANSACTION_LIST_FAILED = 'GET_TRANSACTIOLISTLS_FAILED'
export const LOADING_GET_TRANSACTION_LIST = 'LOADING_GET_TRANSALISTETAILS'
export const RESET_GET_TRANSACTION_LIST = 'RESET_GET_TRANSACTION_LIST'
export const GET_TRANSACTION_LIST_NETWORK_ERROR =
  'GET_TRANSACTION_LIST_NETWORK_ERROR'

// Transaction details
export const GET_TRANSACTION_DETAILS_SUCCESS = 'GET_TRANSACTION_DETAILS_SUCCESS'
export const GET_TRANSACTION_DETAILS_FAILED = 'GET_TRANSACTION_DETAILS_FAILED'
export const LOADING_GET_TRANSACTION_DETAILS = 'LOADING_GET_TRANSACTION_DETAILS'
export const RESET_GET_TRANSACTION_DETAILS = 'RESET_GET_TRANSACTION_DETAILS'
export const GET_TRANSACTION_DETAILS_NETWORK_ERROR =
  'GET_TRANSACTION_DETAILS_NETWORK_ERROR'

//Get All Security Alert List

export const GET_ALL_SECURITY_ALERT_LIST_SUCCESS = 'GET_ALL_SECURITY_ALERT_LIST_SUCCESS'
export const GET_ALL_SECURITY_ALERT_LIST_FAILED = 'GET_ALL_SECURITY_ALERT_LIST_FAILED'
export const LOADING_GET_ALL_SECURITY_ALERT_LIST = 'LOADING_GET_ALL_SECURITY_ALERT_LIST'
export const RESET_GET_ALL_SECURITY_ALERT_LIST = 'RESET_GET_ALL_SECURITY_ALERT_LIST'
export const GET_ALL_SECURITY_ALERT_LIST_NETWORKERROR =
  'GET_ALL_SECURITY_ALERT_LIST_NETWORKERROR'


// Get Guest List

export const GET_GUEST_LIST_SUCCESS = 'GET_GUEST_LIST_SUCCESS'
export const GET_GUEST_LIST_FAILED = 'GET_GUEST_LIST_FAILED'
export const LOADING_GET_GUEST_LIST = 'LOADING_GET_GUEST_LIST'
export const RESET_GET_GUEST_LIST = 'RESET_GET_GUEST_LIST'
export const GET_GUEST_LIST_NETWORKERROR = 'GET_GUEST_LIST_NETWORKERROR'

//Export Excel All Invoice

export const GET_EXPORT_EXCEL_INVOICE_SUCCESS = 'GET_EXPORT_EXCEL_INVOICE_SUCCESS'
export const GET_EXPORT_EXCEL_INVOICE_FAILED = 'GET_EXPORT_EXCEL_INVOICE_FAILED'
export const LOADING_GET_EXPORT_EXCEL_INVOICE = 'LOADING_GET_EXPORT_EXCEL_INVOICE'
export const RESET_GET_EXPORT_EXCEL_INVOICE = 'RESET_GET_EXPORT_EXCEL_INVOICE'
export const GET_EXPORT_EXCEL_INVOICE_NETWORKERROR = 'GET_EXPORT_EXCEL_INVOICE_NETWORKERROR'

// Update Admin User

export const UPDATE_ADMIN_USER_SUCCESS = 'UPDATE_ADMIN_USER_SUCCESS'
export const UPDATE_ADMIN_USER_FAILED = 'UPDATE_ADMIN_USER_FAILED'
export const LOADING_UPDATE_ADMIN_USER = 'LOADING_UPDATE_ADMIN_USER'
export const RESET_UPDATE_ADMIN_USER = 'RESET_UPDATE_ADMIN_USER'
export const UPDATE_ADMIN_USER_NETWORK_ERROR = 'UPDATE_ADMIN_USER_NETWORK_ERROR'


// Update Admin Society Role 

export const UPDATE_ADMIN_SOCIETY_ROLE_SUCCESS = 'UPDATE_ADMIN_SOCIETY_ROLE_SUCCESS'
export const UPDATE_ADMIN_SOCIETY_ROLE_FAILED = 'UPDATE_ADMIN_SOCIETY_ROLE_FAILED'
export const LOADING_UPDATE_ADMIN_SOCIETY_ROLE = 'LOADING_UPDATE_ADMIN_SOCIETY_ROLE'
export const RESET_UPDATE_ADMIN_SOCIETY_ROLE = 'RESET_UPDATE_ADMIN_SOCIETY_ROLE'
export const UPDATE_ADMIN_SOCIETY_ROLE_NETWORK_ERROR = 'UPDATE_ADMIN_SOCIETY_ROLE_NETWORK_ERROR'

//REFRESH TOKEN
export const REFRESH_AUTH_TOKEN_REQUEST = 'REFRESH_AUTH_TOKEN_REQUEST'
export const REFRESH_AUTH_TOKEN_SUCCESS = 'REFRESH_AUTH_TOKEN_SUCCESS'
export const REFRESH_AUTH_TOKEN_FAILED = 'REFRESH_AUTH_TOKEN_FAILED'
export const REFRESH_AUTH_TOKEN_COMPLETED = 'REFRESH_AUTH_TOKEN_COMPLETED'
export const REFRESH_AUTH_TOKEN_NETWORK_ERROR = 'REFRESH_AUTH_TOKEN_NETWORK_ERROR'

// Badge Count

export const GET_BADGE_COUNT_SUCCESS = 'GET_BADGE_COUNT_SUCCESS'
export const GET_BADGE_COUNT_FAILED = 'GET_BADGE_COUNT_FAILED'
export const LOADING_GET_BADGE_COUNT = 'LOADING_GET_BADGE_COUNT'
export const RESET_GET_BADGE_COUNT = 'RESET_GET_BADGE_COUNT'
export const GET_BADGE_COUNT_NETWORKERROR = 'GET_BADGE_COUNT_NETWORKERROR'

// Logout 

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'
export const LOADING_LOGOUT = 'LOADING_LOGOUT'
export const RESET_LOGOUT = 'RESET_LOGOUT'
export const LOGOUT_NETWORKERROR = 'LOGOUT_NETWORKERROR'

// Error Page

export const IS_SESSION = "IS_SESSION" 

// AddUnitType

export const ADD_UNIT_TYPE_SUCCESS = 'ADD_UNIT_TYPE_SUCCESS'
export const ADD_UNIT_TYPE_FAILED = 'ADD_UNIT_TYPE_FAILED'
export const LOADING_ADD_UNIT_TYPE = 'LOADING_ADD_UNIT_TYPE'
export const RESET_ADD_UNIT_TYPE = 'RESET_ADD_UNIT_TYPE'
export const ADD_UNIT_TYPE_NETWORKERROR = 'ADD_UNIT_TYPE_NETWORKERROR'

// GetAllUnitType

export const GET_ALL_UNIT_TYPE_SUCCESS = 'GET_ALL_UNIT_TYPE_SUCCESS'
export const GET_ALL_UNIT_TYPE_FAILED = 'GET_ALL_UNIT_TYPE_FAILED'
export const LOADING_GET_ALL_UNIT_TYPE = 'LOADING_GET_ALL_UNIT_TYPE'
export const RESET_GET_ALL_UNIT_TYPE = 'RESET_GET_ALL_UNIT_TYPE'
export const GET_ALL_UNIT_TYPE_NETWORKERROR = 'GET_ALL_UNIT_TYPE_NETWORKERROR'

// UpdateUnitType

export const UPDATE_UNIT_TYPE_SUCCESS = 'UPDATE_UNIT_TYPE_SUCCESS'
export const UPDATE_UNIT_TYPE_FAILED = 'UPDATE_UNIT_TYPE_FAILED'
export const LOADING_UPDATE_UNIT_TYPE = 'LOADING_UPDATE_UNIT_TYPE'
export const RESET_UPDATE_UNIT_TYPE = 'RESET_UPDATE_UNIT_TYPE'
export const UPDATE_UNIT_TYPE_NETWORKERROR = 'UPDATE_UNIT_TYPE_NETWORKERROR'

//AddUnitSize

export const ADD_UNIT_SIZE_SUCCESS = 'ADD_UNIT_SIZE_SUCCESS'
export const ADD_UNIT_SIZE_FAILED = 'ADD_UNIT_SIZE_FAILED'
export const LOADING_ADD_UNIT_SIZE = 'LOADING_ADD_UNIT_SIZE'
export const RESET_ADD_UNIT_SIZE = 'RESET_ADD_UNIT_SIZE'
export const ADD_UNIT_SIZE_NETWORKERROR = 'ADD_UNIT_SIZE_NETWORKERROR'

// UpdateUnitSize

export const UPDATE_UNIT_SIZE_SUCCESS = 'UPDATE_UNIT_SIZE_SUCCESS'
export const UPDATE_UNIT_SIZE_FAILED = 'UPDATE_UNIT_SIZE_FAILED'
export const LOADING_UPDATE_UNIT_SIZE = 'LOADING_UPDATE_UNIT_SIZE'
export const RESET_UPDATE_UNIT_SIZE = 'RESET_UPDATE_UNIT_SIZE'
export const UPDATE_UNIT_SIZE_NETWORKERROR = 'UPDATE_UNIT_SIZE_NETWORKERROR'

// GetAllUnitSize

export const GET_ALL_UNIT_SIZE_SUCCESS = 'GET_ALL_UNIT_SIZE_SUCCESS'
export const GET_ALL_UNIT_SIZE_FAILED = 'GET_ALL_UNIT_SIZE_FAILED'
export const LOADING_GET_ALL_UNIT_SIZE = 'LOADING_GET_ALL_UNIT_SIZE'
export const RESET_GET_ALL_UNIT_SIZE = 'RESET_GET_ALL_UNIT_SIZE'
export const GET_ALL_UNIT_SIZE_NETWORKERROR = 'GET_ALL_UNIT_SIZE_NETWORKERROR'

// AddSocietyInterest

export const ADD_SOCIETY_INTEREST_SUCCESS = 'ADD_SOCIETY_INTEREST_SUCCESS'
export const ADD_SOCIETY_INTEREST_FAILED = 'ADD_SOCIETY_INTEREST_FAILED'
export const LOADING_ADD_SOCIETY_INTEREST = 'LOADING_ADD_SOCIETY_INTEREST'
export const RESET_ADD_SOCIETY_INTEREST = 'RESET_ADD_SOCIETY_INTEREST'
export const ADD_SOCIETY_INTEREST_NETWORKERROR = 'ADD_SOCIETY_INTEREST_NETWORKERROR'

// UpdateSocietyInterest

export const UPDATE_SOCIETY_INTEREST_SUCCESS = 'UPDATE_SOCIETY_INTEREST_SUCCESS'
export const UPDATE_SOCIETY_INTEREST_FAILED = 'UPDATE_SOCIETY_INTEREST_FAILED'
export const LOADING_UPDATE_SOCIETY_INTEREST = 'LOADING_UPDATE_SOCIETY_INTEREST'
export const RESET_UPDATE_SOCIETY_INTEREST = 'RESET_UPDATE_SOCIETY_INTEREST'
export const UPDATE_SOCIETY_INTEREST_NETWORKERROR = 'UPDATE_SOCIETY_INTEREST_NETWORKERROR'

// GetSocietyInterest

export const GET_SOCIETY_INTEREST_SUCCESS = 'GET_SOCIETY_INTEREST_SUCCESS'
export const GET_SOCIETY_INTEREST_FAILED = 'GET_SOCIETY_INTEREST_FAILED'
export const LOADING_GET_SOCIETY_INTEREST = 'LOADING_GET_SOCIETY_INTEREST'
export const RESET_GET_SOCIETY_INTEREST = 'RESET_GET_SOCIETY_INTEREST'
export const GET_SOCIETY_INTEREST_NETWORKERROR = 'GET_SOCIETY_INTEREST_NETWORKERROR'

// DeleteSocietyInterest

export const DELETE_SOCIETY_INTEREST_SUCCESS = 'DELETE_SOCIETY_INTEREST_SUCCESS'
export const DELETE_SOCIETY_INTEREST_FAILED = 'DELETE_SOCIETY_INTEREST_FAILED'
export const LOADING_DELETE_SOCIETY_INTEREST = 'LOADING_DELETE_SOCIETY_INTEREST'
export const RESET_DELETE_SOCIETY_INTEREST = 'RESET_DELETE_SOCIETY_INTEREST'
export const DELETE_SOCIETY_INTEREST_NETWORKERROR = 'DELETE_SOCIETY_INTEREST_NETWORKERROR'

//AmenityListCalendar

export const GETAMENITYLISTCALENDAR_SUCCESS = 'GETAMENITYLISTCALENDAR_SUCCESS'
export const GETAMENITYLISTCALENDAR_FAILED = 'GETAMENITYLISTCALENDAR_FAILED'
export const LOADING_GETAMENITYLISTCALENDAR = 'LOADING_GETAMENITYLISTCALENDAR'
export const RESET_GETAMENITYLISTCALENDAR = 'RESET_GETAMENITYLISTCALENDAR'
export const GETAMENITYLISTCALENDAR_NETWORKERROR = 'GETAMENITYLISTCALENDAR_NETWORKERROR'



