import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  ModalFooter,
} from 'reactstrap'
import {
  getNoticeList,
  resetgetNoticeList,
} from 'redux/actions/getNoticeListAction'
import { getGateway, resetGetGateway } from 'redux/actions/getGatewayAction'
import AddGateway from './AddGateway'
import ViewGatewayDetails from './ViewGatewayDetails'
import EditGateway from './EditGateway'
import { deleteGateway } from 'redux/actions/deleteGatewayAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import viewImg from 'assets/img/Icons/Privacy/show_pwd.png'
import updateImg from 'assets/img/Brand/buttons/edit-icon.png'
import deleteImg from 'assets/img/Brand/buttons/trash-icon.png'
import { Alert } from 'antd'
import PaginationComponent from 'views/Pagination'

const GatewayMasterDetails = (onTap) => {
  // useSelector
  const addGatewaySel = useSelector(({ addGateway }) => addGateway)
  const deleteGatewaySel = useSelector(({ deleteGateway }) => deleteGateway)

  const [modal, setModal] = useState(false)
  const [deletesGatewayD, setDeleteGatewayD] = useState('')
  const [societyName , setSocietyName] = useState('')
  const [view, setView] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [errMessage, seterrMessage] = useState('')

  const [update, setUpdate] = useState('')
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [updateErrMsg, setUpdateErrMsg] = useState('')

  const [limit] = useState(6)
  const [page, setPage] = useState(1)
  //
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  // useSelector
  const getGatewayList = useSelector(({ getGateway }) => getGateway)

  const totalItems = getGatewayList.data.count

  // Request
  const getGatewayListRequest = () => {
    const getGatewayListReq = {
      initiatedBy: login.data.user.role,
      limit,
      page
    }
    return dispatch(getGateway(getGatewayListReq))
  }

  const deleteGatewayRequest = (id) => {
    const deleteGatewayReq = {
      initiatedBy: login.data.user.role,
      acctId: id,
    }
    return dispatch(deleteGateway(deleteGatewayReq))
  }

  const handleSubmit = (e) =>{
    deleteGatewayRequest(e)
  }

  console.log(getGatewayList, 'Its the Selector')
  console.log(login, 'Login')

  // const deleteBtn = (d) => {
  //   deleteGatewayRequest(d)
  // }

  const toggleDetails = () => {
    setModal(!modal)
  }

  const viewModalBtn = (d) => {
    setView(d)
    setOpenModal(true)
  }
  const updateModalBtn = (d) => {
    setUpdate(d)
    setEditModal(true)
  }

  const deleteModalBtn = (d) => {
    setDeleteGatewayD(d.acctId)
    setSocietyName(d.displayName)
    setDeleteModal(true)
  }

  const toggleDelete = () =>{
    setDeleteModal(!deleteModal)
  }

  const toggleModal = () => {
    seterrMessage('')
    setOpenModal(!openModal)
  }

  const updateToggleModal = () => {
    setUpdateErrMsg('')
    setEditModal(!editModal)
  }

  useEffect(() => {
    getGatewayListRequest()
  }, [])

  useEffect(() => {
    // if (addGatewaySel.success) {
    //   getGatewayListRequest()
    //   setModal(false)
    //   setEditModal(false)
    // }
    if (addGatewaySel.success) {
      getGatewayListRequest()
      setModal(false)
      setEditModal(false)
      NotificationPopups('success', 'Success', addGatewaySel.data.message)
    }
    if (addGatewaySel.failed) {
      NotificationPopups('error', 'Failed', addGatewaySel.data.message)
    }
    if (addGatewaySel.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
  }, [addGatewaySel.success])

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  useEffect(() => {
    getGatewayListRequest()
  }, [page])

  useEffect(() => {
    setPage(1)
  }, [onTap])

  useEffect(() => {
    if (deleteGatewaySel.success) {
      setDeleteModal(false)
      NotificationPopups('success', 'Success', deleteGatewaySel.data.message)
      getGatewayListRequest()
    }
  }, [deleteGatewaySel.success])

  console.log(deletesGatewayD,"deletesGateway")

  return (
    <div className="listcontent">
      <Card>
        <CardBody>
          <Row style={{ justifyContent: 'center', marginTop: '10px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div
                style={{
                  float: 'right',
                  paddingRight: '10px',
                }}
              >
                <Button
                  color="secondary"
                  className="modalCustomButton"
                  onClick={() => {
                    setModal(!modal)
                    console.log(modal, 'modal')
                  }}
                >
                  Add New Integration
                </Button>
              </div>
              <Col>
                <div className="typeHeading" id="onTop">
                  Gateway Master Details
                </div>
              </Col>
            </div>
          </Row>
          <Row>
            {getGatewayList.success && (
              <Col md="12" style={{ padding: '20px', fontSize: '0.8rem' }}>
                <Table responsive style={{ textAlign: 'center' }}>
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Society Name</th>
                      <th>Payment Mode</th>
                      <th>Payment Brand</th>
                      <th>Integration Type</th>
                      <th>Member User Name/Number</th>
                      <th>Member ID/SID</th>
                      <th>Currency</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {getGatewayList.success && (
                    <tbody>
                      {getGatewayList.data.gatewayList.map((d, id) => (
                        <React.Fragment key={id}>
                          <tr key={id} id={`toggler${id}`}>
                            <td>{id + 1}</td>
                            <td>{d.displayName}</td>
                            <td>{d.acctPaymentMode}</td>
                            <td>{d.acctPaymentBrand}</td>
                            <td>{d.integrationType}</td>
                            <td>{d.accName}</td>
                            <td>{d.acctToId}</td>
                            <td>{d.accTerminalCurrency}</td>
                            <td>
                              <div style={{ display: 'flex', gap: '20px' }}>
                                <span
                                  title="View"
                                  style={{ margin: 'auto', cursor: 'pointer' }}
                                >
                                  <img
                                    src={viewImg}
                                    onClick={() => {
                                      viewModalBtn(d)
                                    }}
                                    style={{ width: '28px', height: '20px' }}
                                  />
                                </span>
                                <span
                                  title="Update"
                                  style={{ margin: 'auto', cursor: 'pointer' }}
                                >
                                  <img
                                    src={updateImg}
                                    onClick={() => {
                                      updateModalBtn(d)
                                    }}
                                    style={{ width: '28px', height: '20px' }}
                                  />
                                </span>
                                <span
                                  title="Delete"
                                  style={{ margin: 'auto', cursor: 'pointer' }}
                                >
                                  <img
                                    src={deleteImg}
                                    // onClick={deleteBtn(d)}
                                    // onClick={() => deleteBtn(d.acctId)}
                                    onClick={() => deleteModalBtn(d)}
                                    style={{ width: '28px', height: '20px' }}
                                  />
                                </span>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  )}
                </Table>
              </Col>
            )}
            <Col md="12">
              {getGatewayList.failed && (
                <div
                  className="col-12"
                  style={{ textAlign: 'center', marginTop: '30px' }}
                >
                  <Alert message={getGatewayList.data.message} type="info" />
                </div>
              )}
            </Col>

            <Col>
              {getGatewayList.success && (
                <div
                  style={{ marginLeft: '10px' }}
                >
                  <PaginationComponent
                    limit={limit}
                    totalItems={totalItems}
                    onSelect={handlePagination}
                    defaultPage={page}
                  />
                </div>
              )}
            </Col>

            {modal && (
              <Modal
                isOpen={modal}
                toggle={toggleDetails}
                size="lg"
                style={{ maxWidth: '1100px', maxHeight: '500px' }}
              >
                <ModalHeader toggle={toggleDetails}>
                  Add New Integration
                </ModalHeader>
                <ModalBody>
                  <AddGateway />
                </ModalBody>
              </Modal>
            )}
          </Row>
        </CardBody>
      </Card>

      {openModal && (
        <ViewGatewayDetails
          view={view}
          modal={openModal}
          toggle={toggleModal}
          errMessage={errMessage}
        />
      )}
      {editModal && (
        <EditGateway
          update={update}
          modal={editModal}
          toggle={updateToggleModal}
        // errMessage={updateErrMsg}
        />
      )}

      {
        deleteModal && (
          <Modal isOpen={deleteModal}  
          toggle={toggleDelete}   
          size="lg"
          style={{ maxWidth: '500px', maxHeight: '500px' }}>
            <ModalHeader toggle={toggleDelete}>Delete</ModalHeader>
            <ModalBody style={{textAlign:'center'}}>
             <span>You are sure you want to remove this integration? <br/>
             Deleting this will permanently remove integration with {societyName}
             </span>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={()=>handleSubmit(deletesGatewayD)}>
                Ok
              </Button>{' '}
              <Button color="secondary" onClick={toggleDelete}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        )
      }

    </div>
  )
}

export default GatewayMasterDetails
