import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Table,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Tooltip, Popconfirm, Alert, Switch } from 'antd'
import { Spin } from 'antd'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetUpdateDocument,
  updateDocument,
} from 'redux/actions/updateDocumentAction'
import { resetGetDocument, getDocument } from 'redux/actions/getDocumentAction'
import {
  resetdeleteDocType,
  deleteDocType,
} from 'redux/actions/deleteDocTypeAction'
import PaginationComponent from 'views/Pagination'
import AddDocument from './AddDocument'

function Document(onTap) {
  const [modal, setModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [editdocType, seteditdocType] = useState('')
  const [id, setId] = useState('')
  const [isActive, setisActive] = useState()
  const [isMandatory, setIsMandatory] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [limit] = useState(6)
  const [page, setPage] = useState(1)

  const docType = useSelector(({ getDocument }) => getDocument)
  const addeddocType = useSelector(({ addDocument }) => addDocument)
  const updatedDocType = useSelector(({ updateDocument }) => updateDocument)
  const login = useSelector(({ login }) => login)
  const [initiatedBy] = useState(login.data.user.role)
  const deletedDocType = useSelector(({ deleteDocType }) => deleteDocType)
  //   const updatedDocType = useSelector(({ updateDocument }) => updateDocument)
  console.log(docType, 'doctype')
  const dispatch = useDispatch()
  const pageSize = docType.data.pages
  const totalItems = docType.data.count

  const getDocTypeRequest = () => {
    if (initiatedBy === 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy: 'SuperAdmin',
        limit,
        page,
      }
      return dispatch(getDocument(docTypeReq))
    }
  }
  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }
  useEffect(() => {
    if (initiatedBy === 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy,
        limit,
        page,
      }
      return dispatch(getDocument(docTypeReq))
    }
  }, [page])

  useEffect(() =>{
    setPage(1)
  },[onTap])

  useEffect(() => {
    getDocTypeRequest()
  }, [addeddocType.success])

  useEffect(() => {
    if (updatedDocType.success) {
      seterrMessage('')
      setModal()
      NotificationPopups('success', 'Success', updatedDocType.data.message)
      getDocTypeRequest()
    }
    if (updatedDocType.failed) {
      seterrMessage(updatedDocType.data.message)
    }
    if (updatedDocType.network || deletedDocType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    if (deletedDocType.success) {
      NotificationPopups('success', 'Success', deletedDocType.data.message)
      getDocTypeRequest()
    }
    if (deletedDocType.failed) {
      NotificationPopups('error', 'Failed', deletedDocType.data.message)
    }

    return () => {
      dispatch(resetUpdateDocument())
    }
  }, [updatedDocType.success, updatedDocType.failed, updatedDocType.network])

  const handleDelete = (id) => {
    const deleteDocTypeReq = {
      id: id,
    }
    return dispatch(deleteDocType(deleteDocTypeReq))
  }

  const openEditModal = (type) => {
    setId(type.id)
    seteditdocType(type.type)
    setisActive(type.isActive)
    setModal(!modal)
  }

  const toggle = () => {
    seterrMessage('')
    setModal(!modal)
  }
  const toggleEdit = () => {
    setEditModal(!editModal)
  }

  const handleChange = ({ target: { name, value } }) => {
    //console.log(value)
    if (name === 'editdocType') seteditdocType(value)

    //console.log(value)
  }

  const handleEditDocType = () => {
    const editDocTypeReq = {
      id: id,
      type: editdocType.trim(),
      initiatedBy: 'SuperAdmin',
      isActive,
    }
    //console.log(editDocTypeReq)
    return dispatch(updateDocument(editDocTypeReq))
  }

  const onChange = (checked) => {
    setisActive(checked)
    console.log(`switch to ${checked}`)
  }

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Row>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={{ marginRight: '15px' }}>
                  <Button
                    color="secondary"
                    className="modalCustomButton"
                    onClick={() => {
                      setEditModal(!editModal)
                    }}
                  >
                    Add Document
                  </Button>
                </div>
                <Col>
                  <div className="typeHeading" id="onTop">
                    {' '}
                    Document List
                  </div>
                </Col>
              </div>

              {docType.success && (
                <Col md="12" style={{ padding: '20px' }}>
                  <Table responsive style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>Document </th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    {docType.success && (
                      <tbody>
                        {docType.data.activeType.map((d, id) => (
                          <React.Fragment key={id}>
                            <tr key={id} id={`toggler${id}`}>
                              <td>{d.type}</td>
                              <td>{d.isActive ? 'Active' : 'Inactive'}</td>
                              <td>
                                <Button
                                  color="secondary"
                                  className="modalCustomButton"
                                  onClick={() => {
                                    openEditModal(d)
                                  }}
                                >
                                  Edit
                                </Button>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    )}
                  </Table>
                </Col>
              )}
            </Row>
            <Col>
              {docType.success && !docType.data.activeType.length && (
                <div style={{ textAlign: 'center', padding: '20px 0px' }}>
                  <Alert message="No Active Document Type Found" type="info" />
                </div>
              )}
              {docType.failed && (
                <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                  <Alert message={docType.data.message} type="info" />
                </div>
              )}
            </Col>
            <Col>
              {docType.success && (
                <PaginationComponent
                limit={limit}
                totalItems={totalItems}
                onSelect={handlePagination}
                defaultPage={page}
              />
              )}
            </Col>
          </CardBody>
        </Card>
      </Col>
      {editModal && (
        <Modal
          isOpen={editModal}
          toggle={toggleEdit}
          size="sm"
          style={{ maxWidth: '350px' }}
        >
          <ModalHeader toggle={toggleEdit}>Add Document</ModalHeader>
          <ModalBody>
            <AddDocument />
          </ModalBody>
        </Modal>
      )}
      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="sm"
          centered
          style={{ maxWidth: '250px' , marginLeft:"auto", marginRight:"auto"}}
        >
          <ModalHeader toggle={toggle}>Edit</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="editdocType">Document Type</Label>
              <Input
                type="text"
                name="editdocType"
                id="editdocType"
                value={editdocType}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Status</Label>
              <br />
              <Switch
                defaultChecked={isActive}
                name="isActive"
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={onChange}
              />
            </FormGroup>
            <div style={{ textAlign: 'center' }} className="errorText">
              {errMessage}
            </div>
            <Button
              color="secondary"
              className="modalCustomButton"
              onClick={handleEditDocType}
              disabled={updatedDocType.isLoading || !editdocType}
            >
              {updatedDocType.isLoading ? <Spin /> : 'Edit'}
            </Button>
            <Button
              color="secondary"
              style={{ float: 'right' }}
              className="modalCustomButton"
              onClick={toggle}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>
      )}
    </Row>
  )
}

export default Document
