import axios from '../../config/axios'
import {
  GETBUILDINGLIST_SUCCESS,
  GETBUILDINGLIST_FAILED,
  LOADING_GETBUILDINGLIST,
  RESET_GETBUILDINGLIST,
  GETBUILDINGLIST_NETWORKERROR,
} from '../types'

const resetGetBuildingList = () => {
  return {
    type: RESET_GETBUILDINGLIST,
  }
}

const getBuildingList = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GETBUILDINGLIST })
  axios
    .post('society/getAreaList', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => 
      dispatch({ type: GETBUILDINGLIST_NETWORKERROR, payload: err })
    )
}

function success(data) {
  return {
    type: GETBUILDINGLIST_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GETBUILDINGLIST_FAILED,
    payload: data,
  }
}

export { resetGetBuildingList, getBuildingList }
