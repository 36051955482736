import moment from 'moment'

export default class Util {
  /*static uniqueIdGenerator(name) {
    const strSplit = name.split(" ")[0][0];
    const uniqueNumber = Math.floor(Math.random() * 10000000000);
    return "".concat(strSplit, uniqueNumber);
  }*/

  static formatDate(date) {
    // const d = date.split('-')
    // const Date = `${d[1]}-${d[0]}-${2}`
    const Date = moment(date, 'YYYY-MM-DD')
    return moment(Date).format('Do MMM,YYYY')
  }

  static dayMonthFormatDate(date) {
    // const d = date.split('-')
    // const Date = `${d[1]}-${d[0]}-${2}`
    const Date = moment(date, 'YYYY-MM-DD')
    return moment(Date).format('Do MMM')
  }

  static formatDateTime(d) {
    // d=new Date('30-12-20')
    return moment(d).format('Do MMM,YYYY h:mmm:ss A')
  }
  static formatDateTimeHour(d) {
    return moment(d).format('Do MMM,YYYY LTS')
  }

  static statusColor(status) {
    if (status === 'pending') {
      return 'orange'
    } else if (status === 'approved') {
      return 'green'
    } else if (status === 'rejected') {
      return 'red'
    }
  }

  //Returns Doc is Image or Not
  static attachmentType(a) {
    if (a) {
      const myArr = a.split(',')

      switch (myArr[0]) {
        case 'data:image/png;base64':
        case 'data:image/jpeg;base64':
        case 'data:image/jpg;base64':
        case 'data:image/webp;base64':
          return 'image'

        default:
          return 'notImage'
      }
    }
  }

  //Returns Icons for docs
  static iconType(a) {
    if (a) {
      const myArr = a.split(',')
      switch (myArr[0]) {
        case 'data:application/pdf;base64':
          return 'far fa-file-pdf'
        case 'data:application/msword;base64':
        case 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64':
          return 'far fa-file-word'

        default:
          return 'far fa-file-alt'
      }
    }
  }

  static verifyMobile(d) {
    // const m = /^(0|[1-9][0-9]*)$/
    const m = /^[0-9\b]*$/
    return m.test(d)
  }

  static verifyAlpahbet(d) {
    const m = /^[a-zA-Z ]*$/
    return m.test(d)
  }
  static alphaNumeric(d) {
    const m = /^([0-9A-Za-z]+\s?)*$/
    // const m = /^([0-9A-Za-z\b])*$/
    return m.test(d)
  }

  static aaddhaar(d) {
    const re = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;
    return re.test(d)
  }

  static pancard(d) {
    const regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
    return regex.test(d)
  }

  static verifyEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  static verifyName(name) {
    const re = /^[a-zA-Z ]*$/
    //const re = /[a-zA-Z][a-zA-Z ]/;
    //const re = /[a-zA-Z]/;
    return re.test(name)
  }

  /*static verifyAmount(amount) {
    const re = /^\d+(\.\d{0,2})?$/;
    return re.test(amount);
  }*/

  static verifyNumber(num) {
    const re = /^[0-9\b]+$/
    //const re = /^\d+$/g;
    return re.test(num)
  }

  /*static verifyDate(date) {
    const re = /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/;
    return re.test(date);
  }*/

  static verifyPassword(pwd) {
    const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[@#$%^*!])(?=\S+$).{8,20}$/
    return re.test(pwd)
  }

  /*static formatPhoneNumber(contact, code) {
    const reg = new RegExp(`\\+?s?${code}\\D+`);
    return contact.replace(reg, "").replace(/[^0-9]+/g, "");
  }*/

  /*static isImageUrl(url) {
    return /null$/.test(url);
  }*/

  /*static getFileName(url) {
    if (!url) return null;
    return url.match(/\w+.\w+$/g).join``;
  }*/

  /*static checkInputSpace(val) {
    return /^\s/.test(val);
  }*/

  /*static randomColor() {
    return (
      "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")
    );
  }*/
  static checkWhiteSpace(value) {
    const re = /^(?!\s)[a-zA-Z0-9_\s-]*$/
    return re.test(value)
  }
  static alphabetsOnlywithspaces(value) {
    const re = /^(?!\s)[a-zA-Z_\s-]*$/
    return re.test(value)
  }
  static input(value) {
    const re = /[^a-zA-Z0-9\-_\s]/g
    return re.test(value)
  }
  static noWhiteSpace(value) {
    const re = /^\S*$/
    return re.test(value)
  }

  static alphabetsOnly(value) {
    const re = /^(?!.*\S\s{2,})(?!\s)\s*(.*?)\s*$/
    return re.test(value) ? Util.alphabetsOnlywithspaces(value) ? true : false : false
  }

  static alphaNumOnly(value) {
    const re = /^[A-Za-z0-9]+ [A-Za-z0-9]+$/
    return re.test(value)
  }

  static decimalUpto2(value){
    const re = /^[0-9]*\.?[0-9]*$/ 
    return re.test(value)
  }

  // static imageUrl = process.env.NODE_ENV == 'development' ? "file:///E:/MySocietyServiceApp/mysocietyservicesapplicaton/documents/" : "https://mysocietyapp.paymentz.com/Documents/"
  static imageUrl = 'https://mysocietyapp.paymentz.com/Documents/'

  // static imageUrl = process.env.NODE_ENV == 'development' ? "file:///E:/apps/tomcat/tomcat8/webapps/Documents/" : "https://mysocietyapp.paymentz.com/Documents/"

  // static floorAndFlat(value) {
  //   const re =
  //     +(
  //       startRange.charAt(0) * (+fIdx + 1) +
  //       startRange.slice(1, startRange.length)
  //     ) + +rIdx
  //   return re.test(value)
  // }

  //regulax experssion for special characters and alphanumeric values
  // "/^(?!\s)[a-zA-Z0-9!@#$&()-`.+,/\"]*$"
}
