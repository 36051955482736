// authReducer.js

import {
    REFRESH_AUTH_TOKEN_REQUEST,
    REFRESH_AUTH_TOKEN_SUCCESS,
    REFRESH_AUTH_TOKEN_FAILED,
    REFRESH_AUTH_TOKEN_COMPLETED
} from "redux/types";

const initialState = {
    token: null,
    isRefreshing: false,
    error: null,
    isCompleted: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REFRESH_AUTH_TOKEN_REQUEST:
            return {
                ...state,
                isRefreshing: true,
                error: null,
            };

        case REFRESH_AUTH_TOKEN_SUCCESS:
            return {
                ...state,
                token: action.payload,
                isRefreshing: false,
                error: null,
            };

        case REFRESH_AUTH_TOKEN_FAILED:
            return {
                ...state,
                isRefreshing: false,
                error: action.payload,
            };

        case REFRESH_AUTH_TOKEN_COMPLETED:
            return {
                ...state,
                isCompleted: true
            }
        // Add other cases as needed, e.g., handling login, logout, etc.

        default:
            return state;
    }
};